import { ConfirmationHelper } from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"
import { COVERAGE_CONFIG, QuotaUsageType } from "../config/coverage.config"

export class CheckerHelper {
    /**
     * Run checks with quota confirmation popups if hit some limits
     */
    static runChecksWithQuotaConfirmation(
        usageQuota: IPracticeUsageQuota,
        onRunChecks: () => void | Promise<void>,
        quotaUsageType: QuotaUsageType,
        checksAmount: number
    ) {
        // don't make any validation for ignored check types
        if (COVERAGE_CONFIG.usageQuota.ignoredTypes.includes(quotaUsageType)) {
            return onRunChecks()
        }

        const neededLeftQuotaAmount =
            usageQuota.checkTypeCreditsAmount[quotaUsageType] *
            Math.max(1, checksAmount)

        const isHardQuotaPotentialHit =
            usageQuota.hardQuotaLeft - neededLeftQuotaAmount < 0

        const isSoftQuotaPotentialHit =
            usageQuota.softQuotaLeft - neededLeftQuotaAmount < 0

        if (!isHardQuotaPotentialHit && !isSoftQuotaPotentialHit) {
            return onRunChecks()
        }

        const onContactSupport = () => {
            window.location.href = `mailto:${GENERAL_CONFIG.supportEmail}`
        }

        if (isHardQuotaPotentialHit) {
            ConfirmationHelper.enableConfirmation(
                onContactSupport,
                "OV Check limit will be exceeded",
                [
                    "Running this check will exceed the maximum number of additional OneVerify checks allowed for your organization this month.",
                    "Your organization’s credits will reset on the 1st of next month.",
                    "Please note that API checks do not count towards the check limit.",
                ],
                undefined,
                "Contact support",
                undefined,
                false,
                {
                    isLeftAligned: true,
                    withCloseIcon: true,
                }
            )
        } else if (isSoftQuotaPotentialHit) {
            ConfirmationHelper.enableConfirmation(
                onRunChecks,
                "Monthly OV check limit reached",
                [
                    `Your organization has exceeded the ${new Intl.NumberFormat().format(
                        usageQuota.softQuotaLimit
                    )} included OneVerify checks for this month.`,
                    "Additional checks will be charged at a premium rate until your limit resets on the 1st of next month.",
                    "Please note that API checks do not count towards the check limit.",
                ],
                onContactSupport,
                "Run check anyway",
                "Contact support",
                true,
                {
                    isLeftAligned: true,
                    withCloseIcon: true,
                }
            )
        }
    }
}
