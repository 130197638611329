import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { PoliciesListType } from "../../config/policies.config"
import { ReportSavedFiltersService } from "../../services/reportSavedFilters.service"

/**
 * Get list of saved filters
 */
export const reportSavedFiltersGetList = createAsyncThunk<
    {
        reportType: PoliciesListType
        items: IReportSavedFilter[]
    },
    IThunkActionWithPracticeData<{
        reportType: PoliciesListType
    }>
>("reportSavedFiltersGetListPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("reportSavedFiltersGetListLoading"))

    const reportType = action.payload.reportType

    try {
        const result = await ReportSavedFiltersService.getList(
            action.practice,
            reportType
        )

        if (result) {
            action.onSuccess?.(result)
        } else {
            action.onError?.(result)
        }

        return {
            reportType,
            items: result || [],
        }
    } catch (e) {
        return {
            reportType,
            items: [],
        }
    } finally {
        action.onFinally?.()

        dispatch(runtimeStopLoading("reportSavedFiltersGetListLoading"))
    }
})

/**
 * Create saved filter
 */
export const reportSavedFiltersCreate = createAsyncThunk<
    {
        reportType: PoliciesListType
        item?: IReportSavedFilter
    },
    IThunkActionWithPracticeData<ICreateSavedFilterRequestData>
>("reportSavedFiltersCreatePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("reportSavedFiltersCreateLoading"))

    const reportType = action.payload.reportType

    try {
        const result = await ReportSavedFiltersService.create(
            action.practice,
            action.payload
        )

        if (result) {
            action.onSuccess?.(result)
        } else {
            action.onError?.(result)
        }

        return {
            reportType,
            item: result,
        }
    } catch (e) {
        return {
            reportType,
        }
    } finally {
        action.onFinally?.()

        dispatch(runtimeStopLoading("reportSavedFiltersCreateLoading"))
    }
})

/**
 * Delete saved filter
 */
export const reportSavedFiltersDelete = createAsyncThunk<
    {
        reportType: PoliciesListType
        deletedId?: number
    },
    IThunkActionWithPracticeData<{
        reportType: PoliciesListType
        filterId: number
    }>
>("reportSavedFiltersDeletePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("reportSavedFiltersDeleteLoading"))

    const reportType = action.payload.reportType

    try {
        const result = await ReportSavedFiltersService.delete(
            action.practice,
            action.payload.filterId
        )

        if (result) {
            action.onSuccess?.(result)
        } else {
            action.onError?.(result)
        }

        return {
            reportType,
            deletedId: result?.deletedId,
        }
    } catch (e) {
        return {
            reportType,
        }
    } finally {
        action.onFinally?.()

        dispatch(runtimeStopLoading("reportSavedFiltersDeleteLoading"))
    }
})
