import { UtilHelper } from "nirvana-react-elements"

import {
    AvailablePlanType,
    COVERAGE_CONFIG,
    CoveragePatientType,
} from "../config/coverage.config"
import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { CalculatorResultType } from "../config/checker.config"

export class CheckerService {
    /**
     * Run single coverage check
     */
    static async runContinuousMonitoringCoverageCheck(
        inputData: IContinuousMonitoringCoverageCheckInputData,
        practice: IPractice,
        willRetry: boolean,
        isInNetworkCheck = false
    ): Promise<ICoverageResult | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_GET_ESTIMATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            CheckerService.mapContinuousMonitoringCheckInputData(
                inputData,
                practice,
                isInNetworkCheck
            ),
            "POST",
            undefined,
            undefined,
            undefined,
            willRetry ? [400] : undefined
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Run smart scan coverage check
     */
    static async runSmartScanCoverageCheck(
        requestData: IGetCoverageEstimateData,
        practice: IPractice,
        inNetworkOverride = false
    ): Promise<ICoverageResult | undefined> {
        if (!requestData.firstName || !requestData.lastName) {
            return undefined
        }

        const {
            id,
            payerId,
            memberId,
            inNetwork,
            cptCode,
            sessionCharge,
            customerPatientId,
            ...rest
        } = requestData

        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_GET_SMART_SCAN_ESTIMATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...rest,

                customerPatientId: customerPatientId || undefined,

                cptCode:
                    cptCode || COVERAGE_CONFIG.defaultHealthProviderCptCode,

                sessionCharge:
                    sessionCharge ||
                    COVERAGE_CONFIG.defaultHealthProviderRate * 100,

                inNetwork:
                    typeof inNetwork === "undefined"
                        ? inNetworkOverride
                        : inNetwork,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Run intake coverage check
     */
    static async runIntakeCoverageCheck(
        inputData: IIntakeCheckerInputData,
        practice: IPractice
    ): Promise<ICommonCheckResult | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_GET_INTAKE_ESTIMATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            CheckerService.mapIntakeCheckInputData(inputData, practice),
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Run medicaid coverage check
     */
    static async runMedicaidCoverageCheck(
        inputData: IMedicaidCheckerInputData,
        practice: IPractice,
        willRetry: boolean
    ): Promise<ICoverageResult | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_GET_MEDICAID_ESTIMATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            CheckerService.mapMedicaidCheckInputDataToRequestData(
                inputData,
                practice
            ),
            "POST",
            undefined,
            undefined,
            undefined,
            willRetry ? [400] : undefined
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Live coverage check input data to estimate expected data - continuous monitoring
     */
    static mapContinuousMonitoringCheckInputData(
        inputData: IContinuousMonitoringCoverageCheckInputData,
        practice: IPractice,
        inNetworkCheckOverride?: boolean
    ): IGetCoverageEstimateData {
        const {
            customNpi,
            payer,
            memberId,
            sessionRate,
            inNetworkCheck,
            outNetworkCheck,
            firstName,
            lastName,
            customerPatientId,
            validationErrors,
            passThroughColumns,
            ...rest
        } = inputData

        return {
            ...rest,

            memberId: memberId || undefined,
            customerPatientId: customerPatientId || undefined,
            firstName: firstName || undefined,
            lastName: lastName || undefined,

            providerNpi: customNpi || practice.groupNPI,
            payerId: payer.id,

            inNetwork:
                typeof inNetworkCheckOverride !== "undefined"
                    ? inNetworkCheckOverride
                    : inNetworkCheck,

            // sessionRate is in $, should be converted to cents
            sessionCharge: UtilHelper.formatDollarsToCents(sessionRate),
        }
    }

    /**
     * Get default errored results
     */
    static getDefaultErrorCoverageResult(
        resultId: string,
        payer: IPayer | null,
        memberId?: string,
        isInNetworkCheck?: boolean
    ): ICoverageResult {
        return {
            resultId,
            memberId: memberId || "",
            payer,
            isInNetworkCheck:
                typeof isInNetworkCheck !== "undefined"
                    ? isInNetworkCheck
                    : true,

            // From ICoverageResult
            coverageQuotaExceeded: false,
            planType: AvailablePlanType.NOT_APPLICABLE,
            planName: null,
            planBeginDate: null,
            possibleMVOBTrigger: false,
            coverageError: null,
            modalityCoverageStatus: null,
            eligibilityBeginDate: null,
            eligibilityEndDate: null,
            relationshipToSubscriber: null,
            override: null,
            flags: [],
            customerPatientType: null,
            customerPatientId: null,
            customerPatientNextAppointmentDate: null,
            thirdPartyPayer: null,
            additionalPolicy: null,
            planResetSoon: null,
            resetBenefitsStatus: null,
            resetBenefits: null,
            priorAuthorization: null,
            visitsTotal: null,
            visitsRemaining: null,
            insuranceType: null,
            managedCareOrganization: null,
            eligibilityType: null,

            // From ICalculatorResult
            planStatus: null,
            modalityCoverageStatusTernary: null,
            enrollmentAllowed: false,
            isError: true,
            resultType: CalculatorResultType.generalError,
            groupName: null,
            groupId: null,
            copayment: null,
            coinsurance: null,
            deductible: null,
            remainingDeductible: null,
            oopMax: null,
            remainingOopMax: null,
            currentSessionRate: null,
            memberObligation: null,
            postDeductibleMemberObligation: null,
            postDeductiblePayerObligation: null,
            postOopMaxMemberObligation: null,
            postOopMaxPayerObligation: null,
            remainingSessionsBeforeDeductible: null,
            remainingSessionsBeforeOopMax: null,
            planEndDate: null,
            isTherapistNirvanaUser: false,
            overriddenPayer: null,
            payerObligation: null,
            createdAt: new Date().getTime(),
        }
    }

    /**
     * Map intake form data to request data
     */
    private static mapIntakeCheckInputData(
        inputData: IIntakeCheckerInputData,
        practice: IPractice
    ): IGetIntakeCoverageEstimateData {
        const { patientInformation, memberInformation } = inputData

        return {
            providerNpi: practice.groupNPI,
            firstName: patientInformation.firstName,
            lastName: patientInformation.lastName,
            dob: UtilHelper.dateToMysqlFormat(patientInformation.dob.toDate()),
            payerId: patientInformation.payer.id,
            cptCode: patientInformation.cptCode,
            memberId: memberInformation.memberId,
            zipCode: memberInformation.zipCode,
            // sessionRate is in $, should be converted to cents
            sessionRate: UtilHelper.formatDollarsToCents(
                patientInformation.sessionRate
            ),
        }
    }

    /**
     * Map medicaid form data to request data
     */
    private static mapMedicaidCheckInputDataToRequestData(
        inputData: IMedicaidCheckerInputData,
        practice: IPractice
    ): IGetMedicaidCoverageEstimateData {
        const {
            cptCode,
            memberId,
            providerNpi,
            customerPatientType,
            customerPatientId,
            customerPatientNextAppointmentDate,
            validationErrors,
            passThroughColumns,

            ...requiredData
        } = inputData

        return {
            ...requiredData,

            providerNpi: providerNpi || practice.groupNPI,
            cptCode: cptCode || undefined,
            memberId: memberId || undefined,
            customerPatientType: customerPatientType || undefined,
            customerPatientId: customerPatientId || undefined,
            customerPatientNextAppointmentDate:
                customerPatientNextAppointmentDate || undefined,
        }
    }

    /**
     * Map intake input data to continuous monitoring input data
     */
    static mapIntakeInputDataToContinuousMonitoringInputData(
        data: IIntakeCheckerInputData,
        id: string,
        payer: IPayer
    ): IContinuousMonitoringCoverageCheckInputData {
        return {
            id,
            payer,
            memberId: data.memberInformation.memberId,
            dob: UtilHelper.dateToMysqlFormat(
                data.patientInformation.dob.toDate()
            ),
            firstName: data.patientInformation.firstName,
            lastName: data.patientInformation.lastName,
            cptCode: data.patientInformation.cptCode,
            sessionRate: data.patientInformation.sessionRate,

            // customer specific data
            customerPatientType: CoveragePatientType.NEW_PATIENT,
        }
    }

    /**
     * Map medicaid check data to continuous monitoring input data
     */
    static mapMedicaidCheckDataToContinuousMonitoringInputData(
        coverageCheck: IMedicaidCoverageCheck,
        fallbackValue?: string
    ): IContinuousMonitoringCoverageCheckInputData {
        const memberId =
            coverageCheck.result?.memberId ||
            coverageCheck.inputData.memberId ||
            fallbackValue

        return {
            id:
                coverageCheck.inputData.id ||
                coverageCheck.result?.resultId ||
                new Date().getTime().toString(),
            payer:
                coverageCheck.result?.payer ||
                CheckerService.getDefaultMedicaidPayer(),

            memberId:
                memberId ===
                    COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown &&
                typeof fallbackValue !== "undefined"
                    ? fallbackValue
                    : memberId ||
                      COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,

            dob:
                coverageCheck.result?.demographics?.dob ||
                coverageCheck.inputData.dob,
            firstName:
                coverageCheck.result?.demographics?.firstName ||
                coverageCheck.inputData.firstName,
            lastName:
                coverageCheck.result?.demographics?.lastName ||
                coverageCheck.inputData.lastName,
            cptCode: coverageCheck.inputData.cptCode || "",
            sessionRate: "0",
            inNetworkCheck: true,

            // customer specific data
            customerPatientType: coverageCheck.inputData.customerPatientType,
            customerPatientId: coverageCheck.inputData.customerPatientId,
            customerPatientNextAppointmentDate:
                coverageCheck.inputData.customerPatientNextAppointmentDate,
        }
    }

    /**
     * Generate default medicaid payer
     */
    private static getDefaultMedicaidPayer(): IPayer {
        return {
            id: new Date().getTime(),
            insuranceName: "Medicaid",
            payerId: "",
            isCoverageSupported: false,
            isDiscoverSupported: false,
        }
    }
}
