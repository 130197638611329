import React, { useMemo } from "react"
import {
    NIRVANA_COLORS,
    PrimaryText,
    TooltipElement,
} from "nirvana-react-elements"

import { useAppSelector } from "../../store/selectors/app.selector"
import { userDetailsSelector } from "../../store/selectors/userDetails.selector"
import { COVERAGE_CONFIG, QuotaUsageType } from "../../config/coverage.config"
import { MultiProgressElement } from "../../elements/multiProgress.element"

export const OrganizationQuoteComponent: React.FunctionComponent<{
    className?: string
}> = ({ className }) => {
    const { selectedPracticeUsageQuota } = useAppSelector(userDetailsSelector)

    const checksAlreadyUsed = useMemo<number>(() => {
        if (!selectedPracticeUsageQuota) {
            return 0
        }

        return Object.values(QuotaUsageType).reduce((memo, key) => {
            const amount =
                COVERAGE_CONFIG.usageQuota.ignoredTypes.includes(key) ||
                !selectedPracticeUsageQuota?.creditsUsedBreakdown?.[key]
                    ? 0
                    : selectedPracticeUsageQuota.creditsUsedBreakdown[key]

            return memo + amount
        }, 0)
    }, [selectedPracticeUsageQuota])

    const outOfLimit = useMemo<boolean>(() => {
        return (
            !!selectedPracticeUsageQuota &&
            checksAlreadyUsed >= selectedPracticeUsageQuota.softQuotaLimit
        )
    }, [selectedPracticeUsageQuota, checksAlreadyUsed])

    const usageTypePercentages = useMemo<IMultiProgressItem[]>(() => {
        return Object.values(QuotaUsageType).reduce((memo, type) => {
            if (COVERAGE_CONFIG.usageQuota.ignoredTypes.includes(type)) {
                return memo
            }

            const config = COVERAGE_CONFIG.usageQuota.usageTypeConfig[type]

            const amount =
                COVERAGE_CONFIG.usageQuota.ignoredTypes.includes(type) ||
                !selectedPracticeUsageQuota?.creditsUsedBreakdown?.[type]
                    ? 0
                    : selectedPracticeUsageQuota.creditsUsedBreakdown[type]

            const percentage = Math.floor(
                (amount / (selectedPracticeUsageQuota?.softQuotaLimit || 1)) *
                    100
            )

            return [
                ...memo,

                {
                    color: config.color,
                    percentage,
                },
            ]
        }, [] as IMultiProgressItem[])
    }, [selectedPracticeUsageQuota])

    return selectedPracticeUsageQuota ? (
        <div
            className={`
                relative p-16px rounded-8px
                ${
                    outOfLimit
                        ? "bg-brand-errorBg border border-solid border-brand-error"
                        : "bg-brand-warmLight"
                }
                ${className}
            `}
        >
            <PrimaryText
                typography="subtitle"
                color={
                    outOfLimit
                        ? NIRVANA_COLORS.brand.error
                        : NIRVANA_COLORS.brand.primary
                }
            >
                OneVerify Monthly Credits:{" "}
                <span className="text-semibold">
                    {new Intl.NumberFormat().format(checksAlreadyUsed)} /{" "}
                    {new Intl.NumberFormat().format(
                        selectedPracticeUsageQuota.softQuotaLimit
                    )}
                </span>{" "}
                Used
            </PrimaryText>

            <PrimaryText className="mt-12px" typography="textSmall">
                Quota refers to checks made in OneVerify only - API checks are
                not included
            </PrimaryText>

            <MultiProgressElement
                className="mt-16px"
                items={usageTypePercentages}
            />

            <PrimaryText className="mt-16px" typography="caption" weight="bold">
                Credits Used Breakdown
            </PrimaryText>

            <div
                className="
                    mt-12px flex items-center gap-4
                    md:block
                "
            >
                {Object.values(QuotaUsageType).map((type, index) => {
                    if (
                        COVERAGE_CONFIG.usageQuota.ignoredTypes.includes(type)
                    ) {
                        return null
                    }

                    const config =
                        COVERAGE_CONFIG.usageQuota.usageTypeConfig[type]

                    const creditsPerCheck =
                        selectedPracticeUsageQuota.checkTypeCreditsAmount[type]

                    return (
                        <div
                            key={index}
                            className="
                                flex-1 flex gap-2
                                md:mt-8px
                            "
                        >
                            <div
                                className="w-12px h-12px rounded-3px relative top-5px"
                                style={{
                                    backgroundColor: config.color,
                                }}
                            />

                            <div className="flex-1">
                                <div className="flex items-center">
                                    <PrimaryText typography="textSemibold">
                                        {config.label}
                                    </PrimaryText>

                                    {config.tooltip ? (
                                        <TooltipElement
                                            className="ml-4px"
                                            text={config.tooltip}
                                        />
                                    ) : null}

                                    <PrimaryText className="ml-8px">
                                        {new Intl.NumberFormat().format(
                                            selectedPracticeUsageQuota
                                                .creditsUsedBreakdown[type]
                                        )}
                                    </PrimaryText>
                                </div>

                                <PrimaryText
                                    className="mt-4px"
                                    typography="caption"
                                >
                                    {creditsPerCheck} credit
                                    {creditsPerCheck > 1 ? "s" : ""} per check
                                </PrimaryText>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    ) : null
}
