import { useMemo, useState } from "react"
import moment from "moment-timezone"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
} from "../config/policies.config"
import { AvailableCoveragePortalPermission } from "../config/rolesPermissions.config"
import { PermissionsHelper } from "../helpers/permissions.helper"
import { useAppSelector } from "../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../store/selectors/selectedPracticeRole.selector"
import { useIsFeatureFlagEnabled } from "./isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../config/featureFlags.config"
import { useBulkOverrides } from "./bulkOverrides.hooks"
import { UtilHelper } from "../helpers/util.helper"

export const usePoliciesList = () => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const { isOverridesEnabled } = useBulkOverrides()

    const isFlagsResolutionEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_FLAGS_RESOLUTION
    )

    const [selectedPolicies, setSelectedPolicies] = useState<
        ICoverageCheckHistory[]
    >([])

    const canManageFlags = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.managePolicyFlags,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const canManageOverrides = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.overrideCoverageData,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const canRunCoverageChecks = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.runCoverageChecks,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const isPolicySelected = (policy: ICoverageCheckHistory): boolean => {
        return !!selectedPolicies.find(
            item =>
                item.coverageResult.resultId === policy.coverageResult.resultId
        )
    }

    const onPoliciesSelected = (items: ICoverageCheckHistory[]) => {
        const newNotSelected = items.filter(item => !isPolicySelected(item))

        setSelectedPolicies(current => [...current, ...newNotSelected])
    }

    const onPoliciesDeselected = (items: ICoverageCheckHistory[]) => {
        const notNeededRequestIds = items.map(
            item => item.coverageResult.resultId
        )

        setSelectedPolicies(current =>
            current.filter(
                policy =>
                    !notNeededRequestIds.includes(
                        policy.coverageResult.resultId
                    )
            )
        )
    }

    const onDeselectAllPolicies = () => {
        setSelectedPolicies([])
    }

    const processFiltersFormData = (
        data: IPoliciesListFiltersData,
        selectedFlags?: CoveragePortalFlagType[]
    ): IPoliciesListFiltersData => {
        let result: IPoliciesListFiltersData = { ...data }

        const dateRanges: (keyof IPoliciesListFiltersData)[] = [
            "checkDate",
            "nextAppointmentDate",
            "planEndDate",
        ]

        // Convert dates to mysql format for date ranges
        dateRanges.forEach(key => {
            const typedDataRange = result[key] as
                | IPoliciesDateRangeFilterData
                | undefined

            result = {
                ...result,

                [key]: typedDataRange?.type
                    ? {
                          ...typedDataRange,

                          dateFrom: typedDataRange.dateFrom
                              ? UtilHelper.dateToMysqlFormat(
                                    moment(typedDataRange.dateFrom).toDate()
                                )
                              : typedDataRange.dateTo
                              ? UtilHelper.dateToMysqlFormat(
                                    moment(typedDataRange.dateTo)
                                        .subtract(
                                            POLICIES_CONFIG.defaultReportsExtendedGoBackDays,
                                            "days"
                                        )
                                        .toDate()
                                )
                              : undefined,

                          dateTo: typedDataRange.dateTo
                              ? UtilHelper.dateToMysqlFormat(
                                    moment(typedDataRange.dateTo).toDate()
                                )
                              : undefined,

                          // convert to number
                          daysAmount: typedDataRange.daysAmount
                              ? +typedDataRange.daysAmount
                              : undefined,
                      }
                    : undefined,
            }
        })

        result = {
            ...result,

            // Convert date to mysql format for dob
            memberDob: result.memberDob
                ? UtilHelper.dateToMysqlFormat(
                      moment(result.memberDob).toDate()
                  )
                : undefined,

            flags: selectedFlags?.length ? selectedFlags : undefined,
        }

        return result
    }

    return {
        // vars
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageFlags,
        canManageOverrides,
        canRunCoverageChecks,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        isPolicySelected,
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
        processFiltersFormData,
    }
}
