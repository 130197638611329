/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    PageHelmetElement,
    SpinnerElement,
    useScrollFix,
} from "nirvana-react-elements"

import {
    PoliciesListType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { BulkOverridesComponent } from "../overrides/bulkOverrides/bulkOverrides.component"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { VisualizationWrapperComponent } from "../visualizationWrapper.component"
import { PlanYearResetVisualizationComponent } from "./planYearResetVisualization.component"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../../../config/featureFlags.config"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PoliciesListHeaderComponent } from "../policiesList/policiesListHeader.component"

export const PlanYearResetsComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions([AvailableCoveragePortalPermission.viewPlanYearResets])

    useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_PLAN_YEAR_RESETS,
        undefined,
        ROUTES_CONFIG.profileUrl
    )

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const scrollableTableRef = useRef<HTMLDivElement>(null)

    const {
        // vars
        isOverridesEnabled,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return canManageOverrides && isOverridesEnabled
    }, [isOverridesEnabled, canManageOverrides])

    const showBulkOverrideButton = useMemo<boolean>(() => {
        return isOverridesEnabled && canManageOverrides
    }, [isOverridesEnabled, canManageOverrides])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            PoliciesListHelper.getSavedColumnsConfiguration(
                PoliciesListType.PLAN_YEAR_RESETS,
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        PoliciesListHelper.saveColumnsConfigurations(
            PoliciesListType.PLAN_YEAR_RESETS,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesListHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Plan Year Resets"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {selectedPracticeRole ? (
                <PoliciesListHeaderComponent
                    className="mb-24px"
                    listType={PoliciesListType.PLAN_YEAR_RESETS}
                    setSelectedPolicies={setSelectedPolicies}
                    scrollableTableRef={scrollableTableRef}
                    withExport
                    withSavingFilterPresets
                />
            ) : (
                <SpinnerElement containerClassName="mt-32px text-center" />
            )}

            {!!selectedPracticeRole && (
                <VisualizationWrapperComponent
                    adjustHorizontalScrollReference={scrollableTableRef}
                    listType={PoliciesListType.PLAN_YEAR_RESETS}
                >
                    <PlanYearResetVisualizationComponent className="flex-1" />
                </VisualizationWrapperComponent>
            )}

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    listType={PoliciesListType.PLAN_YEAR_RESETS}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    scrollableElementRef={scrollableTableRef}
                    onChangeColumnOrder={onChangeColumnOrder}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                />
            ) : null}

            {showBulkOverrideButton && (
                <BulkOverridesComponent
                    listType={PoliciesListType.PLAN_YEAR_RESETS}
                    onCancelBulkOverride={onDeselectAllPolicies}
                    coverageChecksToOverride={selectedPolicies}
                    setCoverageChecksToOverride={setSelectedPolicies}
                />
            )}
        </div>
    )
}
