/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { PrimaryText, NIRVANA_COLORS } from "nirvana-react-elements"

import { CSV_CHECKER_CONFIG } from "../../../config/csvChecker.config"
import { CsvHelper } from "../../../helpers/csv.helper"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"
import { CoverageCheckerCheckType } from "../../../config/checker.config"
import { CheckerService } from "../../../services/checker.service"

import trashIcon from "../../../assets/images/icons/trash-red.svg"

export const SingleCsvCoverageCheckComponent: React.FunctionComponent<
    ISingleCsvCoverageCheckComponentProps
> = props => {
    const containerRef = useRef<HTMLDivElement>(null)

    const { csvCheckType } = useAppSelector(checkerSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [isRowHovered, setIsRowHovered] = useState<boolean>(false)
    const [actionsContainerMarginLeft, setActionsContainerMarginLeft] =
        useState<number>(0)

    useEffect(() => {
        if (!containerRef.current || !isRowHovered) {
            return
        }

        window.addEventListener("scroll", adjustActionsContainerMarginLeft)

        return () => {
            window.removeEventListener(
                "scroll",
                adjustActionsContainerMarginLeft
            )
        }
    }, [containerRef.current, isRowHovered])

    const adjustActionsContainerMarginLeft = () => {
        if (!containerRef.current) {
            return
        }

        const boundaries = containerRef.current?.getBoundingClientRect()

        if (!boundaries) {
            return
        }

        setActionsContainerMarginLeft(boundaries.left * -1)
    }

    return (
        <div
            ref={containerRef}
            className={`
                ${props.className}
                flex group relative
            `}
            onMouseEnter={() => {
                setIsRowHovered(true)
                adjustActionsContainerMarginLeft()
            }}
            onMouseLeave={() => setIsRowHovered(false)}
        >
            <div className="table-row w-full">
                <div
                    className={`
                        p-16px
                        border-b border-solid border-brand-warmLight
                        ${
                            props.coverageCheck.inputData.validationErrors
                                ?.length
                                ? "bg-brand-redLight"
                                : "bg-transparent hover:bg-brand-white"
                        }
                    `}
                >
                    <div className="flex items-center">
                        <div className="mr-16px flex-shrink-0 w-100px">
                            <PrimaryText>
                                {props.coverageCheck.inputData.id}
                            </PrimaryText>
                        </div>

                        {CSV_CHECKER_CONFIG.uploadedCsvPreviewMapping[
                            csvCheckType
                        ].map((mapping, index) => {
                            return (
                                <div
                                    key={index}
                                    className="mr-16px flex-shrink-0 w-250px"
                                >
                                    <PrimaryText>
                                        {CsvHelper.getSingleExportColumn(
                                            mapping,
                                            csvCheckType ===
                                                CoverageCheckerCheckType.MEDICAID
                                                ? CheckerService.mapMedicaidCheckDataToContinuousMonitoringInputData(
                                                      {
                                                          inputData: props
                                                              .coverageCheck
                                                              .inputData as IMedicaidCheckerInputData,
                                                          result: null,
                                                      }
                                                  )
                                                : (props.coverageCheck
                                                      .inputData as IContinuousMonitoringCoverageCheckInputData),
                                            undefined,
                                            selectedPracticeRole,
                                            csvCheckType ===
                                                CoverageCheckerCheckType.MEDICAID
                                                ? {
                                                      state: (
                                                          props.coverageCheck
                                                              .inputData as IMedicaidCheckerInputData
                                                      ).state,
                                                  }
                                                : undefined
                                        )}
                                    </PrimaryText>
                                </div>
                            )
                        })}
                    </div>

                    {/*VALIDATION ERRORS*/}
                    {props.coverageCheck?.inputData.validationErrors?.length ? (
                        <div className="mt-24px">
                            {props.coverageCheck?.inputData.validationErrors.map(
                                (error, index) => {
                                    return (
                                        <div key={index}>
                                            <PrimaryText
                                                color={
                                                    NIRVANA_COLORS.brand.error
                                                }
                                                className="mt-16px"
                                            >
                                                {error}
                                            </PrimaryText>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    ) : null}
                </div>

                {/*Keeping this block always to the right of row is tricky because we have horizontal scroll in table, but doable with JS*/}
                {/*we can't use position:fixed since position should be relative to row, not to window*/}
                {/*Check adjustActionsContainerMarginLeft above for details where it uses setActionsContainerMarginLeft*/}
                {/*it will update marginLeft on scroll and will keep element to the right*/}
                {/*JUST show when no results yet*/}
                <div
                    className="
                        hidden group-hover:block absolute z-10
                        h-full w-192px bg-brand-lilacLight top-0
                        border-l border-solid border-brand-lilac
                    "
                    style={{
                        left: "100vw",
                        transform: "translateX(-100%)",
                        marginLeft: `${actionsContainerMarginLeft}px`,
                    }}
                >
                    <div className="flex items-center h-full">
                        <div
                            className="flex-1 flex items-center justify-center cursor-pointer h-full relative"
                            onClick={() =>
                                props.onInputDataRemove(
                                    props.coverageCheck.inputData
                                )
                            }
                        >
                            <div className="absolute screen-center">
                                <img
                                    className="mx-auto mt-10px"
                                    src={trashIcon}
                                    alt="Remove"
                                />
                                <PrimaryText centered size={10}>
                                    Remove
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
