/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { ButtonElement } from "nirvana-react-elements"

import { CoveragePortalFlagType } from "../../../config/policies.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"
import { PoliciesFiltersFormComponent } from "./policiesFiltersForm.component"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { PoliciesSavedFiltersComponent } from "./policiesSavedFilters.component"

export const ReportsFiltersComponent: React.FunctionComponent<
    IReportsFiltersComponentProps
> = props => {
    const formMethods = useForm<IPoliciesListFiltersData>()
    const { handleSubmit, watch } = formMethods

    const dataWatcher = watch()

    const { processFiltersFormData } = usePoliciesList()

    const [filtersFormKey, setFiltersFormKey] = useState<number>(
        new Date().getTime()
    )

    const [selectedFlags, setSelectedFlags] = useState<
        CoveragePortalFlagType[] | undefined
    >(props.existingFilters.flags)

    const mappedDataWatcher = useMemo<IPoliciesListFiltersData>(() => {
        // doing JSON functions to remove undefined values
        return JSON.parse(
            JSON.stringify(processFiltersFormData(dataWatcher, selectedFlags))
        )
    }, [JSON.stringify(dataWatcher), selectedFlags])

    const [selectedSavedFilter, setSelectedSavedFilter] =
        useState<IReportSavedFilter>()

    const [isFilterAlreadySaved, setIsFilterAlreadySaved] =
        useState<boolean>(false)

    const [resetTriggeredAt, setResetTriggeredAt] = useState<number>()
    const [isSavingFilter, setIsSavingFilter] = useState<boolean>(false)

    const onResetFilters = () => {
        setResetTriggeredAt(new Date().getTime())
        setSelectedSavedFilter(undefined)
        setSelectedFlags([])
        setFiltersFormKey(new Date().getTime())
    }

    const onSelectSavedFilter = (savedFilter: IReportSavedFilter) => {
        setResetTriggeredAt(undefined)
        setSelectedFlags(savedFilter.filtersConfiguration.flags || [])
        setSelectedSavedFilter(savedFilter)

        setTimeout(() => {
            setFiltersFormKey(new Date().getTime())
        })
    }

    const onSubmitForm = (data: IPoliciesListFiltersData) => {
        const mappedData = processFiltersFormData(data, selectedFlags)

        onApplyFilters(mappedData)
    }

    const onApplyFilters = (data: IPoliciesListFiltersData) => {
        PoliciesListHelper.set(
            props.listType +
                GENERAL_CONFIG.browserStorageKeys
                    .policiesFiltersConfigurationSuffix,
            data
        )

        props.onFiltersSubmitted(data)
    }

    const onSavedFiltersAsPreset = async () => {
        const filtersValid = await formMethods.trigger()

        if (!filtersValid) {
            return
        }

        setIsSavingFilter(true)
    }

    return (
        <div className={`relative ${props.className}`}>
            <div className="flex h-full">
                <div
                    className={`
                        relative px-24px pb-24px
                        ${
                            props.withSavingFilterPresets
                                ? "w-450px border-r border-solid border-brand-warmLight"
                                : "flex-1"
                        }
                    `}
                >
                    {isSavingFilter && (
                        <div className="absolute left-0 top-0 w-full h-full z-200 bg-brand-offWhite60" />
                    )}

                    <FormProvider {...formMethods}>
                        <form
                            className="h-full"
                            onSubmit={handleSubmit(onSubmitForm)}
                        >
                            <PoliciesFiltersFormComponent
                                key={filtersFormKey}
                                className="h-full overflow-y-auto pb-100px"
                                listType={props.listType}
                                existingFilters={
                                    selectedSavedFilter?.filtersConfiguration ||
                                    (!resetTriggeredAt
                                        ? props.existingFilters
                                        : {})
                                }
                                selectedFlags={selectedFlags}
                                setSelectedFlags={setSelectedFlags}
                                flagsDisplayedAmount={
                                    !props.withSavingFilterPresets
                                        ? 2
                                        : undefined
                                }
                            />

                            <div
                                className="
                                    absolute bottom-0 z-100 left-0 w-full flex items-center p-24px
                                    bg-brand-offWhite border-t border-solid border-brand-warmLight
                                "
                            >
                                <ButtonElement
                                    buttonClassName="min-w-100px"
                                    label="Reset"
                                    onClick={onResetFilters}
                                    htmlType="button"
                                    type="default"
                                />

                                <div className="flex-1" />

                                {props.withSavingFilterPresets ? (
                                    <ButtonElement
                                        className="ml-16px"
                                        label="Save as Preset"
                                        onClick={onSavedFiltersAsPreset}
                                        htmlType="button"
                                        type="default"
                                        disabled={isFilterAlreadySaved}
                                    />
                                ) : null}

                                <ButtonElement
                                    className="ml-16px"
                                    buttonClassName="min-w-100px"
                                    label="Filter"
                                    htmlType="submit"
                                    type="primary"
                                />
                            </div>
                        </form>
                    </FormProvider>
                </div>

                {props.withSavingFilterPresets ? (
                    <PoliciesSavedFiltersComponent
                        className="flex-1 h-full overflow-hidden"
                        listType={props.listType}
                        filters={mappedDataWatcher}
                        onSelectSavedFilter={onSelectSavedFilter}
                        onApplySavedFilter={savedFilter => {
                            onApplyFilters(savedFilter.filtersConfiguration)
                        }}
                        onSameSavedFilterFoundChange={setIsFilterAlreadySaved}
                        needSaveCurrentFilters={isSavingFilter}
                        onSaveCurrentFiltersDone={() => {
                            setIsSavingFilter(false)
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}
