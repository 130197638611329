/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import {
    NIRVANA_COLORS,
    PrimaryText,
    SpinnerElement,
} from "nirvana-react-elements"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
    PoliciesListType,
} from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import {
    policiesGetMedicaidReportsAggregationsPlanType,
    policiesGetReportsAggregationsFlags,
} from "../../../store/thunks/policies.thunks"
import { UtilHelper } from "../../../helpers/util.helper"
import { MedicaidAggregationCardComponent } from "./medicaidAggregationCard.component"

export const MedicaidFlagsVisualizationComponent: React.FunctionComponent<
    IReportsFlagsVisualizationComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const {
        medicaidFilters,
        reportsAggregationsFlags,
        medicaidAggregationsPlanType,
    } = useAppSelector(policiesSelector)

    const isLoading = useMemo<boolean>(() => {
        return (
            RuntimeHelper.isReportsAggregationsFlagsLoading() ||
            RuntimeHelper.isMedicaidAggregationsPlanTypeLoading()
        )
    }, [runtimeState.isLoading])

    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const thunkPromises = [
            dispatch(
                policiesGetReportsAggregationsFlags({
                    practice: selectedPracticeRole.practice,
                    payload: {
                        filters: {
                            ...medicaidFilters,
                            isMedicaid: true,
                        },
                    },
                })
            ),
            dispatch(
                policiesGetMedicaidReportsAggregationsPlanType({
                    practice: selectedPracticeRole.practice,
                    payload: {
                        filters: medicaidFilters,
                    },
                })
            ),
        ]

        return () => {
            thunkPromises.forEach(thunkPromise => thunkPromise.abort())
        }
    }, [selectedPracticeRole?.practice.id, JSON.stringify(medicaidFilters)])

    const mappedPlanTypeAggregations = useMemo<
        IMedicaidAggregatedCalculations[] | null
    >(() => {
        if (!medicaidAggregationsPlanType) {
            return null
        }

        return POLICIES_CONFIG.medicaidAggregationsConfigs.map(config => {
            const totalChecks =
                medicaidAggregationsPlanType.aggregations[config.planType]
                    ?.total || 0

            const activeChecks =
                medicaidAggregationsPlanType.aggregations[config.planType]
                    ?.detail.ACTIVE || 0

            const inactiveChecks =
                medicaidAggregationsPlanType.aggregations[config.planType]
                    ?.detail.INACTIVE || 0

            return {
                label: config.label,
                color: config.color,
                progressColor: NIRVANA_COLORS.brand.primary,
                trailColor: inactiveChecks
                    ? NIRVANA_COLORS.brand.vibrantPurple
                    : undefined,

                value: totalChecks,
                percentage: UtilHelper.getPercentageValue(
                    totalChecks,
                    medicaidAggregationsPlanType.total
                ),
                progressPercentage: UtilHelper.getPercentageValue(
                    activeChecks,
                    activeChecks + inactiveChecks
                ),
                children: [
                    {
                        label: "Active",
                        value: activeChecks,
                        percentage: UtilHelper.getPercentageValue(
                            activeChecks,
                            activeChecks + inactiveChecks
                        ),
                        color: NIRVANA_COLORS.brand.primary,
                    },
                    {
                        label: "Inactive",
                        value: inactiveChecks,
                        percentage: UtilHelper.getPercentageValue(
                            inactiveChecks,
                            activeChecks + inactiveChecks
                        ),
                        color: NIRVANA_COLORS.brand.vibrantPurple,
                    },
                ],
            }
        })
    }, [medicaidAggregationsPlanType])

    const mappedFlagsAggregation =
        useMemo<IMedicaidAggregatedCalculations | null>(() => {
            if (!reportsAggregationsFlags) {
                return null
            }

            const children = [] as IMedicaidAggregatedCalculationsChild[]

            Object.keys(reportsAggregationsFlags.aggregations)
                .filter(flagType =>
                    POLICIES_CONFIG.flagTypesByViewType[
                        PoliciesListType.MEDICAID
                    ].includes(flagType as CoveragePortalFlagType)
                )
                .forEach(flagType => {
                    const value =
                        reportsAggregationsFlags.aggregations?.[
                            flagType as CoveragePortalFlagType
                        ]

                    if (!value) {
                        return
                    }

                    children.push({
                        label: POLICIES_CONFIG.flagTypeMappings[
                            flagType as CoveragePortalFlagType
                        ].label,
                        value,
                        percentage: UtilHelper.getPercentageValue(
                            value,
                            reportsAggregationsFlags.total_checks
                        ),
                        filterFlag: flagType as CoveragePortalFlagType,
                    })
                })

            return {
                label: "Other Alerts",
                isInlinedSubLabel: true,
                value: reportsAggregationsFlags.total,
                children,
            }
        }, [reportsAggregationsFlags])

    return (
        <div
            className={`
                relative min-h-550px bg-brand-lilacLight
                ${props.className}
            `}
        >
            {isLoading && (
                <div
                    className="
                        absolute z-10 w-full h-full bg-brand-whiteTransparent85
                        flex items-center justify-center
                    "
                >
                    <SpinnerElement />
                </div>
            )}

            {!!mappedPlanTypeAggregations && !!mappedFlagsAggregation && (
                <div className="py-40px px-24px">
                    <PrimaryText size={29} lineHeight={34} weight="bold">
                        Alerts
                    </PrimaryText>

                    {/*ITEMS without inlined label then in row*/}
                    <div className="mt-24px flex">
                        {mappedPlanTypeAggregations.map((item, index) => {
                            return (
                                <MedicaidAggregationCardComponent
                                    key={index}
                                    className={`
                                        flex-1 self-stretch
                                        ${index ? "ml-24px" : ""}
                                    `}
                                    calculation={item}
                                />
                            )
                        })}
                    </div>

                    <MedicaidAggregationCardComponent
                        calculation={mappedFlagsAggregation}
                        className="mt-24px"
                    />
                </div>
            )}
        </div>
    )
}
