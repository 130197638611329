import React from "react"

import { authSetLoginStep } from "../../../../store/slices/auth.slice"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { authSelector } from "../../../../store/selectors/auth.selector"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { PhoneNumberForm } from "../../mfa/phoneNumberForm"
import { authUpdatePhoneNumber } from "../../../../store/thunks/auth.thunks"
import { LoginStep } from "../../../../config/auth.config"

export const PhoneNumberStep: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const authState = useAppSelector(authSelector)

    const onSubmit = (phoneNumber: string) => {
        dispatch(
            authUpdatePhoneNumber({
                payload: {
                    phoneNumber,
                },
                onSuccess: () => {
                    dispatch(authSetLoginStep(LoginStep.MFA_CODE_COLLECTION))
                },
            })
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <PhoneNumberForm
                title="Secure Your Account"
                subtitle={
                    "We require multi-factor authentication (MFA) to keep your account safe.\nIt only takes a minute!"
                }
                subtitle2="We’ll send a verification code to your phone as part of multi-factor authentication (MFA). Enter your number to continue."
                isCtaLoading={authState.isLoading}
                onSubmit={onSubmit}
                withEnrollmentCheckbox
                withTopIcon
            />
        </div>
    )
}
