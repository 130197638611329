import {
    BrowserStorageHelper as NirvanaBrowserStorageHelper,
    BrowserStorageType,
    ISelectRawOption,
    PolicyModality,
} from "nirvana-react-elements"
import moment, { Moment } from "moment-timezone"

import {
    POLICIES_CONFIG,
    PoliciesListType,
    PolicyHeaderDragDirection,
    PolicyRangeDateFilterType,
    ResetBenefitStatus,
} from "../config/policies.config"
import { GENERAL_CONFIG } from "../config/general.config"
import { PoliciesHelper } from "./policies.helper"
import { UtilHelper } from "./util.helper"
import { ROUTES_CONFIG } from "../config/routes.config"
import { POLICIES_COLUMNS_CONFIG } from "../config/policiesColumns.config"
import { COVERAGE_CONFIG } from "../config/coverage.config"
import { CHECKER_CONFIG } from "../config/checker.config"

export class PoliciesListHelper extends NirvanaBrowserStorageHelper {
    /**
     * Get policies list base url based on general view type
     */
    static getPoliciesListBaseUrl(viewType: PoliciesListType): string {
        switch (viewType) {
            case PoliciesListType.REPORTS:
                return ROUTES_CONFIG.reportsUrl

            case PoliciesListType.HISTORY_SEARCH:
                return ROUTES_CONFIG.searchUrl

            case PoliciesListType.OVERRIDES:
                return ROUTES_CONFIG.overridesUrl

            case PoliciesListType.PLAN_YEAR_RESETS:
                return ROUTES_CONFIG.planYearResetsUrl

            case PoliciesListType.MEDICAID:
                return ROUTES_CONFIG.medicaidReportsUrl
        }
    }

    /**
     * Get default configuration for columns based on view type
     * Uses LOCAL storage
     */
    static getSavedColumnsConfiguration(
        viewType: PoliciesListType,
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        let defaultColumns: IPolicyColumnConfiguration[]

        switch (viewType) {
            case PoliciesListType.HISTORY_SEARCH:
                defaultColumns =
                    PoliciesListHelper.getDefaultHistoricalChecksColumns(
                        availableModalities
                    )

                break

            case PoliciesListType.REPORTS:
                defaultColumns =
                    PoliciesListHelper.getDefaultReportsColumns(
                        availableModalities
                    )

                break

            case PoliciesListType.MEDICAID:
                defaultColumns =
                    PoliciesListHelper.getDefaultMedicaidReportsColumns(
                        availableModalities
                    )

                break

            case PoliciesListType.OVERRIDES:
                defaultColumns =
                    PoliciesListHelper.getDefaultOverridesColumns(
                        availableModalities
                    )

                break

            case PoliciesListType.PLAN_YEAR_RESETS:
                defaultColumns =
                    PoliciesListHelper.getDefaultPlanYearResetsColumns(
                        availableModalities
                    )

                break
        }

        return PoliciesListHelper.getDefaultPoliciesColumnsConfiguration(
            PoliciesListHelper.getModalitiesBasedStorageKey(
                viewType +
                    GENERAL_CONFIG.browserStorageKeys
                        .policiesColumnsConfigurationSuffix,
                availableModalities
            ),
            defaultColumns
        )
    }

    /**
     * Get default configuration for filters for different views
     * Uses LOCAL storage
     */
    static getSavedFiltersConfiguration(
        viewType: PoliciesListType,
        defaultCheckDate?: IPoliciesDateRangeFilterData
    ): IPoliciesListFiltersData {
        return (
            PoliciesListHelper.get(
                viewType +
                    GENERAL_CONFIG.browserStorageKeys
                        .policiesFiltersConfigurationSuffix
            ) || {
                ...(defaultCheckDate
                    ? {
                          checkDate: defaultCheckDate,
                      }
                    : {}),
            }
        )
    }

    /**
     * Get default columns for different views
     */
    static getDefaultColumns(
        viewType: PoliciesListType,
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        switch (viewType) {
            case PoliciesListType.HISTORY_SEARCH:
                return PoliciesListHelper.getDefaultHistoricalChecksColumns(
                    availableModalities
                )

            case PoliciesListType.REPORTS:
                return PoliciesListHelper.getDefaultReportsColumns(
                    availableModalities
                )

            case PoliciesListType.MEDICAID:
                return PoliciesListHelper.getDefaultMedicaidReportsColumns(
                    availableModalities
                )

            case PoliciesListType.OVERRIDES:
                return PoliciesListHelper.getDefaultOverridesColumns(
                    availableModalities
                )

            case PoliciesListType.PLAN_YEAR_RESETS:
                return PoliciesListHelper.getDefaultPlanYearResetsColumns(
                    availableModalities
                )
        }
    }

    /**
     * Save columns configurations for future usage
     */
    static saveColumnsConfigurations(
        viewType: PoliciesListType,
        columnsConfigurations: IPolicyColumnConfiguration[],
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ) {
        const key =
            viewType +
            GENERAL_CONFIG.browserStorageKeys.policiesColumnsConfigurationSuffix

        PoliciesListHelper.set(
            PoliciesListHelper.getModalitiesBasedStorageKey(
                key,
                availableModalities
            ),
            columnsConfigurations,
            BrowserStorageType.localStorage
        )
    }

    /**
     * Move column to some other place in list
     */
    static changeSingleColumnOrdering(
        columns: IPolicyColumnConfiguration[],
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ): IPolicyColumnConfiguration[] {
        // First find and remove needed column
        const source = columns.find(
            item => item.label === columnLabelMoveSource
        )

        if (!source) {
            return columns
        }

        const newColumns = columns.filter(item => item.label !== source.label)

        // Find target column index
        const targetIndex = newColumns.findIndex(
            item => item.label === columnLabelMoveTarget
        )

        if (targetIndex < 0) {
            return columns
        }

        // Adjust based on drag direction
        // If after add 1
        newColumns.splice(
            targetIndex +
                (direction === PolicyHeaderDragDirection.AFTER ? 1 : 0),
            0,
            source
        )

        return newColumns
    }

    /**
     * Make sure to return function for getting value inside each object
     */
    static extendColumnsConfigurationsWithFunctionValues(
        columns: IPolicyColumnConfiguration[],
        defaultValues: IPolicyColumnConfiguration[]
    ): IPolicyColumnConfiguration[] {
        return columns.map(item => {
            const neededInitialItem = defaultValues.find(
                initial => initial.label === item.label
            )

            if (!neededInitialItem) {
                return item
            }

            return {
                ...item,

                getCustomValue: neededInitialItem.getCustomValue,
            }
        })
    }

    /**
     * Calculate value for aggregation by status code
     */
    static getStatusCodeAggregationValue(
        data: IGetCoverageChecksAggregationsStatusCodes,
        config: IReportsStatusCodesWidgetConfig
    ): number {
        if (!config.aggregationRules?.length) {
            return 0
        }

        let result = 0

        for (const rule of config.aggregationRules) {
            if (rule.statusCode && !rule.errorCode) {
                result += data.aggregations[rule.statusCode]?.total || 0

                continue
            }

            // Below we are mapping through error code, so need to be set
            if (!rule.errorCode) {
                continue
            }

            // That is set on rule, or all if not set
            const neededStatusCodes = rule.statusCode
                ? [rule.statusCode]
                : Object.keys(data.aggregations)

            for (const statusCode of neededStatusCodes) {
                result +=
                    data.aggregations[statusCode]?.detail?.[rule.errorCode] || 0
            }
        }

        return result
    }

    /**
     * Convert plan year resets status to chart values
     */
    static mapPlanYearResetsStatusToChartValues(
        resetBenefitStatus: ResetBenefitStatus,
        value: number,
        valueComparePercentage: number
    ): IPoliciesAggregatedChartValues {
        return {
            label: COVERAGE_CONFIG.selectRenderedResetBenefitStatus[
                resetBenefitStatus
            ].displayValue,

            color: POLICIES_CONFIG.planYearResetsStatusColors[
                resetBenefitStatus
            ],
            bordered: resetBenefitStatus === ResetBenefitStatus.NOT_DETECTED,

            value,
            percentage: UtilHelper.getPercentageValue(
                value,
                valueComparePercentage
            ),
        }
    }

    /**
     * Get filters that are mapped for display
     */
    static getMappedDisplayFilters(
        filters: IPoliciesListFiltersData
    ): ISelectRawOption[] {
        const appliedFiltersKeys = Object.keys(
            filters
        ) as (keyof IPoliciesListFiltersData)[]

        return appliedFiltersKeys
            .map((key, index) => {
                // Hide has some filters completely from UI
                if (POLICIES_CONFIG.filtersHiddenDisplayKeys.includes(key)) {
                    return null
                }

                let value: any = filters[key]

                switch (key) {
                    case "checkDate":
                    case "nextAppointmentDate":
                    case "planEndDate":
                        if (!value) {
                            return null
                        }

                        const infiniteDateFallback = "∞"

                        const { from, to } =
                            PoliciesListHelper.getRelativeDateRangeValues(value)

                        value = `${from || infiniteDateFallback} - ${
                            to || infiniteDateFallback
                        }`

                        break

                    case "memberDob":
                        value = value
                            ? moment(value).format(
                                  GENERAL_CONFIG.defaultMomentDateFormat
                              )
                            : value

                        break

                    case "payer":
                        value = filters.payer?.insuranceName || null

                        break

                    case "flags":
                        value =
                            value && Array.isArray(value)
                                ? value
                                      .map(
                                          flag =>
                                              POLICIES_CONFIG.flagTypeMappings[
                                                  flag
                                              ].label
                                      )
                                      .join(", ")
                                : value

                        break

                    case "patientType":
                        value = value
                            ? COVERAGE_CONFIG.selectRenderedPatientTypes[value]
                                  ?.displayValue
                            : null

                        break

                    case "resetBenefitsStatus":
                        value = value
                            ? COVERAGE_CONFIG.selectRenderedResetBenefitStatus[
                                  value
                              ]?.displayValue
                            : null

                        break

                    case "planType":
                        value = value
                            ? CHECKER_CONFIG.planTypeMapping[value]
                            : null

                        break
                }

                if (!value) {
                    return null
                }

                return {
                    key,
                    label: POLICIES_CONFIG.filtersDisplayMapping[key],
                    value,
                }
            })
            .filter(item => !!item) as ISelectRawOption[]
    }

    /**
     * Get relative date range values
     */
    static getRelativeDateRangeValues(
        relativeDateRange: IPoliciesDateRangeFilterData,
        relativeToDate?: Date | Moment
    ): { from?: string; to?: string } {
        let from: Moment | undefined
        let to: Moment | undefined

        switch (relativeDateRange.type) {
            case PolicyRangeDateFilterType.TODAY:
                from = moment(relativeToDate).startOf("day")
                to = moment(relativeToDate).endOf("day")

                break

            case PolicyRangeDateFilterType.DAY_BEFORE:
                from = moment(relativeToDate).subtract(1, "day").startOf("day")
                to = moment(relativeToDate).endOf("day")

                break

            case PolicyRangeDateFilterType.DAY_AFTER:
                from = moment(relativeToDate).startOf("day")
                to = moment(relativeToDate).add(1, "day").endOf("day")

                break

            case PolicyRangeDateFilterType.LAST_X_DAYS:
                from = relativeDateRange.daysAmount
                    ? moment(relativeToDate)
                          .subtract(relativeDateRange.daysAmount, "days")
                          .startOf("day")
                    : undefined

                to = moment(relativeToDate).endOf("day")

                break

            case PolicyRangeDateFilterType.NEXT_X_DAYS:
                from = moment(relativeToDate).startOf("day")
                to = relativeDateRange.daysAmount
                    ? moment(relativeToDate)
                          .add(relativeDateRange.daysAmount, "days")
                          .endOf("day")
                    : undefined

                break

            case PolicyRangeDateFilterType.CUSTOM_RANGE:
                from = relativeDateRange.dateFrom
                    ? moment(relativeDateRange.dateFrom).startOf("day")
                    : undefined

                to = relativeDateRange.dateTo
                    ? moment(relativeDateRange.dateTo).endOf("day")
                    : undefined
        }

        return {
            from: from?.format(GENERAL_CONFIG.defaultMomentDateFormat),
            to: to?.format(GENERAL_CONFIG.defaultMomentDateFormat),
        }
    }

    /**
     * Get storage key that is modalities based
     */
    private static getModalitiesBasedStorageKey(
        key: string,
        availableModalities: PolicyModality[]
    ): string {
        return `${key}_${availableModalities.join("_")}`
    }

    /**
     * Get default values for policies columns configurations
     */
    private static getDefaultPoliciesColumnsConfiguration(
        browserStorageKey: string,
        defaultValues: IPolicyColumnConfiguration[]
    ): IPolicyColumnConfiguration[] {
        let result: IPolicyColumnConfiguration[] =
            PoliciesListHelper.get(
                browserStorageKey,
                undefined,
                BrowserStorageType.localStorage
            ) || defaultValues

        // Add columns that exist in defaults but don't in existing
        // Basically if added new columns it will be added to the end automatically
        const missingColumns = defaultValues.filter(
            item => !result.find(innerItem => innerItem.label === item.label)
        )

        result = [...result, ...missingColumns]

        return PoliciesListHelper.extendColumnsConfigurationsWithFunctionValues(
            result,
            defaultValues
        )
    }

    /**
     * Process additional global columns
     * Hide some of them, change order, or change default visibility
     */
    private static processAdditionalColumns(
        baseColumns: IPolicyColumnConfiguration[],
        ignoreProperty: keyof Pick<
            IExtendedPolicyColumnConfiguration,
            | "ignoreHistoricalPolicies"
            | "ignoreReports"
            | "ignoreMedicaidReports"
            | "ignoreOverrides"
            | "ignorePlanYearResets"
        >,
        orderProperty: keyof Pick<
            IExtendedPolicyColumnConfiguration,
            | "historicalPoliciesOrder"
            | "reportsOrder"
            | "medicaidReportsOrder"
            | "overridesOrder"
            | "planYearResetsOrder"
        >,
        enabledOverrideProperty: keyof Pick<
            IExtendedPolicyColumnConfiguration,
            | "isHistoricalPoliciesEnabled"
            | "isReportsEnabled"
            | "isMedicaidReportsEnabled"
            | "isOverridesEnabled"
            | "isPlanYearResetsEnabled"
        >,
        availableModalities = [PolicyModality.MENTAL_HEALTH],
        addModalitySpecificColumns = true
    ): IPolicyColumnConfiguration[] {
        // Make a copy
        baseColumns = [...baseColumns]

        const additionalColumns = addModalitySpecificColumns
            ? [
                  ...PoliciesHelper.getModalitiesSpecificColumnsConfigurations(
                      availableModalities
                  ),
              ].filter(item => !item[ignoreProperty])
            : []

        // Add columns with needed order to needed place
        for (const column of additionalColumns) {
            const orderIndex = column[orderProperty]

            if (typeof orderIndex === "undefined") {
                continue
            }

            baseColumns.splice(orderIndex, 0, column)
        }

        const result = [
            ...baseColumns,

            // Add columns without needed order to the end
            ...additionalColumns.filter(
                item => typeof item[orderProperty] === "undefined"
            ),
        ]

        // Override default visibility and being default by config from column itself
        return result.map(item => {
            const enabledOverride = item[enabledOverrideProperty]

            const isEnabled =
                typeof enabledOverride !== "undefined"
                    ? enabledOverride
                    : item.isEnabled

            const isDefaultColumn =
                typeof enabledOverride !== "undefined"
                    ? enabledOverride
                    : item.isDefaultColumn

            return {
                ...item,

                isEnabled,
                isDefaultColumn,
            }
        })
    }

    // SEARCH

    /**
     * Get default historical checks columns
     */
    private static getDefaultHistoricalChecksColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesListHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.historicalPoliciesColumns,
            "ignoreHistoricalPolicies",
            "historicalPoliciesOrder",
            "isHistoricalPoliciesEnabled",
            availableModalities
        )
    }

    // REPORTS

    /**
     * Get default reports columns
     */
    private static getDefaultReportsColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesListHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.reportsPoliciesColumns,
            "ignoreReports",
            "reportsOrder",
            "isReportsEnabled",
            availableModalities
        )
    }

    // MEDICAID

    /**
     * Get default medicaid reports columns
     */
    private static getDefaultMedicaidReportsColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesListHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.medicaidReportsPoliciesColumns,
            "ignoreMedicaidReports",
            "medicaidReportsOrder",
            "isMedicaidReportsEnabled",
            availableModalities,
            // Pass true if need to add columns that are specific to modalities to medicaid, for now not showing
            false
        )
    }

    // OVERRIDES

    /**
     * Get default overrides columns
     */
    private static getDefaultOverridesColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesListHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.overridesPoliciesColumns,
            "ignoreOverrides",
            "overridesOrder",
            "isOverridesEnabled",
            availableModalities
        )
    }

    // PLAN YEAR RESETS

    /**
     * Get default plan year resets columns
     */
    private static getDefaultPlanYearResetsColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesListHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.planYearResetsPoliciesColumns,
            "ignorePlanYearResets",
            "planYearResetsOrder",
            "isPlanYearResetsEnabled",
            availableModalities
        )
    }
}
