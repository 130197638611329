import { NIRVANA_GENERAL_CONFIG } from "nirvana-react-elements"

export const GENERAL_CONFIG = {
    // Common values from our elements library that can be overridden here if needed
    ...NIRVANA_GENERAL_CONFIG,

    defaultPageTitle: "OneVerify",

    headerHeight: 74,

    isLocal: process.env.REACT_APP_ENV === "local",
    isStaging: process.env.REACT_APP_ENV === "stage",
    isSandbox: process.env.REACT_APP_ENV === "sandbox",
    isProduction: process.env.REACT_APP_ENV === "prod",

    urlSearchParamsKeys: {
        ...NIRVANA_GENERAL_CONFIG.urlSearchParamsKeys,

        checkerMode: "checkerMode",
        create: "create",
        results: "results",
        filters: "filters",
        ignoreListReload: "ignoreListReload",
        reloadPoliciesList: "reloadPoliciesList",
    },

    browserStorageKeys: {
        ...NIRVANA_GENERAL_CONFIG.browserStorageKeys,

        appVersion: "appVersion",

        authForceResetMFA: "authNeedResetMFA",

        selectedPracticeId: "selectedPracticeId",

        policiesColumnsConfigurationSuffix: "_columnsConfiguration",
        policiesFiltersConfigurationSuffix: "_filtersConfiguration",
        policiesVisualizationCollapsedSuffix: "_visualizationCollapsed",
    },

    defaultMomentDateFormatShortYear: "MM/DD/YY",
    defaultMomentDateFormatReadable: "MMM D. YYYY",

    eagleEye: {
        headers: {
            userId: "nirvana-eagle-eye-user-id",
            practiceId: "nirvana-eagle-eye-practice-id",
        },
    },
}
