/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { PopupWrapperElement, PrimaryText } from "nirvana-react-elements"

import { ReportsFiltersComponent } from "../policies/filters/reportsFilters.component"

import closeIcon from "../../assets/images/icons/close-dark.svg"

export const PoliciesFilterPopupComponent: React.FunctionComponent<
    IPoliciesFilterPopupComponentProps
> = props => {
    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        props.onFiltersSubmitted(data)
        props.onDone()
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
            ignoreOverlay
            popupWidthClassName="
                w-screen! h-screen! rounded-none! shadow-none! p-0! bg-transparent!
            "
        >
            <div className="flex h-full">
                <div className="w-755px flex flex-col shadow-s bg-brand-offWhite md:w-full md:shadow-none">
                    <div
                        className="
                            flex items-center pl-32px pr-16px pt-24px pb-16px
                            border-b border-solid border-brand-warmLight
                        "
                    >
                        <PrimaryText typography="h3" className="flex-1 mr-24px">
                            Filters
                        </PrimaryText>

                        <div
                            className="cursor-pointer p-16px"
                            onClick={props.onDone}
                        >
                            <img src={closeIcon} alt="Close" title="Close" />
                        </div>
                    </div>

                    <ReportsFiltersComponent
                        className="w-full flex-1 overflow-y-auto"
                        listType={props.listType}
                        onFiltersSubmitted={onFiltersSubmitted}
                        existingFilters={props.existingFilters}
                        withSavingFilterPresets={props.withSavingFilterPresets}
                    />
                </div>

                <div className="flex-1 h-full backdrop-filter backdrop-blur-sm md:hidden" />
            </div>
        </PopupWrapperElement>
    )
}
