import {
    CoveragePatientType,
    PayerCoverageCheckNetwork,
} from "./coverage.config"
import { GENERAL_CONFIG } from "./general.config"
import { FeatureFlag } from "./featureFlags.config"
import { CoverageCheckerCheckType } from "./checker.config"

export enum ExportDataSource {
    inputData = "inputData",
    outputData = "outputData",
    practiceRole = "practiceRole",
    checkTypeSpecificData = "checkTypeSpecificData",
}

export const CSV_CHECKER_CONFIG = {
    get typeTabs(): ICheckerTypeTab[] {
        return [
            {
                key: CoverageCheckerCheckType.CONTINUOUS_MONITORING,
                label: "Continuous monitoring",
                featureFlag:
                    FeatureFlag.ONE_VERIFY_CONTINUOUS_MONITORING_MANUAL_CHECK,
            },
            {
                key: CoverageCheckerCheckType.MEDICAID,
                label: "Medicaid",
                featureFlag: FeatureFlag.ONE_VERIFY_MEDICAID_MANUAL_CHECK,
            },
        ]
    },

    maxBulkCoverageChecksCSV: 2 * 1000, // 2 000 at a time checks
    maxBulkCoverageChecksCSVExtended: 50 * 1000, // 50 000 at a time checks

    downloadFileName: "Nirvana OneVerify - {{checksType}} Results {{time}}.csv",

    get csvCheckerTemplateUrl(): Record<
        CoverageCheckerCheckType,
        string | null
    > {
        return {
            [CoverageCheckerCheckType.CONTINUOUS_MONITORING]:
                "https://nirvana-assets.s3.amazonaws.com/nirvana-coverage-template-v2.3.csv",

            [CoverageCheckerCheckType.MEDICAID]:
                "https://nirvana-assets.s3.us-east-1.amazonaws.com/nirvana-medicaid-coverage-template-v1.0.csv",

            [CoverageCheckerCheckType.INTAKE]: null,
        }
    },

    // Should come in order we expect it in csv
    get uploadCsvHeaders(): Record<CoverageCheckerCheckType, string[]> {
        return {
            [CoverageCheckerCheckType.CONTINUOUS_MONITORING]: [
                "healthProviderNpi",
                "cptCode",
                "sessionCharge",
                "payerExternalId",
                "memberId",
                "dob",
                "firstName",
                "lastName",
                "network",
                "customerPatientType",
                "customerPatientId",
                "customerPatientNextAppointmentDate",
            ],

            [CoverageCheckerCheckType.MEDICAID]: [
                "healthProviderNpi",
                "state",
                "dob",
                "firstName",
                "lastName",
                "memberId",
                "cptCode",
                "customerPatientType",
                "customerPatientId",
                "customerPatientNextAppointmentDate",
            ],

            [CoverageCheckerCheckType.INTAKE]: [],
        }
    },

    get uploadedCsvPreviewMapping(): Record<
        CoverageCheckerCheckType,
        ICsvFieldsMapping[]
    > {
        const commonCustomerData: ICsvFieldsMapping[] = [
            {
                header: `Patient Type (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientType",
                },
            },
            {
                header: `Patient ID (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientId",
                },
            },
            {
                header: `Next Appointment Date (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientNextAppointmentDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },
        ]

        const providerFields: ICsvFieldsMapping[] = [
            {
                header: "Provider NPI",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customNpi",
                },
                alternativeDataSource: {
                    source: ExportDataSource.practiceRole,
                    valuePath: "practice.groupNPI",
                },
            },
            {
                header: "CPT Code",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "cptCode",
                },
            },
        ]

        const commonPatientFields: ICsvFieldsMapping[] = [
            {
                header: "Member Id",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "memberId",
                },
            },
            {
                header: "Member DOB",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "dob",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },
            {
                header: "Member First Name",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "firstName",
                },
            },
            {
                header: "Member Last Name",
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "lastName",
                },
            },
        ]

        return {
            [CoverageCheckerCheckType.CONTINUOUS_MONITORING]: [
                ...providerFields,

                {
                    header: "Provider Session Cost",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "sessionRate",
                    },
                    formatting: {
                        currency: true,
                    },
                },
                {
                    header: `Payer Id`,
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "payer.payerId",
                    },
                },

                ...commonPatientFields,

                {
                    header: `Network`,
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "inNetworkCheck",
                    },
                    formatting: {
                        isNetworkTypeValue: true,
                    },
                },

                ...commonCustomerData,
            ],

            [CoverageCheckerCheckType.MEDICAID]: [
                {
                    header: `Member State`,
                    dataSource: {
                        source: ExportDataSource.checkTypeSpecificData,
                        valuePath: "state",
                    },
                },

                ...commonPatientFields,

                ...providerFields,

                ...commonCustomerData,
            ],

            [CoverageCheckerCheckType.INTAKE]: [],
        }
    },

    get resultsCorrectedDataCsvMapping(): ICsvFieldsMapping[] {
        return [
            {
                header: `Corrected Payer ID (${GENERAL_CONFIG.supportedInsurersUrl})`,
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "overriddenPayer.payerId",
                },
                alternativeDataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "payer.payerId",
                },
                dataCompareWith: {
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "payer.payerId",
                    },
                },
            },
            {
                header: "Corrected Member First Name",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.firstName",
                },
                dataCompareWith: {
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "firstName",
                    },
                },
            },
            {
                header: "Corrected Member Last Name",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.lastName",
                },
                dataCompareWith: {
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "lastName",
                    },
                },
            },
            {
                header: "Corrected Member ID",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.memberId",
                },
                dataCompareWith: {
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "memberId",
                    },
                },
            },
            {
                header: "Corrected Member DOB",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "demographics.dob",
                },
                dataCompareWith: {
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "dob",
                    },
                },
            },
        ]
    },

    get resultsCsvMapping(): Record<
        CoverageCheckerCheckType,
        ICsvFieldsMapping[]
    > {
        const commonResultsData: ICsvFieldsMapping[] = [
            {
                header: "Time Stamp of the Coverage Check",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "createdAt",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateTimeFormat,
                },
            },
            {
                header: "Response Status",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "responseStatus",
                },
            },
            {
                header: "Request Id",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "requestId",
                },
            },
            {
                header: "Result Type",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "resultType",
                },
            },
            {
                header: "Coverage Status",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "resultType",
                },
                formatting: {
                    isCoverageStatusValue: true,
                },
            },
            {
                header: "Plan Status",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planStatus",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Plan Begin Date",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planBeginDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Plan End Date",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planEndDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Plan Type",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planType",
                },
                formatting: {
                    planType: true,
                },
            },
            {
                header: "Plan Name",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "planName",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Group Name",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "groupName",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
            {
                header: "Group ID",
                dataSource: {
                    source: ExportDataSource.outputData,
                    valuePath: "groupId",
                },
                formatting: {
                    convertOnEmptyToUnknown: true,
                },
            },
        ]

        const commonInputData: ICsvFieldsMapping[] = [
            {
                header: `Patient Type (optional). Options: ${Object.values(
                    CoveragePatientType
                ).join("; ")}`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientType",
                },
            },
            {
                header: `Patient ID (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientId",
                },
            },
            {
                header: `Next Appointment Date (optional)`,
                dataSource: {
                    source: ExportDataSource.inputData,
                    valuePath: "customerPatientNextAppointmentDate",
                },
                formatting: {
                    dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                },
            },
        ]

        return {
            [CoverageCheckerCheckType.CONTINUOUS_MONITORING]: [
                // INPUT DATA
                {
                    header: "Provider NPI",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "customNpi",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.practiceRole,
                        valuePath: "practice.groupNPI",
                    },
                },
                {
                    header: "CPT Code",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "cptCode",
                    },
                },
                {
                    header: "Provider Session Cost",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "sessionRate",
                    },
                    formatting: {
                        currency: true,
                    },
                },
                {
                    header: `Payer Id (${GENERAL_CONFIG.supportedInsurersUrl})`,
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "payer.payerId",
                    },
                },
                {
                    header: "Member Id",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.memberId",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "memberId",
                    },
                },
                {
                    header: "Member DOB",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "dob",
                    },
                    formatting: {
                        dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                    },
                },
                {
                    header: "Member First Name (optional)",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.firstName",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "firstName",
                    },
                },
                {
                    header: "Member Last Name (optional)",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.lastName",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "lastName",
                    },
                },
                {
                    header: `Network (optional). Options: ${Object.values(
                        PayerCoverageCheckNetwork
                    ).join("; ")}`,
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "isInNetworkCheck",
                    },
                    formatting: {
                        isNetworkTypeValue: true,
                    },
                },

                ...commonInputData,

                // RESULTS BELOW
                ...commonResultsData,

                {
                    header: "Copayment",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "copayment",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Coinsurance",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "coinsurance",
                    },
                    formatting: {
                        suffixValue: "%",
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Total Deductible",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "deductible",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Remaining Deductible",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "remainingDeductible",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Total Out-of-pocket Max",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "oopMax",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Remaining Out-of-pocket Max",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "remainingOopMax",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Estimated Member Obligation",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "memberObligation",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Estimated Payer Obligation",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "payerObligation",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Estimated Post Deductible Session Rate",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "postDeductibleMemberObligation",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Estimated Post Out-of-pocket Max Session Rate",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "postOopMaxMemberObligation",
                    },
                    formatting: {
                        currency: true,
                        isCents: true,
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Estimated Remaining Sessions Before Deductible",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "remainingSessionsBeforeDeductible",
                    },
                    formatting: {
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Estimated Remaining Sessions Before Out-of-pocket Max",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "remainingSessionsBeforeOopMax",
                    },
                    formatting: {
                        convertOnEmptyToUnknown: true,
                    },
                },
                {
                    header: "Member Gender",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.gender",
                    },
                    formatting: {
                        gender: true,
                    },
                },
                {
                    header: "Member Address",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.address",
                    },
                    formatting: {
                        convertOnEmptyToUnknown: true,
                        address: true,
                    },
                },
                {
                    header: "Third Party Payer",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "thirdPartyPayer",
                    },
                },

                ...CSV_CHECKER_CONFIG.resultsCorrectedDataCsvMapping,

                {
                    header: "Error Code",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "coverageError.code",
                    },
                },
                {
                    header: "Error Message",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "coverageError.message",
                    },
                },
            ],

            [CoverageCheckerCheckType.MEDICAID]: [
                // INPUT DATA
                {
                    header: "Provider NPI",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "customNpi",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.practiceRole,
                        valuePath: "practice.groupNPI",
                    },
                },
                {
                    header: "Member State (two-letter state abbreviation)",
                    dataSource: {
                        source: ExportDataSource.checkTypeSpecificData,
                        valuePath: "state",
                    },
                },
                {
                    header: "Member DOB",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "dob",
                    },
                    formatting: {
                        dateFormat: GENERAL_CONFIG.defaultMomentDateFormat,
                    },
                },
                {
                    header: "Member First Name",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.firstName",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "firstName",
                    },
                },
                {
                    header: "Member Last Name",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.lastName",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "lastName",
                    },
                },
                {
                    header: "Member Id (optional but recommended / required for some states. If the member ID isn’t available Nirvana can try to locate the policy)",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.memberId",
                    },
                    alternativeDataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "memberId",
                    },
                },
                {
                    header: "CPT Code (optional)",
                    dataSource: {
                        source: ExportDataSource.inputData,
                        valuePath: "cptCode",
                    },
                },

                ...commonInputData,

                // RESULTS BELOW
                {
                    header: "MCO",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "managedCareOrganization",
                    },
                },
                {
                    header: "Eligibility Type",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "eligibilityType",
                    },
                },

                ...commonResultsData,

                {
                    header: "Member Gender",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.gender",
                    },
                    formatting: {
                        gender: true,
                    },
                },
                {
                    header: "Member Address",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "demographics.address",
                    },
                    formatting: {
                        convertOnEmptyToUnknown: true,
                        address: true,
                    },
                },
                {
                    header: "Third Party Payer",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "thirdPartyPayer",
                    },
                },
                {
                    header: "Error Code",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "coverageError.code",
                    },
                },
                {
                    header: "Error Message",
                    dataSource: {
                        source: ExportDataSource.outputData,
                        valuePath: "coverageError.message",
                    },
                },
            ],

            [CoverageCheckerCheckType.INTAKE]: [],
        }
    },
}
