import { ResourcesConfig } from "@aws-amplify/core"

export const AWS_CONFIG = {
    get amplify(): ResourcesConfig {
        return {
            Auth: {
                Cognito: {
                    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID || "",
                    userPoolClientId:
                        process.env.REACT_APP_COGNITO_APP_CLIENT_ID || "",
                },
            },
        }
    },
}
