/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { PrimaryText, TooltipElement } from "nirvana-react-elements"

import copyIcon from "../../../assets/images/icons/copy-dark.svg"
import checkIcon from "../../../assets/images/icons/check-dark.svg"

export const CoverageInputDataDifferenceComponent: React.FunctionComponent<
    ICoverageInputDataDifferenceComponentProps
> = props => {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    const isOverriddenDataDifferent = useMemo<boolean>(() => {
        return (
            !!props.initialData &&
            !!props.overridenData &&
            props.initialData.toLowerCase().trim() !==
                props.overridenData.toLowerCase().trim()
        )
    }, [props.initialData, props.overridenData])

    useEffect(() => {
        if (!isCopied) {
            return
        }

        const timeoutId = setTimeout(() => setIsCopied(false), 3 * 1000)

        return () => {
            try {
                clearTimeout(timeoutId)
            } catch (e) {}
        }
    }, [isCopied])

    const getHighlightedTextDifference = (
        overridenData: string,
        initialData?: string
    ): JSX.Element => {
        return (
            <>
                {overridenData.split("").map((newChar, index) => {
                    return (
                        <span
                            key={index}
                            className={
                                !initialData ||
                                newChar.toLowerCase() !==
                                    initialData.charAt(index)?.toLowerCase()
                                    ? "text-semibold"
                                    : undefined
                            }
                        >
                            {newChar}
                        </span>
                    )
                })}
            </>
        )
    }

    return (
        <div className={`relative ${props.className}`}>
            <PrimaryText
                typography="text"
                className={
                    isOverriddenDataDifferent
                        ? `
                            flex items-center
                            pt-4px relative left--8px bg-brand-white
                            px-8px rounded-4px border border-solid border-brand-black-transparent-010
                        `
                        : ""
                }
            >
                <div className="flex-1 mouseflow-ignore">
                    {isOverriddenDataDifferent && props.overridenData
                        ? getHighlightedTextDifference(
                              props.overridenData,
                              props.initialData
                          )
                        : props.initialData}
                </div>

                {isOverriddenDataDifferent && props.overridenData && (
                    <>
                        <CopyToClipboard
                            text={props.overridenData}
                            onCopy={() => {
                                setIsCopied(true)
                            }}
                        >
                            <img
                                className="inline-block cursor-pointer ml-16px"
                                src={isCopied ? checkIcon : copyIcon}
                                alt="Copy"
                            />
                        </CopyToClipboard>

                        {props.initialData && (
                            <TooltipElement
                                className="ml-4px"
                                text={`You entered: <u>${props.initialData}</u>`}
                            />
                        )}
                    </>
                )}
            </PrimaryText>
        </div>
    )
}
