/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import * as PapaParse from "papaparse"
import {
    AvailableLookupConsumer,
    ButtonElement,
    DropZoneFileElement,
    IFileToUpload,
    PrimaryText,
    useCptCodes,
} from "nirvana-react-elements"

import {
    checkerSetContinuousMonitoringInputData,
    checkerSetMedicaidInputData,
    checkerSetRunningState,
} from "../../../store/slices/checker.slice"
import {
    CoverageCheckerCheckType,
    CoverageCheckerRunningState,
} from "../../../config/checker.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { profileSelector } from "../../../store/selectors/profile.selector"
import { UtilHelper } from "../../../helpers/util.helper"
import { OrganizationQuoteComponent } from "../../general/quotaChecksProgressComponent.component"
import { CsvHelper } from "../../../helpers/csv.helper"
import { CheckTypeSelectorComponent } from "../checkTypeSelector.component"
import { CSV_CHECKER_CONFIG } from "../../../config/csvChecker.config"
import { ToastrHelper } from "../../../helpers/toastr.helper"

import fileIcon from "../../../assets/images/icons/file-dark.svg"
import hamburgerIcon from "../../../assets/images/icons/hamburger-white.svg"

export const UploadCsvComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const checkerState = useAppSelector(checkerSelector)
    const profile = useAppSelector(profileSelector)

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const [providedCsvFile, setProvidedCsvFile] = useState<IFileToUpload>()

    const templateUrl = useMemo<string | null>(() => {
        return CSV_CHECKER_CONFIG.csvCheckerTemplateUrl[
            checkerState.csvCheckType
        ]
    }, [checkerState.csvCheckType])

    // https://www.papaparse.com/
    const processCsv = () => {
        if (!providedCsvFile?.rawFile) {
            return
        }

        dispatch(
            checkerSetRunningState(CoverageCheckerRunningState.PROCESS_CSV)
        )

        PapaParse.parse(providedCsvFile.rawFile, {
            header: true,

            // Since in our csv we have some random values for header
            // We'd like to transform it to what code expects
            transformHeader(header: string, index: number): string {
                return (
                    CSV_CHECKER_CONFIG.uploadCsvHeaders[
                        checkerState.csvCheckType
                    ][index] || header
                )
            },

            complete: async csv => {
                let processedInputData:
                    | IContinuousMonitoringCoverageCheckInputData[]
                    | IMedicaidCheckerInputData[]
                    | null = null

                const maxBulkCoverageChecksCSV = UtilHelper.isInternalUser(
                    profile
                )
                    ? CSV_CHECKER_CONFIG.maxBulkCoverageChecksCSVExtended
                    : CSV_CHECKER_CONFIG.maxBulkCoverageChecksCSV

                switch (checkerState.csvCheckType) {
                    case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                        processedInputData =
                            await CsvHelper.processUploadedContinuousMonitoringCsv(
                                csv,
                                availableCptCodes,
                                checkerState.csvCheckType,
                                selectedPracticeRole,
                                maxBulkCoverageChecksCSV
                            )

                        processedInputData &&
                            dispatch(
                                checkerSetContinuousMonitoringInputData(
                                    processedInputData
                                )
                            )

                        break

                    case CoverageCheckerCheckType.MEDICAID:
                        processedInputData =
                            await CsvHelper.processUploadedMedicaidCsv(
                                csv,
                                availableCptCodes,
                                checkerState.csvCheckType,
                                selectedPracticeRole,
                                maxBulkCoverageChecksCSV
                            )

                        processedInputData &&
                            dispatch(
                                checkerSetMedicaidInputData(processedInputData)
                            )

                        break

                    case CoverageCheckerCheckType.INTAKE:
                        ToastrHelper.error("Intake CSV is not supported yet.")

                        break
                }

                if (processedInputData) {
                    // So breathing loader looks good
                    await UtilHelper.sleep(2)

                    dispatch(
                        checkerSetRunningState(
                            CoverageCheckerRunningState.PREVIEW_CSV
                        )
                    )
                }
            },
        })
    }

    return (
        <>
            <OrganizationQuoteComponent className="max-w-800px mt-24px" />

            <CheckTypeSelectorComponent isCsvTypeSelector className="mt-24px" />

            <div
                className={`
                    ${props.className}
                    relative max-w-800px rounded-8px
                    p-24px bg-brand-warmLight025
                `}
            >
                <PrimaryText typography="text">
                    Nirvana can process .csv files automatically.
                    {templateUrl
                        ? ` For the format that we require, please download template below ${
                              checkerState.csvCheckType ===
                              CoverageCheckerCheckType.CONTINUOUS_MONITORING
                                  ? " and also make sure to check out payers that we support."
                                  : "."
                          }`
                        : ""}
                </PrimaryText>

                {templateUrl ? (
                    <div className="mt-24px flex items-center justify-center">
                        <div>
                            <a
                                href={templateUrl}
                                target="_blank"
                                rel="noreferrer"
                                download
                            >
                                <ButtonElement
                                    label="CSV Template"
                                    type="default"
                                    size="middle"
                                    icon={fileIcon}
                                    htmlType="button"
                                />
                            </a>
                        </div>

                        {checkerState.csvCheckType ===
                        CoverageCheckerCheckType.CONTINUOUS_MONITORING ? (
                            <div className="ml-24px">
                                <a
                                    href={GENERAL_CONFIG.supportedInsurersUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    download
                                >
                                    <ButtonElement
                                        label="Supported Payers"
                                        type="default"
                                        size="middle"
                                        icon={fileIcon}
                                        htmlType="button"
                                    />
                                </a>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <DropZoneFileElement
                    className="mt-32px w-full"
                    withRawFile={true}
                    onFileAccepted={file => setProvidedCsvFile(file)}
                    onFileCancel={() => setProvidedCsvFile(undefined)}
                    acceptMimes={{
                        "text/csv": [],
                    }}
                />

                <ButtonElement
                    className="text-center mt-32px"
                    label="Process"
                    type="primary"
                    size="large"
                    htmlType="button"
                    buttonClassName="w-full"
                    icon={hamburgerIcon}
                    disabled={!providedCsvFile?.rawFile}
                    onClick={processCsv}
                />
            </div>
        </>
    )
}
