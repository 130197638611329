/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { PrimaryText } from "nirvana-react-elements"

import {
    POLICIES_CONFIG,
    ResetBenefitStatus,
} from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { UtilHelper } from "../../../helpers/util.helper"
import { PoliciesAggregationsChartComponent } from "../policiesAggregationsChart.component"
import { PoliciesAggregationsChartLegendComponent } from "../policiesAggregationsChartLegend.component"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"

export const PlanYearResetVisualizationGeneralComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const { planYearResetsAggregations } = useAppSelector(policiesSelector)

    const [hoveredLabel, setHoveredLabel] = useState<string>()

    const aggregatedCalculations = useMemo<
        IPoliciesAggregatedChartValues[] | null
    >(() => {
        if (!planYearResetsAggregations?.totalPoliciesPlanYearReset) {
            return null
        }

        const result = [] as IPoliciesAggregatedChartValues[]

        for (const resetBenefitStatus of Object.values(ResetBenefitStatus)) {
            result.push(
                PoliciesListHelper.mapPlanYearResetsStatusToChartValues(
                    resetBenefitStatus,
                    planYearResetsAggregations.statusAggregations[
                        resetBenefitStatus
                    ] || 0,
                    planYearResetsAggregations.totalPoliciesPlanYearReset
                )
            )
        }

        return result
    }, [JSON.stringify(planYearResetsAggregations)])

    return planYearResetsAggregations ? (
        <div
            className={`
                relative px-24px
                ${props.className}
            `}
        >
            <PrimaryText centered size={29} lineHeight={35} weight="medium">
                Total Policies Resetting:{" "}
                <span className="font-bold">
                    {planYearResetsAggregations.totalPoliciesPlanYearReset}
                </span>
            </PrimaryText>

            {planYearResetsAggregations.totalPolicies ? (
                <PrimaryText centered>
                    {UtilHelper.getPercentageValue(
                        planYearResetsAggregations.totalPoliciesPlanYearReset,
                        planYearResetsAggregations.totalPolicies
                    )}
                    % of all {planYearResetsAggregations.totalPolicies} plans
                </PrimaryText>
            ) : null}

            {aggregatedCalculations?.length ? (
                <>
                    <div className="mt-16px w-full mx-auto md:w-300px">
                        <PoliciesAggregationsChartComponent
                            className="mx-auto"
                            data={aggregatedCalculations}
                            hoveredLabel={hoveredLabel}
                            widthPercentage={75}
                        />
                    </div>

                    <PoliciesAggregationsChartLegendComponent
                        className="
                            mt-16px w-10/12 mx-auto
                            md:w-full
                        "
                        data={aggregatedCalculations}
                        label={`${POLICIES_CONFIG.planYearResetsTargetYear} Status`}
                        onLabelHover={setHoveredLabel}
                    />
                </>
            ) : null}
        </div>
    ) : null
}
