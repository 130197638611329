/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { CheckboxElement, PrimaryText } from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"

import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../../../config/featureFlags.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"

export const PoliciesListRowComponent: React.FunctionComponent<
    IPoliciesListRowComponentProps
> = props => {
    const navigate = useNavigate()

    const isOverridesEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_OVERRIDES
    )

    const onNavigateToPolicy = () => {
        if (props.ignoreNavigateToPolicy) {
            return
        }

        const neededUrl = PoliciesHelper.getSinglePolicyBaseUrl(
            props.listType
        ).replace(":nirvanaRequestId", props.policy.coverageResult.resultId)

        navigate(neededUrl)
    }

    return (
        <div
            ref={props.elementRef}
            className={`
                ${props.className}
                flex group relative
            `}
        >
            <div className="table-row w-full">
                <div
                    className={`
                        min-h-55px
                        ${!props.ignoreHorizontalPadding ? "px-16px" : ""}
                        ${
                            !props.ignoreRowBorder
                                ? "border-b border-solid border-brand-warmLight"
                                : ""
                        }
                        ${!props.ignoreNavigateToPolicy ? "cursor-pointer" : ""}
                        ${
                            props.isExternallySelected
                                ? "bg-brand-lilacLight"
                                : `bg-transparent ${
                                      props.highlightOnHover
                                          ? "hover:bg-brand-whitePurple"
                                          : ""
                                  }`
                        }
                    `}
                    onClick={onNavigateToPolicy}
                >
                    <div className="flex items-stretch">
                        {props.isSelectable && (
                            <div
                                className="mr-16px relative top-16px"
                                onClick={e => e.stopPropagation()}
                            >
                                <CheckboxElement
                                    name="select"
                                    defaultChecked={props.isExternallySelected}
                                    watchDefaultCheckedChange={true}
                                    onChange={newValue =>
                                        newValue
                                            ? props.onSelected?.(props.policy)
                                            : props.onDeselectedItem?.(
                                                  props.policy
                                              )
                                    }
                                    disabled={props.isSelectionCheckboxDisabled}
                                />
                            </div>
                        )}

                        {props.columns.map((columnConfig, index) => {
                            if (!columnConfig.isEnabled) {
                                return null
                            }

                            const isCellHighlighted =
                                isOverridesEnabled &&
                                PoliciesHelper.isColumnCellHighlighted(
                                    props.policy,
                                    columnConfig
                                )

                            return (
                                <div
                                    key={index}
                                    className={`
                                        py-16px mr-16px flex-shrink-0
                                        ${
                                            columnConfig.widthClassName ||
                                            "w-250px"
                                        }
                                        ${
                                            isCellHighlighted
                                                ? "bg-brand-greenHighlight px-16px border-b-2px border-solid border-brand-primary"
                                                : ""
                                        }
                                    `}
                                >
                                    <PrimaryText
                                        className={
                                            columnConfig.mouseflowIgnore
                                                ? "mouseflow-ignore"
                                                : ""
                                        }
                                    >
                                        {PoliciesHelper.getMappedColumnValue(
                                            props.policy,
                                            columnConfig
                                        )}
                                    </PrimaryText>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
