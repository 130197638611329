import { createSlice } from "@reduxjs/toolkit"

import {
    reportSavedFiltersCreate,
    reportSavedFiltersDelete,
    reportSavedFiltersGetList,
} from "../thunks/reportSavedFilters.thunks"

const initialState: IReportsSavedFiltersState = {
    savedFilters: {},
}

const reportSavedFiltersSlice = createSlice({
    name: "reportSavedFilters",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            reportSavedFiltersGetList.fulfilled,
            (state, action) => {
                state.savedFilters[action.payload.reportType] =
                    action.payload.items
            }
        )

        builder.addCase(reportSavedFiltersCreate.fulfilled, (state, action) => {
            if (!action.payload.item) {
                return
            }

            const existingItems: IReportSavedFilter[] =
                state.savedFilters[action.payload.reportType] || []

            state.savedFilters[action.payload.reportType] = [
                action.payload.item,

                ...existingItems,
            ]
        })

        builder.addCase(reportSavedFiltersDelete.fulfilled, (state, action) => {
            if (!action.payload.deletedId) {
                return
            }

            const existingItems: IReportSavedFilter[] =
                state.savedFilters[action.payload.reportType] || []

            state.savedFilters[action.payload.reportType] =
                existingItems.filter(
                    item => item.id !== action.payload.deletedId
                )
        })
    },
})

// export const {} = reportSavedFiltersSlice.actions

export const reportSavedFiltersReducer = reportSavedFiltersSlice.reducer
