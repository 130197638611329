/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { PrimaryText, SpinnerElement } from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesGetMedicaidReportsAggregationsActivePlanType } from "../../../store/thunks/policies.thunks"
import { PoliciesAggregationsChartComponent } from "../policiesAggregationsChart.component"
import { UtilHelper } from "../../../helpers/util.helper"
import { PoliciesAggregationsChartLegendComponent } from "../policiesAggregationsChartLegend.component"
import { POLICIES_CONFIG } from "../../../config/policies.config"

export const MedicaidActiveChecksVisualizationComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const { medicaidFilters, medicaidAggregationsActivePlanType } =
        useAppSelector(policiesSelector)

    const [hoveredLabel, setHoveredLabel] = useState<string>()

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isMedicaidAggregationsPlanTypeLoading()
    }, [runtimeState.isLoading])

    const aggregatedCalculations = useMemo<IPoliciesAggregatedChartValues[]>(
        () =>
            medicaidAggregationsActivePlanType
                ? POLICIES_CONFIG.medicaidAggregationsConfigs.map(config => {
                      const value =
                          medicaidAggregationsActivePlanType.aggregations.ACTIVE
                              .detail?.[config.planType] || 0

                      return {
                          label: config.label,
                          color: config.color,
                          value,
                          percentage: UtilHelper.getPercentageValue(
                              value,
                              medicaidAggregationsActivePlanType.aggregations
                                  .ACTIVE.total || 0
                          ),
                      }
                  })
                : [],
        [medicaidAggregationsActivePlanType]
    )

    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        const thunkPromise = dispatch(
            policiesGetMedicaidReportsAggregationsActivePlanType({
                practice: selectedPracticeRole.practice,
                payload: {
                    filters: medicaidFilters,
                },
            })
        )

        return () => {
            thunkPromise.abort()
        }
    }, [selectedPracticeRole?.practice.id, JSON.stringify(medicaidFilters)])

    return (
        <div
            className={`
                relative min-h-550px
                ${props.className}
            `}
        >
            {isLoading ? (
                <div
                    className="
                        absolute z-10 w-full h-full bg-brand-whiteTransparent85
                        flex items-center justify-center
                    "
                >
                    <SpinnerElement />
                </div>
            ) : null}

            {medicaidAggregationsActivePlanType && (
                <div className="py-40px px-24px">
                    <PrimaryText
                        centered
                        size={29}
                        lineHeight={34}
                        weight="bold"
                    >
                        Active:{" "}
                        {medicaidAggregationsActivePlanType.aggregations.ACTIVE
                            .total || 0}
                    </PrimaryText>
                    <PrimaryText centered size={18} lineHeight={24}>
                        of {medicaidAggregationsActivePlanType.total} Total
                        Checks (
                        {UtilHelper.getPercentageValue(
                            medicaidAggregationsActivePlanType.aggregations
                                .ACTIVE.total || 0,
                            medicaidAggregationsActivePlanType.total
                        )}
                        %)
                    </PrimaryText>

                    <PoliciesAggregationsChartComponent
                        className="mt-16px mx-auto"
                        data={aggregatedCalculations}
                        hoveredLabel={hoveredLabel}
                        widthPercentage={80}
                    />

                    <PoliciesAggregationsChartLegendComponent
                        className="mt-16px"
                        data={aggregatedCalculations}
                        label="Plan Type"
                        onLabelHover={setHoveredLabel}
                    />
                </div>
            )}
        </div>
    )
}
