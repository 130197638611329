/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { ISelectRawOption, PrimaryText } from "nirvana-react-elements"

import { POLICIES_CONFIG } from "../../../config/policies.config"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"

export const PoliciesFiltersDisplayComponent: React.FunctionComponent<
    IPoliciesFiltersDisplayComponentProps
> = props => {
    const mappedFilters = useMemo<ISelectRawOption[]>(() => {
        return PoliciesListHelper.getMappedDisplayFilters(props.filters)
    }, [props.filters])

    return mappedFilters.length ? (
        <div
            className={`
                relative flex items-start
                ${props.className}
            `}
        >
            <PrimaryText
                className="mr-8px mt-12px flex-shrink-0"
                typography="h6"
            >
                Filtering by:
            </PrimaryText>

            <div className="mt-9px flex items-center flex-wrap gap-2">
                {mappedFilters.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="px-16px pt-8px pb-6px rounded-22px bg-brand-warmLight"
                        >
                            <PrimaryText
                                className={`
                                    ${
                                        item.key &&
                                        POLICIES_CONFIG.filtersMouseFlowIgnoreFields.includes(
                                            item.key as keyof IPoliciesListFiltersData
                                        )
                                            ? "mouseflow-ignore"
                                            : ""
                                    }
                                `}
                                size={14}
                                lineHeight={14}
                            >
                                {item.label}: {item.value}
                            </PrimaryText>
                        </div>
                    )
                })}
            </div>
        </div>
    ) : null
}
