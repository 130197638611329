export enum LoginStep {
    PASSWORD = "PASSWORD",
    MFA_PHONE_COLLECTION = "MFA_PHONE_COLLECTION",
    MFA_CODE_COLLECTION = "MFA_CODE_COLLECTION",
}

export const AUTH_CONFIG = {
    mfaCodeSendMaxAttempts: 5,
    mfaCodeSendDelayMS: 60 * 1000,
    rememberedDeviceExpirationDays: 14,
}
