import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import { FeatureFlag } from "../../config/featureFlags.config"
import { runtimeCheckUserIsLoggedIn } from "../thunks/runtime.thunks"

const initialState: IRuntimeState = {
    isLoggedIn: null,
    isLoading: [],
    enabledFeatureFlags: null,
}

const runtimeSlice = createSlice({
    name: "runtime",
    initialState,
    reducers: {
        runtimeReset: () => initialState,

        runtimeStartLoading: (state, action: PayloadAction<string>) => {
            state.isLoading = [...state.isLoading, action.payload]
        },
        runtimeStopLoading: (state, action: PayloadAction<string>) => {
            state.isLoading = state.isLoading.filter(
                item => item !== action.payload
            )
        },
        runtimeSetEnabledFeatureFlags: (
            state,
            action: PayloadAction<FeatureFlag[] | null>
        ) => {
            state.enabledFeatureFlags = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(
            runtimeCheckUserIsLoggedIn.fulfilled,
            (state, action) => {
                state.isLoggedIn = action.payload
            }
        )
    },
})

export const {
    runtimeReset,
    runtimeStartLoading,
    runtimeStopLoading,
    runtimeSetEnabledFeatureFlags,
} = runtimeSlice.actions

export const runtimeReducer = runtimeSlice.reducer
