import React from "react"
import { useNavigate } from "react-router-dom"

import { useAppSelector } from "../../../../store/selectors/app.selector"
import { authSelector } from "../../../../store/selectors/auth.selector"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { authProcessMfaCode } from "../../../../store/thunks/auth.thunks"
import { CodeForm } from "../../mfa/codeForm"

export const CodeStep: React.FunctionComponent<{
    className?: string
}> = props => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const authState = useAppSelector(authSelector)

    const onSubmit = (code: string, rememberDevice?: boolean) => {
        dispatch(
            authProcessMfaCode({
                payload: {
                    code,
                    withLoggingIn: true,
                    rememberDevice,
                },
                navigate,
            })
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <CodeForm
                isCtaLoading={authState.isLoading}
                onSubmit={onSubmit}
                withTopIcon
            />
        </div>
    )
}
