/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText } from "nirvana-react-elements"

import {
    POLICIES_CONFIG,
    ResetBenefitStatus,
} from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { UtilHelper } from "../../../helpers/util.helper"
import { PoliciesAggregationsChartLegendComponent } from "../policiesAggregationsChartLegend.component"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"

export const PlanYearResetVisualizationPayersComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const { planYearResetsAggregations } = useAppSelector(policiesSelector)

    const payersMappedData = useMemo<
        IPlanYearResetsAggregationPayersData[] | null
    >(() => {
        if (!planYearResetsAggregations?.payerAggregations.length) {
            return null
        }

        const result = [] as IPlanYearResetsAggregationPayersData[]

        for (const item of planYearResetsAggregations.payerAggregations) {
            const innerData: IPlanYearResetsAggregationPayersData = {
                payerName: item.name,
                externalIds: item.externalIds,
                amount: item.totalPoliciesPlanYearReset,
                percentage: UtilHelper.getPercentageValue(
                    item.totalPoliciesPlanYearReset,
                    planYearResetsAggregations.totalPoliciesPlanYearReset
                ),
                statusAggregations: [],
            }

            for (const resetBenefitStatus of Object.values(
                ResetBenefitStatus
            )) {
                innerData.statusAggregations.push(
                    PoliciesListHelper.mapPlanYearResetsStatusToChartValues(
                        resetBenefitStatus,
                        item.statusAggregations[resetBenefitStatus] || 0,
                        item.totalPoliciesPlanYearReset
                    )
                )
            }

            result.push(innerData)
        }

        return result
    }, [JSON.stringify(planYearResetsAggregations)])

    return payersMappedData?.length ? (
        <div
            className={`
                relative px-24px
                ${props.className}
            `}
        >
            <PrimaryText typography="h4">
                {POLICIES_CONFIG.planYearResetsTargetYear} Policies Detected by
                Major Payers
            </PrimaryText>

            {payersMappedData.map((item, index) => {
                return (
                    <div className="mt-22px" key={index}>
                        <PrimaryText typography="subtitle">
                            <span className="text-bold">
                                {item.payerName}: {item.amount}
                            </span>{" "}
                            ({item.percentage}% of total #)
                        </PrimaryText>

                        <div
                            className="
                                flex items-center mt-12px w-443px
                                md:w-full
                            "
                        >
                            {item.statusAggregations.map((innerItem, index) => (
                                <div
                                    key={index}
                                    className={`h-9px ${
                                        innerItem.bordered &&
                                        innerItem.percentage
                                            ? "border border-solid border-brand-warmShadow"
                                            : ""
                                    }`}
                                    style={{
                                        width: `${innerItem.percentage}%`,
                                        backgroundColor: innerItem.color,
                                    }}
                                />
                            ))}
                        </div>

                        <PoliciesAggregationsChartLegendComponent
                            className="mt-10px"
                            data={item.statusAggregations}
                            compact
                        />
                    </div>
                )
            })}
        </div>
    ) : null
}
