/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText, TooltipElement } from "nirvana-react-elements"

import {
    COVERAGE_CONFIG,
    CoverageBreakdownSection,
} from "../../../config/coverage.config"
import { HtmlHelper } from "../../../helpers/html.helper"
import { useSingleOverride } from "../../../hooks/singleOverride.hooks"
import { InlinedOverrideFormFieldComponent } from "../../policies/overrides/inlinedOverrideFormField.component"
import { PlanYearResetBreakdownComponent } from "../../policies/planYearResets/planYearResetBreakdown.component"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../../../config/featureFlags.config"

export const CoverageResultBreakdownDetailsSectionComponent: React.FunctionComponent<
    ICoverageResultBreakdownDetailsSectionComponentProps
> = props => {
    const { defaultData } = useSingleOverride(
        props.coverageCheckHistory,
        props.coverageCheckHistory.coverageResult.override,
        props.coverageCheckHistory.coverageResult.resultId
    )

    const isPlanYearResetsEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_PLAN_YEAR_RESETS
    )

    const withPlanYearResetWidget = useMemo<boolean>(() => {
        return (
            isPlanYearResetsEnabled &&
            props.sectionKey === CoverageBreakdownSection.POLICY_GENERAL &&
            !!props.coverageCheckHistory.coverageResult.planResetSoon
        )
    }, [
        props.sectionKey,
        props.coverageCheckHistory.coverageResult.planResetSoon,
        isPlanYearResetsEnabled,
    ])

    const sectionConfig = useMemo<ICoverageBreakdownSectionConfig>(() => {
        return COVERAGE_CONFIG.coverageBreakdownSections[props.sectionKey]
    }, [props.sectionKey])

    const dataItems = useMemo<{
        left: ICoverageBreakdownData[]
        right: ICoverageBreakdownData[]
    }>(() => {
        if (sectionConfig.singleColumn) {
            return {
                left: props.data,
                right: [],
            }
        }

        const middleIndex = Math.ceil(props.data.length / 2)

        return {
            left: props.data.slice(0, middleIndex),
            right: props.data.slice(middleIndex),
        }
    }, [sectionConfig, props.data])

    const getRenderedBreakdownItem = (
        item: ICoverageBreakdownData,
        index: number
    ): JSX.Element | null => {
        const isOverriding = item.fieldKey && props.isOverridesModeActivated

        const modifiedItem: ICoverageBreakdownData = {
            ...item,
            value: isOverriding ? (
                <InlinedOverrideFormFieldComponent
                    fieldKey={item.fieldKey}
                    fallback={item.value}
                    defaultData={defaultData}
                    isDisabled={props.isOverridesFormDisabled}
                />
            ) : (
                item.value
            ),
            valueDifference: isOverriding ? undefined : item.valueDifference,
            tooltip: isOverriding ? undefined : item.tooltip,
            isHighlighted: isOverriding ? undefined : item.isHighlighted,
        }

        return item.label !== COVERAGE_CONFIG.breakdownPlaceholderLabel
            ? HtmlHelper.getCoverageBreakdownItem(
                  modifiedItem,
                  index,
                  undefined,
                  sectionConfig.withSectionLeftBorder
              )
            : null
    }

    return props.data.length ? (
        <div
            className={`
                relative mb-12px
                ${props.className}
            `}
        >
            {withPlanYearResetWidget && (
                <PlanYearResetBreakdownComponent
                    className="mb-24px"
                    coverageCheckHistory={props.coverageCheckHistory}
                />
            )}

            {/*header*/}
            <div
                className={`
                    flex items-center
                    ${sectionConfig.withSectionDividerLine ? "mb-6px" : ""}
                `}
            >
                {sectionConfig.icon ? (
                    <div className="mr-8px relative top--2px">
                        <img
                            src={sectionConfig.icon}
                            alt={sectionConfig.title}
                        />
                    </div>
                ) : null}

                <PrimaryText
                    size={sectionConfig.bigFont ? 16 : 14}
                    lineHeight={24}
                >
                    {sectionConfig.title}
                </PrimaryText>

                {sectionConfig.tooltip && (
                    <TooltipElement
                        className="ml-4px relative top--1px"
                        isInlined
                        text={sectionConfig.tooltip}
                    />
                )}

                {sectionConfig.withSectionDividerLine && (
                    <div className="ml-8px flex-1 h-1px bg-brand-black020" />
                )}
            </div>

            {/*data*/}
            <div
                className={`
                    flex items-start md:block mb-12px
                    ${
                        sectionConfig.withSectionLeftBorder
                            ? "pl-16px mt-4px border-l border-solid border-brand-primary"
                            : ""
                    }
                `}
            >
                <div className="flex-1 mr-24px md:mr-0px">
                    {dataItems.left.map(getRenderedBreakdownItem)}
                </div>

                <div className="flex-1">
                    {dataItems.right.map(getRenderedBreakdownItem)}
                </div>
            </div>
        </div>
    ) : null
}
