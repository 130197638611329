import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { PoliciesListType } from "../config/policies.config"

export class ReportSavedFiltersService {
    /**
     * Get list of saved filters
     */
    static async getList(
        practice: IPractice,
        reportType: PoliciesListType
    ): Promise<IReportSavedFilter[] | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.SAVED_FILTERS_GET_LIST.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                reportType,
            }
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Create saved filter
     */
    static async create(
        practice: IPractice,
        data: ICreateSavedFilterRequestData
    ): Promise<IReportSavedFilter | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.SAVED_FILTERS_CREATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            data,
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Delete saved filter
     */
    static async delete(
        practice: IPractice,
        filterId: number
    ): Promise<
        | {
              deletedId: number
          }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.SAVED_FILTERS_DELETE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":filterId", filterId.toString()),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
