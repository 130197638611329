/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import moment from "moment-timezone"
import {
    DatePickerElement,
    InputElement,
    ISelectRenderedOption,
    PrimaryText,
    SelectElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import {
    POLICIES_CONFIG,
    PolicyRangeDateFilterType,
} from "../config/policies.config"
import { PoliciesListHelper } from "../helpers/policiesList.helper"

export const RelativeDateRangeInputElement: React.FunctionComponent<
    IRelativeDateRangeInputElementProps
> = props => {
    const {
        formState: { errors },
        control,
        register,
        unregister,
        setValue,
        watch,
    } = useFormContext()

    const dataWatcher: IPoliciesDateRangeFilterData | undefined = watch(
        props.inputRootKey
    )

    const [today] = useState(new Date())

    const availableRangeTypes = useMemo<PolicyRangeDateFilterType[]>(() => {
        return [
            PolicyRangeDateFilterType.TODAY,

            ...(props.pastDatesAllowed
                ? POLICIES_CONFIG.pastRangeDateFilterTypes
                : []),

            ...(props.futureDatesAllowed
                ? POLICIES_CONFIG.futureRangeDateFilterTypes
                : []),

            PolicyRangeDateFilterType.CUSTOM_RANGE,
        ]
    }, [props.pastDatesAllowed, props.futureDatesAllowed])

    const availableSelectOptions = useMemo<ISelectRenderedOption[]>(() => {
        return availableRangeTypes.map(item => {
            const displayValue =
                POLICIES_CONFIG.rangeDateFilterTypeMapping[item]

            return {
                key: item,
                displayValue,
                value: item,
                dropdownDisplayValue: displayValue,
            }
        })
    }, availableRangeTypes)

    const relativePreview = useMemo<string | null>(() => {
        if (
            !dataWatcher?.type ||
            [
                PolicyRangeDateFilterType.TODAY,
                PolicyRangeDateFilterType.CUSTOM_RANGE,
            ].includes(dataWatcher.type) ||
            ([
                PolicyRangeDateFilterType.NEXT_X_DAYS,
                PolicyRangeDateFilterType.LAST_X_DAYS,
            ].includes(dataWatcher.type) &&
                !dataWatcher.daysAmount)
        ) {
            return null
        }

        const { from, to } =
            PoliciesListHelper.getRelativeDateRangeValues(dataWatcher)

        if (!from || !to) {
            return null
        }

        return `If report is run today:  ${from} - ${to}`
    }, [JSON.stringify(dataWatcher)])

    // Set default value if it's provided - it can be set afterwards, but works better if set before
    useEffect(() => {
        if (!props.defaultValue) {
            return
        }

        setValue(props.inputRootKey, props.defaultValue)
    }, [props.defaultValue])

    return (
        <div
            className={`
                ${props.className}
            `}
        >
            {props.labelPrefix || props.labelBoldPart || props.labelSuffix ? (
                <PrimaryText className="mb-4px">
                    {props.labelPrefix || ""}

                    {props.labelBoldPart ? (
                        <>
                            {" "}
                            <span className="text-bold">
                                {props.labelBoldPart}
                            </span>
                        </>
                    ) : null}

                    {props.labelSuffix ? <> {props.labelSuffix}</> : null}
                </PrimaryText>
            ) : null}

            <SelectElement
                placeholder={props.placeholder}
                fieldGroupName={props.inputRootKey}
                name="type"
                allowClear={props.allowClear}
                reactHookFormRegister={register}
                reactHookFormSet={setValue}
                reactHookFormUnregister={unregister}
                renderedOptions={availableSelectOptions}
                validations={props.selectValidations}
                reactHookFormErrors={errors?.[props.inputRootKey]}
                defaultValue={
                    props.defaultValue?.type
                        ? POLICIES_CONFIG.rangeDateFilterTypeMapping[
                              props.defaultValue.type
                          ]
                        : undefined
                }
            />

            {dataWatcher?.type &&
            [
                PolicyRangeDateFilterType.NEXT_X_DAYS,
                PolicyRangeDateFilterType.LAST_X_DAYS,
            ].includes(dataWatcher?.type) ? (
                <div className="mt-12px flex items-center gap-1">
                    <PrimaryText>
                        {dataWatcher.type ===
                        PolicyRangeDateFilterType.NEXT_X_DAYS
                            ? "Next"
                            : "Last"}
                    </PrimaryText>

                    <InputElement
                        className="w-60px"
                        innerInputClassName="text-center"
                        fieldGroupName={props.inputRootKey}
                        name="daysAmount"
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            min: VALIDATION_CONFIG.minAmount,
                            max: {
                                value: 365,
                                message: "Maximum amount is 365",
                            },
                        }}
                        reactHookErrors={errors?.[props.inputRootKey]}
                        hideErrors
                        forceHighlightOnError
                        defaultValue={props.defaultValue?.daysAmount}
                    />

                    <PrimaryText>days</PrimaryText>
                </div>
            ) : null}

            {dataWatcher?.type === PolicyRangeDateFilterType.CUSTOM_RANGE ? (
                <div className="mt-36px flex gap-4">
                    <DatePickerElement
                        className="flex-1"
                        fieldGroupName={props.inputRootKey}
                        name="dateFrom"
                        label="From"
                        defaultValue={
                            props.defaultValue?.dateFrom
                                ? moment(props.defaultValue.dateFrom)
                                : undefined
                        }
                        reactHookFormValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        shouldValidate
                        reactHookFormErrors={errors?.[props.inputRootKey]}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        minDate={!props.pastDatesAllowed ? today : undefined}
                        maxDate={!props.futureDatesAllowed ? today : undefined}
                        isLabelStatic
                    />

                    <DatePickerElement
                        className="flex-1"
                        fieldGroupName={props.inputRootKey}
                        name="dateTo"
                        label="To"
                        defaultValue={
                            props.defaultValue?.dateTo
                                ? moment(props.defaultValue.dateTo)
                                : undefined
                        }
                        reactHookFormValidations={{
                            required: VALIDATION_CONFIG.required,
                            validate: () => {
                                const fromDate = watch(
                                    `${props.inputRootKey}.dateFrom`
                                ) as moment.Moment | undefined

                                const toDate = watch(
                                    `${props.inputRootKey}.dateTo`
                                ) as moment.Moment | undefined

                                return (
                                    !fromDate ||
                                    !toDate ||
                                    fromDate
                                        .clone()
                                        .startOf("day")
                                        .isBefore(
                                            toDate.clone().endOf("day")
                                        ) ||
                                    `"From" date must be less than "To" date`
                                )
                            },
                        }}
                        shouldValidate
                        reactHookFormErrors={errors?.[props.inputRootKey]}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        minDate={!props.pastDatesAllowed ? today : undefined}
                        maxDate={!props.futureDatesAllowed ? today : undefined}
                        isLabelStatic
                    />
                </div>
            ) : null}

            {relativePreview ? (
                <div className="mt-12px rounded-22px py-8px px-16px bg-brand-warmLight w-auto">
                    <PrimaryText
                        typography="caption"
                        className="relative top-2px"
                    >
                        {relativePreview}
                    </PrimaryText>
                </div>
            ) : null}
        </div>
    )
}
