import React, { useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { ROUTES_CONFIG } from "../config/routes.config"
import { PublicRoute } from "../router"
import { authReset } from "../store/slices/auth.slice"
import { useAppDispatch } from "../store/appDispatch.hook"

// ROUTES
import { NotFoundComponent } from "../components/general/notFound.component"
import { RegisterComponent } from "../components/auth/register/register.component"
import { RegisterSuccessComponent } from "../components/auth/register/registerSuccess.component"
import { LoginComponent } from "../components/auth/login/login.component"
import { RestoreComponent } from "../components/auth/restore.component"
import { RestoreProcessComponent } from "../components/auth/restoreProcess.component"
import { LogoutComponent } from "../components/auth/logout.component"
import { ConfirmUserComponent } from "../components/auth/confirmUser.component"

export const AuthContainer: React.FunctionComponent<
    IContainerComponentProps
> = ({ isLoggedIn }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(authReset())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Remove /auth from route, since it's new requirement for v6 router
    // It nests under parent
    const getCleanAuthRoute = (route: string): string =>
        route.replace(ROUTES_CONFIG.authUrl, "")

    return (
        <div className="max-w-600px mx-auto">
            <Routes>
                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.loginUrl)}
                    element={
                        <PublicRoute isLoggedIn={isLoggedIn}>
                            <LoginComponent />
                        </PublicRoute>
                    }
                />

                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.registerUrl)}
                    element={
                        <PublicRoute isLoggedIn={isLoggedIn}>
                            <RegisterComponent />
                        </PublicRoute>
                    }
                />

                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.registerSuccessUrl)}
                    element={<RegisterSuccessComponent />}
                />

                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.restoreUrl)}
                    element={
                        <PublicRoute isLoggedIn={isLoggedIn}>
                            <RestoreComponent />
                        </PublicRoute>
                    }
                />

                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.restoreProcessUrl)}
                    element={
                        <PublicRoute isLoggedIn={isLoggedIn}>
                            <RestoreProcessComponent />
                        </PublicRoute>
                    }
                />

                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.confirmUserUrl)}
                    element={<ConfirmUserComponent />}
                />

                <Route
                    path={getCleanAuthRoute(ROUTES_CONFIG.logoutUrl)}
                    element={<LogoutComponent />}
                />

                <Route
                    path="/"
                    element={<Navigate replace to={ROUTES_CONFIG.loginUrl} />}
                />

                <Route path="*" element={<NotFoundComponent />} />
            </Routes>
        </div>
    )
}
