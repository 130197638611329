import { createAsyncThunk } from "@reduxjs/toolkit"
import {
    BrowserStorageHelper,
    BrowserStorageType,
    UtilHelper,
} from "nirvana-react-elements"

import {
    authReset,
    authSetAutoSubmitLoginPasswordStep,
    authSetCheckUserInvitation,
    authSetForgotPasswordEmail,
    authSetIsLoading,
    authSetLoginData,
    authSetLoginStep,
    authSetMfaChallengePhoneNumber,
} from "../slices/auth.slice"
import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { AuthService } from "../../services/auth.service"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { LoginStep } from "../../config/auth.config"
import { AuthHelper } from "../../helpers/auth.helper"
import { GENERAL_CONFIG } from "../../config/general.config"

/**
 * Login
 */
export const authLogin = createAsyncThunk<void, IThunkActionData<ILoginData>>(
    "authLoginPrefix",
    async (action, { dispatch }) => {
        dispatch(authSetIsLoading(true))

        try {
            const {
                result,
                ignoreOnSuccessCallback,
                ignoreOnErrorCallback,
                newLoginStep,
                mfaChallengePhoneNumber,
            } = await AuthService.login(action.payload, action.navigate)

            if (newLoginStep) {
                dispatch(authSetLoginData(action.payload))
                dispatch(authSetLoginStep(newLoginStep))
            }

            if (mfaChallengePhoneNumber) {
                dispatch(
                    authSetMfaChallengePhoneNumber(mfaChallengePhoneNumber)
                )
            }

            if (result) {
                !ignoreOnSuccessCallback &&
                    action.onSuccess &&
                    action.onSuccess(result)
            } else {
                !ignoreOnErrorCallback &&
                    action.onError &&
                    action.onError(result)
            }
        } catch (e) {
        } finally {
            dispatch(authSetIsLoading(false))

            action.onFinally && action.onFinally()
        }
    }
)

/**
 * Register
 */
export const authRegister = createAsyncThunk<
    void,
    IThunkActionData<IRegisterData>
>("authRegisterPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.register(action.payload)

        if (result) {
            dispatch(authReset())

            UtilHelper.redirectTo(
                ROUTES_CONFIG.registerSuccessUrl +
                    `?email=${action.payload.emailData?.email}`,
                action.navigate
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Check if email is available
 */
export const authCheckEmailAvailable = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authCheckEmailAvailablePrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.emailAvailable(action.payload)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Resend verification link
 */
export const authResendVerificationLink = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authResendVerificationLinkPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.resendVerificationLink(action.payload)

        if (result) {
            ToastrHelper.success("Verification link was resent")

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Init forgot password flow
 */
export const authForgotPassword = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authForgotPasswordPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.forgotPassword(action.payload)

        if (result) {
            dispatch(
                authSetForgotPasswordEmail({
                    email: action.payload,
                })
            )

            UtilHelper.redirectTo(
                ROUTES_CONFIG.restoreProcessUrl,
                action.navigate
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Process forgot password flow
 */
export const authForgotPasswordProcess = createAsyncThunk<
    void,
    IThunkActionData<IPasswordRestoreFinishData>
>("authForgotPasswordProcessPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.forgotPasswordProcess(action.payload)

        if (result) {
            UtilHelper.redirectTo(ROUTES_CONFIG.loginUrl, action.navigate)

            ToastrHelper.success("Your password was successfully changed!")

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Confirm user
 */
export const authConfirmUser = createAsyncThunk<
    void,
    IThunkActionData<IConfirmUserData>
>("authConfirmUserPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.confirmUser(
            action.payload.email,
            action.payload.confirmationCode
        )

        if (result) {
            ToastrHelper.success(
                "Congratulations, your account was confirmed. You can now login using your credentials"
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()

        UtilHelper.redirectTo(ROUTES_CONFIG.loginUrl, action.navigate)
    }
})

/**
 * Check invite token
 */
export const authCheckInviteToken = createAsyncThunk<
    void,
    IThunkActionData<string>
>("authCheckInviteTokenPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("authCheckInviteTokenLoading"))

    try {
        const result = await AuthService.checkInviteToken(action.payload)

        dispatch(authSetCheckUserInvitation(result))

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("authCheckInviteTokenLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Update associated phone number - through amplify
 * User needs to be authenticated with amplify
 */
export const authUpdatePhoneNumber = createAsyncThunk<
    void,
    IThunkActionData<IUpdatePhoneNumberData>
>("authUpdatePhoneNumberPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.updatePhoneNumber(
            action.payload.phoneNumber
        )

        if (result) {
            dispatch(authSetMfaChallengePhoneNumber(action.payload.phoneNumber))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Process MFA code - through amplify
 * Handles confirming phone number (for enabling MFA)
 * Also when user is logging in and MFA is requested
 * Also can be handling update of phone number from profile, without withLoggingIn there
 */
export const authProcessMfaCode = createAsyncThunk<
    void,
    IThunkActionData<IProcessMFACodeData>
>("authProcessMfaCodePrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.processMFACode(action.payload, () => {
            dispatch(authSetLoginStep(LoginStep.PASSWORD))
        })

        // if null returned from processMFACode (can happen only during login process)
        // then we need to auto login user and they will be presented with MFA setup screen
        // This essentially is flow for force resetting MFA by user:
        // - they request it from profile page
        // - we store identifier in session storage
        // - log them out and removing their remembered devices
        // - once they log in again (with existing MFA passing)
        // - we allow them to set new MFA phone number
        if (result === null && action.payload.withLoggingIn) {
            dispatch(authSetAutoSubmitLoginPasswordStep(true))
            dispatch(authSetLoginStep(LoginStep.PASSWORD))

            return
        }

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Resend MFA code - through amplify
 * Handles confirming phone number and regular login MFA challenge
 */
export const authResendMfaCode = createAsyncThunk<
    void,
    IThunkActionData<{
        loginData: ILoginData | null
    }>
>("authResendMfaCodePrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        const result = await AuthService.resendMfaCode(action.payload.loginData)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            ToastrHelper.error(
                "Failed to resend MFA code. Please try again or start login process again"
            )

            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})

/**
 * Will forget user's current device, log user out and then will redirect to login page
 * Setting variable in session storage will ensure that user is requested to reset MFA on next login
 */
export const authEditMFAPhoneNumber = createAsyncThunk<
    void,
    IThunkActionData<null>
>("authEditMFAPhoneNumberPrefix", async (action, { dispatch }) => {
    dispatch(authSetIsLoading(true))

    try {
        await AuthService.checkRememberedDevicesExpired(true)

        AuthHelper.logout(undefined, action.navigate, true)
    } catch (e) {
        BrowserStorageHelper.remove(
            GENERAL_CONFIG.browserStorageKeys.authForceResetMFA,
            BrowserStorageType.sessionStorage
        )
    } finally {
        dispatch(authSetIsLoading(false))

        action.onFinally && action.onFinally()
    }
})
