/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    ConfirmationHelper,
    InputElement,
    PrimaryText,
    VALIDATION_CONFIG,
    NIRVANA_COLORS,
    SpinnerElement,
    CheckboxElement,
} from "nirvana-react-elements"
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import { isEqual } from "lodash-es"

import {
    reportSavedFiltersCreate,
    reportSavedFiltersDelete,
    reportSavedFiltersGetList,
} from "../../../store/thunks/reportSavedFilters.thunks"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { reportSavedFiltersSelector } from "../../../store/selectors/reportSavedFilters.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"

import trashIcon from "../../../assets/images/icons/trash-dark-small.svg"
import arrowRightIcon from "../../../assets/images/icons/arrow-right-dark-small.svg"
import cancelIcon from "../../../assets/images/icons/close-input-control.svg"
import submitIcon from "../../../assets/images/icons/check-input-control.svg"

export const PoliciesSavedFiltersComponent: React.FunctionComponent<
    IPoliciesSavedFiltersComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        register,
        setValue,
        unregister,
    } = useForm<IPoliciesSaveFilterFormData | any>()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const savedFilterState = useAppSelector(reportSavedFiltersSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const hasManagePracticeWideFilters = useHasPermissions(
        AvailableCoveragePortalPermission.managePracticeWideSavedFilters,
        undefined,
        false
    )

    const [deletingId, setDeletingId] = useState<number>()

    const savedFilters = useMemo(
        () => savedFilterState.savedFilters[props.listType] || [],
        [JSON.stringify(savedFilterState), props.listType]
    )

    const isListLoading = useMemo(() => {
        return RuntimeHelper.isReportSavedFiltersListLoading()
    }, [runtimeState.isLoading])

    const isCreateLoading = useMemo(() => {
        return RuntimeHelper.isReportSavedFiltersCreateLoading()
    }, [runtimeState.isLoading])

    const isDeleteLoading = useMemo(() => {
        return RuntimeHelper.isReportSavedFiltersDeleteLoading()
    }, [runtimeState.isLoading])

    // Load filters on change of props.listType
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            reportSavedFiltersGetList({
                practice: selectedPracticeRole.practice,
                payload: {
                    reportType: props.listType,
                },
            })
        )
    }, [selectedPracticeRole?.practice.id, props.listType])

    // Identify if passed filters already saved -> if so - notify parent so it can disable save button
    useEffect(() => {
        const filterExists = savedFilters.some(item => {
            return areFiltersEqual(item)
        })

        props.onSameSavedFilterFoundChange(filterExists)
    }, [JSON.stringify(savedFilters), JSON.stringify(props.filters)])

    const onSaveCurrentFilters = async (data: IPoliciesSaveFilterFormData) => {
        if (!selectedPracticeRole) {
            return
        }

        await dispatch(
            reportSavedFiltersCreate({
                practice: selectedPracticeRole.practice,
                payload: {
                    reportType: props.listType,
                    name: data.name,
                    filtersConfiguration: props.filters,
                    isPracticeWide: data.isPracticeWide,
                },
                onSuccess: () => {
                    reset()
                    props.onSaveCurrentFiltersDone()
                },
            })
        )
    }

    const onDeleteSavedFilter = (filter: IReportSavedFilter) => {
        if (!selectedPracticeRole) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                setDeletingId(filter.id)

                dispatch(
                    reportSavedFiltersDelete({
                        practice: selectedPracticeRole.practice,
                        payload: {
                            reportType: props.listType,
                            filterId: filter.id,
                        },
                        onFinally: () => {
                            setDeletingId(undefined)
                        },
                    })
                )
            },
            undefined,
            `Filter will be permanently removed.`
        )
    }

    const areFiltersEqual = (savedFilterItem: IReportSavedFilter) => {
        return isEqual(savedFilterItem.filtersConfiguration, props.filters)
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div className="h-full bg-brand-warmLight">
                <div className="border-b border-solid border-brand-warmLight bg-brand-offWhite">
                    <PrimaryText className="px-8px py-16px">
                        Saved Filters
                    </PrimaryText>
                </div>

                {props.needSaveCurrentFilters ? (
                    <div className="bg-brand-lilacLight p-8px">
                        <form
                            onSubmit={handleSubmit(onSaveCurrentFilters)}
                            noValidate={true}
                        >
                            <div className="relative">
                                <InputElement
                                    name="name"
                                    innerInputClassName="pr-64px!"
                                    placeholder="Name your filter"
                                    reactHookControl={control}
                                    reactHookErrors={errors}
                                    hideErrors
                                    forceHighlightOnError
                                    reactHookValidations={{
                                        required: VALIDATION_CONFIG.required,
                                        minLength: VALIDATION_CONFIG.minLength,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                />

                                <div className="flex items-center justify-center absolute z-10 h-full top-0 right-0">
                                    <div
                                        className={`
                                            pl-8px pr-6px mr-4px h-full flex flex-col justify-center
                                            ${
                                                !isCreateLoading
                                                    ? "cursor-pointer"
                                                    : ""
                                            }
                                        `}
                                        onClick={
                                            !isCreateLoading
                                                ? props.onSaveCurrentFiltersDone
                                                : undefined
                                        }
                                    >
                                        {!isCreateLoading ? (
                                            <img
                                                src={cancelIcon}
                                                alt="Cancel"
                                            />
                                        ) : null}
                                    </div>

                                    <div
                                        className={`
                                            pl-6px pr-16px h-full flex flex-col justify-center
                                            ${
                                                !isCreateLoading
                                                    ? "cursor-pointer"
                                                    : ""
                                            }
                                        `}
                                        onClick={
                                            !isCreateLoading
                                                ? handleSubmit(
                                                      onSaveCurrentFilters
                                                  )
                                                : undefined
                                        }
                                    >
                                        {isCreateLoading ? (
                                            <SpinnerElement size="small" />
                                        ) : (
                                            <img src={submitIcon} alt="Save" />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {hasManagePracticeWideFilters ? (
                                <CheckboxElement
                                    className="mt-12px"
                                    name="isPracticeWide"
                                    reactHookFormRegister={register}
                                    reactHookFormUnregister={unregister}
                                    reactHookFormSet={setValue}
                                    defaultChecked={false}
                                    label={
                                        <PrimaryText
                                            className="relative top--1px"
                                            size={14}
                                            lineHeight={18}
                                        >
                                            Share filter with entire practice
                                        </PrimaryText>
                                    }
                                />
                            ) : null}
                        </form>
                    </div>
                ) : (
                    <div className="relative h-full overflow-y-auto pb-24px">
                        {!savedFilters.length ? (
                            <div className="absolute screen-center">
                                {isListLoading ? (
                                    <SpinnerElement containerClassName="text-center" />
                                ) : (
                                    <>
                                        <div className="text-center">
                                            <FontAwesomeIcon
                                                icon={faFolderOpen}
                                                color={
                                                    NIRVANA_COLORS.brand.primary
                                                }
                                                size="lg"
                                            />
                                        </div>

                                        <PrimaryText
                                            typography="text"
                                            weight="medium"
                                            centered
                                            className="mt-16px"
                                        >
                                            No saved filters yet
                                        </PrimaryText>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="pb-32px">
                                {savedFilters.map((savedFilter, index) => {
                                    const isSelected =
                                        areFiltersEqual(savedFilter)

                                    return (
                                        <div
                                            key={index}
                                            className={`
                                                relative py-16px pl-8px pr-70px border-b border-solid border-brand-warmLight
                                                flex items-center
                                                ${
                                                    !isSelected
                                                        ? "cursor-pointer bg-brand-white"
                                                        : "bg-brand-lilacLight"
                                                }
                                            `}
                                            onClick={() =>
                                                !isSelected &&
                                                props.onSelectSavedFilter(
                                                    savedFilter
                                                )
                                            }
                                        >
                                            <div className="flex-1 w-0px">
                                                <PrimaryText className="truncate">
                                                    {savedFilter.name}
                                                </PrimaryText>

                                                <PrimaryText typography="textSmall">
                                                    {savedFilter.isPracticeWide
                                                        ? "Shared with practice"
                                                        : ""}
                                                </PrimaryText>
                                            </div>

                                            <div className="absolute z-10 right-0 top-0 h-full flex items-center">
                                                <div
                                                    className={`
                                                        pl-8px pr-6px mr-4px h-full flex flex-col justify-center
                                                        ${
                                                            !isDeleteLoading &&
                                                            savedFilter.canUserManageFilter
                                                                ? "cursor-pointer"
                                                                : ""
                                                        }
                                                    `}
                                                    onClick={
                                                        !isDeleteLoading &&
                                                        savedFilter.canUserManageFilter
                                                            ? e => {
                                                                  e.stopPropagation()

                                                                  onDeleteSavedFilter(
                                                                      savedFilter
                                                                  )
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    {!isDeleteLoading &&
                                                    savedFilter.canUserManageFilter ? (
                                                        <img
                                                            src={trashIcon}
                                                            alt="Remove"
                                                        />
                                                    ) : deletingId ===
                                                      savedFilter.id ? (
                                                        <SpinnerElement size="small" />
                                                    ) : null}
                                                </div>

                                                <div
                                                    className="cursor-pointer pl-6px pr-16px h-full flex flex-col justify-center"
                                                    onClick={e => {
                                                        e.stopPropagation()

                                                        props.onApplySavedFilter(
                                                            savedFilter
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        src={arrowRightIcon}
                                                        alt="Apply"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
