/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    PageHelmetElement,
    SpinnerElement,
    PrimaryText,
    ButtonElement,
    UtilHelper,
    ConfirmationHelper,
    useStateCallback,
    InputElement,
} from "nirvana-react-elements"

import {
    coveragePayersDelete,
    coveragePayersGetList,
} from "../../../store/thunks/coveragePayers.thunks"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { COVERAGE_CONFIG } from "../../../config/coverage.config"
import { UpsertCoveragePayerPopupComponent } from "../../popups/upsertCoveragePayerPopup.component"
import { PermissionsHelper } from "../../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { coveragePayersSelector } from "../../../store/selectors/coveragePayers.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"

import plusIcon from "../../../assets/images/icons/plus-dark.svg"
import removeIcon from "../../../assets/images/icons/trash-red.svg"
import editIcon from "../../../assets/images/icons/edit-dark.svg"
import searchIcon from "../../../assets/images/icons/search-dark.svg"

export const CoveragePayersComponent: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const coveragePayers = useAppSelector(coveragePayersSelector)

    const [isInitiated, setIsInitiated] = useState<boolean>(false)

    const [payerToEdit, setPayerToEdit] = useStateCallback<
        ICoveragePayer | undefined
    >(undefined)

    const [isUpsertingPayer, setIsUpsertingPayer] = useState<boolean>(
        !!searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.create)
    )

    const [searchTerm, setSearchTerm] = useStateCallback<string | undefined>(
        undefined
    )

    const [searchedPayers, setSearchedPayers] = useState<ICoveragePayer[]>([])

    const isLoadingList = useMemo<boolean>(() => {
        return RuntimeHelper.isGettingPayersLoading()
    }, [runtimeState.isLoading])

    const isCreatingPayer = useMemo<boolean>(() => {
        return RuntimeHelper.isCreatePayerLoading()
    }, [runtimeState.isLoading])

    const canReadPayers = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.readPayersConfiguration,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const canManagePayers = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.managePayersConfiguration,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    // Load fresh list of payers on selectedPracticeRole change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            coveragePayersGetList({
                payload: undefined,
                practice: selectedPracticeRole.practice,
            })
        )

        // Remove creation flag from url so it's not triggered again
        searchParams.delete(GENERAL_CONFIG.urlSearchParamsKeys.create)
        setSearchParams(searchParams)

        // Mark component as initiated
        // Without this spinner jumps and messy UI happens
        setTimeout(() => setIsInitiated(true))
    }, [selectedPracticeRole?.id])

    const onAddPayer = () => {
        if (!canManagePayers) {
            return
        }

        // Make sure to remove "payerToEdit" just in case
        payerToEdit
            ? setPayerToEdit(undefined, () => {
                  setIsUpsertingPayer(true)
              })
            : setIsUpsertingPayer(true)
    }

    const onEditPayer = (item: ICoveragePayer) => {
        if (!canManagePayers) {
            return
        }

        setPayerToEdit(item, () => {
            setIsUpsertingPayer(true)
        })
    }

    const onUpsertingPayerDone = () => {
        setIsUpsertingPayer(false)
        setPayerToEdit(undefined)
    }

    const onRemovePayer = (item: ICoveragePayer) => {
        if (!selectedPracticeRole || !canManagePayers) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                dispatch(
                    coveragePayersDelete({
                        payload: item.id,
                        practice: selectedPracticeRole.practice,
                    })
                )
            },
            undefined,
            `Payer "${item.payer.insuranceName}" will be permanently removed`
        )
    }

    const onSearchChanged = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (searchTerm === event.target.value) {
            return
        }

        // Search is implemented just on front end since full list is loaded (not paginated)
        setSearchTerm(
            event.target.value ? event.target.value.toLowerCase() : undefined,
            newSearchTerm => {
                setSearchedPayers(
                    !newSearchTerm
                        ? []
                        : coveragePayers.filter(item =>
                              item.payer.insuranceName
                                  .toLowerCase()
                                  .includes(newSearchTerm)
                          )
                )
            }
        )
    }

    return (
        <div className="max-w-840px mx-auto relative pb-24px">
            <PageHelmetElement
                title="Payers"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {isLoadingList || !isInitiated || !canReadPayers ? (
                <SpinnerElement containerClassName="mt-20px text-center" />
            ) : (
                <>
                    <div className="flex items-center md:block">
                        <PrimaryText className="mr-16px" typography="h4">
                            Payers
                        </PrimaryText>

                        <div className="flex-1 flex items-center sm:block md:mt-16px">
                            {canManagePayers ? (
                                <ButtonElement
                                    className="flex-1 mr-24px sm:mt-24px sm:mr-0px"
                                    label="Add Payer"
                                    icon={plusIcon}
                                    onClick={onAddPayer}
                                    isLoading={isCreatingPayer}
                                />
                            ) : (
                                <div className="flex-1" />
                            )}

                            {coveragePayers.length || searchTerm ? (
                                <InputElement
                                    name="searchTerm"
                                    className="w-250px sm:w-full sm:mt-24px"
                                    placeholder="Search by Name"
                                    onChange={onSearchChanged}
                                    allowClear={true}
                                    inputSuffix={
                                        <img
                                            src={searchIcon}
                                            alt="Filter"
                                            title="Filter"
                                            className="ml-5px"
                                        />
                                    }
                                    defaultValue={searchTerm}
                                />
                            ) : null}
                        </div>
                    </div>

                    {!coveragePayers.length &&
                    !searchTerm &&
                    canManagePayers ? (
                        <PrimaryText className="w-max mt-16px px-24px py-40px bg-brand-warmLight025 rounded-16px">
                            Enter payer information so that we can look up the
                            appropriate information when you run a member check.
                        </PrimaryText>
                    ) : (
                        <div className="mt-32px">
                            {/*HEADINGS*/}
                            <div
                                className="
                                    flex items-center px-16px py-8px
                                    sticky top-0 bg-brand-warmLight05 z-10
                                    md:hidden
                                "
                            >
                                <PrimaryText
                                    typography="h6"
                                    className="opacity-75 w-175px mr-9px"
                                >
                                    Payer
                                </PrimaryText>

                                <PrimaryText
                                    typography="h6"
                                    className="opacity-75 w-155px mr-9px"
                                >
                                    Default Check
                                </PrimaryText>

                                <PrimaryText
                                    typography="h6"
                                    className="opacity-75 min-w-200px flex-1 mr-9px"
                                >
                                    CPT Code(s) + Session Rate(s)
                                </PrimaryText>

                                {/*ACTIONS EDIT / REMOVE placeholder*/}
                                {canManagePayers && <div className="w-56px" />}
                            </div>

                            {(searchTerm ? searchedPayers : coveragePayers).map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className="
                                            relative p-16px flex items-center
                                            border-b border-solid border-brand-warmLight
                                            md:mt-24px md:block md:border md:rounded-8px
                                            md:border-brand-warmShadow md:bg-brand-whitePurple
                                        "
                                    >
                                        <PrimaryText className="w-175px mr-9px md:mt-8px md:w-auto">
                                            {/*LABEL FOR MOBILES / TABLETS*/}
                                            <span className="opacity-50 hidden md:inline-block">
                                                Payer:&nbsp;
                                            </span>

                                            {item.payer.insuranceName}
                                        </PrimaryText>

                                        <PrimaryText className="w-155px mr-9px md:mt-8px md:w-auto">
                                            {/*LABEL FOR MOBILES / TABLETS*/}
                                            <span className="opacity-50 hidden md:inline-block">
                                                Default Check:&nbsp;
                                            </span>

                                            {
                                                COVERAGE_CONFIG
                                                    .payerCoverageCheckNetworkMapped[
                                                    item
                                                        .defaultCoverageCheckNetwork
                                                ]?.displayValue
                                            }
                                        </PrimaryText>

                                        <PrimaryText className="min-w-200px flex-1 mr-9px flex items-center flex-wrap gap-2 md:mt-8px md:w-auto">
                                            {/*LABEL FOR MOBILES / TABLETS*/}
                                            <span className="opacity-50 hidden md:inline-block">
                                                CPT Code(s) + Session
                                                Rate(s):&nbsp;
                                                {"\n"}
                                            </span>

                                            {item.rates.map(
                                                (rate, rateIndex) => (
                                                    <div
                                                        key={rateIndex}
                                                        className="
                                                            flex items-center
                                                            px-8px bg-brand-lilacLight rounded-4px
                                                            border border-solid border-brand-vibrantPurple
                                                        "
                                                    >
                                                        <PrimaryText typography="h6">
                                                            {rate.code}
                                                        </PrimaryText>
                                                        <PrimaryText
                                                            typography="h6"
                                                            fontFamily="PPMori"
                                                        >
                                                            &nbsp;
                                                            {"·"}&nbsp;
                                                            {UtilHelper.getFormattedMoney(
                                                                rate.amount,
                                                                !(
                                                                    rate.amount %
                                                                    100
                                                                )
                                                            )}
                                                        </PrimaryText>
                                                    </div>
                                                )
                                            )}
                                        </PrimaryText>

                                        {/*ACTIONS EDIT / REMOVE*/}
                                        {canManagePayers && (
                                            <div
                                                className="
                                                    w-56px flex items-center
                                                    md:absolute md:top-16px md:right-16px
                                                "
                                            >
                                                <img
                                                    className="mr-24px cursor-pointer"
                                                    src={removeIcon}
                                                    alt="Remove"
                                                    onClick={() =>
                                                        onRemovePayer(item)
                                                    }
                                                />

                                                <img
                                                    className="cursor-pointer"
                                                    src={editIcon}
                                                    alt="Edit"
                                                    onClick={() =>
                                                        onEditPayer(item)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )
                            )}

                            {!(searchTerm ? searchedPayers : coveragePayers)
                                .length && (
                                <PrimaryText
                                    className="mt-24px opacity-75"
                                    typography="h6"
                                    centered
                                >
                                    No payers found
                                </PrimaryText>
                            )}
                        </div>
                    )}
                </>
            )}

            {canManagePayers && (
                <UpsertCoveragePayerPopupComponent
                    isActive={isUpsertingPayer}
                    onDone={onUpsertingPayerDone}
                    existingPayer={payerToEdit}
                />
            )}
        </div>
    )
}
