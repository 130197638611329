import {
    HttpHelper as NirvanaHttpHelper,
    AvailableHttpMethod,
} from "nirvana-react-elements"

import { AuthHelper } from "./auth.helper"
import { ToastrHelper } from "./toastr.helper"
import { GENERAL_CONFIG } from "../config/general.config"

export class HttpHelper {
    /**
     * Send request to server
     */
    static async sendRequest(
        url: string,
        data: IIndexable = {},
        method: AvailableHttpMethod = "GET",
        headers = {},
        appendApiUrl: string | undefined = process.env.REACT_APP_API_URL,
        errorToastrTimeout = GENERAL_CONFIG.defaultToastrTimeout,
        ignoreToastrErrorCodes: number[] = [],
        abortSignal?: AbortSignal
    ): Promise<any> {
        headers = Object.assign({}, headers)

        if (await AuthHelper.isLoggedIn()) {
            const adminViewAsData = AuthHelper.getEagleEyeViewAsData()

            // Override what current user will see (practice and therapist data), aka God mode
            if (adminViewAsData) {
                headers[GENERAL_CONFIG.eagleEye.headers.userId] =
                    adminViewAsData.user?.id

                headers[GENERAL_CONFIG.eagleEye.headers.practiceId] =
                    adminViewAsData.practice?.id
            }
        }

        return await NirvanaHttpHelper.sendRequest(
            url,
            data,
            method,
            appendApiUrl,
            headers,
            await AuthHelper.getToken(),
            errorToastrTimeout,
            [...ignoreToastrErrorCodes, 401],
            ToastrHelper.error,
            ToastrHelper.warning,
            () => {
                ToastrHelper.error(
                    "Auth token expired, please refresh the page and log in again to continue."
                )

                AuthHelper.logout()
            },
            undefined,
            undefined,
            undefined,
            abortSignal
        )
    }
}
