import {
    IsEnum,
    IsIn,
    IsNotEmpty,
    IsOptional,
    IsString,
    ValidateIf,
} from "class-validator"
import { Transform } from "class-transformer"
import { usaStates } from "typed-usa-states"

import {
    CoveragePatientType,
    PayerCoverageCheckNetwork,
} from "../config/coverage.config"

class CommonCustomerDataDTO {
    // To handle empty strings in CSV for patient type column
    @Transform(({ value }) => value || undefined)
    @IsOptional()
    @IsEnum(CoveragePatientType)
    customerPatientType?: CoveragePatientType

    @IsOptional()
    @IsString()
    customerPatientId?: string

    @IsOptional()
    @IsString()
    customerPatientNextAppointmentDate?: string
}

export class RawCsvInputContinuousMonitoringDTO extends CommonCustomerDataDTO {
    @IsOptional()
    @IsString()
    memberId?: string

    @IsNotEmpty({
        message: "Member DOB should not be empty",
    })
    @IsString()
    dob: string

    @ValidateIf(fullObj => !fullObj.memberId)
    @IsNotEmpty({
        message: "First name is required when member ID is not provided",
    })
    @IsString()
    firstName?: string

    @ValidateIf(fullObj => !fullObj.memberId)
    @IsNotEmpty({
        message: "Last name is required when member ID is not provided",
    })
    @IsString()
    lastName?: string

    @IsNotEmpty({
        message: "Provider Session Cost should not be empty",
    })
    @IsString()
    sessionCharge: string

    @IsNotEmpty({
        message: "CPT code should not be empty",
    })
    @IsString()
    cptCode: string

    @IsOptional()
    @IsString()
    network?: PayerCoverageCheckNetwork

    @IsNotEmpty({
        message: "Provider NPI should not be empty",
    })
    @IsString()
    healthProviderNpi: string

    @IsNotEmpty({
        message: "Payer ID should not be empty",
    })
    @IsString()
    payerExternalId: string
}

export class RawCsvInputMedicaidDTO extends CommonCustomerDataDTO {
    @IsNotEmpty({
        message: "Provider NPI should not be empty",
    })
    @IsString()
    healthProviderNpi: string

    @IsNotEmpty({
        message: "State code should not be empty",
    })
    @IsString()
    @IsIn(usaStates.map(item => item.abbreviation), {
        message: "Invalid state abbreviation",
    })
    state: string

    @IsNotEmpty({
        message: "Member DOB should not be empty",
    })
    @IsString()
    dob: string

    @IsNotEmpty({
        message: "Member first name should not be empty",
    })
    @IsString()
    firstName: string

    @IsNotEmpty({
        message: "Member last name should not be empty",
    })
    @IsString()
    lastName: string

    @IsOptional()
    @IsString()
    memberId?: string

    @IsOptional()
    @IsString()
    cptCode?: string
}
