import React from "react"
import moment, { Moment } from "moment-timezone"
import { isObject } from "lodash-es"
import * as PapaParse from "papaparse"
import { plainToInstance } from "class-transformer"
import { validate } from "class-validator"
import {
    ISelectRenderedOption,
    PolicyModality,
    PrimaryText,
    REGEX_CONFIG,
    TooltipElement,
} from "nirvana-react-elements"

import {
    AvailableModalityCoverageStatus,
    AvailablePlanStatus,
    COVERAGE_CONFIG,
    PayerCoverageCheckNetwork,
} from "../config/coverage.config"
import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
    PolicyDenialRisk,
    ResetBenefitStatus,
} from "../config/policies.config"
import {
    CSV_CHECKER_CONFIG,
    ExportDataSource,
} from "../config/csvChecker.config"
import {
    CHECKER_CONFIG,
    CalculatorResultType,
    CoverageCheckerCheckType,
} from "../config/checker.config"
import {
    RawCsvInputContinuousMonitoringDTO,
    RawCsvInputMedicaidDTO,
} from "../dto/rawCsvInput.dto"
import { POLICIES_COLUMNS_CONFIG } from "../config/policiesColumns.config"
import { UtilHelper } from "./util.helper"
import { PoliciesHelper } from "./policies.helper"
import { GENERAL_CONFIG } from "../config/general.config"
import { PlanYearResetBreakdownComponent } from "../components/policies/planYearResets/planYearResetBreakdown.component"
import { LookupService } from "../services/lookup.service"

import benefitsNotDetectedIcon from "../assets/images/icons/reset-benefits-not-detected.svg"
import benefitsNotRequiredIcon from "../assets/images/icons/reset-benefits-action-not-required.svg"
import benefitsRequiredIcon from "../assets/images/icons/reset-benefits-action-required.svg"

export class CsvHelper {
    /**
     * Get single column for download CSV based on mapping
     */
    static getSingleExportColumn(
        mapping: ICsvFieldsMapping,
        inputData: IContinuousMonitoringCoverageCheckInputData,
        resultData?: ICoverageResult,
        selectedPracticeRole?: IPracticeRole,
        checkTypeSpecificData?: ICsvFieldsMappingCheckSpecificData
    ): any {
        const emptyColumnValue = mapping.formatting?.convertOnEmptyToUnknown
            ? COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown
            : undefined

        let columnValue = CsvHelper.getSingleExportColumnValue(
            mapping.dataSource,
            inputData,
            resultData,
            selectedPracticeRole,
            emptyColumnValue,
            checkTypeSpecificData
        )

        if (columnValue === emptyColumnValue && mapping.alternativeDataSource) {
            columnValue = CsvHelper.getSingleExportColumnValue(
                mapping.alternativeDataSource,
                inputData,
                resultData,
                selectedPracticeRole,
                emptyColumnValue,
                checkTypeSpecificData
            )
        }

        // Process special formatting for columns, like money or date formatting
        // Also check if it has some value
        if (mapping.formatting && columnValue !== emptyColumnValue) {
            columnValue = CsvHelper.getFormattedColumnValue(
                columnValue,
                mapping.formatting,
                emptyColumnValue,
                false,
                resultData
            )
        }

        // Always convert boolean to 1 | 0
        if (typeof columnValue === "boolean") {
            columnValue = +columnValue
        }

        // If we need to compare with resulted value, find resulted data and compare - add to csv only if differs
        if (mapping.dataCompareWith) {
            const compareValue = CsvHelper.getSingleExportColumn(
                {
                    header: mapping.header,

                    ...mapping.dataCompareWith,
                },
                inputData,
                resultData,
                selectedPracticeRole
            )

            if (
                !columnValue ||
                columnValue?.toLowerCase() === compareValue?.toLowerCase()
            ) {
                return emptyColumnValue
            } else {
                return columnValue
            }
        }

        return columnValue
    }

    /**
     * Get formatting of some value based on formatting config
     */
    static getFormattedColumnValue(
        columnValue: any,
        formatting: ICsvFieldFormatting,
        emptyColumnValue: any,
        jsxSupportedFormatting = false,
        coverageResultData?: ICoverageResult | null
    ): any {
        try {
            if (formatting.currency) {
                let amount = parseFloat(columnValue.toString())

                // Convert to cents if it's not already in cents
                if (!formatting.isCents) {
                    amount = UtilHelper.formatDollarsToCents(amount)
                }

                if (isNaN(amount)) {
                    columnValue = emptyColumnValue
                } else {
                    // Convert to $
                    columnValue = UtilHelper.getFormattedMoney(
                        amount,
                        formatting.showZeroFractionDigits
                    )
                }
            }

            if (formatting.dateFormat) {
                columnValue = moment(columnValue).format(formatting.dateFormat)
            }

            if (formatting.suffixValue) {
                columnValue = `${columnValue}${formatting.suffixValue}`
            }

            if (formatting.isCoverageStatusValue) {
                columnValue =
                    columnValue === CalculatorResultType.noCoverage
                        ? AvailableModalityCoverageStatus.inactive
                        : CHECKER_CONFIG.activeCoverageResults.includes(
                              columnValue
                          )
                        ? AvailableModalityCoverageStatus.active
                        : AvailableModalityCoverageStatus.unknown
            }

            if (formatting.resultType) {
                columnValue = columnValue
                    ? CHECKER_CONFIG.activeCoverageResults.includes(columnValue)
                        ? CHECKER_CONFIG.calculatorResultTypeTitleMapping
                              .activeCoverage
                        : CHECKER_CONFIG.calculatorResultTypeTitleMapping[
                              columnValue
                          ] || null
                    : null
            }

            if (formatting.isNetworkTypeValue) {
                columnValue = columnValue
                    ? PayerCoverageCheckNetwork.IN
                    : PayerCoverageCheckNetwork.OUT
            }

            if (formatting.isInNetworkCheckMapped) {
                columnValue = columnValue
                    ? PayerCoverageCheckNetwork.IN
                    : PayerCoverageCheckNetwork.OUT

                columnValue =
                    COVERAGE_CONFIG.payerCoverageCheckNetworkMapped[columnValue]
                        ?.displayValue || columnValue
            }

            if (formatting.address) {
                columnValue =
                    UtilHelper.getFormattedDemographicsAddress(columnValue)
            }

            if (formatting.gender) {
                columnValue = CHECKER_CONFIG.genderMapping[columnValue]
            }

            if (formatting.planType) {
                columnValue = CHECKER_CONFIG.planTypeMapping[columnValue]
            }

            if (formatting.planStatus) {
                columnValue = CHECKER_CONFIG.activePlanStatuses.includes(
                    columnValue
                )
                    ? AvailablePlanStatus.active
                    : columnValue
            }

            if (formatting.modalityCoverageStatus) {
                columnValue =
                    COVERAGE_CONFIG.availableModalityCoverageStatusMapping[
                        columnValue
                    ] ||
                    COVERAGE_CONFIG.availableModalityCoverageStatusMapping[
                        AvailableModalityCoverageStatus.unknown
                    ]
            }

            if (formatting.priorAuthorization) {
                columnValue =
                    COVERAGE_CONFIG.availablePriorAuthorizationMapping[
                        columnValue
                    ] || emptyColumnValue
            }

            if (formatting.flagsList) {
                let icon =
                    POLICIES_CONFIG.policyDenialRiskIconsMapping[
                        PolicyDenialRisk.NONE
                    ]

                let text = "No Alerts"

                if (Array.isArray(columnValue) && columnValue.length) {
                    const mappings = (
                        columnValue as CoveragePortalFlagType[]
                    ).map(item => POLICIES_CONFIG.flagTypeMappings[item])

                    text = mappings.map(item => item.label).join("\n")

                    const denialRisk = PoliciesHelper.getPolicyDenialRisk(
                        columnValue as CoveragePortalFlagType[]
                    )

                    icon =
                        POLICIES_CONFIG.policyDenialRiskIconsMapping[denialRisk]
                }

                columnValue = jsxSupportedFormatting ? (
                    <div className="flex items-start">
                        <div className="relative top--2px mr-8px">
                            <img src={icon} alt="flag icon" />
                        </div>

                        <PrimaryText>
                            {text.split("\n").map((str, index) => (
                                <div key={index}>{str}</div>
                            ))}
                        </PrimaryText>
                    </div>
                ) : (
                    text.split("\n").join(", ")
                )
            }

            if (formatting.denialRisk) {
                if (!Array.isArray(columnValue) || !columnValue.length) {
                    columnValue =
                        POLICIES_CONFIG.denialRiskMapping[PolicyDenialRisk.NONE]
                } else {
                    const denialRisk = PoliciesHelper.getPolicyDenialRisk(
                        columnValue as CoveragePortalFlagType[]
                    )

                    columnValue = POLICIES_CONFIG.denialRiskMapping[denialRisk]
                }
            }

            if (columnValue && formatting.isPatientTypeMapped) {
                columnValue =
                    COVERAGE_CONFIG.selectRenderedPatientTypes[columnValue]
                        ?.displayValue
            }

            if (columnValue && formatting.resetBenefitStatus) {
                const mappedValue =
                    COVERAGE_CONFIG.selectRenderedResetBenefitStatus[
                        columnValue
                    ]?.displayValue

                if (!jsxSupportedFormatting) {
                    columnValue = mappedValue
                } else {
                    let icon = benefitsNotDetectedIcon

                    switch (columnValue) {
                        case ResetBenefitStatus.DETECTED_NO_ACTION_REQUIRED:
                            icon = benefitsNotRequiredIcon

                            break

                        case ResetBenefitStatus.DETECTED_ACTION_REQUIRED:
                            icon = benefitsRequiredIcon

                            break
                    }

                    columnValue = (
                        <div className="flex items-center">
                            <div className="relative top--2px mr-8px">
                                <img src={icon} alt="icon" />
                            </div>

                            <PrimaryText>{mappedValue}</PrimaryText>
                        </div>
                    )
                }
            }

            if (formatting.resetBenefitDifference) {
                columnValue = isObject(columnValue) ? columnValue : {}

                const differenceKeys: string[] = []

                if (coverageResultData) {
                    Object.keys(columnValue).forEach(key => {
                        let resetBenefitValue = columnValue[key]
                        let coverageResultValue = coverageResultData[key]

                        // Make it null if empty for any reason
                        resetBenefitValue =
                            typeof resetBenefitValue === "undefined"
                                ? null
                                : resetBenefitValue

                        // Make it null if empty for any reason
                        coverageResultValue =
                            typeof coverageResultValue === "undefined"
                                ? null
                                : coverageResultValue

                        if (resetBenefitValue !== coverageResultValue) {
                            differenceKeys.push(key)
                        }
                    })
                }

                const mappedDifferences = differenceKeys.map(
                    key =>
                        Object.values(
                            POLICIES_COLUMNS_CONFIG.columnsConfig
                        ).find(
                            column =>
                                column.sourcePropertyPath === key ||
                                column.alternativeSourcePropertyPath === key
                        )?.label || key
                )

                columnValue = mappedDifferences.join(", ") || emptyColumnValue

                if (jsxSupportedFormatting) {
                    columnValue = (
                        <div className="flex items-center">
                            <PrimaryText>{columnValue}</PrimaryText>

                            {coverageResultData ? (
                                <TooltipElement
                                    className="ml-4px relative top--2px flex-shrink-0"
                                    text={
                                        <PlanYearResetBreakdownComponent
                                            className="shadow-7 top--24px"
                                            coverageCheckHistory={
                                                {
                                                    // safe to cast to full coverage check, since it's checking only result values inside
                                                    coverageResult:
                                                        coverageResultData,
                                                } as ICoverageCheckHistory
                                            }
                                        />
                                    }
                                />
                            ) : null}
                        </div>
                    )
                }
            }
        } catch (e) {}

        return columnValue
    }

    /**
     * Get coverage checker download columns
     */
    static getCoverageCheckerDownloadCSVColumns(
        neededModalities = [PolicyModality.MENTAL_HEALTH],
        csvCheckType: CoverageCheckerCheckType
    ): ICsvFieldsMapping[] {
        const neededColumns = [
            ...CSV_CHECKER_CONFIG.resultsCsvMapping[csvCheckType],
        ]

        const additionalColumns = [
            ...Object.values(POLICIES_COLUMNS_CONFIG.columnsConfig),

            ...PoliciesHelper.getModalitiesSpecificColumnsConfigurations(
                neededModalities
            ),
        ].filter(item => item.addCheckerCSVDownload)

        // Process additional columns that require specific order
        for (const column of additionalColumns) {
            if (typeof column.coverageCheckerCsvDownloadOrder === "undefined") {
                continue
            }

            neededColumns.splice(
                column.coverageCheckerCsvDownloadOrder,
                0,
                ...PoliciesHelper.convertPolicyColumnsToCsvFieldsMappings([
                    column,
                ])
            )
        }

        const additionalColumnsWithoutOrder =
            PoliciesHelper.convertPolicyColumnsToCsvFieldsMappings(
                additionalColumns.filter(
                    item =>
                        typeof item.coverageCheckerCsvDownloadOrder ===
                        "undefined"
                )
            )

        // Will insert new columns without order before data corrections columns
        const index = neededColumns.findIndex(
            item =>
                item.header ===
                CSV_CHECKER_CONFIG.resultsCorrectedDataCsvMapping[0].header
        )

        // Just append to end
        if (index < 0) {
            return [...neededColumns, ...additionalColumnsWithoutOrder]
        }

        neededColumns.splice(index, 0, ...additionalColumnsWithoutOrder)

        return neededColumns
    }

    /**
     * Download a CSV document
     */
    static createAndDownloadCsvDocument(data: string, fileName = "download") {
        let url: string = ""

        try {
            const blob = new Blob([data], {
                type: "text/csv",
            })

            url = URL.createObjectURL(blob)

            const downloadLink = document.createElement("a")

            downloadLink.href = url
            downloadLink.download = `${fileName}-${moment().format(
                GENERAL_CONFIG.defaultMomentDateTimeFormat
            )}.csv`

            downloadLink.click()
        } finally {
            URL.revokeObjectURL(url)
        }
    }

    /**
     * Check different formats of date string
     */
    static getPossibleDateField(
        value?: string,
        failOnAllInvalid = false
    ): {
        primaryFormat?: Moment
        secondaryFormat?: Moment
        thirdFormat?: Moment
    } {
        const primaryFormat = value
            ? moment(value, GENERAL_CONFIG.defaultMomentDateFormat, true)
            : undefined

        const secondaryFormat = value
            ? moment(value, GENERAL_CONFIG.mysqlMomentDateFormat, true)
            : undefined

        const thirdFormat = value
            ? moment(
                  value,
                  GENERAL_CONFIG.defaultMomentDateFormatShortYear,
                  true
              )
            : undefined

        if (
            failOnAllInvalid &&
            !primaryFormat?.isValid() &&
            !secondaryFormat?.isValid() &&
            !thirdFormat?.isValid()
        ) {
            throw new Error("All date formats are invalid")
        }

        return {
            primaryFormat,
            secondaryFormat,
            thirdFormat,
        }
    }

    /**
     * Get data parsed from uploaded CSV of type ContinuousMonitoring
     */
    static async processUploadedContinuousMonitoringCsv(
        csv: PapaParse.ParseResult<unknown>,
        availableCptCodes: ISelectRenderedOption[],
        csvCheckType: CoverageCheckerCheckType,
        selectedPracticeRole?: IPracticeRole,
        maxBulkCoverageChecks = CSV_CHECKER_CONFIG.maxBulkCoverageChecksCSV
    ): Promise<IContinuousMonitoringCoverageCheckInputData[]> {
        let foundPayers: IPayer[] = []

        const processedInputData: IContinuousMonitoringCoverageCheckInputData[] =
            []

        for (const [index, row] of csv.data.entries()) {
            const rawRow = row as IIndexable

            // +2 since it's header + code index starts from 0
            const id = (index + 2).toString()

            try {
                if (processedInputData.length >= maxBulkCoverageChecks) {
                    break
                }

                const typedRow = plainToInstance(
                    RawCsvInputContinuousMonitoringDTO,
                    row
                )

                let validationErrors = (await validate(typedRow))
                    .map(item =>
                        item.constraints
                            ? Object.values(item.constraints)
                            : [item.toString()]
                    )
                    .flat()

                const commonData = CsvHelper.processUploadedCommonCsvRow(
                    typedRow,
                    csvCheckType,
                    selectedPracticeRole,
                    rawRow
                )

                if (commonData.validationErrors?.length) {
                    validationErrors = [
                        ...validationErrors,
                        ...commonData.validationErrors,
                    ]
                }

                let payer: IPayer | undefined

                // Process payer
                // Try to find it in lookup
                if (typedRow.payerExternalId) {
                    const alreadyFound = foundPayers.find(
                        item => item.payerId === typedRow.payerExternalId
                    )

                    if (alreadyFound) {
                        payer = alreadyFound
                    } else {
                        const lookupPayerResult =
                            await LookupService.lookupPayers(
                                typedRow.payerExternalId
                            )

                        if (lookupPayerResult.length) {
                            payer = lookupPayerResult[0]

                            // Save found payer for the future
                            // In case next row will need same payer object - we won't need to go to API
                            foundPayers = [...foundPayers, lookupPayerResult[0]]
                        }

                        if (!payer) {
                            validationErrors.push("Incorrect payer ID")
                        }
                    }
                }

                // Check CPT code to use
                if (
                    !availableCptCodes.find(
                        item => item.value === typedRow.cptCode
                    )
                ) {
                    validationErrors.push("Not supported CPT code")
                }

                const rowInputData: IContinuousMonitoringCoverageCheckInputData =
                    {
                        id,

                        validationErrors,

                        memberId: typedRow.memberId || undefined,

                        firstName: typedRow.firstName || undefined,
                        lastName: typedRow.lastName || undefined,

                        dob:
                            commonData.dob ||
                            UtilHelper.dateToMysqlFormat(new Date()),

                        payer: payer || ({} as IPayer),

                        // Leave as is in $
                        sessionRate: typedRow.sessionCharge?.replace("$", ""),
                        cptCode: typedRow.cptCode,

                        inNetworkCheck:
                            typedRow.network !== PayerCoverageCheckNetwork.OUT,

                        outNetworkCheck:
                            typedRow.network ===
                                PayerCoverageCheckNetwork.OUT ||
                            typedRow.network ===
                                PayerCoverageCheckNetwork.IN_OUT,

                        customerPatientType:
                            typedRow.customerPatientType || undefined,

                        customerPatientId:
                            typedRow.customerPatientId || undefined,

                        customerPatientNextAppointmentDate:
                            commonData.nextAppointmentDate,

                        passThroughColumns: commonData.passThroughColumns,
                    }

                // Process provider NPI
                // Set it as custom if regex is correct and it doesn't match practice's group NPI
                if (
                    typedRow.healthProviderNpi &&
                    REGEX_CONFIG.npi.test(typedRow.healthProviderNpi) &&
                    typedRow.healthProviderNpi !==
                        selectedPracticeRole?.practice.groupNPI
                ) {
                    rowInputData.customNpi = typedRow.healthProviderNpi
                }

                // Divide in and out checks into different rows
                // It will also make IDs for these different rows uniques
                if (rowInputData.inNetworkCheck) {
                    processedInputData.push({
                        ...rowInputData,

                        id: rowInputData.outNetworkCheck
                            ? `${rowInputData.id}_IN`
                            : rowInputData.id,

                        inNetworkCheck: true,
                        outNetworkCheck: false,
                    })
                }

                if (rowInputData.outNetworkCheck) {
                    processedInputData.push({
                        ...rowInputData,

                        id: rowInputData.inNetworkCheck
                            ? `${rowInputData.id}_OUT`
                            : rowInputData.id,

                        inNetworkCheck: false,
                        outNetworkCheck: true,
                    })
                }
            } catch (e) {
                processedInputData.push({
                    id,
                    validationErrors: [
                        `Something went wrong during processing CSV row. ${
                            e instanceof Error
                                ? `Error message: ${e.message}.`
                                : ""
                        } `,
                    ],
                    payer: {} as IPayer,
                } as IContinuousMonitoringCoverageCheckInputData)
            }
        }

        return CsvHelper.getSortedCsvUploadInputData<IContinuousMonitoringCoverageCheckInputData>(
            processedInputData
        )
    }

    /**
     * Get data parsed from uploaded CSV of type Medicaid
     */
    static async processUploadedMedicaidCsv(
        csv: PapaParse.ParseResult<unknown>,
        availableCptCodes: ISelectRenderedOption[],
        csvCheckType: CoverageCheckerCheckType,
        selectedPracticeRole?: IPracticeRole,
        maxBulkCoverageChecks = CSV_CHECKER_CONFIG.maxBulkCoverageChecksCSV
    ): Promise<IMedicaidCheckerInputData[]> {
        const processedInputData: IMedicaidCheckerInputData[] = []

        for (const [index, row] of csv.data.entries()) {
            const rawRow = row as Record<string, string>

            // +2 since it's header + code index starts from 0
            const id = (index + 2).toString()

            try {
                if (processedInputData.length >= maxBulkCoverageChecks) {
                    break
                }

                const typedRow = plainToInstance(RawCsvInputMedicaidDTO, row)

                let validationErrors = (await validate(typedRow))
                    .map(item =>
                        item.constraints
                            ? Object.values(item.constraints)
                            : [item.toString()]
                    )
                    .flat()

                const commonData = CsvHelper.processUploadedCommonCsvRow(
                    typedRow,
                    csvCheckType,
                    selectedPracticeRole,
                    rawRow
                )

                if (commonData.validationErrors?.length) {
                    validationErrors = [
                        ...validationErrors,
                        ...commonData.validationErrors,
                    ]
                }

                // Check CPT code to use
                if (
                    typedRow.cptCode &&
                    !availableCptCodes.find(
                        item => item.value === typedRow.cptCode
                    )
                ) {
                    validationErrors.push("Not supported CPT code")
                }

                // Check if state requires member ID
                if (
                    COVERAGE_CONFIG.medicaidStatesRequiringMemberID.includes(
                        typedRow.state
                    ) &&
                    !typedRow.memberId
                ) {
                    validationErrors.push(
                        `Member ID is required for state ${typedRow.state}`
                    )
                }

                const rowInputData: IMedicaidCheckerInputData = {
                    id,

                    providerNpi: typedRow.healthProviderNpi,

                    state: typedRow.state,
                    memberId: typedRow.memberId || undefined,

                    dob:
                        commonData.dob ||
                        UtilHelper.dateToMysqlFormat(new Date()),

                    firstName: typedRow.firstName,
                    lastName: typedRow.lastName,

                    cptCode: typedRow.cptCode,

                    customerPatientType:
                        typedRow.customerPatientType || undefined,

                    customerPatientId: typedRow.customerPatientId || undefined,

                    customerPatientNextAppointmentDate:
                        commonData.nextAppointmentDate,

                    validationErrors,

                    passThroughColumns: commonData.passThroughColumns,
                }

                processedInputData.push(rowInputData)
            } catch (e) {
                processedInputData.push({
                    id,
                    validationErrors: [
                        `Something went wrong during processing CSV row. ${
                            e instanceof Error
                                ? `Error message: ${e.message}.`
                                : ""
                        } `,
                    ],
                } as IMedicaidCheckerInputData)
            }
        }

        return CsvHelper.getSortedCsvUploadInputData<IMedicaidCheckerInputData>(
            processedInputData
        )
    }

    /**
     * Process uploaded common CSV row
     */
    private static processUploadedCommonCsvRow(
        row: {
            dob: string
            customerPatientNextAppointmentDate?: string
        },
        csvCheckType: CoverageCheckerCheckType,
        selectedPracticeRole?: IPracticeRole,
        rawRow?: Record<string, string>
    ): {
        validationErrors?: string[]
        dob?: string
        nextAppointmentDate?: string
        passThroughColumns: Record<string, string>
    } {
        const validationErrors: string[] = []

        const {
            primaryFormat: possibleDob,
            secondaryFormat: possibleSecondDob,
            thirdFormat: possibleThirdDob,
        } = CsvHelper.getPossibleDateField(row.dob)

        const dobToUse = possibleDob?.isValid()
            ? possibleDob
            : possibleSecondDob?.isValid()
            ? possibleSecondDob
            : possibleThirdDob?.isValid()
            ? possibleThirdDob
            : undefined

        if (row.dob) {
            if (!dobToUse) {
                validationErrors.push(
                    "Format of DOB is wrong. Should be one of: MM/DD/YYYY or MM/DD/YY or YYYY-MM-DD."
                )
            } else if (dobToUse?.isSameOrAfter(moment().startOf("day"))) {
                validationErrors.push("DOB should be more than today.")
            }
        }

        const dob = dobToUse?.isValid()
            ? UtilHelper.dateToMysqlFormat(dobToUse.toDate())
            : undefined

        if (!dob && dobToUse) {
            validationErrors.push(
                "DOB in CSV is in wrong format. Using fallback value."
            )
        }

        const {
            primaryFormat: primaryNextApptDate,
            secondaryFormat: secondaryNextApptDate,
            thirdFormat: thirdNextApptDate,
        } = CsvHelper.getPossibleDateField(
            row.customerPatientNextAppointmentDate
        )

        const nextAppointmentDateToUse = primaryNextApptDate?.isValid()
            ? primaryNextApptDate
            : secondaryNextApptDate?.isValid()
            ? secondaryNextApptDate
            : thirdNextApptDate?.isValid()
            ? thirdNextApptDate
            : undefined

        if (
            nextAppointmentDateToUse?.isSameOrBefore(
                moment().subtract(1, "day").startOf("day")
            )
        ) {
            validationErrors.push(
                "Next appointment date should be in the future."
            )
        }

        const nextAppointmentDate = nextAppointmentDateToUse?.isValid()
            ? UtilHelper.dateToMysqlFormat(nextAppointmentDateToUse.toDate())
            : undefined

        const passThroughColumns: Record<string, string> = {}

        const resultDownloadExtraHeaders =
            CsvHelper.getCoverageCheckerDownloadCSVColumns(
                selectedPracticeRole?.availableModalities,
                csvCheckType
            ).map(item => item.header)

        // Process extra columns that are just pass through
        // We'd need to go through "row" keys - it preserves order
        // And see which ones are extra (compare to CHECKER_CONFIG.uploadCsvHeaders index value)
        // And just add them to inputData.passThroughColumns
        // Then during export they will be appended to end of csv
        // Also make sure not to add headers that are part of our output - in case they reused previously downloaded csv
        Object.keys(rawRow || {}).forEach((key, index) => {
            if (
                index <
                    CSV_CHECKER_CONFIG.uploadCsvHeaders[csvCheckType].length ||
                resultDownloadExtraHeaders.includes(key) ||
                !rawRow
            ) {
                return
            }

            passThroughColumns[key] = rawRow[key]
        })

        return {
            validationErrors,
            dob,
            nextAppointmentDate,
            passThroughColumns,
        }
    }

    /**
     * Get data parsed from uploaded CSV sorted by amount of errors in row
     */
    private static getSortedCsvUploadInputData<
        Type extends { validationErrors?: string[] }
    >(data: Type[]): Type[] {
        const dataCopy = [...data]

        dataCopy.sort((x, y) => {
            const xHasErrors = !!x.validationErrors?.length
            const yHasErrors = !!y.validationErrors?.length

            return xHasErrors === yHasErrors ? 0 : xHasErrors ? -1 : 1
        })

        return dataCopy
    }

    /**
     * Get single column for download CSV based on config
     */
    private static getSingleExportColumnValue(
        fieldConfiguration: ICsvDataFieldConfig,
        inputData: IContinuousMonitoringCoverageCheckInputData,
        resultData?: ICoverageResult,
        selectedPracticeRole?: IPracticeRole,
        emptyColumnValue?: string,
        checkTypeSpecificData?: ICsvFieldsMappingCheckSpecificData
    ): any {
        let columnValue: any

        switch (fieldConfiguration.source) {
            case ExportDataSource.inputData:
                columnValue = inputData

                break

            case ExportDataSource.outputData:
                columnValue = resultData

                break

            case ExportDataSource.practiceRole:
                columnValue = selectedPracticeRole

                break

            case ExportDataSource.checkTypeSpecificData:
                columnValue = checkTypeSpecificData

                break
        }

        if (!columnValue) {
            return emptyColumnValue
        }

        // Go through each value path and assign it's value to column value, break down by "." so we can support nested properties of objects
        fieldConfiguration.valuePath.split(".").forEach(pathPart => {
            try {
                columnValue = columnValue?.[pathPart]
            } catch (e) {
                columnValue = emptyColumnValue
            }
        })

        // IF resulting value is undefined or null -> assign empty string to it
        if (typeof columnValue === "undefined" || columnValue === null) {
            columnValue = emptyColumnValue
        }

        return columnValue
    }
}
