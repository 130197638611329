import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class PoliciesService {
    /**
     * Get list of list of policies for reports
     */
    static async getReportsList(
        practice: IPractice,
        pagination: IPagination,
        filters: IPoliciesListFiltersData = {},
        sort: IPoliciesListSortData = {},
        abortSignal?: AbortSignal,
        additionalRequestData: Record<string, any> = {}
    ): Promise<ICoverageHistoryListResult | undefined> {
        return await PoliciesService.getPoliciesList(
            API_ROUTES.COVERAGE_REPORTS_GET_LIST,
            practice,
            pagination,
            filters,
            sort,
            abortSignal,
            additionalRequestData
        )
    }

    /**
     * Get list of flagged policies
     */
    static async getHistoryList(
        practice: IPractice,
        pagination: IPagination,
        filters: IPoliciesListFiltersData = {},
        sort: IPoliciesListSortData = {},
        abortSignal?: AbortSignal,
        additionalRequestData: Record<string, any> = {}
    ): Promise<ICoverageHistoryListResult | undefined> {
        return await PoliciesService.getPoliciesList(
            API_ROUTES.COVERAGE_HISTORY_GET_LIST,
            practice,
            pagination,
            filters,
            sort,
            abortSignal,
            additionalRequestData
        )
    }

    /**
     * Get list of overridden policies
     */
    static async getOverridesList(
        practice: IPractice,
        pagination: IPagination,
        filters: IPoliciesListFiltersData = {},
        sort: IPoliciesListSortData = {},
        abortSignal?: AbortSignal,
        additionalRequestData: Record<string, any> = {}
    ): Promise<ICoverageHistoryListResult | undefined> {
        return await PoliciesService.getPoliciesList(
            API_ROUTES.COVERAGE_OVERRIDES_GET_LIST,
            practice,
            pagination,
            filters,
            sort,
            abortSignal,
            additionalRequestData
        )
    }

    /**
     * Get list of plan year resets policies
     */
    static async getPlanYearResetsList(
        practice: IPractice,
        pagination: IPagination,
        filters: IPoliciesListFiltersData = {},
        sort: IPoliciesListSortData = {},
        abortSignal?: AbortSignal,
        additionalRequestData: Record<string, any> = {}
    ): Promise<ICoverageHistoryListResult | undefined> {
        return await PoliciesService.getPoliciesList(
            API_ROUTES.COVERAGE_PLAN_YEAR_RESETS_GET_LIST,
            practice,
            pagination,
            filters,
            sort,
            abortSignal,
            additionalRequestData
        )
    }

    /**
     * Get list of list of policies for medicaid
     */
    static async getMedicaidList(
        practice: IPractice,
        pagination: IPagination,
        filters: IPoliciesListFiltersData = {},
        sort: IPoliciesListSortData = {},
        abortSignal?: AbortSignal,
        additionalRequestData: Record<string, any> = {}
    ): Promise<ICoverageHistoryListResult | undefined> {
        return await PoliciesService.getPoliciesList(
            API_ROUTES.COVERAGE_MEDICAID_GET_LIST,
            practice,
            pagination,
            filters,
            sort,
            abortSignal,
            additionalRequestData
        )
    }

    /**
     * Get aggregations for medicaid report by plan type
     */
    static async getMedicaidReportsAggregationPlanType(
        practice: IPractice,
        filters: IPoliciesListFiltersData = {},
        abortSignal?: AbortSignal
    ): Promise<IGetMedicaidCoverageChecksAggregationsPlanType | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_MEDICAID_GET_AGGREGATION_PLAN_TYPE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...PoliciesService.mapPoliciesFilters(filters),
            },
            "GET",
            undefined,
            undefined,
            undefined,
            undefined,
            abortSignal
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get aggregations for medicaid report by active plan type
     */
    static async getMedicaidReportsAggregationActivePlanType(
        practice: IPractice,
        filters: IPoliciesListFiltersData = {},
        abortSignal?: AbortSignal
    ): Promise<
        IGetMedicaidCoverageChecksAggregationsActivePlanType | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_MEDICAID_GET_AGGREGATION_ACTIVE_PLAN_TYPE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...PoliciesService.mapPoliciesFilters(filters),
            },
            "GET",
            undefined,
            undefined,
            undefined,
            undefined,
            abortSignal
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get aggregations for report by status code
     */
    static async getReportsAggregationStatusCodes(
        practice: IPractice,
        filters: IPoliciesListFiltersData = {},
        abortSignal?: AbortSignal
    ): Promise<IGetCoverageChecksAggregationsStatusCodes | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_REPORTS_GET_AGGREGATION_STATUS_CODES.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...PoliciesService.mapPoliciesFilters(filters),
            },
            "GET",
            undefined,
            undefined,
            undefined,
            undefined,
            abortSignal
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get aggregations for report by flags
     */
    static async getReportsAggregationFlags(
        practice: IPractice,
        filters: IPoliciesListFiltersData = {},
        abortSignal?: AbortSignal
    ): Promise<IGetCoverageChecksAggregationsFlags | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_REPORTS_GET_AGGREGATION_FLAGS.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...PoliciesService.mapPoliciesFilters(filters),
            },
            "GET",
            undefined,
            undefined,
            undefined,
            undefined,
            abortSignal
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get aggregations for plan year resets
     */
    static async getPlanYearResetsAggregations(
        practice: IPractice,
        filters: IPoliciesListFiltersData = {},
        abortSignal?: AbortSignal
    ): Promise<IPlanYearResetAggregations | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_PLAN_YEAR_RESETS_GET_AGGREGATIONS.replace(
                ":practiceId",
                practice.id.toString()
            ),
            {
                ...PoliciesService.mapPoliciesFilters(filters),
            },
            "GET",
            undefined,
            undefined,
            undefined,
            undefined,
            abortSignal
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get single historical coverage check
     */
    static async getSingle(
        practice: IPractice,
        nirvanaRequestId: string,
        withPolicyDataReFetch?: boolean,
        ignoreToastrErrorCodes: number[] = []
    ): Promise<ICoverageCheckHistory | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_HISTORY_GET_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":nirvanaRequestId", nirvanaRequestId),
            {
                withPolicyDataReFetch,
            },
            undefined,
            undefined,
            undefined,
            undefined,
            ignoreToastrErrorCodes
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Retry single historical coverage check
     */
    static async retrySingle(
        practice: IPractice,
        nirvanaRequestId: string
    ): Promise<ICoverageResult | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_HISTORY_RETRY_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":nirvanaRequestId", nirvanaRequestId),
            undefined,
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Resolve flag for coverage check
     */
    static async resolveFlag(
        practice: IPractice,
        flagId: number
    ): Promise<ICoverageCheckHistory | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_FLAGS_RESOLVE_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":flagId", flagId.toString()),
            undefined,
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get policies list - common method for all types of lists
     */
    private static async getPoliciesList(
        baseUrl: string,
        practice: IPractice,
        pagination: IPagination,
        filters: IPoliciesListFiltersData = {},
        sort: IPoliciesListSortData = {},
        abortSignal?: AbortSignal,
        additionalRequestData: Record<string, any> = {}
    ): Promise<ICoverageHistoryListResult | undefined> {
        const result = await HttpHelper.sendRequest(
            baseUrl.replace(":practiceId", practice.id.toString()),
            {
                ...additionalRequestData,
                ...sort,
                ...PoliciesService.mapPoliciesFilters(filters),

                start: pagination.start,
                count: pagination.count,
            } as IGetCoverageHistoryListData,
            "GET",
            undefined,
            undefined,
            undefined,
            undefined,
            abortSignal
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Map filters for reports, policies, aggregations etc
     */
    private static mapPoliciesFilters(
        filters: IPoliciesListFiltersData = {}
    ): Record<string, any> {
        const {
            payer,
            checkDate,
            nextAppointmentDate,
            planEndDate,
            ...restFilters
        } = filters

        return {
            // Make sure not to send empty values
            ...Object.keys(restFilters)
                .filter(key => !!restFilters[key])
                .reduce(
                    (accumulator, key) => ({
                        ...accumulator,
                        [key]: restFilters[key],
                    }),
                    {}
                ),

            payerId: payer?.payerId,
            checkDate: JSON.stringify(checkDate),
            nextAppointmentDate: JSON.stringify(nextAppointmentDate),
            planEndDate: JSON.stringify(planEndDate),
        }
    }
}
