/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    ButtonElement,
    NirvanaBreathingLoaderElement,
    PrimaryText,
    TooltipElement,
} from "nirvana-react-elements"

import {
    policiesGetFullList,
    policiesReloadList,
    retryBulkCheck,
} from "../../../store/thunks/policies.thunks"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { ToastrHelper } from "../../../helpers/toastr.helper"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { CheckerHelper } from "../../../helpers/checker.helper"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { userDetailsSelector } from "../../../store/selectors/userDetails.selector"
import { PoliciesListType } from "../../../config/policies.config"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"

import closeIcon from "../../../assets/images/icons/close-dark.svg"

export const BulkPolicyControlComponent: React.FunctionComponent<
    IBulkPolicyControlComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const { selectedPracticeUsageQuota } = useAppSelector(userDetailsSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [performedSelectAll, setPerformedSelectAll] = useState<boolean>(false)

    const {
        policiesGettingFullListProgress,
        reportsPagination,
        historyPagination,
        overridesPagination,
        planYearResetsPagination,
        medicaidPagination,
    } = useAppSelector(policiesSelector)

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isGettingFullPoliciesListLoading()
    }, [runtimeState.isLoading])

    const isLoadingBulkRetry = useMemo<boolean>(() => {
        return RuntimeHelper.isRetryBulkPolicyLoading()
    }, [runtimeState.isLoading])

    const typeBasedPagination = useMemo<IPagination>(() => {
        switch (props.listType) {
            case PoliciesListType.REPORTS:
                return reportsPagination

            case PoliciesListType.HISTORY_SEARCH:
                return historyPagination

            case PoliciesListType.OVERRIDES:
                return overridesPagination

            case PoliciesListType.PLAN_YEAR_RESETS:
                return planYearResetsPagination

            case PoliciesListType.MEDICAID:
                return medicaidPagination
        }
    }, [
        props.listType,
        reportsPagination,
        historyPagination,
        overridesPagination,
        planYearResetsPagination,
        medicaidPagination,
    ])

    const tooltipText = useMemo<string | null>(() => {
        if (props.withBulkOverride && props.withBulkRecheck) {
            return TOOLTIPS_CONFIG.bulkActions.genericBulkAction
        }

        if (props.withBulkRecheck) {
            return TOOLTIPS_CONFIG.bulkActions.eligibleForBulkRecheck
        }

        if (props.withBulkOverride) {
            return TOOLTIPS_CONFIG.bulkActions.eligibleForBulkOverridePolicies
        }

        return null
    }, [props.withBulkRecheck, props.withBulkOverride])

    const isSelectAllAvailable = useMemo<boolean>(() => {
        return (
            !performedSelectAll &&
            typeBasedPagination.total > props.coverageChecks.length
        )
    }, [performedSelectAll, typeBasedPagination, props.coverageChecks])

    // Reset allow all selection when hiding component
    useEffect(() => {
        if (!props.coverageChecks.length && typeBasedPagination.total) {
            setPerformedSelectAll(false)
        }
    }, [props.coverageChecks, typeBasedPagination.total])

    const onSelectAllCoverageChecks = async () => {
        if (!selectedPracticeRole) {
            return
        }

        setPerformedSelectAll(true)

        const fetchedItems = await dispatch(
            policiesGetFullList({
                practice: selectedPracticeRole.practice,
                payload: {
                    type: props.listType,
                },
            })
        ).unwrap()

        props.setCoverageChecks(
            fetchedItems.filter(item => {
                const overrideEligible = props.withBulkOverride
                    ? PoliciesHelper.isPolicyBulkOverrideEligible(item)
                    : false

                const recheckEligible = props.withBulkRecheck
                    ? PoliciesHelper.isPolicyRecheckEligible(item)
                    : false

                return overrideEligible || recheckEligible
            })
        )
    }

    const bulkRecheckQuotaConfirmation = () => {
        if (!selectedPracticeUsageQuota || !props.coverageChecks.length) {
            return
        }

        CheckerHelper.runChecksWithQuotaConfirmation(
            selectedPracticeUsageQuota,
            onBulkRecheck,
            props.quotaUsageType,
            props.coverageChecks.length
        )
    }

    const onBulkRecheck = async () => {
        if (!selectedPracticeRole || !props.coverageChecks.length) {
            return
        }

        // Filter out items that are not eligible for recheck
        const nirvanaRequestIds = props.coverageChecks
            .filter(PoliciesHelper.isPolicyRecheckEligible)
            .map(item => item.coverageResult.resultId)

        await dispatch(
            retryBulkCheck({
                practice: selectedPracticeRole.practice,
                payload: {
                    nirvanaRequestIds,
                    policyViewType: props.listType,
                },
                onSuccess: onBulkRecheckDone,
            })
        )
    }

    const onBulkRecheckDone = async () => {
        ToastrHelper.success("Policies were re-checked successfully")

        if (selectedPracticeRole) {
            dispatch(
                policiesReloadList({
                    practice: selectedPracticeRole.practice,
                    payload: {
                        policiesViewType: props.listType,
                    },
                })
            )
        }

        props.onCancelBulkPolicyControl()
    }

    return !props.coverageChecks.length ||
        (!props.withBulkRecheck && !props.withBulkOverride) ? null : (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <NirvanaBreathingLoaderElement
                isActive={isLoading || isLoadingBulkRetry}
            />

            <div
                className="
                    fixed bottom-16px horizontal-center flex items-center
                    bg-brand-white rounded-8px p-16px shadow-7
                "
            >
                {props.withBulkRecheck && (
                    <ButtonElement
                        label={`${props.bulkRecheckLabel} ${
                            isLoading
                                ? ` (${policiesGettingFullListProgress}%)`
                                : ""
                        }`}
                        onClick={bulkRecheckQuotaConfirmation}
                        htmlType="button"
                        type="primary"
                        isLoading={isLoading}
                    />
                )}

                {props.withBulkOverride && (
                    <ButtonElement
                        className={props.withBulkRecheck ? "ml-8px" : ""}
                        label={`${props.bulkOverrideLabel} ${
                            isLoading
                                ? ` (${policiesGettingFullListProgress}%)`
                                : ""
                        }`}
                        onClick={props.onBulkOverride}
                        htmlType="button"
                        type="primary"
                        isLoading={isLoading}
                    />
                )}

                {!isLoading && (
                    <>
                        <PrimaryText
                            className="ml-16px mr-8px"
                            typography="text"
                        >
                            {props.coverageChecks.length} selected
                        </PrimaryText>

                        {isSelectAllAvailable && (
                            <div
                                className="cursor-pointer ml-8px mr-16px flex items-center"
                                onClick={onSelectAllCoverageChecks}
                            >
                                <PrimaryText typography="textBold" underline>
                                    Select all{" "}
                                    {`${typeBasedPagination?.total} `}
                                    policies
                                </PrimaryText>

                                {tooltipText && (
                                    <TooltipElement
                                        text={tooltipText}
                                        className="ml-8px"
                                        placement="top"
                                    />
                                )}
                            </div>
                        )}

                        <div
                            className="cursor-pointer p-8px relative top--1px"
                            onClick={props.onCancelBulkPolicyControl}
                        >
                            <img src={closeIcon} alt="cancel" />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
