/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    PageHelmetElement,
    useIsMobile,
    NIRVANA_THEME,
} from "nirvana-react-elements"

import {
    billerGroupsSelectSingle,
    billerGroupsSelectUser,
} from "../../../store/slices/billerGroups.slice"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { BillerGroupsSidebarComponent } from "./billerGroupSidebar.component"
import { BillerGroupMainComponent } from "./billerGroupMain.component"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { billerGroupsSelector } from "../../../store/selectors/billerGroups.selector"
import { billerGroupsGetSelected } from "../../../store/thunks/billerGroups.thunks"
import { useAppDispatch } from "../../../store/appDispatch.hook"

export const BillerGroupsComponent: React.FunctionComponent<
    IBillerGroupsComponentProps
> = props => {
    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const billerGroups = useAppSelector(billerGroupsSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    // We need this so in the moment when we are loading selected item from server
    // Sidebar still shows correct item selected
    const [sidebarSelectedBillerGroup, setSidebarSelectedBillerGroup] =
        useState<IBillerGroup>()

    const [groupCreationTrigger, setGroupCreationTrigger] = useState<number>()

    const isBillerGroupLoading = useMemo(
        () => RuntimeHelper.isBillerGroupsSingleItemLoading(),
        [runtimeState.isLoading]
    )

    // Select biller group from server once it's selected in sidebar
    // This way we'll get full data that we need
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        // Deselect user
        dispatch(billerGroupsSelectUser())

        if (!sidebarSelectedBillerGroup) {
            // Deselect only if selected
            billerGroups.selectedItem && dispatch(billerGroupsSelectSingle({}))

            return
        }

        dispatch(
            billerGroupsGetSelected({
                payload: {
                    billerGroupId: sidebarSelectedBillerGroup.id,
                },
                practice: selectedPracticeRole.practice,
            })
        )
    }, [sidebarSelectedBillerGroup?.id])

    // We need to keep these in sync so sidebar shows correctly selected item
    // And if it was deselected in state, deselect in sidebar
    useEffect(() => {
        if (!billerGroups.selectedItem && sidebarSelectedBillerGroup) {
            setSidebarSelectedBillerGroup(undefined)
        }
    }, [billerGroups.selectedItem?.id])

    const onBillerGroupCreate = () => {
        // Deselect user
        dispatch(billerGroupsSelectUser())
        setGroupCreationTrigger(new Date().getTime())
    }

    return (
        <div className="relative pb-24px">
            <PageHelmetElement
                title="Biller Groups"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <div
                style={{
                    height: !isTablet
                        ? `calc(100vh - ${GENERAL_CONFIG.headerHeight}px)`
                        : undefined,
                }}
                className="flex items-start md:block"
            >
                <BillerGroupsSidebarComponent
                    className="w-300px md:w-screen mr-24px"
                    onCreate={onBillerGroupCreate}
                    selectedBillerGroup={
                        isBillerGroupLoading
                            ? sidebarSelectedBillerGroup
                            : billerGroups.selectedItem ||
                              sidebarSelectedBillerGroup
                    }
                    onBillerGroupSelected={setSidebarSelectedBillerGroup}
                    onSidebarTabSelected={props.onSidebarTabSelected}
                    sidebarAvailableTabs={props.sidebarAvailableTabs}
                    sidebarSelectedTab={props.sidebarSelectedTab}
                />

                <BillerGroupMainComponent
                    className="flex-1"
                    creationTrigger={groupCreationTrigger}
                />
            </div>
        </div>
    )
}
