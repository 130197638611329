import {
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"
import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

import { GENERAL_CONFIG } from "../../config/general.config"
import { LoginStep } from "../../config/auth.config"

const initialState: IAuthState = {
    isLoading: false,
    loginStep: LoginStep.PASSWORD,
    loginData: null,
    autoSubmitLoginPasswordStep: false,
    mfaChallengePhoneNumber: null,
    forgotPasswordEmail: undefined,
    userInvitation: undefined,
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authReset: () => {
            return initialState
        },
        authSetIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        authSetLoginStep: (state, action: PayloadAction<LoginStep>) => {
            state.loginStep = action.payload
        },
        authSetLoginData: (state, action: PayloadAction<ILoginData | null>) => {
            state.loginData = action.payload
        },
        authSetAutoSubmitLoginPasswordStep: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.autoSubmitLoginPasswordStep = action.payload
        },
        authSetMfaChallengePhoneNumber: (
            state,
            action: PayloadAction<string | null>
        ) => {
            state.mfaChallengePhoneNumber = action.payload
        },
        authSetForgotPasswordEmail: (
            state,
            action: PayloadAction<{ email: string }>
        ) => {
            state.forgotPasswordEmail = action.payload.email
        },
        authSetCheckUserInvitation: (
            state,
            action: PayloadAction<IOrganizationUserInvite | undefined>
        ) => {
            if (!action.payload) {
                BrowserStorageHelper.remove(
                    GENERAL_CONFIG.browserStorageKeys.inviteToken,
                    BrowserStorageType.sessionStorage
                )
            }

            state.userInvitation = action.payload
        },
    },
})

export const {
    authReset,
    authSetIsLoading,
    authSetLoginStep,
    authSetLoginData,
    authSetAutoSubmitLoginPasswordStep,
    authSetMfaChallengePhoneNumber,
    authSetForgotPasswordEmail,
    authSetCheckUserInvitation,
} = authSlice.actions

export const authReducer = authSlice.reducer
