import { PolicyModality } from "nirvana-react-elements"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
    PoliciesListType,
    PolicyDenialRisk,
} from "../config/policies.config"
import { CHECKER_CONFIG } from "../config/checker.config"
import { ExportDataSource } from "../config/csvChecker.config"
import { CsvHelper } from "./csv.helper"
import { UtilHelper } from "./util.helper"
import { POLICIES_COLUMNS_CONFIG } from "../config/policiesColumns.config"
import { ROUTES_CONFIG } from "../config/routes.config"
import { OVERRIDES_CONFIG } from "../config/overrides.config"

export class PoliciesHelper {
    /**
     * Get single policy base url based on general view type
     */
    static getSinglePolicyBaseUrl(viewType: PoliciesListType): string {
        switch (viewType) {
            case PoliciesListType.REPORTS:
                return ROUTES_CONFIG.reportsSinglePolicyUrl

            case PoliciesListType.HISTORY_SEARCH:
                return ROUTES_CONFIG.searchSinglePolicyUrl

            case PoliciesListType.OVERRIDES:
                return ROUTES_CONFIG.overridesSinglePolicyUrl

            case PoliciesListType.PLAN_YEAR_RESETS:
                return ROUTES_CONFIG.planYearResetsSinglePolicyUrl

            case PoliciesListType.MEDICAID:
                return ROUTES_CONFIG.medicaidReportsSinglePolicyUrl
        }
    }

    /**
     * Get mapped policy column value based on coverage check
     */
    static getMappedColumnValue = (
        coverageCheckHistory: ICoverageCheckHistory,
        columnConfig: IPolicyColumnConfiguration,
        emptyColumnValue: string | null = null,
        jsxSupportedFormatting = true,
        onlyCheckResult = false
    ): JSX.Element | string | null => {
        let columnValue: any = columnConfig.getCustomValue
            ? columnConfig.getCustomValue(coverageCheckHistory)
            : onlyCheckResult
            ? coverageCheckHistory.coverageResult
            : columnConfig.source
            ? coverageCheckHistory[columnConfig.source]
            : undefined

        emptyColumnValue = columnConfig.emptyColumnValue || emptyColumnValue

        if (
            !columnValue &&
            !(
                columnConfig.alternativeSource &&
                columnConfig.alternativeSourcePropertyPath
            )
        ) {
            return emptyColumnValue
        }

        // Go through each value path and assign it's value to column value, break down by "." so we can support nested properties of objects
        // Only in case there's no custom value
        if (!columnConfig.getCustomValue && columnConfig.sourcePropertyPath) {
            columnConfig.sourcePropertyPath.split(".").forEach(pathPart => {
                try {
                    columnValue = columnValue?.[pathPart]
                } catch (e) {
                    columnValue = emptyColumnValue
                }
            })
        }

        // IF resulting value is undefined or null -> assign empty string to it
        if (typeof columnValue === "undefined" || columnValue === null) {
            columnValue = emptyColumnValue
        }

        // Try with alternative source
        if (
            columnValue === emptyColumnValue &&
            columnConfig.alternativeSource &&
            columnConfig.alternativeSourcePropertyPath
        ) {
            return PoliciesHelper.getMappedColumnValue(
                coverageCheckHistory,
                {
                    ...columnConfig,
                    source: columnConfig.alternativeSource,
                    sourcePropertyPath:
                        columnConfig.alternativeSourcePropertyPath,
                    alternativeSource: undefined,
                    alternativeSourcePropertyPath: undefined,
                },
                emptyColumnValue,
                jsxSupportedFormatting,
                onlyCheckResult
            )
        }

        return PoliciesHelper.getFormattedColumnValue(
            columnValue,
            columnConfig,
            emptyColumnValue,
            jsxSupportedFormatting,
            coverageCheckHistory.coverageResult
        )
    }

    /**
     * Get formatted column value based on config
     */
    static getFormattedColumnValue(
        columnValue: any,
        columnConfig: IPolicyColumnConfiguration,
        emptyColumnValue: string | null = null,
        jsxSupportedFormatting = true,
        coverageResultData?: ICoverageResult | null
    ): JSX.Element | string | null {
        if (
            columnConfig.formatting &&
            (columnValue !== emptyColumnValue ||
                columnConfig.formatOnEmptyColumn)
        ) {
            columnValue = CsvHelper.getFormattedColumnValue(
                columnValue,
                columnConfig.formatting,
                emptyColumnValue,
                jsxSupportedFormatting,
                coverageResultData
            )
        }

        try {
            return columnValue !== emptyColumnValue &&
                !columnConfig.ignoreValueCapitalization
                ? UtilHelper.capitalizeWordsInSentence(columnValue)
                : columnValue
        } catch (e) {
            return columnValue
        }
    }

    /**
     * Check if column cell is highlighted
     */
    static isColumnCellHighlighted(
        coverageCheckHistory: ICoverageCheckHistory,
        columnConfig: IPolicyColumnConfiguration
    ): boolean {
        let result: any = columnConfig.isHighlightedSource
            ? coverageCheckHistory[columnConfig.isHighlightedSource]
            : undefined

        if (!result) {
            return false
        }

        // Go through each value path and assign it's value to result, break down by "." so we can support nested properties of objects
        if (columnConfig.isHighlightedSourcePropertyPath) {
            const initialResult = result

            const pathVariants = Array.isArray(
                columnConfig.isHighlightedSourcePropertyPath
            )
                ? columnConfig.isHighlightedSourcePropertyPath
                : [columnConfig.isHighlightedSourcePropertyPath]

            for (const path of pathVariants) {
                result = initialResult

                for (const pathPart of path.split(".")) {
                    try {
                        result = result?.[pathPart]
                    } catch (e) {
                        result = undefined
                    }
                }

                if (result) {
                    break
                }
            }
        }

        return typeof result !== "undefined" && result !== null
    }

    /**
     * Get columns configurations specific to different modalities
     */
    static getModalitiesSpecificColumnsConfigurations(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IExtendedPolicyColumnConfiguration[] {
        let result: IExtendedPolicyColumnConfiguration[] = []

        for (const modality of availableModalities) {
            result = [
                ...result,

                ...POLICIES_COLUMNS_CONFIG.modalitySpecificColumns[modality],
            ]
        }

        return result
    }

    /**
     * Convert columns configurations to CSV fields mappings
     * Supports only coverage api result mappings
     */
    static convertPolicyColumnsToCsvFieldsMappings(
        columns: IPolicyColumnConfiguration[]
    ): ICsvFieldsMapping[] {
        const result: ICsvFieldsMapping[] = []

        for (const column of columns) {
            const dataSource: ICsvDataFieldConfig | undefined =
                column.source === "coverageResult" && column.sourcePropertyPath
                    ? {
                          source: ExportDataSource.outputData,
                          valuePath: column.sourcePropertyPath,
                      }
                    : column.alternativeSource === "coverageResult" &&
                      column.alternativeSourcePropertyPath
                    ? {
                          source: ExportDataSource.outputData,
                          valuePath: column.alternativeSourcePropertyPath,
                      }
                    : undefined

            // Ensure there is an available source
            if (!dataSource) {
                continue
            }

            // In the case of a sourcePropertyPath and an alternativeSourcePropertyPath, include both
            // Okay if dataSource and alternativeDataSource end up the same
            const alternativeDataSource: ICsvDataFieldConfig | undefined =
                column.alternativeSource === "coverageResult" &&
                column.alternativeSourcePropertyPath
                    ? {
                          source: ExportDataSource.outputData,
                          valuePath: column.alternativeSourcePropertyPath,
                      }
                    : undefined

            result.push({
                header: column.label,
                dataSource,
                ...(alternativeDataSource ? { alternativeDataSource } : {}),
                formatting: column.formatting,
            })
        }

        return result
    }

    /**
     * Map list of flags to denial risk
     */
    static getPolicyDenialRisk(
        flags?: CoveragePortalFlagType[] | null
    ): PolicyDenialRisk {
        if (!flags?.length) {
            return PolicyDenialRisk.NONE
        }

        const mappings = flags.map(
            item => POLICIES_CONFIG.flagTypeMappings[item]
        )

        const maxSeverity = Math.max(...mappings.map(item => item.severity))

        if (maxSeverity >= POLICIES_CONFIG.highAttentionFlagSeverityCheck) {
            return PolicyDenialRisk.HIGH
        } else if (
            maxSeverity >= POLICIES_CONFIG.mediumAttentionFlagSeverityCheck
        ) {
            return PolicyDenialRisk.MEDIUM
        }

        return PolicyDenialRisk.LOW
    }

    /**
     * Get demographics difference between input and result
     */
    static getDemographicsDifference(
        inputData:
            | IContinuousMonitoringCoverageCheckInputData
            | IGetCoverageEstimateData,
        resultDemographics?: ICoverageResultDemographics
    ): Partial<ICoverageResultDemographics> {
        if (!resultDemographics) {
            return {}
        }

        return Object.keys(resultDemographics).reduce((memo, key) => {
            let inputValue = inputData[key]
            const demographicsValue = resultDemographics[key]

            // Don't process attributes like address and gender
            // Check if input required values were provided
            if (
                !demographicsValue ||
                (!inputValue &&
                    CHECKER_CONFIG.demographicsDifference.inputRequiredKeys.includes(
                        key as keyof ICoverageResultDemographics
                    )) ||
                CHECKER_CONFIG.demographicsDifference.ignoreKeys.includes(
                    key as keyof ICoverageResultDemographics
                )
            ) {
                return memo
            }

            if (
                inputValue?.toLowerCase()?.trim() !==
                demographicsValue?.toLowerCase()?.trim()
            ) {
                memo[key] = demographicsValue
            }

            return memo
        }, {})
    }

    /**
     * Check if policy is eligible for bulk override
     */
    static isPolicyBulkOverrideEligible(
        policy: ICoverageCheckHistory
    ): boolean {
        return (
            !!policy.coverageResult.responseStatus &&
            OVERRIDES_CONFIG.bulkOverrides.allowedResponseStatusCodes.includes(
                policy.coverageResult.responseStatus
            )
        )
    }

    /**
     * Check if policy is eligible for recheck
     */
    static isPolicyRecheckEligible(policy: ICoverageCheckHistory): boolean {
        return (
            !!policy.coverageResult.responseStatus &&
            POLICIES_CONFIG.singlePolicyHistoryAllowedRunCheckStatusCodes.includes(
                policy.coverageResult.responseStatus
            )
        )
    }
}
