import {
    AvailablePlanStatus,
    AvailablePlanType,
    Gender,
} from "./coverage.config"
import { FeatureFlag } from "./featureFlags.config"
import { PoliciesListType } from "./policies.config"

export enum CalculatorResultType {
    unknownPlanStatus = "unknownPlanStatus",
    unknownDemographics = "unknownDemographics",
    activeCoverage = "activeCoverage",
    activePartialCoverage = "activePartialCoverage",
    activePlanCoverageUnknown = "activePlanCoverageUnknown",
    noCoverage = "noCoverage",
    generalError = "generalError",
    memberNotFound = "memberNotFound",
    connectionIssue = "connectionIssue",
    payerNotSupported = "payerNotSupported",
    providerNotRecognized = "providerNotRecognized",
    errorRetrievingCoverage = "errorRetrievingCoverage",
    memberIdPayerIdInvalid = "memberIdPayerIdInvalid",
    planTypeNotSupported = "planTypeNotSupported",
    invalidInput = "invalidInput",
    unexpectedError = "unexpectedError",
    tooManyRequests = "tooManyRequests",
    payerFailure = "payerFailure",
}

export enum CoverageCheckerMode {
    MANUAL = "manual",
    CSV = "csv",
}

export enum CoverageCheckerCheckType {
    CONTINUOUS_MONITORING = "continuousMonitoring",
    INTAKE = "intake",
    MEDICAID = "medicaid",
}

export enum CoverageCheckerRunningState {
    INPUT_MANUAL = "INPUT_MANUAL",
    INPUT_CSV = "INPUT_CSV",
    PROCESS_CSV = "PROCESS_CSV",
    PREVIEW_CSV = "PREVIEW_CSV",
    RESULTS_MANUAL = "RESULTS_MANUAL",
    RESULTS_CSV = "RESULTS_CSV",
}

export const CHECKER_CONFIG = {
    maxGetEstimateRetry: 3,

    maxBulkCoverageChecks: 10, // 10 at a time checks

    // How many checks we process at once
    processBatchSize: 10,
    processBatchSizeExtended: 25,

    get manualTypeTabs(): ICheckerTypeTab[] {
        return [
            {
                key: CoverageCheckerCheckType.CONTINUOUS_MONITORING,
                label: "Continuous monitoring",
                featureFlag:
                    FeatureFlag.ONE_VERIFY_CONTINUOUS_MONITORING_MANUAL_CHECK,
            },
            {
                key: CoverageCheckerCheckType.INTAKE,
                label: "Intake",
                featureFlag: FeatureFlag.ONE_VERIFY_INTAKE_MANUAL_CHECK,
            },
            {
                key: CoverageCheckerCheckType.MEDICAID,
                label: "Medicaid",
                featureFlag: FeatureFlag.ONE_VERIFY_MEDICAID_MANUAL_CHECK,
            },
        ]
    },

    get demographicsDifference(): Record<
        "inputRequiredKeys" | "ignoreKeys",
        (keyof ICoverageResultDemographics)[]
    > {
        return {
            inputRequiredKeys: ["memberId"],
            ignoreKeys: ["gender", "address"],
        }
    },

    get activeCoverageResults(): CalculatorResultType[] {
        return [
            CalculatorResultType.activeCoverage,
            CalculatorResultType.activePartialCoverage,
        ]
    },

    get activePlanStatuses(): AvailablePlanStatus[] {
        return [AvailablePlanStatus.activeAtRisk, AvailablePlanStatus.active]
    },

    get genderMapping(): Record<Gender, string> {
        return {
            [Gender.male]: "Male",
            [Gender.female]: "Female",
        }
    },

    get planTypeMapping(): Record<AvailablePlanType, string> {
        return {
            [AvailablePlanType.ORIGINAL_MEDICAID]: "Original Medicaid",
            [AvailablePlanType.MANAGED_MEDICAID]: "Managed Medicaid",
            [AvailablePlanType.MEDICARE_ADVANTAGE]: "Medicare Advantage",
            [AvailablePlanType.ORIGINAL_MEDICARE]: "Original Medicare",
            [AvailablePlanType.UNKNOWN]: "Unknown",
            [AvailablePlanType.NOT_APPLICABLE]: "N/A",
        }
    },

    get planTypeMappingByViewType(): Record<
        PoliciesListType,
        Partial<Record<AvailablePlanType, string>>
    > {
        return {
            [PoliciesListType.MEDICAID]: {
                [AvailablePlanType.ORIGINAL_MEDICAID]:
                    CHECKER_CONFIG.planTypeMapping[
                        AvailablePlanType.ORIGINAL_MEDICAID
                    ],
                [AvailablePlanType.MANAGED_MEDICAID]:
                    CHECKER_CONFIG.planTypeMapping[
                        AvailablePlanType.MANAGED_MEDICAID
                    ],
                [AvailablePlanType.NOT_APPLICABLE]:
                    CHECKER_CONFIG.planTypeMapping[
                        AvailablePlanType.NOT_APPLICABLE
                    ],
            },
            [PoliciesListType.HISTORY_SEARCH]: this.planTypeMapping,
            [PoliciesListType.OVERRIDES]: this.planTypeMapping,
            [PoliciesListType.PLAN_YEAR_RESETS]: this.planTypeMapping,
            [PoliciesListType.REPORTS]: this.planTypeMapping,
        }
    },

    get calculatorResultTypeTitleMapping(): Record<
        CalculatorResultType,
        string
    > {
        return {
            [CalculatorResultType.activeCoverage]: "Active",
            [CalculatorResultType.activePartialCoverage]:
                "Active - Partial Data",

            [CalculatorResultType.noCoverage]: "Inactive",

            [CalculatorResultType.activePlanCoverageUnknown]:
                "Unknown Coverage Status",
            [CalculatorResultType.unknownPlanStatus]: "Unknown Plan Status",
            [CalculatorResultType.unknownDemographics]: "Unknown Demographics",
            [CalculatorResultType.generalError]: "API Error",
            [CalculatorResultType.memberNotFound]: "Member Not Found",
            [CalculatorResultType.connectionIssue]: "Failed Connection",
            [CalculatorResultType.payerNotSupported]: "Payer Not Supported",
            [CalculatorResultType.providerNotRecognized]:
                "Provider Not Recognized",
            [CalculatorResultType.errorRetrievingCoverage]:
                "Error Retrieving Coverage",
            [CalculatorResultType.memberIdPayerIdInvalid]:
                "Member ID With a Different Payer",
            [CalculatorResultType.planTypeNotSupported]:
                "Plan Type Not Supported",
            [CalculatorResultType.invalidInput]: "Invalid Data Format",
            [CalculatorResultType.unexpectedError]: "Unexpected Error",
            [CalculatorResultType.tooManyRequests]: "Too Many Requests",
            [CalculatorResultType.payerFailure]: "Payer Failure",
        }
    },

    get calculatorResultTypeColorMapping(): Record<
        CalculatorResultType,
        string
    > {
        return {
            [CalculatorResultType.activeCoverage]: "#7064EA",
            [CalculatorResultType.activePartialCoverage]: "#AE9BEA",

            [CalculatorResultType.noCoverage]: "#2F1D47",

            [CalculatorResultType.activePlanCoverageUnknown]: "#800000",
            [CalculatorResultType.unknownPlanStatus]: "#761919",
            [CalculatorResultType.unknownDemographics]: "#E0115F",
            [CalculatorResultType.generalError]: "#B22222",
            [CalculatorResultType.memberNotFound]: "#BC2626",
            [CalculatorResultType.connectionIssue]: "#A45A52",
            [CalculatorResultType.payerNotSupported]: "#EA3C53",
            [CalculatorResultType.providerNotRecognized]: "#D21F3C",
            [CalculatorResultType.errorRetrievingCoverage]: "#420D09",
            [CalculatorResultType.memberIdPayerIdInvalid]: "#5E1914",
            [CalculatorResultType.planTypeNotSupported]: "#8D021F",
            [CalculatorResultType.invalidInput]: "#FFA07A",
            [CalculatorResultType.unexpectedError]: "#E9967A",
            [CalculatorResultType.tooManyRequests]: "#DB7093",
            [CalculatorResultType.payerFailure]: "#BA1607",
        }
    },

    get quickResultsSuccessfulResults(): CalculatorResultType[] {
        return [
            ...CHECKER_CONFIG.activeCoverageResults,

            CalculatorResultType.noCoverage,
        ]
    },

    get quickViewExcludedResultTypes(): CalculatorResultType[] {
        return [CalculatorResultType.activePlanCoverageUnknown]
    },
}
