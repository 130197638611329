/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    PageHelmetElement,
    SpinnerElement,
    useScrollFix,
} from "nirvana-react-elements"

import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "../../../config/rolesPermissions.config"
import {
    PoliciesListType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import {
    FEATURE_FLAGS_CONFIG,
    FeatureFlag,
} from "../../../config/featureFlags.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { VisualizationWrapperComponent } from "../visualizationWrapper.component"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { ReportsStatusCodesVisualizationComponent } from "./reportsStatusCodesVisualization.component"
import { ReportsFlagsVisualizationComponent } from "./reportsFlagsVisualization.component"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"
import { QuotaUsageType } from "../../../config/coverage.config"
import { BulkPolicyControlComponent } from "./bulkPolicyControl.component"
import { BulkOverridesComponent } from "../overrides/bulkOverrides/bulkOverrides.component"
import { PoliciesListHeaderComponent } from "../policiesList/policiesListHeader.component"

export const ReportsComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions(
        [
            AvailableCoveragePortalPermission.managePolicyFlags,
            AvailableCoveragePortalPermission.resolveFlags,
            AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
        ],
        PermissionsExistenceLogicalOperator.OR
    )

    useIsFeatureFlagEnabled(
        FEATURE_FLAGS_CONFIG.reportsFeatureFlags,
        undefined,
        ROUTES_CONFIG.profileUrl
    )

    const isReportVisualizationEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_REPORTS_VISUALIZATION
    )

    const {
        // vars
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageFlags,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    const scrollableTableRef = useRef<HTMLDivElement>(null)

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [isBulkOverrideActive, setIsBulkOverrideActive] =
        useState<boolean>(false)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return (
            (canManageFlags && isFlagsResolutionEnabled) ||
            (canManageOverrides && isOverridesEnabled)
        )
    }, [
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageOverrides,
        canManageFlags,
    ])

    const showBulkOverrideButton = useMemo<boolean>(() => {
        return isOverridesEnabled && canManageOverrides
    }, [isOverridesEnabled, canManageOverrides])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            PoliciesListHelper.getSavedColumnsConfiguration(
                PoliciesListType.REPORTS,
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        PoliciesListHelper.saveColumnsConfigurations(
            PoliciesListType.REPORTS,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesListHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    const onCancelBulkOverride = () => {
        setIsBulkOverrideActive(false)
        setSelectedPolicies([])
    }

    const onBulkOverride = () => {
        setSelectedPolicies(current => {
            return current.filter(policy => {
                return PoliciesHelper.isPolicyBulkOverrideEligible(policy)
            })
        })

        setIsBulkOverrideActive(true)
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Reports"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {selectedPracticeRole ? (
                <PoliciesListHeaderComponent
                    className="mb-24px"
                    listType={PoliciesListType.REPORTS}
                    setSelectedPolicies={setSelectedPolicies}
                    scrollableTableRef={scrollableTableRef}
                    withExport
                    withSavingFilterPresets
                />
            ) : (
                <SpinnerElement containerClassName="mt-32px text-center" />
            )}

            {!!selectedPracticeRole && isReportVisualizationEnabled && (
                <VisualizationWrapperComponent
                    adjustHorizontalScrollReference={scrollableTableRef}
                    listType={PoliciesListType.REPORTS}
                >
                    <ReportsStatusCodesVisualizationComponent
                        className="
                            w-350px self-stretch
                            border-r border-solid border-brand-warmShadow
                        "
                    />

                    <ReportsFlagsVisualizationComponent className="flex-1 self-stretch" />
                </VisualizationWrapperComponent>
            )}

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    scrollableElementRef={scrollableTableRef}
                    listType={PoliciesListType.REPORTS}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                    onChangeColumnOrder={onChangeColumnOrder}
                    isSinglePolicySelectable={
                        PoliciesHelper.isPolicyBulkOverrideEligible
                    }
                />
            ) : null}

            {listPoliciesSelectable && (
                <>
                    <BulkPolicyControlComponent
                        listType={PoliciesListType.REPORTS}
                        onCancelBulkPolicyControl={onDeselectAllPolicies}
                        coverageChecks={selectedPolicies}
                        setCoverageChecks={setSelectedPolicies}
                        quotaUsageType={QuotaUsageType.GENERAL}
                        withBulkOverride={showBulkOverrideButton}
                        bulkOverrideLabel="Bulk Override"
                        onBulkOverride={onBulkOverride}
                    />

                    {isBulkOverrideActive && (
                        <BulkOverridesComponent
                            listType={PoliciesListType.REPORTS}
                            onCancelBulkOverride={onCancelBulkOverride}
                            coverageChecksToOverride={selectedPolicies}
                            setCoverageChecksToOverride={setSelectedPolicies}
                        />
                    )}
                </>
            )}
        </div>
    )
}
