/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { PrimaryText } from "nirvana-react-elements"

import { BulkOverrideTab } from "../../../../config/overrides.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { BulkOverridesPreviewComponent } from "./bulkOverridesPreview.component"
import { BulkOverridesFormComponent } from "./bulkOverridesForm.component"

import leftArrowIcon from "../../../../assets/images/icons/arrow-left-dark.svg"

export const BulkOverridesComponent: React.FunctionComponent<
    IBulkOverridesComponentProps
> = props => {
    const [isOverrideFormVisible, setIsOverrideFormVisible] =
        useState<boolean>(false)

    // Show nothing if no policies selected for override
    return props.coverageChecksToOverride.length ? (
        <div
            className="
                no-body-scroll fixed z-30 left-0 bottom-0 w-screen
                bg-brand-offWhite overflow-y-auto
            "
            style={{
                height: `calc(100vh - ${GENERAL_CONFIG.headerHeight}px)`,
            }}
        >
            <div
                className="
                    px-8px pt-32px pb-8px flex items-center
                    border-b border-solid border-brand-warmLight
                "
            >
                <div
                    className="p-8px mr-8px cursor-pointer"
                    onClick={() => {
                        props.onCancelBulkOverride()
                    }}
                >
                    <img src={leftArrowIcon} alt="back" />
                </div>

                <PrimaryText typography="h3">Bulk Override</PrimaryText>
            </div>

            <div className="py-24px">
                <BulkOverridesPreviewComponent
                    coverageChecks={props.coverageChecksToOverride}
                    onPreviewTabChange={newTab => {
                        setIsOverrideFormVisible(
                            newTab === BulkOverrideTab.allowed
                        )
                    }}
                />

                {isOverrideFormVisible ? (
                    <BulkOverridesFormComponent
                        className="px-46px mt-16px"
                        coverageChecks={props.coverageChecksToOverride}
                        onDone={props.onCancelBulkOverride}
                        listType={props.listType}
                        benefitStructureNotRequired={
                            props.benefitStructureNotRequired
                        }
                    />
                ) : null}
            </div>
        </div>
    ) : null
}
