/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import moment from "moment-timezone"
import * as PapaParse from "papaparse"
import { CSVLink } from "react-csv"
import { PageFloatingButton } from "nirvana-react-elements"

import {
    CHECKER_CONFIG,
    CoverageCheckerCheckType,
    CoverageCheckerMode,
} from "../../config/checker.config"
import { PayerCoverageCheckNetwork } from "../../config/coverage.config"
import { CSV_CHECKER_CONFIG } from "../../config/csvChecker.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { UtilHelper } from "../../helpers/util.helper"
import { CsvHelper } from "../../helpers/csv.helper"
import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { checkerSelector } from "../../store/selectors/checker.selector"
import { CheckerService } from "../../services/checker.service"

import downloadWhiteImg from "../../assets/images/icons/download-white.svg"

export const ResultsDownloadComponent: React.FunctionComponent<
    IResultsDownloadComponentProps
> = props => {
    const checkerState = useAppSelector(checkerSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const checksType = useMemo<CoverageCheckerCheckType>(() => {
        return checkerState.checkerMode === CoverageCheckerMode.MANUAL
            ? checkerState.manualCheckType
            : checkerState.csvCheckType
    }, [
        checkerState.csvCheckType,
        checkerState.manualCheckType,
        checkerState.checkerMode,
    ])

    const resultsCount = useMemo<number>(() => {
        switch (checksType) {
            case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                return (
                    checkerState.continuousMonitoringCoverageChecks?.reduce(
                        (accumulator, item) =>
                            accumulator +
                            (item.result?.IN ? 1 : 0) +
                            (item.result?.OUT ? 1 : 0),
                        0
                    ) || 0
                )

            case CoverageCheckerCheckType.MEDICAID:
                return checkerState.medicaidChecks.filter(item => item.result)
                    .length

            case CoverageCheckerCheckType.INTAKE:
                return 0
        }
    }, [
        checksType,
        checkerState.medicaidChecks,
        checkerState.continuousMonitoringCoverageChecks,
    ])

    const getProcessedCsvResults = (): string => {
        let coverageChecks:
            | IContinuousMonitoringCoverageCheck[]
            | IMedicaidCoverageCheck[]
            | undefined

        switch (checksType) {
            case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                coverageChecks = checkerState.continuousMonitoringCoverageChecks

                break

            case CoverageCheckerCheckType.MEDICAID:
                coverageChecks = checkerState.medicaidChecks

                break

            case CoverageCheckerCheckType.INTAKE:
                coverageChecks = undefined

                break
        }

        if (!coverageChecks?.length || !resultsCount) {
            return ""
        }

        const csvData = [] as (string | number | boolean)[][]

        let headersAdded = false

        // Since our indexes can be messed up in state array (because removing adding rows)
        // We'd like to get indexes in similar manner how we are doing in manual checker component
        const checksIndexes = UtilHelper.getRealIndexesOfArray(coverageChecks)

        // Go through each index of our checks, so basically go through each check input and result data
        checksIndexes.forEach(index => {
            const inputData = coverageChecks?.[index].inputData

            if (!inputData) {
                return
            }

            let resultsData: (ICoverageResult | undefined)[] = []

            switch (checksType) {
                case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                    resultsData = [
                        coverageChecks?.[index].result?.[
                            PayerCoverageCheckNetwork.IN
                        ],

                        coverageChecks?.[index].result?.[
                            PayerCoverageCheckNetwork.OUT
                        ],
                    ]

                    break

                case CoverageCheckerCheckType.MEDICAID:
                    resultsData = [
                        coverageChecks?.[index].result as
                            | ICoverageResult
                            | undefined,
                    ]

                    break

                case CoverageCheckerCheckType.INTAKE:
                    break
            }

            resultsData.forEach(result => {
                if (!result) {
                    return
                }

                const columns = CsvHelper.getCoverageCheckerDownloadCSVColumns(
                    selectedPracticeRole?.availableModalities,
                    checksType
                )

                // We need to process headers here, so we add passThrough headers as well
                if (!headersAdded) {
                    // Add header rows
                    // Include also pass through if they are there
                    csvData.push([
                        ...columns.map(mapping => mapping.header),

                        ...Object.keys(inputData.passThroughColumns || {}),
                    ])

                    headersAdded = true
                }

                let csvRow = [] as (string | number | boolean)[]

                // Go through each column for our final csv and process check using mappings
                columns.forEach(mapping => {
                    const columnValue = CsvHelper.getSingleExportColumn(
                        mapping,

                        checksType === CoverageCheckerCheckType.MEDICAID
                            ? CheckerService.mapMedicaidCheckDataToContinuousMonitoringInputData(
                                  {
                                      inputData:
                                          inputData as IMedicaidCheckerInputData,
                                      result,
                                  },
                                  ""
                              )
                            : (inputData as IContinuousMonitoringCoverageCheckInputData),
                        result,
                        selectedPracticeRole,
                        checksType === CoverageCheckerCheckType.MEDICAID
                            ? {
                                  state: (
                                      inputData as IMedicaidCheckerInputData
                                  ).state,
                              }
                            : undefined
                    )

                    // Add value to row
                    csvRow.push(columnValue)
                })

                // Add passThrough columns to the end of csv rows
                if (inputData.passThroughColumns) {
                    Object.keys(inputData.passThroughColumns).forEach(key => {
                        if (!inputData.passThroughColumns) {
                            return
                        }

                        csvRow.push(inputData.passThroughColumns[key])
                    })
                }

                // Process each value and make sure there are no commas...
                csvRow = csvRow.map(column =>
                    typeof column === "string"
                        ? column.replace(/,/g, "")
                        : column
                )

                // Add row to csv
                csvData.push(csvRow)
            })
        })

        return PapaParse.unparse(csvData)
    }

    const processedCsvData = useMemo<string>(() => {
        return getProcessedCsvResults()
    }, [
        checksType,
        checkerState.continuousMonitoringCoverageChecks,
        checkerState.medicaidChecks,
    ])

    const csvFileName = useMemo<string>(() => {
        return CSV_CHECKER_CONFIG.downloadFileName
            .replace(
                "{{time}}",
                moment().format(GENERAL_CONFIG.defaultMomentDateTimeFormat)
            )
            .replace(
                "{{checksType}}",
                CHECKER_CONFIG.manualTypeTabs.find(
                    item => item.key === checksType
                )?.label || ""
            )
    }, [processedCsvData, checksType])

    return resultsCount ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <CSVLink
                data={processedCsvData}
                filename={csvFileName}
                target="_blank"
            >
                <PageFloatingButton
                    label={`Download CSV with ${resultsCount} result${
                        resultsCount === 1 ? "" : "s"
                    }`}
                    zIndex={30}
                    icon={downloadWhiteImg}
                />
            </CSVLink>
        </div>
    ) : null
}
