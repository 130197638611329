/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    ISelectRenderedOption,
    PrimaryText,
    ButtonElement,
} from "nirvana-react-elements"

import {
    policiesGetReportsList,
    policiesGetHistoryList,
    policiesReloadList,
    policiesGetOverridesList,
    policiesGetPlanYearResetsList,
    policiesGetMedicaidList,
} from "../../../store/thunks/policies.thunks"
import {
    AvailableCoverageHistorySortDirection,
    AvailableCoverageHistorySortField,
    POLICIES_CONFIG,
    PoliciesListType,
} from "../../../config/policies.config"
import {
    policiesSetHistorySort,
    policiesSetMedicaidSort,
    policiesSetOverridesSort,
    policiesSetPlanYearResetsSort,
    policiesSetReportsSort,
} from "../../../store/slices/policies.slice"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { UtilHelper } from "../../../helpers/util.helper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { PoliciesListPaginationComponent } from "./policiesListPagination.component"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { PoliciesListTableComponent } from "./policiesListTable.component"

export const PoliciesListComponent: React.FunctionComponent<
    IPoliciesListComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const policiesState = useAppSelector(policiesSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const {
        policyItems,
        nextPagination,
        sortConfiguration,
        filtersConfiguration,
        isGettingListError,
    } = useMemo<{
        policyItems: ICoverageCheckHistory[]
        nextPagination: IPagination
        sortConfiguration: IPoliciesListSortData
        filtersConfiguration: IPoliciesListFiltersData
        isGettingListError: boolean
    }>(() => {
        switch (props.listType) {
            case PoliciesListType.REPORTS:
                return {
                    policyItems: policiesState.reportsItems,
                    nextPagination: policiesState.reportsPagination,
                    sortConfiguration: policiesState.reportsSort,
                    filtersConfiguration: policiesState.reportsFilters,
                    isGettingListError: policiesState.reportsIsError,
                }

            case PoliciesListType.HISTORY_SEARCH:
                return {
                    policyItems: policiesState.historyItems,
                    nextPagination: policiesState.historyPagination,
                    sortConfiguration: policiesState.historySort,
                    filtersConfiguration: policiesState.historyFilters,
                    isGettingListError: policiesState.historyIsError,
                }

            case PoliciesListType.OVERRIDES:
                return {
                    policyItems: policiesState.overridesItems,
                    nextPagination: policiesState.overridesPagination,
                    sortConfiguration: policiesState.overridesSort,
                    filtersConfiguration: policiesState.overridesFilters,
                    isGettingListError: policiesState.overridesIsError,
                }

            case PoliciesListType.PLAN_YEAR_RESETS:
                return {
                    policyItems: policiesState.planYearResetsItems,
                    nextPagination: policiesState.planYearResetsPagination,
                    sortConfiguration: policiesState.planYearResetsSort,
                    filtersConfiguration: policiesState.planYearResetsFilters,
                    isGettingListError: policiesState.planYearResetsIsError,
                }

            case PoliciesListType.MEDICAID:
                return {
                    policyItems: policiesState.medicaidItems,
                    nextPagination: policiesState.medicaidPagination,
                    sortConfiguration: policiesState.medicaidSort,
                    filtersConfiguration: policiesState.medicaidFilters,
                    isGettingListError: policiesState.medicaidIsError,
                }
        }
    }, [props.listType, policiesState])

    const [selectedPaginationOption, setSelectedPaginationOption] =
        useState<ISelectRenderedOption>(
            POLICIES_CONFIG.availablePaginationOptions[0]
        )

    const [initialLoadTriggered, setInitialLoadTriggered] =
        useState<boolean>(false)
    const [reloadListTrigger, setReloadListTrigger] = useState<number>(
        new Date().getTime()
    )

    const isListLoading = useMemo<boolean>(() => {
        switch (props.listType) {
            case PoliciesListType.REPORTS:
                return RuntimeHelper.isGetPoliciesReportsLoading()

            case PoliciesListType.OVERRIDES:
                return RuntimeHelper.isGetPoliciesOverridesLoading()

            case PoliciesListType.HISTORY_SEARCH:
                return RuntimeHelper.isGetPoliciesHistoryLoading()

            case PoliciesListType.PLAN_YEAR_RESETS:
                return RuntimeHelper.isGetPoliciesPlanYearResetsLoading()

            case PoliciesListType.MEDICAID:
                return RuntimeHelper.isGetPoliciesMedicaidLoading()
        }
    }, [runtimeState.isLoading, props.listType])

    // Once reload trigger happens -> reload full list with needed length / filters / etc
    // Don't reload list if there are items and through url was asked not to
    useEffect(() => {
        !initialLoadTriggered && setInitialLoadTriggered(true)

        if (
            typeof nextPagination !== "undefined" &&
            searchParams.get(
                GENERAL_CONFIG.urlSearchParamsKeys.ignoreListReload
            )
        ) {
            setTimeout(() => {
                removeIgnoreListSearchParam()
            })

            return
        }

        const thunkPromise = getItems(
            UtilHelper.getPagination(selectedPaginationOption.value),
            () => {
                removeIgnoreListSearchParam()
            }
        )

        return () => {
            thunkPromise?.abort()
        }
    }, [reloadListTrigger])

    const removeIgnoreListSearchParam = () => {
        if (
            !searchParams.get(
                GENERAL_CONFIG.urlSearchParamsKeys.ignoreListReload
            )
        ) {
            return
        }

        searchParams.delete(GENERAL_CONFIG.urlSearchParamsKeys.ignoreListReload)
        setSearchParams(searchParams)
    }

    // Once filters || pagination || sort length change and initial was loaded -> reload list
    useEffect(() => {
        // Prevent duplicate requests on initial component load
        // Since we are setting filters from state/url/etc
        if (!initialLoadTriggered) {
            return
        }

        setReloadListTrigger(new Date().getTime())
    }, [
        JSON.stringify(filtersConfiguration),
        selectedPaginationOption,
        sortConfiguration,
    ])

    const onReloadList = () => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            policiesReloadList({
                practice: selectedPracticeRole.practice,
                payload: {
                    policiesViewType: props.listType,
                },
            })
        )
    }

    const getItems = (pagination?: IPagination, onSuccess?: () => void) => {
        if (!selectedPracticeRole) {
            return
        }

        const neededPagination = pagination || nextPagination

        if (neededPagination.moreAvailable === false) {
            return
        }

        const actionArgs = {
            practice: selectedPracticeRole.practice,
            payload: {
                pagination: neededPagination,
                filters: filtersConfiguration,
                sort: sortConfiguration,
            },
            onSuccess,
        }

        switch (props.listType) {
            case PoliciesListType.REPORTS:
                return dispatch(policiesGetReportsList(actionArgs))

            case PoliciesListType.HISTORY_SEARCH:
                return dispatch(policiesGetHistoryList(actionArgs))

            case PoliciesListType.OVERRIDES:
                return dispatch(policiesGetOverridesList(actionArgs))

            case PoliciesListType.PLAN_YEAR_RESETS:
                return dispatch(policiesGetPlanYearResetsList(actionArgs))

            case PoliciesListType.MEDICAID:
                return dispatch(policiesGetMedicaidList(actionArgs))
        }
    }

    const onSortChanged = (newSortField: AvailableCoverageHistorySortField) => {
        const payload = {
            sortBy: newSortField,

            sortDirection:
                newSortField !== sortConfiguration?.sortBy
                    ? AvailableCoverageHistorySortDirection.DESC
                    : sortConfiguration.sortDirection ===
                      AvailableCoverageHistorySortDirection.DESC
                    ? AvailableCoverageHistorySortDirection.ASC
                    : AvailableCoverageHistorySortDirection.DESC,
        }

        switch (props.listType) {
            case PoliciesListType.REPORTS:
                dispatch(policiesSetReportsSort(payload))

                break

            case PoliciesListType.HISTORY_SEARCH:
                dispatch(policiesSetHistorySort(payload))

                break

            case PoliciesListType.OVERRIDES:
                dispatch(policiesSetOverridesSort(payload))

                break

            case PoliciesListType.PLAN_YEAR_RESETS:
                dispatch(policiesSetPlanYearResetsSort(payload))

                break

            case PoliciesListType.MEDICAID:
                dispatch(policiesSetMedicaidSort(payload))

                break
        }
    }

    return (
        <div className={`relative ${props.className}`}>
            <PoliciesListTableComponent
                scrollableElementRef={props.scrollableElementRef}
                policies={policyItems}
                columns={props.columns}
                listType={props.listType}
                isListLoading={isListLoading}
                policiesSelectable={props.policiesSelectable}
                sortEnabled={props.sortEnabled}
                sortConfiguration={sortConfiguration}
                onSetSortField={onSortChanged}
                onSelectedItems={props.onSelectedItems}
                onDeselectedItems={props.onDeselectedItems}
                selectedItems={props.selectedItems}
                onChangeColumnOrder={props.onChangeColumnOrder}
                isSinglePolicySelectable={props.isSinglePolicySelectable}
            />

            {!policyItems.length && !isListLoading ? (
                <div className="mt-32px">
                    {isGettingListError ? (
                        <div className="text-center">
                            <PrimaryText typography="h6" centered>
                                Error when performing the search. Please try
                                again.
                            </PrimaryText>

                            <ButtonElement
                                className="mt-24px"
                                htmlType="button"
                                type="primary"
                                label="Try again"
                                onClick={onReloadList}
                            />
                        </div>
                    ) : (
                        <PrimaryText typography="h6" centered>
                            No policies found
                        </PrimaryText>
                    )}
                </div>
            ) : null}

            {!isListLoading && (
                <PoliciesListPaginationComponent
                    getNewItems={getItems}
                    nextPagination={nextPagination}
                    selectedPaginationOption={selectedPaginationOption}
                    setSelectedPaginationOption={setSelectedPaginationOption}
                />
            )}
        </div>
    )
}
