/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import {
    ButtonElement,
    PrimaryText,
    NIRVANA_COLORS,
    InputElement,
    VALIDATION_CONFIG,
    SpinnerElement,
} from "nirvana-react-elements"

import { authLogin } from "../../../../store/thunks/auth.thunks"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { authSelector } from "../../../../store/selectors/auth.selector"
import { ROUTES_CONFIG } from "../../../../config/routes.config"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { authSetAutoSubmitLoginPasswordStep } from "../../../../store/slices/auth.slice"

import mainImage from "../../../../assets/images/shapes/login.svg"
import mailIcon from "../../../../assets/images/icons/mail-dark.svg"
import arrowRightIcon from "../../../../assets/images/icons/arrow-right.svg"

export const PasswordStep: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const authState = useAppSelector(authSelector)

    const [isLoginError, setIsLoginError] = useState<boolean>(false)
    const [isAutoLoginActive, setIsAutoLoginActive] = useState<boolean>(false)

    // If need to auto submit login form and we have needed data
    useEffect(() => {
        if (!authState.autoSubmitLoginPasswordStep || !authState.loginData) {
            return
        }

        setIsAutoLoginActive(true)

        performLogin(authState.loginData, true)

        return () => {
            dispatch(authSetAutoSubmitLoginPasswordStep(false))
        }
    }, [])

    const performLogin = (data: any, isAutoLogin = false) => {
        setIsLoginError(false)

        dispatch(
            authLogin({
                payload: {
                    ...data,
                } as ILoginData,
                navigate,
                onError: () => setIsLoginError(true),
                ...(isAutoLogin
                    ? {
                          onFinally: () => {
                              setIsAutoLoginActive(false)
                          },
                      }
                    : {}),
            })
        )
    }

    return !isAutoLoginActive ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <img
                src={mainImage}
                alt="Nirvana Health"
                title="Nirvana Health"
                className="margin-x-center sm:w-150px"
            />

            <PrimaryText className="mt-32px" typography="h1" centered>
                Welcome back to <br />
                Nirvana Health
            </PrimaryText>

            <PrimaryText typography="subtitle" centered className="mt-32px">
                Insurance coverage solutions optimized for your specialty and
                scale.
            </PrimaryText>

            <form
                onSubmit={handleSubmit(data => {
                    performLogin(data)
                })}
                noValidate={true}
            >
                <div className="mt-44px flex items-start md:block">
                    <InputElement
                        className="mr-24px flex-1 md:mr-0px"
                        name="email"
                        label="Email address"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                        innerInputClassName={
                            isLoginError ? "errored" : undefined
                        }
                    />

                    <InputElement
                        className="flex-1 md:mt-44px"
                        name="password"
                        label="Password"
                        type="password"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            minLength: VALIDATION_CONFIG.minLengthPassword,
                            maxLength: VALIDATION_CONFIG.maxLengthPassword,
                        }}
                        innerInputClassName={
                            isLoginError ? "errored" : undefined
                        }
                    />
                </div>

                <div className="mt-32px flex items-center">
                    <div className="flex-1 mr-16px">
                        <Link to={ROUTES_CONFIG.restoreUrl}>
                            Forgot password?
                        </Link>
                    </div>

                    {isLoginError ? (
                        <div className="mr-24px">
                            <PrimaryText color={NIRVANA_COLORS.brand.error}>
                                Hmmm… wrong credentials provided. <br />
                                Please try again.
                            </PrimaryText>
                        </div>
                    ) : null}

                    <ButtonElement
                        label="Log in"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={authState.isLoading}
                    />
                </div>
            </form>
        </div>
    ) : (
        <SpinnerElement containerClassName="mt-60px text-center" />
    )
}
