/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
    useScrollFix,
    PageHelmetElement,
    FixedTopRightActionElement,
} from "nirvana-react-elements"

import {
    authSetLoginData,
    authSetLoginStep,
} from "../../../store/slices/auth.slice"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { authSelector } from "../../../store/selectors/auth.selector"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { LoginStep } from "../../../config/auth.config"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { PasswordStep } from "./steps/password.step"
import { PhoneNumberStep } from "./steps/phoneNumber.step"
import { CodeStep } from "./steps/code.step"

export const LoginComponent: React.FunctionComponent = () => {
    useScrollFix()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const authState = useAppSelector(authSelector)

    // Init & cleanup
    useEffect(() => {
        dispatch(authSetLoginStep(LoginStep.PASSWORD))

        return () => {
            dispatch(authSetLoginData(null))
        }
    }, [])

    const getLoginStep = () => {
        switch (authState.loginStep) {
            case LoginStep.PASSWORD:
                return <PasswordStep />

            case LoginStep.MFA_PHONE_COLLECTION:
                return <PhoneNumberStep className="mt-200px md:mt-24px" />

            case LoginStep.MFA_CODE_COLLECTION:
                return <CodeStep className="mt-200px md:mt-24px" />
        }
    }

    const getTopRightAction = () => {
        switch (authState.loginStep) {
            case LoginStep.PASSWORD:
                return (
                    <FixedTopRightActionElement
                        ctaAction={() => navigate(ROUTES_CONFIG.registerUrl)}
                        ctaLabel="Create an Account"
                        additionalLabel="Don’t have an login?"
                        className="z-201 md:static! md:mt-32px"
                    />
                )

            case LoginStep.MFA_PHONE_COLLECTION:
            case LoginStep.MFA_CODE_COLLECTION:
                return (
                    <FixedTopRightActionElement
                        ctaAction={() => {
                            dispatch(authSetLoginStep(LoginStep.PASSWORD))
                        }}
                        ctaLabel="Back to Login"
                        className="z-201 md:static! md:mt-32px"
                    />
                )
        }
    }

    return (
        <div className="md:px-16px">
            <PageHelmetElement
                title="Log In"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {getLoginStep()}

            {getTopRightAction()}
        </div>
    )
}
