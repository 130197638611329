import { UtilHelper } from "nirvana-react-elements"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class UserDetailsService {
    /**
     * Get user profile
     */
    static async getProfile(): Promise<IProfile | undefined> {
        const result = await HttpHelper.sendRequest(API_ROUTES.PROFILE_GET)

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Accept invitation to some practice by user
     */
    static async acceptUserInvitation(
        invite: IOrganizationUserInvite
    ): Promise<IProfile | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.PROFILE_ACCEPT_ORG_INVITATION.replace(
                ":inviteToken",
                invite.inviteToken
            ),
            undefined,
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get practice usage quota
     */
    static async getPracticeUsageQuota(
        practice: IPractice
    ): Promise<IPracticeUsageQuota | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.PRACTICE_GET_USAGE_QUOTA.replace(
                ":practiceId",
                practice.id.toString()
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
