/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { Link, useLocation } from "react-router-dom"
import { PrimaryText } from "nirvana-react-elements"
import { MenuDropdownElement } from "elements/menuDropdown.element"

export const DesktopMenuComponent: React.FunctionComponent<
    IMenuProps
> = props => {
    const location = useLocation()

    const isActiveMenuItem = (item: IMenuItem): boolean =>
        item.url === location.pathname

    return (
        <div className={`flex items-center md:hidden ${props.className}`}>
            {props.menuItems.map((item: IMenuItem, index: number) => {
                const isActive = isActiveMenuItem(item)
                const hasSubmenu = !!item.submenu
                const hasSubmenuItems = (item.submenu?.length ?? 0) > 0

                return hasSubmenu ? (
                    hasSubmenuItems ? (
                        <MenuDropdownElement
                            key={index}
                            className={`
                                ${
                                    index !== props.menuItems.length - 1
                                        ? "mr-32px"
                                        : ""
                                }
                            `}
                            label={item.label}
                            items={item.submenu || []}
                        />
                    ) : null
                ) : (
                    <Link to={item.url} key={index} className="no-underline!">
                        <PrimaryText
                            typography={isActive ? "textBold" : "text"}
                            centered
                            className={`
                                ${
                                    index !== props.menuItems.length - 1
                                        ? "mr-32px"
                                        : ""
                                }
                            `}
                        >
                            {item.label}
                        </PrimaryText>
                    </Link>
                )
            })}
        </div>
    )
}
