/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"

import { AvailablePlanType } from "../config/coverage.config"
import { OVERRIDES_CONFIG } from "../config/overrides.config"
import { UtilHelper } from "../helpers/util.helper"
import { useIsFeatureFlagEnabled } from "./isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../config/featureFlags.config"
import { RuntimeHelper } from "../helpers/runtime.helper"
import { useAppSelector } from "../store/selectors/app.selector"
import { runtimeSelector } from "../store/selectors/runtime.selector"
import { ToastrHelper } from "../helpers/toastr.helper"

export const useBulkOverrides = () => {
    const isOverridesEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_OVERRIDES
    )

    const overridesFormMethods = useForm()
    const dataWatcher: ICoverageOverrideFormData = overridesFormMethods.watch()

    const runtimeState = useAppSelector(runtimeSelector)

    const [suggestedOverrideExpirationDate] = useState(moment().endOf("year"))
    const [minOverrideExpirationDate] = useState(moment().add(1, "day"))

    const [overridesFormKey, setOverridesFormKey] = useState<number>(
        new Date().getTime()
    )

    const isCreateOverrideLoading = useMemo(() => {
        return RuntimeHelper.isCoverageOverridesCreationLoading()
    }, [runtimeState.isLoading])

    const onUseSuggestedOverrideExpiration = () => {
        overridesFormMethods.setValue(
            "expirationDate",
            suggestedOverrideExpirationDate
        )
    }

    const getFormDefaultData = (
        policyData: ICoverageOverride | ICoverageResult | null,
        policyDataSecondary?: ICoverageOverride | ICoverageResult,
        fallbackDefaultValues = true
    ): ICoverageOverrideFormData => {
        const benefitStructure =
            policyData?.benefitStructure ||
            policyDataSecondary?.benefitStructure

        // Find current benefit structure and get fields that should be set to null since they are not applicable
        const notApplicableFields = benefitStructure
            ? OVERRIDES_CONFIG.benefitsStructuresConfig.find(
                  item => item.title === benefitStructure
              )?.notApplicableOnBeingExistingResponseFields
            : undefined

        const modalityCoverageStatus =
            policyData?.modalityCoverageStatus ||
            policyDataSecondary?.modalityCoverageStatus

        const eligibilityBeginDate =
            policyData?.eligibilityBeginDate ||
            policyDataSecondary?.eligibilityBeginDate

        const eligibilityEndDate =
            policyData?.eligibilityEndDate ||
            policyDataSecondary?.eligibilityEndDate

        let coinsurance = UtilHelper.getNumericValueIfSet(
            policyData?.coinsurance,
            policyDataSecondary?.coinsurance
        )

        // Don't null fields that are 0 below, so they are still shown in UI
        // That's why checking if !== 0

        coinsurance =
            notApplicableFields?.includes("coinsurance") && coinsurance !== 0
                ? null
                : coinsurance

        let copayment = UtilHelper.getNumericValueIfSet(
            policyData?.copayment,
            policyDataSecondary?.copayment
        )

        copayment =
            notApplicableFields?.includes("copayment") && copayment !== 0
                ? null
                : copayment

        let deductible = UtilHelper.getNumericValueIfSet(
            policyData?.deductible,
            policyDataSecondary?.deductible
        )

        deductible =
            notApplicableFields?.includes("deductible") && deductible !== 0
                ? null
                : deductible

        let remainingDeductible = UtilHelper.getNumericValueIfSet(
            policyData?.remainingDeductible,
            policyDataSecondary?.remainingDeductible
        )

        remainingDeductible =
            notApplicableFields?.includes("remainingDeductible") &&
            remainingDeductible !== 0
                ? null
                : remainingDeductible

        let oopMax = UtilHelper.getNumericValueIfSet(
            policyData?.oopMax,
            policyDataSecondary?.oopMax
        )

        oopMax =
            notApplicableFields?.includes("oopMax") && oopMax !== 0
                ? null
                : oopMax

        let remainingOopMax = UtilHelper.getNumericValueIfSet(
            policyData?.remainingOopMax,
            policyDataSecondary?.remainingOopMax
        )

        remainingOopMax =
            notApplicableFields?.includes("remainingOopMax") &&
            remainingOopMax !== 0
                ? null
                : remainingOopMax

        const planStatus =
            policyData?.planStatus || policyDataSecondary?.planStatus

        const planType = policyData?.planType || policyDataSecondary?.planType

        const planBeginDate =
            policyData?.planBeginDate || policyDataSecondary?.planBeginDate

        const planEndDate =
            policyData?.planEndDate || policyDataSecondary?.planEndDate

        const thirdPartyPayer =
            policyData?.thirdPartyPayer || policyDataSecondary?.thirdPartyPayer

        const memberObligation = UtilHelper.getNumericValueIfSet(
            policyData?.memberObligation,
            policyDataSecondary?.memberObligation
        )

        const payerObligation = UtilHelper.getNumericValueIfSet(
            policyData?.payerObligation,
            policyDataSecondary?.payerObligation
        )

        const result: ICoverageOverrideFormData = {
            modalityCoverageStatus: modalityCoverageStatus || undefined,
            eligibilityBeginDate: eligibilityBeginDate
                ? moment(eligibilityBeginDate)
                : undefined,
            eligibilityEndDate: eligibilityEndDate
                ? moment(eligibilityEndDate)
                : undefined,
            coinsurance:
                coinsurance !== null && typeof coinsurance !== "undefined"
                    ? coinsurance
                    : undefined,
            copayment:
                copayment !== null && typeof copayment !== "undefined"
                    ? UtilHelper.formatCents(copayment)
                    : undefined,
            deductible:
                deductible !== null && typeof deductible !== "undefined"
                    ? UtilHelper.formatCents(deductible)
                    : undefined,
            remainingDeductible:
                remainingDeductible !== null &&
                typeof remainingDeductible !== "undefined"
                    ? UtilHelper.formatCents(remainingDeductible)
                    : undefined,
            oopMax:
                oopMax !== null && typeof oopMax !== "undefined"
                    ? UtilHelper.formatCents(oopMax)
                    : undefined,
            remainingOopMax:
                remainingOopMax !== null &&
                typeof remainingOopMax !== "undefined"
                    ? UtilHelper.formatCents(remainingOopMax)
                    : undefined,
            planStatus: planStatus !== null ? planStatus : undefined,
            planType:
                planType ||
                (fallbackDefaultValues
                    ? AvailablePlanType.NOT_APPLICABLE
                    : undefined),
            planBeginDate: planBeginDate ? moment(planBeginDate) : undefined,
            planEndDate: planEndDate ? moment(planEndDate) : undefined,
            benefitStructure: undefined,
            customBenefitStructure: undefined,
            memberObligation:
                memberObligation !== null &&
                typeof memberObligation !== "undefined"
                    ? UtilHelper.formatCents(memberObligation)
                    : undefined,
            payerObligation:
                payerObligation !== null &&
                typeof payerObligation !== "undefined"
                    ? UtilHelper.formatCents(payerObligation)
                    : undefined,
            thirdPartyPayer: thirdPartyPayer || undefined,
        }

        if (benefitStructure) {
            const knownBenefits = OVERRIDES_CONFIG.benefitsStructuresConfig.map(
                item => item.title
            )

            result.benefitStructure = knownBenefits.includes(benefitStructure)
                ? benefitStructure
                : OVERRIDES_CONFIG.defaultBenefitsStructure

            // If benefit structure is unknown - set it to "custom" for Other option
            result.customBenefitStructure =
                result.benefitStructure ===
                    OVERRIDES_CONFIG.defaultBenefitsStructure &&
                benefitStructure !== OVERRIDES_CONFIG.defaultBenefitsStructure
                    ? benefitStructure
                    : undefined
        }

        return result
    }

    const onResetOverride = () => {
        overridesFormMethods.reset()
        setOverridesFormKey(new Date().getTime())
    }

    const validateOverrideCreation = (
        data: ICoverageOverrideFormData
    ): boolean => {
        let errorMessage: string | undefined

        // Make sure that "different" data has set values for more than 1 item
        // This single item that we ignore is expirationDate
        if (Object.keys(data).length < 2) {
            errorMessage = OVERRIDES_CONFIG.validations.noDataProvided
        }

        // If benefit structure is other -> and description the one from known values -> don't allow it
        if (
            data.customBenefitStructure &&
            OVERRIDES_CONFIG.benefitsStructuresConfig
                .map(item => item.title)
                .includes(data.customBenefitStructure)
        ) {
            errorMessage =
                OVERRIDES_CONFIG.validations.otherBenefitStructureEqualKnown
        }

        // if benefits structure is not Other, don't allow to override obligations
        if (
            !data.customBenefitStructure &&
            (typeof data.memberObligation === "number" ||
                typeof data.payerObligation === "number")
        ) {
            errorMessage = OVERRIDES_CONFIG.validations.memberPayerObligations
        }

        if (!errorMessage) {
            return true
        }

        ToastrHelper.error(errorMessage)

        return false
    }

    const mapCreationFormData = (
        data: any,
        defaultData: IOverrideDefaultData,
        withFieldsIgnoringSameValueCheck = true
    ): ICoverageOverrideFormData => {
        const typedData: ICoverageOverrideFormData = {}

        // Map data so empty string is set as undefined
        // Also remove data that is same as in default data - we don't need to send it to server
        // But we still need to send data that is same in override
        Object.keys(data).forEach(key => {
            const value = data[key]
            const typedKey = key as keyof ICoverageOverrideFormData

            // If value doesn't exist in existing override
            // && if:
            // - value is undefined -> don't send it to server
            // - value is same as default form data -> don't send this data to server unless field is always added even if same and that's requested by function call
            if (
                (typeof defaultData.override[typedKey] === "undefined" ||
                    defaultData.override[typedKey] === null) &&
                (typeof value === "undefined" ||
                    ((value || "").toString() ===
                        (defaultData.form[typedKey] || "").toString() &&
                        (!withFieldsIgnoringSameValueCheck ||
                            !OVERRIDES_CONFIG.bulkOverrides.fieldsIgnoreSameValueCheck.includes(
                                typedKey
                            ))))
            ) {
                return
            }

            // If removed value -> it will result in ""
            // If reset value in dropdown (clicking X) it will result in undefined
            // So we want to send null to server
            typedData[typedKey] =
                value === "" || typeof value === "undefined" ? null : value
        })

        return typedData
    }

    return {
        // vars
        isOverridesEnabled,
        suggestedOverrideExpirationDate,
        minOverrideExpirationDate,
        isCreateOverrideLoading,
        // state / form
        overridesFormKey,
        setOverridesFormKey,
        dataWatcher,
        overridesFormMethods,
        // methods
        getFormDefaultData,
        onUseSuggestedOverrideExpiration,
        onResetOverride,
        validateOverrideCreation,
        mapCreationFormData,
    }
}
