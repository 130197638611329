import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment-timezone"
import {
    ButtonElement,
    ConfirmationHelper,
    PageHelmetElement,
    PrimaryText,
    SpinnerElement,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "config/routes.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { profileSelector } from "../../store/selectors/profile.selector"
import { useIsFeatureFlagEnabled } from "../../hooks/isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../../config/featureFlags.config"
import { AuthHelper } from "../../helpers/auth.helper"
import { UtilHelper } from "../../helpers/util.helper"
import { useAppDispatch } from "../../store/appDispatch.hook"
import { authEditMFAPhoneNumber } from "../../store/thunks/auth.thunks"
import { authSelector } from "../../store/selectors/auth.selector"

import exitIcon from "../../assets/images/icons/exit-red.svg"
import checkGreenIcon from "../../assets/images/icons/check-green.svg"
import checkRedIcon from "../../assets/images/icons/check-red.svg"
import editIcon from "../../assets/images/icons/edit-dark.svg"

export const AccountDetailsComponent: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const profile = useAppSelector(profileSelector)
    const authState = useAppSelector(authSelector)

    const isBillerGroupsEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_BILLER_GROUPS
    )

    const [mfaPhoneNumber, setMFAPhoneNumber] = useState<string | null>()

    useEffect(() => {
        AuthHelper.getMFAPhoneNumber().then(setMFAPhoneNumber)
    }, [])

    const onEditMfaPhoneNumber = () => {
        ConfirmationHelper.enableConfirmation(
            () => {
                if (!mfaPhoneNumber) {
                    return
                }

                dispatch(
                    authEditMFAPhoneNumber({
                        payload: null,
                        navigate,
                    })
                )
            },
            "Are you sure you want to edit your phone number?",
            "This action will require you to login again with entering SMS code from current phone number."
        )
    }

    return (
        <div className="max-w-840px mx-auto relative">
            <PageHelmetElement
                title="Account Details"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {!profile || authState.isLoading ? (
                <SpinnerElement containerClassName="mt-60px text-center" />
            ) : (
                <div>
                    <PrimaryText typography="h5">General</PrimaryText>

                    <div className="mt-16px">
                        <div className="flex items-center sm:block">
                            <PrimaryText
                                className="w-190px mr-10px"
                                typography="textSemibold"
                            >
                                Name
                            </PrimaryText>

                            <PrimaryText
                                typography="text"
                                className="mouseflow-ignore"
                            >
                                {profile.name}
                            </PrimaryText>
                        </div>

                        <div className="flex items-center mt-8px sm:block">
                            <PrimaryText
                                className="w-190px mr-10px"
                                typography="textSemibold"
                            >
                                Email Address
                            </PrimaryText>
                            <PrimaryText
                                typography="text"
                                className="mouseflow-ignore"
                            >
                                {profile.email}
                            </PrimaryText>
                        </div>

                        <div className="flex items-center mt-8px sm:block">
                            <PrimaryText
                                className="w-190px mr-10px"
                                typography="textSemibold"
                            >
                                Password
                            </PrimaryText>
                            <PrimaryText typography="text">
                                ************
                            </PrimaryText>
                        </div>

                        {isBillerGroupsEnabled &&
                        profile.billerGroups?.length ? (
                            <div className="flex items-center mt-8px sm:block">
                                <PrimaryText
                                    className="w-190px mr-10px"
                                    typography="textSemibold"
                                >
                                    Biller Groups
                                </PrimaryText>
                                <PrimaryText typography="text">
                                    {profile.billerGroups
                                        .map(item => item.name)
                                        .join(", ")}
                                </PrimaryText>
                            </div>
                        ) : null}

                        <div className="flex items-center mt-8px sm:block">
                            <PrimaryText
                                className="w-190px mr-10px"
                                typography="textSemibold"
                            >
                                Nirvana Member Since
                            </PrimaryText>
                            <PrimaryText typography="text">
                                {moment(profile.createdAt).format(
                                    GENERAL_CONFIG.defaultMomentDateFormat
                                )}
                            </PrimaryText>
                        </div>
                    </div>

                    <PrimaryText className="mt-16px">
                        To edit, please contact{" "}
                        <a
                            className="text-bold text-brand-vibrantPurple!"
                            href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                        >
                            {GENERAL_CONFIG.supportEmail}
                        </a>
                    </PrimaryText>

                    <PrimaryText className="mt-48px" typography="h5">
                        Security
                    </PrimaryText>

                    <div className="mt-16px">
                        <div className="flex items-center sm:block">
                            <PrimaryText
                                className="w-190px mr-10px"
                                typography="textSemibold"
                            >
                                2-step Verification
                            </PrimaryText>

                            {typeof mfaPhoneNumber !== "undefined" ? (
                                <div className="flex items-center">
                                    <img
                                        className="relative top--2px"
                                        src={
                                            mfaPhoneNumber
                                                ? checkGreenIcon
                                                : checkRedIcon
                                        }
                                        alt="mfa-status"
                                    />

                                    <PrimaryText
                                        className="ml-8px"
                                        typography="text"
                                    >
                                        {mfaPhoneNumber
                                            ? "Enabled"
                                            : "Not Enabled"}
                                    </PrimaryText>
                                </div>
                            ) : null}
                        </div>

                        {mfaPhoneNumber ? (
                            <div className="flex items-center mt-6px sm:block">
                                <PrimaryText
                                    className="w-190px mr-10px"
                                    typography="textSemibold"
                                >
                                    Phone Number
                                </PrimaryText>

                                <div className="flex items-center">
                                    <PrimaryText
                                        className="mr-2px"
                                        typography="text"
                                    >
                                        {UtilHelper.maskString(mfaPhoneNumber)}
                                    </PrimaryText>

                                    <div
                                        className="p-6px cursor-pointer relative top--1px"
                                        onClick={onEditMfaPhoneNumber}
                                    >
                                        <img src={editIcon} alt="edit-mfa" />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className="mt-48px">
                        <Link to={ROUTES_CONFIG.logoutUrl}>
                            <ButtonElement
                                label="Log out"
                                type="default"
                                isDanger
                                htmlType="button"
                                size="large"
                                icon={exitIcon}
                            />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}
