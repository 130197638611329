/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from "react-router-dom"
import {
    BrowserStorageHelper,
    BrowserStorageType,
    NirvanaGlobalFonts,
    useIsOnline,
    OfflineElement,
} from "nirvana-react-elements"

import { AuthContainer } from "./auth.container"
import { AppContainer } from "./app.container"

import { ROUTES_CONFIG } from "../config/routes.config"
import { NotFoundComponent } from "../components/general/notFound.component"
import { HeaderComponent } from "../components/general/header.component"
import { NirvanaBackgroundComponent } from "../components/general/nirvanaBackground.component"
import { PracticeRoleMissingLockComponent } from "../components/general/practiceRoleMissingLock.component"
import { GENERAL_CONFIG } from "../config/general.config"
import { PracticeAcceptInvitationComponent } from "../components/general/practiceAcceptInvitation.component"
import { useAppSelector } from "../store/selectors/app.selector"
import { authSelector } from "../store/selectors/auth.selector"
import { userDetailsSelector } from "../store/selectors/userDetails.selector"
import { authCheckInviteToken } from "../store/thunks/auth.thunks"
import { useAppDispatch } from "../store/appDispatch.hook"
import { useCheckAppVersion } from "../hooks/checkAppVersion.hook"
import { runtimeCheckUserIsLoggedIn } from "../store/thunks/runtime.thunks"
import { runtimeSelector } from "../store/selectors/runtime.selector"
// import { DowntimeComponent } from "components/general/downtime.component"

export const RouterContainer = () => {
    const dispatch = useAppDispatch()

    useCheckAppVersion()

    const location = useLocation()
    const isOnline = useIsOnline()

    const [searchParams] = useSearchParams()

    const authState = useAppSelector(authSelector)
    const userDetailsState = useAppSelector(userDetailsSelector)
    const { isLoggedIn } = useAppSelector(runtimeSelector)

    useEffect(() => {
        const checkIsLoggedIn = () => {
            dispatch(runtimeCheckUserIsLoggedIn())
        }

        checkIsLoggedIn()

        const timerId = setInterval(checkIsLoggedIn, 30 * 1000)

        return () => {
            try {
                clearInterval(timerId)
            } catch (e) {}
        }
    }, [location])

    // On mount see if there's invite token
    useEffect(() => {
        const inviteToken =
            searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.inviteToken) ||
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys.inviteToken,
                undefined,
                BrowserStorageType.sessionStorage
            )

        if (!inviteToken) {
            return
        }

        dispatch(
            authCheckInviteToken({
                payload: inviteToken,

                onSuccess: () => {
                    // Set it to browser storage so it can be used across app
                    // Either before login or after login
                    BrowserStorageHelper.set(
                        GENERAL_CONFIG.browserStorageKeys.inviteToken,
                        inviteToken,
                        BrowserStorageType.sessionStorage
                    )
                },
            })
        )
    }, [])

    return isLoggedIn !== null ? (
        <div>
            <NirvanaGlobalFonts />

            <HeaderComponent />

            {/*<DowntimeComponent />*/}

            {isOnline ? (
                <Routes>
                    <Route
                        path={`${ROUTES_CONFIG.authUrl}/*`}
                        element={<AuthContainer isLoggedIn={isLoggedIn} />}
                    />

                    <Route
                        path="/*"
                        element={<AppContainer isLoggedIn={isLoggedIn} />}
                    />

                    <Route path="*" element={<NotFoundComponent />} />

                    <Route
                        path="/"
                        element={
                            <Navigate
                                replace
                                to={
                                    isLoggedIn
                                        ? ROUTES_CONFIG.baseApplicationUrl
                                        : ROUTES_CONFIG.loginUrl
                                }
                            />
                        }
                    />
                </Routes>
            ) : (
                <OfflineElement />
            )}

            <NirvanaBackgroundComponent />

            {isLoggedIn && authState.userInvitation ? (
                <PracticeAcceptInvitationComponent
                    invite={authState.userInvitation}
                />
            ) : userDetailsState.profile &&
              !userDetailsState.profile.practiceRoles.length ? (
                <PracticeRoleMissingLockComponent />
            ) : null}
        </div>
    ) : null
}
