import { createAsyncThunk } from "@reduxjs/toolkit"

import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import { UserDetailsService } from "../../services/userDetails.service"
import { userDetailsSetProfile } from "../slices/userDetails.slice"
import { authSetCheckUserInvitation } from "../slices/auth.slice"

/**
 * Get profile
 */
export const userDetailsGetProfile = createAsyncThunk<
    void,
    IThunkActionData<undefined> | undefined
>("userDetailsGetProfilePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("userDetailsGetProfileLoading"))

    try {
        const result = await UserDetailsService.getProfile()

        if (result) {
            dispatch(userDetailsSetProfile(result))

            action?.onSuccess && action.onSuccess(result)
        } else {
            action?.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(runtimeStopLoading("userDetailsGetProfileLoading"))

        action?.onFinally && action.onFinally()
    }
})

/**
 * Set selected practice role
 */
export const userDetailsAcceptOrganizationInvite = createAsyncThunk<
    void,
    IThunkActionData<IOrganizationUserInvite>
>("userDetailsAcceptOrganizationInvitePrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("userDetailsAcceptOrganizationInviteLoading"))

    try {
        const result = await UserDetailsService.acceptUserInvitation(
            action.payload
        )

        if (result) {
            dispatch(userDetailsSetProfile(result))
            dispatch(authSetCheckUserInvitation())

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        dispatch(
            runtimeStopLoading("userDetailsAcceptOrganizationInviteLoading")
        )

        action.onFinally && action.onFinally()
    }
})

/**
 * Get practice usage quota
 */
export const userDetailsGetPracticeUsageQuota = createAsyncThunk<
    IPracticeUsageQuota | null,
    IThunkActionData<IPractice>
>("userDetailsGetPracticeUsageQuotaPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("userDetailsGetPracticeUsageQuotaLoading"))

    try {
        const result = await UserDetailsService.getPracticeUsageQuota(
            action.payload
        )

        if (result) {
            action?.onSuccess && action.onSuccess(result)
        } else {
            action?.onError && action.onError(result)
        }

        return result || null
    } catch (e) {
        return null
    } finally {
        dispatch(runtimeStopLoading("userDetailsGetPracticeUsageQuotaLoading"))

        action?.onFinally && action.onFinally()
    }
})
