/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import {
    AvailableLookupConsumer,
    ButtonElement,
    NirvanaBreathingLoaderElement,
    PageHelmetElement,
    PrimaryText,
    SpinnerElement,
    useCptCodes,
    useIsMobile,
    useScrollFix,
} from "nirvana-react-elements"

import {
    checkerReset,
    checkerToggleRunningType,
} from "../../store/slices/checker.slice"
import {
    CoverageCheckerMode,
    CoverageCheckerRunningState,
} from "../../config/checker.config"
import {
    FEATURE_FLAGS_CONFIG,
    FeatureFlagsExistenceLogicalOperator,
} from "../../config/featureFlags.config"
import { checkerSelector } from "../../store/selectors/checker.selector"
import { GENERAL_CONFIG } from "../../config/general.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { ResultsDownloadComponent } from "./resultsDownload.component"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../config/rolesPermissions.config"
import { CoverageCheckerCSVComponent } from "./csv/coverageCheckerCSV.component"
import { CoverageCheckerManualComponent } from "./manual/coverageCheckerManual.component"
import { profileSelector } from "../../store/selectors/profile.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { coveragePayersGetList } from "../../store/thunks/coveragePayers.thunks"
import { useAppDispatch } from "../../store/appDispatch.hook"
import { useIsFeatureFlagEnabled } from "../../hooks/isFeatureFlagEnabled.hook"
import { ROUTES_CONFIG } from "../../config/routes.config"

import refreshIcon from "../../assets/images/icons/refresh-dark.svg"
import uploadIconWhite from "../../assets/images/icons/upload-white.svg"
import uploadIconDark from "../../assets/images/icons/upload-dark.svg"
import magicWandIconWhite from "../../assets/images/icons/magic-wand-white.svg"
import magicWandIconDark from "../../assets/images/icons/magic-wand-dark.svg"

export const CoverageCheckerComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const isMobile = useIsMobile()

    const profile = useAppSelector(profileSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const checkerState = useAppSelector(checkerSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    useIsFeatureFlagEnabled(
        FEATURE_FLAGS_CONFIG.checkerFeatureFlags,
        FeatureFlagsExistenceLogicalOperator.OR,
        ROUTES_CONFIG.profileUrl
    )

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const canRunCoverageChecksByPermissions = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                [
                    AvailableCoveragePortalPermission.runCoverageChecks,
                    AvailableCoveragePortalPermission.runCsvBulkCoverageChecks,
                ],
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    const isManualCheckAvailable = useIsFeatureFlagEnabled(
        FEATURE_FLAGS_CONFIG.manualCheckerFeatureFlags,
        FeatureFlagsExistenceLogicalOperator.OR
    )

    const isBulkChecksAvailable = useIsFeatureFlagEnabled(
        FEATURE_FLAGS_CONFIG.bulkCheckerFeatureFlags,
        FeatureFlagsExistenceLogicalOperator.OR
    )

    const canRunBulkCoverageChecks = useMemo<boolean>(() => {
        return (
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.runCsvBulkCoverageChecks,
                selectedPracticeRole
            ) && isBulkChecksAvailable
        )
    }, [selectedPracticeRole, isBulkChecksAvailable])

    // If received more than X results -> stop loader, so user can preview results already
    // Also show spinner if processing CSV right now
    // If INTAKE checker is loading
    const isLoading = useMemo<boolean>(() => {
        const continuousMonitoringResultsCount =
            checkerState.continuousMonitoringCoverageChecks.filter(
                item => !!item.result
            ).length

        const medicaidResultsCount = checkerState.medicaidChecks.filter(
            item => !!item.result
        ).length

        const isBulkChecksResultsPending =
            continuousMonitoringResultsCount <= 5 && medicaidResultsCount <= 5

        return (
            RuntimeHelper.isIntakeCheckerLoading() ||
            checkerState.runningState ===
                CoverageCheckerRunningState.PROCESS_CSV ||
            (RuntimeHelper.isBulkCoverageChecksLoading() &&
                isBulkChecksResultsPending)
        )
    }, [
        runtimeState.isLoading,
        checkerState.continuousMonitoringCoverageChecks,
        checkerState.runningState,
    ])

    // Listen for browser back btn -> reset Coverage Checker page
    useEffect(() => {
        window.addEventListener("popstate", onReset)

        return () => {
            window.removeEventListener("popstate", onReset)
        }
    }, [])

    // Check if manual or csv flow was requested by default from url
    useEffect(() => {
        const checkerMode = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.checkerMode
        )

        // If some random value was provided
        if (
            !checkerMode ||
            !(Object.values(CoverageCheckerMode) as string[]).includes(
                checkerMode
            )
        ) {
            return
        }

        onToggleRunningType(checkerMode as CoverageCheckerMode)
    }, [searchParams])

    // If user is not allowed to run bulk coverage checks
    // We should switch them to manual input if they have access
    useEffect(() => {
        if (!selectedPracticeRole || !runtimeState.enabledFeatureFlags) {
            return
        }

        switch (checkerState.checkerMode) {
            case CoverageCheckerMode.MANUAL:
                !isManualCheckAvailable &&
                    canRunBulkCoverageChecks &&
                    onToggleRunningType(CoverageCheckerMode.CSV)

                break

            case CoverageCheckerMode.CSV:
                !canRunBulkCoverageChecks &&
                    isManualCheckAvailable &&
                    onToggleRunningType(CoverageCheckerMode.MANUAL)

                break
        }
    }, [
        isManualCheckAvailable,
        canRunBulkCoverageChecks,
        selectedPracticeRole,
        runtimeState.enabledFeatureFlags,
    ])

    // Load fresh coverage payers when selectedPracticeRole changes
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            coveragePayersGetList({
                payload: undefined,
                practice: selectedPracticeRole.practice,
            })
        )
    }, [selectedPracticeRole?.id])

    const onResetSearchParams = () => {
        // Remove results search params flag - it's added when triggering new results check
        searchParams.delete(GENERAL_CONFIG.urlSearchParamsKeys.results)
        setSearchParams(searchParams)
    }

    const onReset = () => {
        dispatch(checkerReset())
        onResetSearchParams()
    }

    const onToggleRunningType = (checkerMode?: CoverageCheckerMode) => {
        dispatch(
            checkerToggleRunningType({
                checkerMode,
            })
        )

        onResetSearchParams()
    }

    const headerTitle = useMemo<string>(() => {
        switch (checkerState.runningState) {
            case CoverageCheckerRunningState.PREVIEW_CSV:
                return "Preview Checks"

            default:
                return "Nirvana Coverage Checker"
        }
    }, [checkerState.runningState])

    const isHeaderReducedWidth = useMemo<boolean>(() => {
        return [
            CoverageCheckerRunningState.INPUT_CSV,
            CoverageCheckerRunningState.INPUT_MANUAL,
        ].includes(checkerState.runningState)
    }, [checkerState.runningState])

    const headerSubtitle = useMemo<JSX.Element>(() => {
        switch (checkerState.runningState) {
            case CoverageCheckerRunningState.PREVIEW_CSV:
                return (
                    <>
                        You can remove records before you run the checks.
                        <br className="md:hidden" /> Note that any checks with
                        errors will not be included in the run.
                    </>
                )

            default:
                return (
                    <>
                        Find out how much your clients can expect to be
                        <br className="md:hidden" />
                        reimbursed for therapy after their insurance kick in.
                    </>
                )
        }
    }, [checkerState.runningState])

    const headerActions = useMemo<JSX.Element | null>(() => {
        const isResultsView = [
            CoverageCheckerRunningState.RESULTS_MANUAL,
            CoverageCheckerRunningState.RESULTS_CSV,
        ].includes(checkerState.runningState)

        const uploadCsvBtn = (
            <ButtonElement
                label="Upload CSV"
                type={isResultsView ? "default" : "primary"}
                size="large"
                icon={isResultsView ? uploadIconDark : uploadIconWhite}
                htmlType="button"
                onClick={() => onToggleRunningType(CoverageCheckerMode.CSV)}
            />
        )

        const enterDataManuallyBtn = (
            <ButtonElement
                label="Run Eligibility Check"
                type={isResultsView ? "default" : "primary"}
                size="large"
                icon={isResultsView ? magicWandIconDark : magicWandIconWhite}
                htmlType="button"
                onClick={() => onToggleRunningType(CoverageCheckerMode.MANUAL)}
            />
        )

        switch (checkerState.runningState) {
            case CoverageCheckerRunningState.INPUT_MANUAL:
                return canRunBulkCoverageChecks ? uploadCsvBtn : null

            case CoverageCheckerRunningState.INPUT_CSV:
                return isManualCheckAvailable ? enterDataManuallyBtn : null

            case CoverageCheckerRunningState.PREVIEW_CSV:
                return (
                    <div className="flex items-center gap-4">
                        {enterDataManuallyBtn}

                        <ButtonElement
                            label="Re-upload CSV"
                            type="default"
                            size="large"
                            icon={refreshIcon}
                            htmlType="button"
                            onClick={onReset}
                        />
                    </div>
                )

            case CoverageCheckerRunningState.RESULTS_MANUAL:
            case CoverageCheckerRunningState.RESULTS_CSV:
                return (
                    <div className="flex items-center gap-4">
                        {uploadCsvBtn}

                        {enterDataManuallyBtn}
                    </div>
                )

            default:
                return null
        }
    }, [
        checkerState.runningState,
        canRunBulkCoverageChecks,
        isManualCheckAvailable,
    ])

    return runtimeState.enabledFeatureFlags ? (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Coverage Checker"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <NirvanaBreathingLoaderElement isActive={isLoading} />

            {!profile || !availableCptCodes.length ? (
                <SpinnerElement containerClassName="text-center mt-48px" />
            ) : canRunCoverageChecksByPermissions ? (
                <div>
                    <PrimaryText
                        className={isMobile ? "mt-72px" : ""}
                        typography={isMobile ? "h2" : "h1"}
                    >
                        {headerTitle}
                    </PrimaryText>

                    <div
                        className={`
                            flex items-center mt-8px
                            md:block
                            ${isHeaderReducedWidth ? "max-w-800px" : ""}
                        `}
                    >
                        <PrimaryText className="mr-128px max-w-480px md:max-w-full md:mr-0px">
                            {headerSubtitle}
                        </PrimaryText>

                        <div className="flex-1 text-right md:mt-24px">
                            {headerActions}
                        </div>
                    </div>

                    {!isLoading && <ResultsDownloadComponent />}

                    <div>
                        {checkerState.checkerMode ===
                        CoverageCheckerMode.CSV ? (
                            <CoverageCheckerCSVComponent
                                isLoading={isLoading}
                            />
                        ) : (
                            <CoverageCheckerManualComponent />
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    ) : (
        <SpinnerElement containerClassName="text-center mt-48px" />
    )
}
