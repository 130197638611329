/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState } from "react"
import { Controller } from "react-hook-form"
import {
    FloatingLabelElement,
    InputErrorElement,
    useIsInputAutofilled,
    useScrollErroredInputIntoView,
} from "nirvana-react-elements"
import PhoneInput, { CountryData } from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const PREFERRED_COUNTRIES: string[] = ["us"]

const DEFAULT_COUNTRIES: string[] = [
    ...PREFERRED_COUNTRIES,

    "ar",
    "br",
    "cz",
    "ec",
    // "in", // comment out for now, India
    "mx",
    "py",
    "ph",
    // "pt", // comment out for now, Portugal
    // "es", // comment out for now, Spain
    "ua",
]

const DEFAULT_COUNTRY_FORMAT_MASKS: Record<string, string> = {
    ec: ". ... ....",
    // pt: "... ... ...", // comment out for now, Portugal
    py: "... ... ... ...",
}

// For some countries like Philippines, formatPeriodCount is not correct in library by 1 character
const COUNTRIES_ALLOWED_LESS_DIGITS: string[] = ["ph"]

export const PhoneInputElement: React.FunctionComponent<
    IPhoneInputElementProps
> = props => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [currentInputValue, setCurrentInputValue] = useState<string>()

    useScrollErroredInputIntoView(
        props.name,
        containerRef,
        props.reactHookErrors,
        props.ignoreScrollIntoViewOnError
    )

    const isAutofilled = useIsInputAutofilled(containerRef)

    const isLabelFloated = useMemo<boolean>(() => {
        return (
            props.isLabelStatic ||
            !!currentInputValue ||
            isFocused ||
            // Handles case when browser autofilled input but user didn't interact with page yet
            (isAutofilled && typeof currentInputValue === "undefined")
        )
    }, [currentInputValue, isFocused, props.isLabelStatic, isAutofilled])

    // Note: value here is phone number without + sign
    const isValid = (value: string, country: {} | CountryData) => {
        if (!country || !("format" in country)) {
            return false
        }

        const formatCharsCount = (country.format?.match(/\./g) || []).length

        const formatCharsCountAlternative =
            COUNTRIES_ALLOWED_LESS_DIGITS.includes(country.countryCode)
                ? formatCharsCount - 1
                : -1

        const valueCharsCount = value.length

        // Handle invalid country code
        if (!value.startsWith(country.dialCode)) {
            return false
        }

        return (
            valueCharsCount === formatCharsCount ||
            valueCharsCount === formatCharsCountAlternative
        )
    }

    return (
        <div
            ref={containerRef}
            className={`
                relative
                ${props.className}
            `}
        >
            {props.label && (
                <FloatingLabelElement
                    labelClassName={!isLabelFloated ? "pl-35px" : undefined}
                    label={props.label}
                    isFloated={isLabelFloated}
                    withRequiredStar={
                        props.withRequiredStar &&
                        props.reactHookValidations?.required
                    }
                    tooltip={props.tooltip}
                />
            )}

            <Controller
                name={props.name}
                control={props.reactHookControl}
                rules={props.reactHookValidations}
                render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                }) => (
                    <>
                        <PhoneInput
                            value={currentInputValue}
                            country={
                                (props.preferredCountries ||
                                    PREFERRED_COUNTRIES)[0]
                            }
                            onChange={(value, country) => {
                                setCurrentInputValue(value)

                                const valid = isValid(value, country)

                                const neededValue = props.noPlusPrefixAdjustment
                                    ? value
                                    : `+${value}`

                                // Only change value in hook form if it's valid
                                onChange(valid ? neededValue : undefined)
                            }}
                            onFocus={() => {
                                setIsFocused(true)
                            }}
                            onBlur={() => {
                                onBlur()

                                setIsFocused(false)
                            }}
                            inputStyle={{
                                height: "44px",
                                fontSize: "16px",
                            }}
                            buttonStyle={{
                                height: "44px",
                                fontSize: "16px",
                            }}
                            containerClass={`
                                rounded-md flex shrink-0
                                border border-solid
                                ${
                                    error?.message &&
                                    (!props.hideErrors ||
                                        props.forceHighlightOnError)
                                        ? "shadow-phone-input-error border-brand-error!"
                                        : "border-brand-warmShadow hover:border-brand-inputHover"
                                }
                                ${
                                    isFocused
                                        ? "shadow-phone-input-focus border-brand-primary!"
                                        : ""
                                }
                                ${props.containerClassName}
                            `}
                            inputClass={`
                                border-0!
                                ${props.inputClassName}
                            `}
                            buttonClass={`
                                z-50! rounded-l-md!
                                border-solid! border-t-0! border-b-0! border-l-0! border-r! border-brand-warmShadow!
                                ${props.buttonClassName}
                            `}
                            dropdownClass={`
                                p-8px!
                                ${props.dropdownClassName}
                            `}
                            onlyCountries={props.countries || DEFAULT_COUNTRIES}
                            preferredCountries={
                                props.preferredCountries || PREFERRED_COUNTRIES
                            }
                            masks={
                                props.countriesFormatMasks ||
                                DEFAULT_COUNTRY_FORMAT_MASKS
                            }
                            placeholder={
                                isLabelFloated || !props.label
                                    ? props.placeholder || ""
                                    : ""
                            }
                            inputProps={{
                                name: props.name,
                                required:
                                    !!props.reactHookValidations?.required,
                                autoFocus: props.autoFocus,
                                country: (props.preferredCountries ||
                                    PREFERRED_COUNTRIES)[0],
                                prefix: "+",
                            }}
                        />

                        {error?.message && !props.hideErrors ? (
                            <InputErrorElement text={error.message} />
                        ) : null}
                    </>
                )}
            />
        </div>
    )
}
