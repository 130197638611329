import { store } from "../store/store"

export class RuntimeHelper {
    ////////////////////////////////////////////////////////////////////////////////// USER DETAILS
    /**
     * Check if accept org invite is loading now
     */
    static isAcceptingOrganizationInviteLoading(): boolean {
        return RuntimeHelper.isLoading(
            "userDetailsAcceptOrganizationInviteLoading"
        )
    }
    ////////////////////////////////////////////////////////////////////////////////// USER DETAILS

    ////////////////////////////////////////////////////////////////////////////////// ORGANIZATION USERS
    /**
     * Check if getting organizations users list is running now
     */
    static isOrganizationUsersListLoading(): boolean {
        return RuntimeHelper.isLoading("organizationUsersGetListLoading")
    }

    /**
     * Check if getting organizations user invites list is running now
     */
    static isOrganizationUserInvitesListLoading(): boolean {
        return RuntimeHelper.isLoading("organizationInvitesGetListLoading")
    }

    /**
     * Check if sending organization invite is being sent now
     */
    static isOrganizationInviteBeingSent(): boolean {
        return RuntimeHelper.isLoading("organizationInvitesCreateLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// ORGANIZATION USERS

    ////////////////////////////////////////////////////////////////////////////////// COVERAGE PAYERS
    /**
     * Check if getting list of coverage payers is loading now
     */
    static isGettingPayersLoading(): boolean {
        return RuntimeHelper.isLoading("coveragePayersGetListLoading")
    }

    /**
     * Check if creating new coverage payer now loading
     */
    static isCreatePayerLoading(): boolean {
        return RuntimeHelper.isLoading("coveragePayersCreateLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// COVERAGE PAYERS

    ////////////////////////////////////////////////////////////////////////////////// CHECKER
    /**
     * Check if bulk coverage checks loading now
     */
    static isBulkCoverageChecksLoading(): boolean {
        return (
            RuntimeHelper.isLoading(
                "checkerRunContinuousMonitoringChecksLoading"
            ) || RuntimeHelper.isLoading("checkerRunMedicaidChecksLoading")
        )
    }

    /**
     * Check if intake checker is loading now
     */
    static isIntakeCheckerLoading(): boolean {
        return RuntimeHelper.isLoading("checkerRunIntakeCheckLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// CHECKER

    ////////////////////////////////////////////////////////////////////////////////// BILLER GROUPS
    /**
     * Check if biller groups list is loading now
     */
    static isBillerGroupsListLoading(): boolean {
        return RuntimeHelper.isLoading("billerGroupsGetListLoading")
    }

    /**
     * Check if biller groups single item loading
     */
    static isBillerGroupsSingleItemLoading(): boolean {
        return RuntimeHelper.isLoading("billerGroupsGetSelectedLoading")
    }

    /**
     * Check if biller groups single item being deleted
     */
    static isBillerGroupsSingleItemDeleteLoading(): boolean {
        return RuntimeHelper.isLoading("billerGroupsDeleteLoading")
    }

    /**
     * Check if biller groups single item is being created
     */
    static isBillerGroupsCreateLoading(): boolean {
        return RuntimeHelper.isLoading("billerGroupsCreateLoading")
    }

    /**
     * Check if biller groups single item is being updated
     */
    static isBillerGroupsUpdateLoading(): boolean {
        return RuntimeHelper.isLoading("billerGroupsUpdateLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// BILLER GROUPS

    ////////////////////////////////////////////////////////////////////////////////// SUPPORT

    /**
     * Check if reporting coverage result is loading now
     */
    static isReportCoverageResultLoading(): boolean {
        return RuntimeHelper.isLoading("supportReportCoverageResultLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// SUPPORT

    ////////////////////////////////////////////////////////////////////////////////// POLICIES

    /**
     * Check if getting policies history loading now
     */
    static isGetPoliciesHistoryLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetHistoryListLoading")
    }

    /**
     * Check if getting single policy history loading now
     */
    static isGetSinglePolicyHistoryLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetSinglePolicyLoading")
    }

    /**
     * Check if retrying single coverage check is loading now
     */
    static isRetrySinglePolicyLoading(): boolean {
        return RuntimeHelper.isLoading("policiesRetrySinglePolicyLoading")
    }

    /**
     * Check if retrying bulk coverage check is loading now
     */
    static isRetryBulkPolicyLoading(): boolean {
        return RuntimeHelper.isLoading("policiesRetryBulkPolicyLoading")
    }

    /**
     * Check if getting policies reports list loading now
     */
    static isGetPoliciesReportsLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetReportsListLoading")
    }

    /**
     * Check if getting policies medicaid list loading now
     */
    static isGetPoliciesMedicaidLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetMedicaidListLoading")
    }

    /**
     * Check if getting policies overrides list loading now
     */
    static isGetPoliciesOverridesLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetOverridesListLoading")
    }

    /**
     * Check if flag is being resolved now
     */
    static isResolvingFlagLoading(): boolean {
        return RuntimeHelper.isLoading("policiesResolveFlagLoading")
    }

    /**
     * Check if policies export is loading now
     */
    static isPoliciesExportLoading(): boolean {
        return RuntimeHelper.isLoading("policiesExportListLoading")
    }

    /**
     * Check if reports aggregations status codes loading now
     */
    static isReportsAggregationsStatusCodesLoading(): boolean {
        return RuntimeHelper.isLoading(
            "policiesGetReportsAggregationsStatusCodesLoading"
        )
    }

    /**
     * Check if reports aggregations flags loading now
     */
    static isReportsAggregationsFlagsLoading(): boolean {
        return RuntimeHelper.isLoading(
            "policiesGetReportsAggregationsFlagsLoading"
        )
    }

    /**
     * Check if medicaid aggregations plan type loading now
     */
    static isMedicaidAggregationsPlanTypeLoading(): boolean {
        return RuntimeHelper.isLoading(
            "medicaidGetReportsAggregationsPlanTypeLoading"
        )
    }

    /**
     * Check if medicaid aggregations active plan type loading now
     */
    static isMedicaidAggregationsActivePlanTypeLoading(): boolean {
        return RuntimeHelper.isLoading(
            "medicaidGetReportsAggregationsActivePlanTypeLoading"
        )
    }

    /**
     * Check if getting full policies list is loading now
     */
    static isGettingFullPoliciesListLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetFullListLoading")
    }

    /**
     * Check if getting policies plan year resets list loading now
     */
    static isGetPoliciesPlanYearResetsLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetPlanYearResetsListLoading")
    }

    /**
     * Check if plan year resets aggregations loading now
     */
    static isPlanYearResetsAggregationsLoading(): boolean {
        return RuntimeHelper.isLoading(
            "policiesGetPlanYearResetsAggregationsLoading"
        )
    }

    ////////////////////////////////////////////////////////////////////////////////// POLICIES

    ////////////////////////////////////////////////////////////////////////////////// COVERAGE OVERRIDES

    /**
     * Check if creating coverage overrides is running now
     */
    static isCoverageOverridesCreationLoading(): boolean {
        return RuntimeHelper.isLoading("createCoverageOverridesLoading")
    }

    /**
     * Check if deleting coverage overrides is running now
     */
    static isCoverageOverridesDeleteLoading(): boolean {
        return RuntimeHelper.isLoading("deleteSingleCoverageOverrideLoading")
    }

    /**
     * Check if getting single coverage overrides is running now
     */
    static isCoverageOverridesGetSingleLoading(): boolean {
        return RuntimeHelper.isLoading("getSingleCoverageOverrideLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// COVERAGE OVERRIDES

    ////////////////////////////////////////////////////////////////////////////////// POLICY COMMENTS

    /**
     * Check if getting policy comments is running now
     */
    static isGetPolicyCommentsLoading(): boolean {
        return RuntimeHelper.isLoading("policiesGetSinglePolicyCommentsLoading")
    }

    /**
     * Check if creating policy comment is running now
     */
    static isPolicyCommentCreationLoading(): boolean {
        return RuntimeHelper.isLoading(
            "policiesCreateSinglePolicyCommentLoading"
        )
    }

    /**
     * Check if updating policy comment is running now
     */
    static isPolicyCommentUpdateLoading(): boolean {
        return RuntimeHelper.isLoading(
            "policiesUpdateSinglePolicyCommentLoading"
        )
    }

    ////////////////////////////////////////////////////////////////////////////////// POLICY COMMENTS

    ////////////////////////////////////////////////////////////////////////////////// REPORT SAVED FILTERS
    /**
     * Check if getting report saved filters list is running now
     */
    static isReportSavedFiltersListLoading(): boolean {
        return RuntimeHelper.isLoading("reportSavedFiltersGetListLoading")
    }

    /**
     * Check if report saved filters create is running now
     */
    static isReportSavedFiltersCreateLoading(): boolean {
        return RuntimeHelper.isLoading("reportSavedFiltersCreateLoading")
    }

    /**
     * Check if deleting report saved filters is running now
     */
    static isReportSavedFiltersDeleteLoading(): boolean {
        return RuntimeHelper.isLoading("reportSavedFiltersDeleteLoading")
    }

    ////////////////////////////////////////////////////////////////////////////////// REPORT SAVED FILTERS

    /**
     * Get current state of loading array
     */
    static isLoading(key: string): boolean {
        const currentlyLoading = store.getState().runtime.isLoading

        return !!~currentlyLoading.indexOf(key)
    }
}
