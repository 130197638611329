/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment-timezone"

import {
    createCoverageOverrides,
    deleteSingleCoverageOverride,
    getSingleCoverageOverride,
} from "../store/thunks/coverageOverrides.thunks"
import { CoverageHistoryHelper } from "../helpers/coverageHistory.helper"
import { PermissionsHelper } from "../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../config/rolesPermissions.config"
import { useAppSelector } from "../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../store/selectors/selectedPracticeRole.selector"
import { RuntimeHelper } from "../helpers/runtime.helper"
import { runtimeSelector } from "../store/selectors/runtime.selector"
import { coverageOverridesSetSelectedPolicyOverride } from "../store/slices/coverageOverrides.slice"
import { useAppDispatch } from "../store/appDispatch.hook"
import { ConfirmationHelper } from "nirvana-react-elements"
import { ToastrHelper } from "../helpers/toastr.helper"
import { coverageOverridesSelector } from "../store/selectors/coverageOverrides.selector"
import { PoliciesHelper } from "../helpers/policies.helper"
import { GENERAL_CONFIG } from "../config/general.config"
import { PoliciesListType } from "../config/policies.config"
import { useBulkOverrides } from "./bulkOverrides.hooks"
import { COVERAGE_CONFIG } from "../config/coverage.config"

export const useSingleOverride = (
    coverageCheckHistory: ICoverageCheckHistory | null,
    override: ICoverageOverride | null,
    nirvanaRequestId?: string,
    getSinglePolicy?: (
        withPolicyDataReFetch?: boolean,
        onSuccess?: () => void
    ) => void,
    reloadPoliciesList?: () => void,
    navigateToPolicyByRequestIdProvided?: (
        nirvanaRequestId: string,
        withPoliciesListReload?: boolean
    ) => void
) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const {
        // vars
        isOverridesEnabled,
        suggestedOverrideExpirationDate,
        minOverrideExpirationDate,
        isCreateOverrideLoading,
        // state / form
        overridesFormKey,
        setOverridesFormKey,
        dataWatcher,
        overridesFormMethods,
        // methods
        getFormDefaultData,
        onUseSuggestedOverrideExpiration,
        onResetOverride,
        validateOverrideCreation,
        mapCreationFormData,
    } = useBulkOverrides()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)
    const overridesState = useAppSelector(coverageOverridesSelector)

    const [isOverrideModeActive, setIsOverrideModeActive] =
        useState<boolean>(false)

    const isGettingSinglePolicyLoading = useMemo(() => {
        return RuntimeHelper.isGetSinglePolicyHistoryLoading()
    }, [runtimeState.isLoading])

    const isDeleteOverrideLoading = useMemo(() => {
        return RuntimeHelper.isCoverageOverridesDeleteLoading()
    }, [runtimeState.isLoading])

    const isGetSingleOverrideLoading = useMemo(() => {
        return RuntimeHelper.isCoverageOverridesGetSingleLoading()
    }, [runtimeState.isLoading])

    const coverageCheckIdentificationData =
        useMemo<IPolicyIdentificationData | null>(() => {
            return CoverageHistoryHelper.getPolicyIdentificationData(
                coverageCheckHistory
            )
        }, [coverageCheckHistory])

    const canOverridePolicyCoverage = useMemo<boolean>(() => {
        return (
            isOverridesEnabled &&
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.overrideCoverageData,
                selectedPracticeRole
            ) &&
            !!coverageCheckHistory?.policy &&
            !!coverageCheckIdentificationData &&
            // TODO enable overrides for Medicaid plans once we implement this feature
            // For now just disabled
            (!coverageCheckHistory?.coverageResult.planType ||
                !COVERAGE_CONFIG.medicaidPlanTypes.includes(
                    coverageCheckHistory.coverageResult.planType
                ))
        )
    }, [
        selectedPracticeRole?.practice.id,
        isOverridesEnabled,
        coverageCheckHistory?.policy,
        coverageCheckIdentificationData,
        coverageCheckHistory?.coverageResult.planType,
    ])

    const isOverrideActionsDisabled = useMemo<boolean>(() => {
        return (
            isGettingSinglePolicyLoading ||
            isDeleteOverrideLoading ||
            isGetSingleOverrideLoading
        )
    }, [
        isGettingSinglePolicyLoading,
        isDeleteOverrideLoading,
        isGetSingleOverrideLoading,
    ])

    const defaultData = useMemo<IOverrideDefaultData>(() => {
        return {
            // Combination of override and coverage result
            form: getFormDefaultData(
                override || null,
                coverageCheckHistory?.coverageResult || undefined
            ),
            // Just from override
            override: getFormDefaultData(override || null, undefined, false),
        }
    }, [coverageCheckHistory?.coverageResult, override])

    // Set override default expiration data
    useEffect(() => {
        // Set default expiration date
        if (!override) {
            return
        }

        overridesFormMethods.setValue(
            "expirationDate",
            moment(override.expirationDate)
        )
    }, [override])

    const onNavigateToPolicyByRequestId = (
        nirvanaRequestId: string,
        withPoliciesListReload = true
    ) => {
        if (navigateToPolicyByRequestIdProvided) {
            navigateToPolicyByRequestIdProvided(nirvanaRequestId)

            return
        }

        const neededUrl =
            PoliciesHelper.getSinglePolicyBaseUrl(
                PoliciesListType.OVERRIDES
            ).replace(":nirvanaRequestId", nirvanaRequestId) +
            (withPoliciesListReload
                ? `?${GENERAL_CONFIG.urlSearchParamsKeys.reloadPoliciesList}=true`
                : "")

        navigate(neededUrl)
    }

    const getSingleOverride = () => {
        if (!coverageCheckIdentificationData || !selectedPracticeRole) {
            return
        }

        dispatch(
            getSingleCoverageOverride({
                practice: selectedPracticeRole.practice,
                payload: {
                    data: {
                        ...coverageCheckIdentificationData,
                        network:
                            CoverageHistoryHelper.getCoverageCheckNetwork(
                                coverageCheckHistory
                            ),
                    },
                },
                onSuccess: (override: ICoverageOverride) => {
                    dispatch(
                        coverageOverridesSetSelectedPolicyOverride(override)
                    )
                },
                onError: () => {
                    dispatch(coverageOverridesSetSelectedPolicyOverride(null))
                },
            })
        )
    }

    const onDeleteOverride = () => {
        if (!selectedPracticeRole) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                if (!overridesState.selectedPolicyOverride) {
                    return
                }

                dispatch(
                    deleteSingleCoverageOverride({
                        practice: selectedPracticeRole.practice,
                        payload: {
                            overrideNirvanaRequestId:
                                overridesState.selectedPolicyOverride
                                    .nirvanaRequestId,
                        },
                        onSuccess: (resultNirvanaRequestId: string) => {
                            if (
                                nirvanaRequestId !== resultNirvanaRequestId ||
                                !getSinglePolicy ||
                                !reloadPoliciesList
                            ) {
                                onNavigateToPolicyByRequestId(
                                    resultNirvanaRequestId
                                )
                            } else {
                                getSinglePolicy(true, () =>
                                    reloadPoliciesList()
                                )
                            }
                        },
                    })
                )
            },
            undefined,
            `Override will be permanently removed`
        )
    }

    const onOverrideDone = (result: ICoverageOverrideResult) => {
        const successfulOverride = result.success[0]

        if (result.errors.length || !successfulOverride) {
            result.errors.forEach(item => {
                ToastrHelper.error(item.msg, undefined, true)
            })

            return
        }

        ToastrHelper.success(
            `Override was successfully ${
                overridesState.selectedPolicyOverride ? "updated" : "created"
            }`
        )

        setIsOverrideModeActive(false)

        // In some cases it returns same - nirvanaRequestId
        // which shouldn't happen, but we need gracefully handle this
        // So in this case just reload everything (not redirect)
        if (
            nirvanaRequestId === successfulOverride.nirvanaRequestId &&
            getSinglePolicy &&
            reloadPoliciesList
        ) {
            getSinglePolicy(true, () => reloadPoliciesList())

            // Set this override in state
            dispatch(
                coverageOverridesSetSelectedPolicyOverride(successfulOverride)
            )
        } else {
            onNavigateToPolicyByRequestId(successfulOverride.nirvanaRequestId)
        }
    }

    const onSaveOverride = (data: any) => {
        if (
            !selectedPracticeRole ||
            !coverageCheckIdentificationData ||
            !nirvanaRequestId
        ) {
            return
        }

        const typedData = mapCreationFormData(data, defaultData)

        const validationResult = validateOverrideCreation(
            mapCreationFormData(data, defaultData, false)
        )

        if (!validationResult) {
            return
        }

        dispatch(
            createCoverageOverrides({
                practice: selectedPracticeRole.practice,
                payload: {
                    data: [
                        {
                            policyIdentification: {
                                ...coverageCheckIdentificationData,
                                network:
                                    CoverageHistoryHelper.getCoverageCheckNetwork(
                                        coverageCheckHistory
                                    ),
                            },
                            formData: typedData,
                            currentRequestId: nirvanaRequestId,
                        },
                    ],
                },
                onSuccess: onOverrideDone,
            })
        )
    }

    // Monitor difference of default data and dataWatcher
    // If default form was changed -> activate save button
    const isFormSubmitDisabled = useMemo<boolean>(() => {
        return (
            JSON.stringify(defaultData.form) === JSON.stringify(dataWatcher) ||
            !dataWatcher.expirationDate
        )
    }, [JSON.stringify(defaultData.form), JSON.stringify(dataWatcher)])

    return {
        // vars
        isOverridesEnabled,
        isOverrideModeActive,
        setIsOverrideModeActive,
        overridesFormKey,
        setOverridesFormKey,
        coverageCheckIdentificationData,
        canOverridePolicyCoverage,
        isDeleteOverrideLoading,
        isGetSingleOverrideLoading,
        isCreateOverrideLoading,
        isOverrideActionsDisabled,
        defaultData,
        suggestedOverrideExpirationDate,
        minOverrideExpirationDate,
        dataWatcher,
        // methods
        overridesFormMethods,
        getSingleOverride,
        onDeleteOverride,
        onResetOverride,
        onSaveOverride,
        onUseSuggestedOverrideExpiration,
        isFormSubmitDisabled,
    }
}
