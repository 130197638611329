/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText } from "nirvana-react-elements"

import {
    AvailableModalityCoverageStatus,
    AvailablePlanStatus,
    AvailablePlanType,
    COVERAGE_CONFIG,
    PayerCoverageCheckNetwork,
} from "../../../../config/coverage.config"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { CHECKER_CONFIG } from "../../../../config/checker.config"
import { HtmlHelper } from "../../../../helpers/html.helper"
import { CoverageBreakdownBadgeElement } from "../../../../elements/coverageBadges/coverageBreakdownBadge.element"
import { UtilHelper } from "../../../../helpers/util.helper"

export const IntakeCheckerResultsOverviewComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const checkerState = useAppSelector(checkerSelector)

    const overviewData = useMemo<ICoverageBreakdownData[] | null>(() => {
        const items: ICoverageBreakdownData[] = []

        const inNetworkResult =
            checkerState.intakeCheck.result?.[PayerCoverageCheckNetwork.IN]
        const outNetworkResult =
            checkerState.intakeCheck.result?.[PayerCoverageCheckNetwork.OUT]

        const inputData = checkerState.intakeCheck.inputData

        const successResult =
            inNetworkResult?.resultType &&
            CHECKER_CONFIG.activeCoverageResults.includes(
                inNetworkResult?.resultType
            )
                ? inNetworkResult
                : outNetworkResult?.resultType &&
                  CHECKER_CONFIG.activeCoverageResults.includes(
                      outNetworkResult?.resultType
                  )
                ? outNetworkResult
                : undefined

        const firstName =
            successResult?.demographics?.firstName ||
            inputData?.patientInformation.firstName

        const lastName =
            successResult?.demographics?.lastName ||
            inputData?.patientInformation.lastName

        items.push({
            label: "Member Name",
            value:
                firstName || lastName
                    ? `${firstName || ""} ${lastName || ""}`
                    : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
        })

        items.push({
            label: "Member ID",
            value:
                successResult?.demographics?.memberId ||
                inputData?.memberInformation.memberId ||
                COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
        })

        const isActivePlanStatus =
            !!successResult?.planStatus &&
            CHECKER_CONFIG.activePlanStatuses.includes(
                successResult?.planStatus
            )

        items.push({
            label: "Plan Status",
            value: (
                <CoverageBreakdownBadgeElement
                    label={
                        isActivePlanStatus
                            ? COVERAGE_CONFIG.availablePlanStatusMapping[
                                  AvailablePlanStatus.active
                              ]
                            : COVERAGE_CONFIG.availablePlanStatusMapping[
                                  successResult?.planStatus ||
                                      AvailablePlanStatus.unknown
                              ]
                    }
                    isActive={isActivePlanStatus}
                    labelTypography="captionSemibold"
                />
            ),
        })

        items.push({
            label: "Coverage Status",
            value: (
                <CoverageBreakdownBadgeElement
                    label={
                        COVERAGE_CONFIG.availableModalityCoverageStatusMapping[
                            successResult?.modalityCoverageStatus ||
                                AvailableModalityCoverageStatus.unknown
                        ] ||
                        COVERAGE_CONFIG.availableModalityCoverageStatusMapping[
                            AvailableModalityCoverageStatus.unknown
                        ]
                    }
                    isActive={!!successResult?.modalityCoverageStatusTernary}
                    labelTypography="captionSemibold"
                />
            ),
        })

        items.push({
            label: "Member Obligation",
            value:
                typeof successResult?.memberObligation === "number"
                    ? `$${UtilHelper.formatCents(
                          successResult.memberObligation
                      )}`
                    : COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
        })

        items.push({
            label: "Insurance Type",
            value:
                successResult?.insuranceType ||
                COVERAGE_CONFIG.defaultFallbackCoverageDataValueUnknown,
        })

        items.push({
            label: "Plan Type",
            value:
                CHECKER_CONFIG.planTypeMapping[
                    successResult?.planType || AvailablePlanType.NOT_APPLICABLE
                ] ||
                CHECKER_CONFIG.planTypeMapping[
                    AvailablePlanType.NOT_APPLICABLE
                ],
        })

        items.push({
            label: "Secondary Policy",
            value: successResult?.additionalPolicy ? "Yes" : "No",
        })

        items.push({
            label: "Third Party Payer",
            value: successResult?.thirdPartyPayer ? "Yes" : "No",
        })

        return items
    }, [checkerState.intakeCheck.inputData, checkerState.intakeCheck.result])

    const overviewContent = useMemo<JSX.Element | null>(() => {
        if (!overviewData) {
            return null
        }

        const middleIndex = Math.ceil(overviewData.length / 2)

        const leftData = overviewData.slice(0, middleIndex)
        const rightData = overviewData.slice(middleIndex)

        return (
            <div className="mt-4px flex items-start md:block">
                <div className="flex-1 mr-12px md:mr-0px">
                    {leftData.map((item, index) => {
                        return HtmlHelper.getCoverageBreakdownItem(item, index)
                    })}
                </div>

                <div className="flex-1">
                    {rightData.map((item, index) => {
                        return HtmlHelper.getCoverageBreakdownItem(item, index)
                    })}
                </div>
            </div>
        )
    }, [overviewData])

    return overviewContent ? (
        <div
            className={`
                relative rounded-8px bg-brand-warmLight p-24px
                max-w-1200px mx-auto
                md:max-w-none md:w-full
                ${props.className}
            `}
        >
            <PrimaryText typography="h4">Overview</PrimaryText>

            <div className="mt-12px">{overviewContent}</div>
        </div>
    ) : null
}
