/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { useScrollFix } from "nirvana-react-elements"
import { useParams } from "react-router-dom"

import { AvailableCoveragePortalPermission } from "../../../config/rolesPermissions.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { SinglePolicyViewComponent } from "./singlePolicyView.component"
import { NotFoundComponent } from "../../general/notFound.component"
import { PoliciesListType } from "../../../config/policies.config"

export const SinglePolicyHistoryComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions([
        AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
    ])

    const { nirvanaRequestId } = useParams()

    return nirvanaRequestId ? (
        <div className="relative">
            <SinglePolicyViewComponent
                nirvanaRequestId={nirvanaRequestId}
                listType={PoliciesListType.HISTORY_SEARCH}
            />
        </div>
    ) : (
        <NotFoundComponent />
    )
}
