import React, { useState } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    PrimaryText,
    CheckboxElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { PhoneInputElement } from "elements/phoneInput.element"

import arrowRightIcon from "../../../assets/images/icons/arrow-right.svg"
import lockIcon from "../../../assets/images/icons/lock-dark.svg"

export const PhoneNumberForm: React.FunctionComponent<
    IPhoneNumberFormProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const [enrollmentAccepted, setEnrollmentAccepted] = useState<boolean>(
        !props.withEnrollmentCheckbox
    )

    const onSubmit = async (
        data: Record<string, string> | { phoneNumber: string }
    ) => {
        if (!enrollmentAccepted) {
            return
        }

        props.onSubmit(data.phoneNumber)
    }

    return (
        <div className={`relative ${props.className}`}>
            {props.withTopIcon && (
                <div className="mb-24px w-full">
                    <img
                        className="mx-auto"
                        src={lockIcon}
                        alt="Lock Icon"
                        width={100}
                    />
                </div>
            )}

            {props.title && (
                <PrimaryText
                    className="whitespace-pre-wrap"
                    typography="h4Medium"
                    centered
                >
                    {props.title}
                </PrimaryText>
            )}

            {props.subtitle && (
                <PrimaryText
                    className="mt-16px whitespace-pre-wrap"
                    typography="text"
                    centered
                >
                    {props.subtitle}
                </PrimaryText>
            )}

            {props.subtitle2 && (
                <PrimaryText
                    className="mt-16px whitespace-pre-wrap"
                    typography="text"
                    centered
                >
                    {props.subtitle2}
                </PrimaryText>
            )}

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div className="mt-44px flex flex-row items-start justify-center md:block">
                    <PhoneInputElement
                        name="phoneNumber"
                        label="Phone Number"
                        isLabelStatic
                        className="mr-24px md:mr-0px md:max-w-300px"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        autoFocus
                        hideErrors
                        forceHighlightOnError
                    />

                    <ButtonElement
                        className="md:mt-16px"
                        buttonClassName="md:w-full"
                        label={props.ctaLabel || "Continue"}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={props.isCtaLoading}
                        disabled={!enrollmentAccepted}
                    />
                </div>

                {props.withEnrollmentCheckbox && (
                    <CheckboxElement
                        className="mt-16px w-445px mx-auto md:w-full"
                        checkboxClassName="flex! items-start!"
                        name="enrollmentAccepted"
                        onChange={setEnrollmentAccepted}
                        label={
                            <PrimaryText>
                                I agree to set up and use Multi-Factor
                                Authentication (MFA) to protect my account.
                            </PrimaryText>
                        }
                    />
                )}
            </form>
        </div>
    )
}
