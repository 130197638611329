import moment from "moment-timezone"
import {
    BrowserStorageHelper,
    BrowserStorageType,
    ConfirmationHelper,
    UtilHelper as NirvanaUtilHelper,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"

export class UtilHelper extends NirvanaUtilHelper {
    /**
     * Check if app version update is needed
     * If this is the case -> reload page, it will fetch new code
     */
    static async checkAppVersionUpdateNeeded() {
        try {
            const currentVersion = BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys.appVersion,
                undefined,
                BrowserStorageType.localStorage
            )

            const newVersion = (
                await fetch("/version.json", { cache: "no-store" }).then(res =>
                    res.json()
                )
            ).version

            if (!currentVersion || currentVersion !== newVersion) {
                ConfirmationHelper.enableConfirmation(
                    () => {
                        BrowserStorageHelper.set(
                            GENERAL_CONFIG.browserStorageKeys.appVersion,
                            newVersion,
                            BrowserStorageType.localStorage
                        )

                        window.location.reload()
                    },
                    "Update for the latest version",
                    "A new version is available. Reload the page to get the latest features.",
                    undefined,
                    "Refresh",
                    undefined,
                    false,
                    {
                        ignoreOnOutsideClick: true,
                    }
                )
            }
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * Get pagination for app
     */
    static getPagination(
        count = GENERAL_CONFIG.defaultExtendedPaginationLength
    ): IPagination {
        return {
            start: 0,
            count,
            total: 0,
            moreAvailable: undefined,
        }
    }

    /**
     * Get formatted demographics address
     */
    static getFormattedDemographicsAddress(
        address: ICoverageResultDemographicsAddress
    ): string {
        return [
            address.streetLine1,
            address.streetLine2,
            address.city,
            address.state,
            address.zip?.substring(0, 5),
        ]
            .filter(item => !!item)
            .join(", ")
    }

    /**
     * Check if user is internal
     * internal user is the one that has @meetnirvana.com in the email
     */
    static isInternalUser(profile: IProfile | null): boolean {
        return !!profile?.email.includes(
            `@${GENERAL_CONFIG.supportEmail.split("@")[1]}`
        )
    }

    /**
     * Capitalize each word in sentence
     */
    static capitalizeWordsInSentence(sentence: string): string {
        const words = sentence.trim().split(" ")

        for (let i = 0; i < words.length; i++) {
            if (!words[i]) {
                continue
            }

            words[i] =
                words[i][0].toUpperCase() + words[i].substring(1).toLowerCase()
        }

        return words.join(" ")
    }

    /**
     * Get percentage representative between 2 numbers
     */
    static getPercentageValue(
        part?: number,
        total?: number,
        fractionDigits = 2
    ): number {
        return parseFloat(
            (((part || 0) / (total || 1)) * 100).toFixed(fractionDigits)
        )
    }

    /**
     * Get numeric value if it's set to something
     * Need this function to handle 0 as value correctly
     */
    static getNumericValueIfSet(
        value?: number | null,
        secondValue?: number | null,
        fallback: null | undefined = null
    ): number | null | undefined {
        return typeof value !== "undefined" && value !== null
            ? value
            : typeof secondValue !== "undefined" && secondValue !== null
            ? secondValue
            : fallback
    }

    /**
     * Calculate relative date range
     * Relative means that there's a range, but then there's "starting identifier" of range
     * And if today is different starting identifier, it will create same range but relative to today
     */
    static calculateRelativeToTodayDateRange(
        relativeToDate: string | number | Date,
        dateFrom?: string | number | Date,
        dateTo?: string | number | Date
    ): {
        dateFrom?: Date
        dateTo?: Date
    } {
        let newDateFrom: Date | undefined
        let newDateTo: Date | undefined
        let diffDays = 0

        const todayDayStart = moment().startOf("day")
        const relativeDayStart = moment(relativeToDate).startOf("day")

        if (dateFrom) {
            diffDays = relativeDayStart.diff(
                moment(dateFrom).startOf("day"),
                "days"
            )

            newDateFrom = todayDayStart
                .clone()
                .subtract(diffDays, "day")
                .toDate()
        }

        if (dateTo) {
            diffDays = relativeDayStart.diff(
                moment(dateTo).startOf("day"),
                "days"
            )

            newDateTo = todayDayStart.clone().subtract(diffDays, "day").toDate()
        }

        return {
            dateFrom: newDateFrom,
            dateTo: newDateTo,
        }
    }

    /**
     * Mask string by hiding some characters and leaving only some visible at the end
     */
    static maskString(str: string, keepCount = 3, replacement = "*"): string {
        // Return the original string if it's charsKeep characters or less
        if (str.length <= keepCount) {
            return str
        }
        // Create a string of replacement
        const maskedPart = `${replacement}`.repeat(str.length - keepCount)

        // Get the last keepCount characters
        const visiblePart = str.slice(-keepCount)

        return maskedPart + visiblePart
    }

    /**
     * Flip object keys and values
     */
    static flipObject(obj: Record<string, any>): Record<string, any> {
        const flipped = Object.entries(obj).map(([key, value]) => [value, key])

        return Object.fromEntries(flipped)
    }
}
