/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PrimaryText, ProgressElement } from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"

import { CoveragePortalFlagType } from "../../../config/policies.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../config/general.config"

export const MedicaidAggregationCardComponent: React.FunctionComponent<
    IMedicaidAggregationCardComponentProps
> = props => {
    const navigate = useNavigate()

    const { medicaidFilters } = useAppSelector(policiesSelector)

    const onFilterFlags = (
        flags: CoveragePortalFlagType | CoveragePortalFlagType[]
    ) => {
        navigate(
            `${ROUTES_CONFIG.medicaidReportsUrl}?${
                GENERAL_CONFIG.urlSearchParamsKeys.filters
            }=${JSON.stringify({
                ...medicaidFilters,
                flags: Array.isArray(flags) ? flags : [flags],
            })}`
        )
    }

    const progressColor = useMemo(
        () => props.calculation.progressColor || props.calculation.color,
        [props.calculation.progressColor, props.calculation.color]
    )

    const progressPercentage = useMemo(
        () =>
            typeof props.calculation.progressPercentage !== "undefined"
                ? props.calculation.progressPercentage
                : props.calculation.percentage,
        [props.calculation.progressPercentage, props.calculation.percentage]
    )

    return (
        <div
            className={`
                relative bg-brand-white rounded-8px p-16px
                ${props.className}
            `}
        >
            <div
                className={`
                    flex items-start
                    ${
                        props.calculation.isInlinedSubLabel
                            ? "items-center"
                            : "items-start"
                    }
                `}
            >
                {props.calculation.color && (
                    <div
                        className={`
                            w-15px h-15px rounded-4px mr-8px relative
                            ${
                                props.calculation.isInlinedSubLabel
                                    ? "top--2px"
                                    : "top-3px"
                            }
                        `}
                        style={{
                            backgroundColor: props.calculation.color,
                        }}
                    />
                )}

                <div
                    className={`
                        flex-1
                        ${props.calculation.filterFlags ? "cursor-pointer" : ""}
                    `}
                    onClick={
                        props.calculation.filterFlags
                            ? onFilterFlags.bind(
                                  {},
                                  props.calculation.filterFlags
                              )
                            : undefined
                    }
                >
                    <PrimaryText typography="subtitle">
                        <span className="text-semibold">
                            {props.calculation.label}: {props.calculation.value}
                        </span>
                        {typeof props.calculation.percentage !==
                            "undefined" && (
                            <span>
                                {props.calculation.isInlinedSubLabel ? (
                                    " "
                                ) : (
                                    <br />
                                )}
                                ({props.calculation.percentage}% of Total
                                Checks)
                            </span>
                        )}
                    </PrimaryText>
                </div>
            </div>

            {progressColor && typeof progressPercentage !== "undefined" ? (
                <ProgressElement
                    containerClassName="mt-24px"
                    percent={progressPercentage}
                    showInfo={false}
                    small={true}
                    bordered={true}
                    trailColor={props.calculation.trailColor || "white"}
                    strokeColor={
                        props.calculation.progressColor ||
                        props.calculation.color
                    }
                />
            ) : null}

            {(props.calculation.children || []).map((child, index) => {
                return (
                    <div
                        key={index}
                        className={`${
                            index === 0 ? "mt-24px" : "mt-16px"
                        } flex items-center ${
                            child.filterFlag ? "cursor-pointer" : ""
                        }`}
                        onClick={() =>
                            child.filterFlag
                                ? onFilterFlags(child.filterFlag)
                                : undefined
                        }
                    >
                        {child.color && (
                            <div
                                className="w-15px h-15px rounded-4px mr-8px relative top--2px"
                                style={{
                                    backgroundColor: child.color,
                                }}
                            />
                        )}
                        <PrimaryText>{child.label}</PrimaryText>

                        <div className="flex-1" />

                        <PrimaryText>
                            <span className="text-semibold">{child.value}</span>{" "}
                            ({child.percentage}%)
                        </PrimaryText>
                    </div>
                )
            })}
        </div>
    )
}
