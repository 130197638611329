/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import {
    ButtonElement,
    DatePickerElement,
    NIRVANA_COLORS,
    NirvanaBreathingLoaderElement,
    PrimaryText,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { FormProvider } from "react-hook-form"
import { isEqual } from "lodash-es"

import {
    POLICIES_COLUMNS_CONFIG,
    PolicyColumnKey,
} from "../../../../config/policiesColumns.config"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { InlinedOverrideFormFieldComponent } from "../inlinedOverrideFormField.component"
import { useBulkOverrides } from "../../../../hooks/bulkOverrides.hooks"
import { OVERRIDES_CONFIG } from "../../../../config/overrides.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../../store/selectors/selectedPracticeRole.selector"
import { createCoverageOverrides } from "../../../../store/thunks/coverageOverrides.thunks"
import { CoverageHistoryHelper } from "../../../../helpers/coverageHistory.helper"
import { policiesReloadList } from "../../../../store/thunks/policies.thunks"
import { ToastrHelper } from "../../../../helpers/toastr.helper"

export const BulkOverridesFormComponent: React.FunctionComponent<
    IBulkOverridesFormComponentProps
> = props => {
    const {
        overridesFormMethods,
        getFormDefaultData,
        dataWatcher,
        overridesFormKey,
        onResetOverride,
        isCreateOverrideLoading,
        minOverrideExpirationDate,
        suggestedOverrideExpirationDate,
        onUseSuggestedOverrideExpiration,
        mapCreationFormData,
        validateOverrideCreation,
    } = useBulkOverrides()

    const dispatch = useAppDispatch()
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const formItemHasValue = (item: any): boolean => {
        return item !== null && typeof item !== "undefined" && item !== ""
    }

    const defaultData = useMemo<IOverrideDefaultData>(() => {
        // Initially we get first's coverage check as a base
        // Then loop through all needed coverage checks and compare values
        // Record if multiple etc
        const finalResult: IOverrideDefaultData = {
            // Combination of override and coverage result
            form: getFormDefaultData(
                null,
                props.coverageChecks[0]?.coverageResult || undefined
            ),
            // Just from override - always will be blank values for bulk
            override: getFormDefaultData(null, undefined, false),
            bulkMultipleValues: {},
        }

        // Start from 2nd
        for (let i = 1; i < props.coverageChecks.length; i++) {
            const newFormResult = getFormDefaultData(
                null,
                props.coverageChecks[i]?.coverageResult || undefined
            )

            for (const key of Object.keys(
                OVERRIDES_CONFIG.bulkOverrides.allowedOverrideFields
            )) {
                const fieldKey = key as PolicyColumnKey

                const formTarget =
                    OVERRIDES_CONFIG.bulkOverrides.allowedOverrideFields[
                        fieldKey
                    ]

                if (
                    OVERRIDES_CONFIG.bulkOverrides.ignoredValueComputationFields.includes(
                        fieldKey
                    )
                ) {
                    // Clean data in final result for this key
                    if (formTarget) {
                        finalResult.form[formTarget] = undefined
                    }

                    // Clean data in differences for this key
                    if (finalResult.bulkMultipleValues) {
                        finalResult.bulkMultipleValues[fieldKey] = undefined
                    }

                    continue
                }

                const newFormValue = formTarget
                    ? newFormResult[formTarget]
                    : undefined

                // If no value - just do nothing and continue
                if (!formItemHasValue(newFormValue)) {
                    continue
                }

                const finalResultFormValue = formTarget
                    ? finalResult.form[formTarget]
                    : undefined

                // Check if current value is different from final result
                // Different - delete value in form
                // Check if this difference already in diff log
                // if same do nothing and just continue
                if (!isEqual(newFormValue, finalResultFormValue)) {
                    finalResult.bulkMultipleValues =
                        finalResult.bulkMultipleValues || {}
                    finalResult.bulkMultipleValues[fieldKey] =
                        finalResult.bulkMultipleValues[fieldKey] || []

                    // Clean data in final result for this key
                    // And push it to diff log
                    if (formTarget) {
                        if (
                            formItemHasValue(finalResultFormValue) &&
                            !finalResult.bulkMultipleValues[fieldKey]?.includes(
                                finalResultFormValue
                            )
                        ) {
                            finalResult.bulkMultipleValues[fieldKey]?.push(
                                finalResultFormValue
                            )
                        }

                        finalResult.form[formTarget] = undefined
                    }

                    if (
                        !finalResult.bulkMultipleValues[fieldKey]?.includes(
                            newFormValue
                        )
                    ) {
                        finalResult.bulkMultipleValues[fieldKey]?.push(
                            newFormValue
                        )
                    }
                }
            }
        }

        return finalResult
    }, [props.coverageChecks])

    // Monitor difference of default data and dataWatcher
    // If default form was changed -> activate save button
    const isFormSubmitDisabled = useMemo<boolean>(() => {
        return (
            JSON.stringify(defaultData.form) === JSON.stringify(dataWatcher) ||
            !dataWatcher.expirationDate
        )
    }, [JSON.stringify(defaultData.form), JSON.stringify(dataWatcher)])

    const onOverrideDone = (result: ICoverageOverrideResult) => {
        const successfulOverride = result.success[0]

        if (result.errors.length || !successfulOverride) {
            result.errors.forEach(item => {
                ToastrHelper.error(item.msg, undefined, true)
            })

            return
        }

        ToastrHelper.success("Overrides were successfully created")

        if (selectedPracticeRole && props.listType) {
            dispatch(
                policiesReloadList({
                    practice: selectedPracticeRole.practice,
                    payload: {
                        policiesViewType: props.listType,
                    },
                })
            )
        }

        props.onDone()
    }

    const onSaveOverride = (data: any) => {
        if (!selectedPracticeRole) {
            return
        }

        const typedData = mapCreationFormData(data, defaultData)

        const validationResult = validateOverrideCreation(
            mapCreationFormData(data, defaultData, false)
        )

        if (!validationResult) {
            return
        }

        const payloadData: IOverrideUpsertRequestRawData[] = []

        for (const coverageCheck of props.coverageChecks) {
            const coverageCheckIdentificationData =
                CoverageHistoryHelper.getPolicyIdentificationData(coverageCheck)

            if (!coverageCheckIdentificationData) {
                continue
            }

            payloadData.push({
                policyIdentification: {
                    ...coverageCheckIdentificationData,
                    network:
                        CoverageHistoryHelper.getCoverageCheckNetwork(
                            coverageCheck
                        ),
                },
                formData: typedData,
                currentRequestId: coverageCheck.coverageResult.resultId,
            })
        }

        dispatch(
            createCoverageOverrides({
                practice: selectedPracticeRole.practice,
                payload: {
                    data: payloadData,
                    sleepAtTheEndSeconds:
                        OVERRIDES_CONFIG.bulkOverrides.sleepAtTheEndSeconds,
                },
                onSuccess: onOverrideDone,
            })
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
            key={overridesFormKey}
        >
            <NirvanaBreathingLoaderElement isActive={isCreateOverrideLoading} />

            <FormProvider {...overridesFormMethods}>
                <div
                    className="
                        p-24px bg-brand-white rounded-8px
                        border border-solid border-brand-offWhite
                    "
                >
                    <PrimaryText typography="h4">Overrides</PrimaryText>

                    <PrimaryText className="mt-8px">
                        Please enter the values you would like to override
                    </PrimaryText>

                    <div className="mt-8px">
                        <div className="w-988px flex flex-wrap justify-between md:w-full md:block">
                            {Object.keys(
                                OVERRIDES_CONFIG.bulkOverrides
                                    .allowedOverrideFields
                            ).map((key, index) => {
                                const fieldKey = key as PolicyColumnKey

                                const label =
                                    POLICIES_COLUMNS_CONFIG.columnsConfig[
                                        fieldKey
                                    ].label

                                const formTarget =
                                    OVERRIDES_CONFIG.bulkOverrides
                                        .allowedOverrideFields[fieldKey]

                                const formValue = formTarget
                                    ? dataWatcher[formTarget]
                                    : undefined

                                const showOverrideWarning =
                                    OVERRIDES_CONFIG.bulkOverrides.allowedOverrideFieldsConfirmationNotice.includes(
                                        fieldKey
                                    ) && formItemHasValue(formValue)

                                return (
                                    <div
                                        key={index}
                                        className="w-400px mt-8px md:w-full"
                                    >
                                        <div className="flex items-start">
                                            <PrimaryText
                                                className="w-200px mr-16px flex items-center flex-shrink-0 md:mr-0px"
                                                typography="textSmall"
                                            >
                                                {label}
                                            </PrimaryText>

                                            <div className="flex-1 relative">
                                                <InlinedOverrideFormFieldComponent
                                                    fieldKey={fieldKey}
                                                    defaultData={defaultData}
                                                    fallback={null}
                                                    benefitStructureNotRequired={
                                                        props.benefitStructureNotRequired
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {showOverrideWarning ? (
                                            <PrimaryText
                                                className="my-6px"
                                                weight="semibold"
                                                size={11}
                                                lineHeight={12}
                                                color={
                                                    NIRVANA_COLORS.brand
                                                        .vibrantPurple
                                                }
                                            >
                                                Are you sure you want to bulk
                                                override the {label}?
                                            </PrimaryText>
                                        ) : null}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-end mt-16px md:block">
                    <PrimaryText
                        typography="textSmall"
                        className="mr-8px min-w-0 flex-shrink-0"
                    >
                        Overrides Expiration Date
                    </PrimaryText>

                    <div className="relative mr-16px md:mr-0px md:max-w-200px">
                        <DatePickerElement
                            name="expirationDate"
                            size="small"
                            defaultValue={dataWatcher.expirationDate}
                            reactHookFormValidations={{
                                required: VALIDATION_CONFIG.required,
                            }}
                            shouldValidate
                            reactHookFormErrors={
                                overridesFormMethods.formState.errors
                            }
                            reactHookFormRegister={
                                overridesFormMethods.register
                            }
                            reactHookFormUnregister={
                                overridesFormMethods.unregister
                            }
                            reactHookFormSet={overridesFormMethods.setValue}
                            minDate={minOverrideExpirationDate.toDate()}
                            disabled={isCreateOverrideLoading}
                        />

                        {!dataWatcher.expirationDate ? (
                            <PrimaryText
                                typography="caption"
                                className="absolute bottom--20px whitespace-nowrap"
                            >
                                Suggested:{" "}
                                <span
                                    className="cursor-pointer text-brand-vibrantPurple underline text-bold"
                                    onClick={onUseSuggestedOverrideExpiration}
                                >
                                    {suggestedOverrideExpirationDate.format(
                                        GENERAL_CONFIG.defaultMomentDateFormat
                                    )}
                                </span>
                            </PrimaryText>
                        ) : null}
                    </div>

                    <div className="flex items-center md:mt-40px">
                        <ButtonElement
                            buttonClassName="w-100px"
                            htmlType="button"
                            type="default"
                            label="Reset"
                            disabled={isCreateOverrideLoading}
                            onClick={() => {
                                onResetOverride()
                            }}
                        />

                        <ButtonElement
                            className="ml-16px"
                            buttonClassName="w-100px"
                            htmlType="button"
                            type="primary"
                            label="Save"
                            disabled={isFormSubmitDisabled}
                            isLoading={isCreateOverrideLoading}
                            onClick={overridesFormMethods.handleSubmit(
                                onSaveOverride
                            )}
                        />
                    </div>
                </div>
            </FormProvider>
        </div>
    )
}
