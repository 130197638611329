/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { ButtonElement } from "nirvana-react-elements"

import {
    POLICIES_CONFIG,
    PoliciesListType,
} from "../../../config/policies.config"
import { PoliciesFiltersFormComponent } from "./policiesFiltersForm.component"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { policiesSelector } from "../../../store/selectors/policies.selector"

export const SearchFiltersComponent: React.FunctionComponent<
    ISearchFiltersComponentProps
> = props => {
    const formMethods = useForm<IPoliciesListFiltersData>()
    const { handleSubmit } = formMethods

    const { processFiltersFormData } = usePoliciesList()

    const { historyFilters } = useAppSelector(policiesSelector)

    const [isDataError, setIsDataError] = useState<boolean>(false)

    const onSubmit = (data: IPoliciesListFiltersData) => {
        setIsDataError(false)

        const mappedData = processFiltersFormData(data)

        const providedMemberData = Object.keys(mappedData)
            .filter(key =>
                POLICIES_CONFIG.searchMemberDataFilters.includes(
                    key as keyof IPoliciesListFiltersData
                )
            )
            .map(key => mappedData[key])
            .filter(item => !!item)

        const providedGeneralData = Object.keys(mappedData)
            .map(key => mappedData[key])
            .filter(item => !!item)

        if (
            (providedMemberData.length &&
                providedMemberData.length <
                    POLICIES_CONFIG.minMemberDataFields) ||
            providedGeneralData.length < POLICIES_CONFIG.minOverallDataFields
        ) {
            setIsDataError(true)

            return
        }

        props.onFiltersSubmitted(mappedData)
    }

    return (
        <div className={`relative ${props.className}`}>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <PoliciesFiltersFormComponent
                        listType={PoliciesListType.HISTORY_SEARCH}
                        existingFilters={historyFilters}
                        isRequired
                        isWide
                        withMemberInformation
                        isDataError={isDataError}
                    />

                    <div className="mt-32px flex items-center">
                        <ButtonElement
                            label="Reset"
                            onClick={props.onResetFilters}
                            htmlType="button"
                            type="default"
                        />

                        <div className="flex-1" />

                        <ButtonElement
                            label="Search"
                            htmlType="submit"
                            type="primary"
                        />
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}
