/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    PageHelmetElement,
    SpinnerElement,
    useScrollFix,
} from "nirvana-react-elements"

import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "../../../config/rolesPermissions.config"
import {
    PoliciesListType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import {
    FEATURE_FLAGS_CONFIG,
    FeatureFlagsExistenceLogicalOperator,
} from "../../../config/featureFlags.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { BulkPolicyControlComponent } from "../reports/bulkPolicyControl.component"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { QuotaUsageType } from "../../../config/coverage.config"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"
import { MedicaidActiveChecksVisualizationComponent } from "./medicaidActiveChecksVisualization.component"
import { VisualizationWrapperComponent } from "../visualizationWrapper.component"
import { MedicaidFlagsVisualizationComponent } from "./medicaidFlagsVisualization.component"
import { PoliciesListHeaderComponent } from "../policiesList/policiesListHeader.component"

export const MedicaidReportsComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions(
        [AvailableCoveragePortalPermission.viewHistoricalCoverageChecks],
        PermissionsExistenceLogicalOperator.OR
    )

    useIsFeatureFlagEnabled(
        FEATURE_FLAGS_CONFIG.medicaidReportsFeatureFlags,
        FeatureFlagsExistenceLogicalOperator.OR,
        ROUTES_CONFIG.profileUrl
    )

    const {
        // vars
        canRunCoverageChecks,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
        onDeselectAllPolicies,
    } = usePoliciesList()

    const scrollableTableRef = useRef<HTMLDivElement>(null)

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        return canRunCoverageChecks
    }, [canRunCoverageChecks])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            PoliciesListHelper.getSavedColumnsConfiguration(
                PoliciesListType.MEDICAID,
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        PoliciesListHelper.saveColumnsConfigurations(
            PoliciesListType.MEDICAID,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesListHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Reports"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {selectedPracticeRole ? (
                <PoliciesListHeaderComponent
                    className="mb-24px"
                    listType={PoliciesListType.MEDICAID}
                    setSelectedPolicies={setSelectedPolicies}
                    scrollableTableRef={scrollableTableRef}
                    withExport
                    withSavingFilterPresets
                />
            ) : (
                <SpinnerElement containerClassName="mt-32px text-center" />
            )}

            {!!selectedPracticeRole && (
                <VisualizationWrapperComponent
                    adjustHorizontalScrollReference={scrollableTableRef}
                    listType={PoliciesListType.MEDICAID}
                >
                    <MedicaidActiveChecksVisualizationComponent
                        className="
                            w-350px self-stretch
                            border-r border-solid border-brand-warmShadow
                        "
                    />

                    <MedicaidFlagsVisualizationComponent className="flex-1 self-stretch" />
                </VisualizationWrapperComponent>
            )}

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    scrollableElementRef={scrollableTableRef}
                    listType={PoliciesListType.MEDICAID}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                    onChangeColumnOrder={onChangeColumnOrder}
                    isSinglePolicySelectable={
                        PoliciesHelper.isPolicyRecheckEligible
                    }
                />
            ) : null}

            {listPoliciesSelectable && (
                <BulkPolicyControlComponent
                    listType={PoliciesListType.MEDICAID}
                    onCancelBulkPolicyControl={onDeselectAllPolicies}
                    coverageChecks={selectedPolicies}
                    setCoverageChecks={setSelectedPolicies}
                    quotaUsageType={QuotaUsageType.MEDICAID}
                    withBulkRecheck={canRunCoverageChecks}
                    bulkRecheckLabel="Check Medicaid"
                />
            )}
        </div>
    )
}
