import React, { useMemo } from "react"

export const MultiProgressElement: React.FunctionComponent<
    IMultiProgressElementProps
> = props => {
    const emptyPartPercentage = useMemo<number>(() => {
        return Math.max(
            Math.floor(
                100 -
                    props.items.reduce(
                        (memo, item) => memo + item.percentage,
                        0
                    )
            ),
            0
        )
    }, [props.items])

    return (
        <div
            className={`
                flex items-center rounded-full overflow-hidden border border-solid border-brand-warmShadow
                ${props.className}
            `}
        >
            {props.items.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={props.heightClassName || "h-16px"}
                        style={{
                            width: `${item.percentage}%`,
                            backgroundColor: item.color,
                        }}
                    />
                )
            })}

            <div
                className={`
                    bg-brand-warmShadow opacity-50
                    ${props.heightClassName || "h-16px"}
                `}
                style={{
                    width: `${emptyPartPercentage}%`,
                }}
            />
        </div>
    )
}
