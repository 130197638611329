/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { ButtonElement } from "nirvana-react-elements"

import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesExportList } from "../../../store/thunks/policies.thunks"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"

import exportIcon from "../../../assets/images/icons/download-up-dark.svg"

export const PoliciesExportListComponent: React.FunctionComponent<
    IPoliciesExportListComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)
    const { policiesGettingFullListProgress } = useAppSelector(policiesSelector)

    const isExportLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isPoliciesExportLoading()
    }, [runtimeState.isLoading])

    const onExportCsv = () => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            policiesExportList({
                practice: selectedPracticeRole.practice,
                payload: {
                    viewType: props.listType,
                },
            })
        )
    }

    return (
        <div className={`relative ${props.className}`}>
            <ButtonElement
                label={`Export CSV${
                    isExportLoading
                        ? ` (${policiesGettingFullListProgress}%)`
                        : ""
                }`}
                className="ml-16px"
                onClick={onExportCsv}
                htmlType="button"
                type="default"
                icon={exportIcon}
                isLoading={isExportLoading}
                disabled={props.isDisabled}
                isRightIcon
            />
        </div>
    )
}
