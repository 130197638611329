import moment from "moment-timezone"

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"
import { OVERRIDES_CONFIG } from "../config/overrides.config"
import { AvailablePlanType } from "../config/coverage.config"

export class CoverageOverridesService {
    /**
     * Get single coverage override
     */
    static async getSingle(
        practice: IPractice,
        data: IPolicyIdentificationData,
        ignoreToastrErrorCodes: number[] = []
    ): Promise<ICoverageOverride | null> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_OVERRIDES_GET_SINGLE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            data,
            "GET",
            undefined,
            undefined,
            undefined,
            ignoreToastrErrorCodes
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result.override || null
            : null
    }

    /**
     * Delete single coverage override
     */
    static async deleteSingle(
        practice: IPractice,
        overrideNirvanaRequestId: string
    ): Promise<
        | {
              nirvanaRequestId: string | null
          }
        | undefined
    > {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_OVERRIDES_DELETE.replace(
                ":practiceId",
                practice.id.toString()
            ).replace(":overrideNirvanaRequestId", overrideNirvanaRequestId),
            undefined,
            "DELETE"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Create coverage override
     */
    static async upsert(
        practice: IPractice,
        data: IOverrideUpsertRequestRawData
    ): Promise<ICoverageOverrideResult | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.COVERAGE_OVERRIDES_CREATE.replace(
                ":practiceId",
                practice.id.toString()
            ),
            CoverageOverridesService.getMappedOverrideData(data),
            "POST"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Map override form data to request that is being sent to API
     */
    static getMappedOverrideData(
        data: IOverrideUpsertRequestRawData
    ): IOverrideUpsertRequestMappedData {
        const benefitStructure =
            typeof data.formData.benefitStructure !== "undefined" &&
            data.formData.benefitStructure !== null
                ? data.formData.benefitStructure
                : null

        return {
            ...data.policyIdentification,

            currentRequestId: data.currentRequestId,

            expirationDate: UtilHelper.dateToMysqlFormat(
                (
                    data.formData.expirationDate || moment().subtract(1, "day")
                ).toDate()
            ),

            modalityCoverageStatus:
                typeof data.formData.modalityCoverageStatus !== "undefined" &&
                data.formData.modalityCoverageStatus !== null
                    ? data.formData.modalityCoverageStatus
                    : null,
            eligibilityBeginDate:
                typeof data.formData.eligibilityBeginDate !== "undefined" &&
                data.formData.eligibilityBeginDate !== null
                    ? UtilHelper.dateToMysqlFormat(
                          data.formData.eligibilityBeginDate.toDate()
                      )
                    : null,
            eligibilityEndDate:
                typeof data.formData.eligibilityEndDate !== "undefined" &&
                data.formData.eligibilityEndDate !== null
                    ? UtilHelper.dateToMysqlFormat(
                          data.formData.eligibilityEndDate.toDate()
                      )
                    : null,
            coinsurance:
                typeof data.formData.coinsurance !== "undefined" &&
                data.formData.coinsurance !== null
                    ? Number(data.formData.coinsurance)
                    : null,
            copayment:
                typeof data.formData.copayment !== "undefined" &&
                data.formData.copayment !== null
                    ? UtilHelper.formatDollarsToCents(data.formData.copayment)
                    : null,
            deductible:
                typeof data.formData.deductible !== "undefined" &&
                data.formData.deductible !== null
                    ? UtilHelper.formatDollarsToCents(data.formData.deductible)
                    : null,
            remainingDeductible:
                typeof data.formData.remainingDeductible !== "undefined" &&
                data.formData.remainingDeductible !== null
                    ? UtilHelper.formatDollarsToCents(
                          data.formData.remainingDeductible
                      )
                    : null,
            oopMax:
                typeof data.formData.oopMax !== "undefined" &&
                data.formData.oopMax !== null
                    ? UtilHelper.formatDollarsToCents(data.formData.oopMax)
                    : null,
            remainingOopMax:
                typeof data.formData.remainingOopMax !== "undefined" &&
                data.formData.remainingOopMax !== null
                    ? UtilHelper.formatDollarsToCents(
                          data.formData.remainingOopMax
                      )
                    : null,
            planStatus:
                typeof data.formData.planStatus !== "undefined" &&
                data.formData.planStatus !== null
                    ? data.formData.planStatus
                    : null,
            planType:
                typeof data.formData.planType !== "undefined" &&
                data.formData.planType !== null &&
                data.formData.planType !== AvailablePlanType.NOT_APPLICABLE
                    ? data.formData.planType
                    : null,
            planBeginDate:
                typeof data.formData.planBeginDate !== "undefined" &&
                data.formData.planBeginDate !== null
                    ? UtilHelper.dateToMysqlFormat(
                          data.formData.planBeginDate.toDate()
                      )
                    : null,
            planEndDate:
                typeof data.formData.planEndDate !== "undefined" &&
                data.formData.planEndDate !== null
                    ? UtilHelper.dateToMysqlFormat(
                          data.formData.planEndDate.toDate()
                      )
                    : null,
            benefitStructure:
                data.formData.benefitStructure ===
                OVERRIDES_CONFIG.defaultBenefitsStructure
                    ? typeof data.formData.customBenefitStructure !==
                          "undefined" &&
                      data.formData.customBenefitStructure !== null
                        ? data.formData.customBenefitStructure
                        : benefitStructure
                    : benefitStructure,

            thirdPartyPayer:
                typeof data.formData.thirdPartyPayer !== "undefined" &&
                data.formData.thirdPartyPayer !== null
                    ? data.formData.thirdPartyPayer
                    : null,

            memberObligation:
                typeof data.formData.memberObligation !== "undefined" &&
                data.formData.memberObligation !== null
                    ? UtilHelper.formatDollarsToCents(
                          data.formData.memberObligation
                      )
                    : null,
            payerObligation:
                typeof data.formData.payerObligation !== "undefined" &&
                data.formData.payerObligation !== null
                    ? UtilHelper.formatDollarsToCents(
                          data.formData.payerObligation
                      )
                    : null,
        }
    }
}
