import React, { useMemo } from "react"
import {
    SpinnerElement,
    PrimaryText,
    PageHelmetElement,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { AvailableCoveragePortalPermission } from "../../config/rolesPermissions.config"
import { useAppSelector } from "../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { OrganizationQuoteComponent } from "../general/quotaChecksProgressComponent.component"

export const OrganizationInformationComponent: React.FunctionComponent = () => {
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const canManageBilling = useMemo<boolean>(
        () =>
            PermissionsHelper.hasPermission(
                AvailableCoveragePortalPermission.manageBilling,
                selectedPracticeRole
            ),
        [selectedPracticeRole]
    )

    return (
        <div className="max-w-840px mx-auto relative pb-24px">
            <PageHelmetElement
                title="Organization"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            {!selectedPracticeRole ? (
                <SpinnerElement containerClassName="mt-60px text-center" />
            ) : (
                <div>
                    <PrimaryText typography="h4">
                        Organization Information
                    </PrimaryText>

                    <div className="mt-16px">
                        <div className="flex items-center sm:block sm:mt-10px">
                            <PrimaryText className="w-160px">
                                Organization Name
                            </PrimaryText>

                            <PrimaryText typography="textSemibold">
                                {selectedPracticeRole.practice.name}
                            </PrimaryText>
                        </div>

                        <div className="flex items-center mt-8px sm:block">
                            <PrimaryText className="w-160px">
                                Group NPI
                            </PrimaryText>

                            <PrimaryText
                                typography="textSemibold"
                                className="mouseflow-ignore"
                            >
                                {selectedPracticeRole.practice.groupNPI}
                            </PrimaryText>
                        </div>
                    </div>

                    <OrganizationQuoteComponent className="mt-24px" />

                    {canManageBilling && (
                        <PrimaryText className="mt-16px">
                            Need to edit or cancel services? Contact{" "}
                            <a
                                className="text-bold text-brand-vibrantPurple!"
                                href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                            >
                                {GENERAL_CONFIG.supportEmail}
                            </a>
                        </PrimaryText>
                    )}
                </div>
            )}
        </div>
    )
}
