/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import {
    AutoCompleteElement,
    ButtonElement,
    DatePickerElement,
    InputElement,
    ISelectRawOption,
    ISelectRenderedOption,
    NIRVANA_COLORS,
    PrimaryText,
    SelectElement,
    TagsSelectElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import moment from "moment-timezone"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
} from "../../../config/policies.config"
import {
    AvailableInsuranceType,
    AvailablePlanType,
    COVERAGE_CONFIG,
} from "../../../config/coverage.config"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"
import { LookupService } from "../../../services/lookup.service"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { LookupHelper } from "../../../helpers/lookup.helper"
import { CHECKER_CONFIG } from "../../../config/checker.config"
import { RelativeDateRangeInputElement } from "../../../elements/relativeDateRangeInput.element"

import expandFiltersIcon from "../../../assets/images/icons/plus-reports-filters.svg"

export const PoliciesFiltersFormComponent: React.FunctionComponent<
    IPoliciesFiltersFormComponentProps
> = props => {
    const {
        formState: { errors },
        control,
        register,
        unregister,
        setValue,
    } = useFormContext<IPoliciesListFiltersData>()

    const [maxStartDate] = useState<Date>(moment().toDate())

    const [additionalFiltersExpanded, setAdditionalFiltersExpanded] =
        useState<boolean>(
            (() => {
                // if some of the additional filters are set, then show them expanded by default
                return POLICIES_CONFIG.listTypeFilters[
                    props.listType
                ].additional.some(key => !!props.existingFilters[key])
            })()
        )

    const availableFlagOptions = useMemo<ISelectRawOption[]>(() => {
        const flagTypes = props.listType
            ? POLICIES_CONFIG.flagTypesByViewType[props.listType]
            : Object.values(CoveragePortalFlagType)

        return flagTypes.map(flag => {
            return {
                label: POLICIES_CONFIG.flagTypeMappings[flag].label,
                value: flag,
            }
        })
    }, [props.listType])

    const availablePlanTypeOptions = useMemo<ISelectRenderedOption[]>(() => {
        return LookupHelper.getRenderedOptionsFromObject<AvailablePlanType>(
            props.listType
                ? CHECKER_CONFIG.planTypeMappingByViewType[props.listType]
                : CHECKER_CONFIG.planTypeMapping
        )
    }, [props.listType])

    const availableInsuranceTypeOptions = useMemo<
        ISelectRenderedOption[]
    >(() => {
        return LookupHelper.getRenderedOptionsFromObject<AvailableInsuranceType>(
            AvailableInsuranceType
        )
    }, [])

    const additionalFiltersAlwaysExpanded = useMemo<boolean>(() => {
        return !!(props.additionalFiltersAlwaysExpanded || props.isRequired)
    }, [props.additionalFiltersAlwaysExpanded, props.isRequired])

    // Set default values (objects) in form
    useEffect(() => {
        props.existingFilters.payer &&
            setValue("payer", props.existingFilters.payer)
    }, [])

    const getRenderedFormFields = (
        filterKeys: (keyof IPoliciesListFiltersData)[]
    ) => {
        return (
            <>
                {filterKeys.map((filterKey, index) => {
                    switch (filterKey) {
                        case "checkDate":
                            return (
                                <RelativeDateRangeInputElement
                                    key={index}
                                    className="mt-24px"
                                    inputRootKey="checkDate"
                                    defaultValue={
                                        props.existingFilters.checkDate
                                    }
                                    placeholder="Select date(s)"
                                    labelPrefix="Search"
                                    labelBoldPart="eligibility check(s)"
                                    labelSuffix="run through Nirvana..."
                                    selectValidations={{
                                        required: VALIDATION_CONFIG.required,
                                    }}
                                    pastDatesAllowed
                                />
                            )

                        case "nextAppointmentDate":
                            return (
                                <RelativeDateRangeInputElement
                                    key={index}
                                    className="mt-24px"
                                    inputRootKey="nextAppointmentDate"
                                    defaultValue={
                                        props.existingFilters
                                            .nextAppointmentDate
                                    }
                                    placeholder="Select date(s)"
                                    labelPrefix="With"
                                    labelBoldPart="next appointment"
                                    labelSuffix="date(s)…"
                                    allowClear
                                    futureDatesAllowed
                                />
                            )

                        case "planEndDate":
                            return (
                                <RelativeDateRangeInputElement
                                    key={index}
                                    className="mt-24px"
                                    inputRootKey="planEndDate"
                                    defaultValue={
                                        props.existingFilters.planEndDate
                                    }
                                    placeholder="Select date(s)"
                                    labelPrefix="With"
                                    labelBoldPart="plan end"
                                    labelSuffix="date(s)…"
                                    allowClear
                                    pastDatesAllowed
                                    futureDatesAllowed
                                />
                            )

                        case "flags":
                            return props.setSelectedFlags ? (
                                <div key={index} className="mt-24px">
                                    <PrimaryText className="mb-4px">
                                        With the following{" "}
                                        <span className="text-bold">
                                            {POLICIES_CONFIG.filtersDisplayMapping.flags?.toLowerCase()}
                                            :
                                        </span>
                                    </PrimaryText>

                                    <TagsSelectElement
                                        placeholder="Select as many as needed"
                                        name="flags"
                                        onSelected={(items: string[]) => {
                                            props.setSelectedFlags?.(
                                                items as CoveragePortalFlagType[]
                                            )
                                        }}
                                        maxTagCount={
                                            props.flagsDisplayedAmount || 1
                                        }
                                        options={availableFlagOptions}
                                        externalValue={props.selectedFlags}
                                        optionsFetcherDebounceMilliseconds={250}
                                    />
                                </div>
                            ) : null

                        case "patientId":
                            return (
                                <InputElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .patientId
                                    }
                                    name="patientId"
                                    reactHookControl={control}
                                    reactHookValidations={{
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={errors}
                                    defaultValue={
                                        props.existingFilters.patientId
                                    }
                                    isLabelStatic
                                />
                            )

                        case "patientType":
                            return (
                                <SelectElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .patientType
                                    }
                                    name="patientType"
                                    allowClear={true}
                                    reactHookFormRegister={register}
                                    reactHookFormSet={setValue}
                                    reactHookFormUnregister={unregister}
                                    renderedOptions={Object.values(
                                        COVERAGE_CONFIG.selectRenderedPatientTypes
                                    )}
                                    defaultValue={
                                        props.existingFilters.patientType
                                            ? COVERAGE_CONFIG
                                                  .selectRenderedPatientTypes[
                                                  props.existingFilters
                                                      .patientType
                                              ].displayValue
                                            : undefined
                                    }
                                />
                            )

                        case "groupId":
                            return (
                                <InputElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .groupId
                                    }
                                    name="groupId"
                                    reactHookControl={control}
                                    reactHookValidations={{
                                        minLength: VALIDATION_CONFIG.minLength,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={errors}
                                    defaultValue={props.existingFilters.groupId}
                                    isLabelStatic
                                />
                            )

                        case "groupName":
                            return (
                                <InputElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .groupName
                                    }
                                    name="groupName"
                                    reactHookControl={control}
                                    reactHookValidations={{
                                        minLength: VALIDATION_CONFIG.minLength,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={errors}
                                    defaultValue={
                                        props.existingFilters.groupName
                                    }
                                    isLabelStatic
                                />
                            )

                        case "planType":
                            return (
                                <SelectElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .planType
                                    }
                                    name="planType"
                                    allowClear={true}
                                    reactHookFormRegister={register}
                                    reactHookFormSet={setValue}
                                    reactHookFormUnregister={unregister}
                                    renderedOptions={availablePlanTypeOptions}
                                    defaultValue={
                                        props.existingFilters.planType
                                            ? CHECKER_CONFIG.planTypeMapping[
                                                  props.existingFilters.planType
                                              ]
                                            : undefined
                                    }
                                />
                            )

                        case "planName":
                            return (
                                <InputElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .planName
                                    }
                                    name="planName"
                                    reactHookControl={control}
                                    reactHookValidations={{
                                        minLength: VALIDATION_CONFIG.minLength,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={errors}
                                    defaultValue={
                                        props.existingFilters.planName
                                    }
                                    isLabelStatic
                                />
                            )

                        case "payer":
                            return (
                                <div key={index} className="mt-24px">
                                    <AutoCompleteElement
                                        name="payer"
                                        label={
                                            POLICIES_CONFIG
                                                .filtersDisplayMapping.payer
                                        }
                                        tooltip={
                                            TOOLTIPS_CONFIG.calculate.payer
                                        }
                                        dataFetcher={(search?: string) =>
                                            LookupService.lookupPayers(search)
                                        }
                                        itemRenderer={
                                            LookupHelper.getRenderedPayer
                                        }
                                        reactHookFormErrors={errors}
                                        reactHookFormRegister={register}
                                        reactHookFormUnregister={unregister}
                                        reactHookFormSet={setValue}
                                        debounceMilliseconds={250}
                                        defaultValue={
                                            props.existingFilters.payer
                                                ? LookupHelper.getRenderedPayer(
                                                      props.existingFilters
                                                          .payer
                                                  ).displayValue
                                                : undefined
                                        }
                                        shouldValidate
                                        isLabelStatic
                                        ignoreAutoSelectionOnBlur
                                        clearOnEmptySearch
                                        notFoundTitle="Don’t see your payer?"
                                        notFoundSubtitle={`
                                            <a
                                                href="mailto:${GENERAL_CONFIG.supportEmail}?subject=Insurance provider not found"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Contact Support
                                            </a>
                                        `}
                                        notFoundAction={() => (
                                            <a
                                                href={
                                                    GENERAL_CONFIG.supportedInsurersUrl
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <ButtonElement
                                                    label="View Supported Payers"
                                                    type="primary"
                                                    htmlType="button"
                                                    size="large"
                                                />
                                            </a>
                                        )}
                                    />

                                    <a
                                        href={
                                            GENERAL_CONFIG.supportedInsurersUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="no-underline!"
                                    >
                                        <PrimaryText
                                            className="mt-8px"
                                            typography="caption"
                                        >
                                            View all supported payers
                                        </PrimaryText>
                                    </a>
                                </div>
                            )

                        case "insuranceType":
                            return (
                                <SelectElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .insuranceType
                                    }
                                    name="insuranceType"
                                    allowClear={true}
                                    reactHookFormRegister={register}
                                    reactHookFormSet={setValue}
                                    reactHookFormUnregister={unregister}
                                    renderedOptions={
                                        availableInsuranceTypeOptions
                                    }
                                    defaultValue={
                                        props.existingFilters.insuranceType
                                    }
                                />
                            )

                        case "resetBenefitsStatus":
                            return (
                                <SelectElement
                                    key={index}
                                    className="mt-24px"
                                    label={
                                        POLICIES_CONFIG.filtersDisplayMapping
                                            .resetBenefitsStatus
                                    }
                                    name="resetBenefitsStatus"
                                    allowClear={true}
                                    reactHookFormRegister={register}
                                    reactHookFormSet={setValue}
                                    reactHookFormUnregister={unregister}
                                    renderedOptions={Object.values(
                                        COVERAGE_CONFIG.selectRenderedResetBenefitStatus
                                    )}
                                    defaultValue={
                                        props.existingFilters
                                            .resetBenefitsStatus
                                            ? COVERAGE_CONFIG
                                                  .selectRenderedResetBenefitStatus[
                                                  props.existingFilters
                                                      .resetBenefitsStatus
                                              ].displayValue
                                            : undefined
                                    }
                                />
                            )

                        default:
                            return null
                    }
                })}
            </>
        )
    }

    return (
        <div className={`relative ${props.className}`}>
            <div
                className={`
                    grid grid-flow-row auto-cols-fr gap-x-4
                    ${props.isWide ? "grid-cols-2" : "grid-cols-1"}
                `}
            >
                {getRenderedFormFields(
                    POLICIES_CONFIG.listTypeFilters[props.listType].main
                )}
            </div>

            {props.withMemberInformation ? (
                <div className="mt-24px p-16px rounded-12px bg-brand-warmLight">
                    {props.isRequired && (
                        <PrimaryText
                            typography="h6"
                            color={
                                props.isDataError
                                    ? NIRVANA_COLORS.brand.error
                                    : undefined
                            }
                        >
                            Enter at least {POLICIES_CONFIG.minMemberDataFields}{" "}
                            fields to make a search from these inputs
                        </PrimaryText>
                    )}

                    <div className="flex md:block mt-44px">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .memberFirstName
                            }
                            name="memberFirstName"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.memberFirstName}
                            isLabelStatic
                        />

                        <InputElement
                            className="
                                flex-1
                                md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .memberLastName
                            }
                            name="memberLastName"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.memberLastName}
                            isLabelStatic
                        />
                    </div>

                    <div className="flex md:block mt-44px">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.memberId
                            }
                            name="memberId"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.memberId}
                            isLabelStatic
                        />

                        <DatePickerElement
                            className="
                                flex-1
                                md:mt-44px
                            "
                            name="memberDob"
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.memberDob
                            }
                            defaultValue={
                                props.existingFilters.memberDob
                                    ? moment(props.existingFilters.memberDob)
                                    : undefined
                            }
                            shouldValidate
                            reactHookFormErrors={errors}
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            maxDate={maxStartDate}
                            allowClear
                            isLabelStatic
                        />
                    </div>
                </div>
            ) : null}

            <div className="mt-24px">
                {!additionalFiltersAlwaysExpanded && (
                    <div
                        className="mt-40px pb-16px flex items-center cursor-pointer"
                        onClick={() =>
                            setAdditionalFiltersExpanded(current => !current)
                        }
                    >
                        <PrimaryText weight="medium">
                            Additional filters
                        </PrimaryText>

                        <div className="flex-1" />

                        <img src={expandFiltersIcon} alt="expand" />
                    </div>
                )}

                {props.isRequired && (
                    <PrimaryText
                        className="mt-24px"
                        typography="h6"
                        color={
                            props.isDataError
                                ? NIRVANA_COLORS.brand.error
                                : undefined
                        }
                    >
                        {props.withMemberInformation ? "Or enter" : "Enter"} any
                        of the following inputs
                    </PrimaryText>
                )}

                <div
                    className={`
                        grid grid-flow-row auto-cols-fr gap-4
                        md:grid-cols-1
                        ${props.isWide ? "grid-cols-3" : "grid-cols-2"}
                        ${
                            additionalFiltersAlwaysExpanded
                                ? "mt-16px"
                                : "mt-8px"
                        }
                        ${
                            additionalFiltersAlwaysExpanded ||
                            additionalFiltersExpanded
                                ? "block"
                                : "hidden"
                        }
                    `}
                >
                    {getRenderedFormFields(
                        POLICIES_CONFIG.listTypeFilters[props.listType]
                            .additional
                    )}
                </div>
            </div>
        </div>
    )
}
