/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

import { UtilHelper } from "../helpers/util.helper"
import { useAppSelector } from "../store/selectors/app.selector"
import { runtimeSelector } from "../store/selectors/runtime.selector"

export const useCheckAppVersion = () => {
    const { isLoggedIn } = useAppSelector(runtimeSelector)

    // Set timer to check for new version every 3 minutes
    useEffect(() => {
        UtilHelper.checkAppVersionUpdateNeeded()

        const intervalId = setInterval(() => {
            UtilHelper.checkAppVersionUpdateNeeded()
        }, 3 * 60 * 1000)

        return () => {
            try {
                clearInterval(intervalId)
            } catch (e) {}
        }
    }, [isLoggedIn])
}
