/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { NIRVANA_COLORS, PrimaryText } from "nirvana-react-elements"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
    policiesSetHistoryFilters,
    policiesSetMedicaidFilters,
    policiesSetOverridesFilters,
    policiesSetPlanYearResetsFilters,
    policiesSetReportsFilters,
} from "../../../store/slices/policies.slice"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListHelper } from "../../../helpers/policiesList.helper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { PoliciesColumnsManagementComponent } from "./policiesColumnsManagement.component"
import { PoliciesFilterPopupComponent } from "../../popups/policiesFilterPopup.component"
import { PoliciesFiltersDisplayComponent } from "../filters/policiesFiltersDisplay.component"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { useScrollableElementBoundaries } from "../../../hooks/scrollableElementBoundaries.hook"
import { PoliciesExportListComponent } from "./policiesExportList.component"
import { PoliciesListType } from "../../../config/policies.config"

export const PoliciesListHeaderComponent: React.FunctionComponent<
    IPoliciesListHeaderComponentProps
> = props => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const scrollableTableBoundaries = useScrollableElementBoundaries(
        props.scrollableTableRef
    )

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const {
        historyItems,
        historyFilters,
        reportsItems,
        reportsFilters,
        overridesItems,
        overridesFilters,
        medicaidItems,
        medicaidFilters,
        planYearResetsItems,
        planYearResetsFilters,
    } = useAppSelector(policiesSelector)

    const [isFiltersPopupActive, setIsFiltersPopupActive] =
        useState<boolean>(false)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const headerTitle = useMemo<string>(() => {
        switch (props.listType) {
            case PoliciesListType.HISTORY_SEARCH:
                return "Search"

            case PoliciesListType.REPORTS:
                return "General Reports"

            case PoliciesListType.MEDICAID:
                return "Medicaid Reports"

            case PoliciesListType.OVERRIDES:
                return "Overrides"

            case PoliciesListType.PLAN_YEAR_RESETS:
                return "Plan Year Resets"
        }
    }, [props.listType])

    const { items: typeBasedItems, filters: typeBasedFilters } = useMemo<{
        items: ICoverageCheckHistory[]
        filters: IPoliciesListFiltersData
    }>(() => {
        switch (props.listType) {
            case PoliciesListType.HISTORY_SEARCH:
                return {
                    items: historyItems,
                    filters: historyFilters,
                }

            case PoliciesListType.REPORTS:
                return {
                    items: reportsItems,
                    filters: reportsFilters,
                }

            case PoliciesListType.OVERRIDES:
                return {
                    items: overridesItems,
                    filters: overridesFilters,
                }

            case PoliciesListType.MEDICAID:
                return {
                    items: medicaidItems,
                    filters: medicaidFilters,
                }

            case PoliciesListType.PLAN_YEAR_RESETS:
                return {
                    items: planYearResetsItems,
                    filters: planYearResetsFilters,
                }
        }
    }, [
        props.listType,
        historyItems,
        historyFilters,
        reportsItems,
        reportsFilters,
        overridesItems,
        overridesFilters,
        medicaidItems,
        medicaidFilters,
        planYearResetsItems,
        planYearResetsFilters,
    ])

    const filtersCount = useMemo<number>(() => {
        return PoliciesListHelper.getMappedDisplayFilters(typeBasedFilters)
            .length
    }, [typeBasedFilters])

    // Monitor filters in search params and set them in state
    useEffect(() => {
        const searchParamsFilters = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.filters
        )

        // If no reports filters by in url (that's our main reports url)
        // Set them in search params so clicking back works fine
        if (!searchParamsFilters) {
            if (props.ignoreFiltersPersistence) {
                return
            }

            searchParams.set(
                GENERAL_CONFIG.urlSearchParamsKeys.filters,
                JSON.stringify(typeBasedFilters)
            )

            setSearchParams(searchParams)

            return
        }

        // In case json is invalid
        try {
            const filtersFromUrl: IPoliciesListFiltersData =
                JSON.parse(searchParamsFilters)

            if (
                JSON.stringify(filtersFromUrl) ===
                JSON.stringify(typeBasedFilters)
            ) {
                return
            }

            switch (props.listType) {
                case PoliciesListType.HISTORY_SEARCH:
                    dispatch(policiesSetHistoryFilters(filtersFromUrl))

                    break

                case PoliciesListType.REPORTS:
                    dispatch(policiesSetReportsFilters(filtersFromUrl))

                    break

                case PoliciesListType.OVERRIDES:
                    dispatch(policiesSetOverridesFilters(filtersFromUrl))

                    break

                case PoliciesListType.MEDICAID:
                    dispatch(policiesSetMedicaidFilters(filtersFromUrl))

                    break

                case PoliciesListType.PLAN_YEAR_RESETS:
                    dispatch(policiesSetPlanYearResetsFilters(filtersFromUrl))

                    break
            }
        } catch (e) {}
    }, [searchParams])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            PoliciesListHelper.getSavedColumnsConfiguration(
                props.listType,
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onEditFilters = () => {
        if (props.onEditFilters) {
            props.onEditFilters()
        } else {
            setIsFiltersPopupActive(true)
        }
    }

    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        // Deselect everything on filters change
        props.setSelectedPolicies?.([])

        navigate(
            `${PoliciesListHelper.getPoliciesListBaseUrl(props.listType)}?${
                GENERAL_CONFIG.urlSearchParamsKeys.filters
            }=${JSON.stringify(data)}`
        )
    }

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        PoliciesListHelper.saveColumnsConfigurations(
            props.listType,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    return (
        <div
            className={`relative px-24px py-12px ${props.className}`}
            style={{
                right: scrollableTableBoundaries?.x,
            }}
        >
            <div
                className="
                    flex items-center
                    md:block
                "
            >
                <PrimaryText
                    className="mr-16px"
                    size={29}
                    lineHeight={35}
                    weight="semibold"
                >
                    {headerTitle}
                </PrimaryText>

                {/*filters btn*/}
                <div
                    className="
                        cursor-pointer rounded-22px px-16px py-13px bg-brand-warmLight
                        flex items-center justify-center
                        border border-solid border-brand-primary
                        hover:bg-brand-lilacLight
                    "
                    onClick={onEditFilters}
                >
                    <PrimaryText
                        typography="buttonText"
                        className="relative top-2px"
                    >
                        Filters
                    </PrimaryText>

                    {filtersCount ? (
                        <PrimaryText
                            className="ml-8px rounded-full bg-brand-primary w-16px h-16px flex items-center justify-center"
                            size={13}
                            lineHeight={13}
                            weight="bold"
                            color={NIRVANA_COLORS.brand.warmLight}
                        >
                            {filtersCount}
                        </PrimaryText>
                    ) : null}
                </div>

                <div className="flex-1 mx-16px md:hideen" />

                <div className="flex items-center md:mt-32px md:justify-center">
                    {columnsConfiguration.length && selectedPracticeRole ? (
                        <>
                            <PoliciesColumnsManagementComponent
                                className="ml-16px"
                                initialColumnsConfiguration={PoliciesListHelper.getDefaultColumns(
                                    props.listType,
                                    selectedPracticeRole.availableModalities
                                )}
                                currentColumnsConfiguration={
                                    columnsConfiguration
                                }
                                onColumnsConfigurationChange={
                                    onSaveColumnsConfiguration
                                }
                                isRightPlacement
                            />
                        </>
                    ) : null}

                    {props.withExport ? (
                        <PoliciesExportListComponent
                            listType={props.listType}
                            isDisabled={!typeBasedItems.length}
                        />
                    ) : null}
                </div>
            </div>

            <PoliciesFiltersDisplayComponent
                className="mt-12px"
                filters={typeBasedFilters}
            />

            {isFiltersPopupActive && (
                <PoliciesFilterPopupComponent
                    isActive
                    listType={props.listType}
                    existingFilters={typeBasedFilters}
                    ignoreFiltersPersistence={props.ignoreFiltersPersistence}
                    onFiltersSubmitted={onFiltersSubmitted}
                    onDone={() => setIsFiltersPopupActive(false)}
                    withSavingFilterPresets={props.withSavingFilterPresets}
                />
            )}
        </div>
    )
}
