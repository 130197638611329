/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import {
    PrimaryText,
    SelectElement,
    SpinnerElement,
    ConfirmationHelper,
    ButtonElement,
    NIRVANA_COLORS,
    NIRVANA_THEME,
    useIsMobile,
} from "nirvana-react-elements"

import {
    AvailableApplicationRole,
    ROLES_PERMISSIONS_CONFIG,
} from "../../../config/rolesPermissions.config"
import {
    organizationInvitesChangeRole,
    organizationInvitesCreate,
    organizationInvitesDelete,
} from "../../../store/thunks/organization.thunks"
import { organizationSelector } from "../../../store/selectors/organization.selector"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"
import { FeatureFlag } from "../../../config/featureFlags.config"

import removeIcon from "../../../assets/images/icons/trash-red.svg"

export const OrganizationInvitesListComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()

    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const organizationState = useAppSelector(organizationSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const isBillerGroupsEnabled = useIsFeatureFlagEnabled(
        FeatureFlag.ONE_VERIFY_BILLER_GROUPS
    )

    const [loadingIds, setLoadingIds] = useState<number[]>([])

    const startLoading = (invite: IOrganizationUserInvite) =>
        setLoadingIds([...loadingIds, invite.id])

    const stopLoading = (invite: IOrganizationUserInvite) =>
        setLoadingIds(loadingIds.filter(item => item !== invite.id))

    const onInviteRoleChange = (
        invite: IOrganizationUserInvite,
        role: AvailableApplicationRole
    ) => {
        if (!selectedPracticeRole || role === invite.role.key) {
            return
        }

        startLoading(invite)

        dispatch(
            organizationInvitesChangeRole({
                payload: {
                    invite,
                    role,
                },
                practice: selectedPracticeRole.practice,
                onFinally: () => stopLoading(invite),
            })
        )
    }

    const onRemoveUserInvite = (invite: IOrganizationUserInvite) => {
        if (!selectedPracticeRole) {
            return
        }

        ConfirmationHelper.enableConfirmation(
            () => {
                startLoading(invite)

                dispatch(
                    organizationInvitesDelete({
                        payload: invite,
                        practice: selectedPracticeRole.practice,
                        onFinally: () => stopLoading(invite),
                    })
                )
            },
            undefined,
            `User's invitation "${invite.email}" will be permanently removed`
        )
    }

    const onResendInvitation = (invite: IOrganizationUserInvite) => {
        if (!selectedPracticeRole) {
            return
        }

        startLoading(invite)

        dispatch(
            organizationInvitesCreate({
                practice: selectedPracticeRole.practice,
                payload: {
                    role: invite.role.key,
                    email: invite.email,
                    billerGroupIds: invite.billerGroups.map(item => item.id),
                },
                onFinally: () => stopLoading(invite),
            })
        )
    }

    return organizationState.invites.length ? (
        <div
            className={`
                ${props.className}
            `}
        >
            {/*HEADINGS*/}
            <div
                className="
                    flex items-center px-16px pt-24px pb-8px
                    border-b border-solid border-brand-warmLight
                    md:hidden
                "
            >
                <PrimaryText typography="h6" color={NIRVANA_COLORS.brand.grey7}>
                    Invited
                </PrimaryText>
            </div>

            {organizationState.invites.map((invite, index) => {
                const isLoading = loadingIds.includes(invite.id)

                return (
                    <div
                        key={index}
                        className={`
                            relative h-56px px-16px flex items-center border-b border-solid border-brand-warmLight
                            md:h-auto md:mt-36px md:block md:border md:border-brand-warmShadow
                            md:rounded-8px md:bg-brand-white md:py-16px
                        `}
                    >
                        {/*STATUS BADGE for mobiles*/}
                        <div
                            className={`
                                hidden md:block
                                absolute top--15px left-10px
                                px-8px py-2px rounded-4px
                                border border-solid w-max
                                bg-brand-offWhite border-brand-warmShadow
                            `}
                        >
                            Invited
                        </div>

                        <PrimaryText className="w-400px mr-16px md:mt-8px md:w-auto">
                            <div>
                                {/*LABEL FOR MOBILES / TABLETS*/}
                                <span className="opacity-50 hidden md:inline-block">
                                    Email:&nbsp;
                                </span>

                                <a
                                    href={`mailto:${invite.email}`}
                                    rel="noopener"
                                    className="mouseflow-ignore underline"
                                >
                                    {invite.email}
                                </a>
                            </div>

                            {invite.billerGroups.length &&
                            isBillerGroupsEnabled ? (
                                <PrimaryText typography="caption">
                                    Biller Groups:{" "}
                                    {invite.billerGroups
                                        .map(item => item.name)
                                        .join(", ")}
                                </PrimaryText>
                            ) : null}
                        </PrimaryText>

                        <PrimaryText className="flex-1 mr-16px md:mt-8px md:w-auto md:flex md:items-center">
                            {/*LABEL FOR MOBILES / TABLETS*/}
                            <span className="opacity-50 hidden md:inline-block md:mr-8px">
                                Role:&nbsp;
                            </span>

                            <SelectElement
                                selectClassName="w-85px! md:w-auto!"
                                name="role"
                                renderedOptions={
                                    ROLES_PERMISSIONS_CONFIG.inviteRenderedRoles
                                }
                                onSelected={(role: AvailableApplicationRole) =>
                                    onInviteRoleChange(invite, role)
                                }
                                externalValue={invite.role.label}
                                disabled={isLoading}
                                transparent
                                inlined={!isTablet}
                            />
                        </PrimaryText>

                        {invite.isExpired && (
                            <ButtonElement
                                className="mr-16px md:mt-16px"
                                label="Resend invitation"
                                htmlType="button"
                                size="middle"
                                disabled={isLoading}
                                onClick={() => onResendInvitation(invite)}
                            />
                        )}

                        {/*ACTION REMOVE*/}
                        <div className="md:absolute md:top-16px md:right-16px">
                            <div
                                className="cursor-pointer"
                                onClick={() =>
                                    !isLoading && onRemoveUserInvite(invite)
                                }
                            >
                                <img src={removeIcon} alt="Remove" />
                            </div>
                        </div>

                        {isLoading && (
                            <div className="absolute z-10 right--15px">
                                <SpinnerElement size="small" />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    ) : null
}
