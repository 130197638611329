/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { PrimaryText } from "nirvana-react-elements"
import useOnclickOutside from "react-cool-onclickoutside"

import hamburgerIcon from "../../../assets/images/icons/hamburger-dark.svg"
import closeIcon from "../../../assets/images/icons/close-dark.svg"

export const MobileMenuComponent: React.FunctionComponent<
    IMenuProps
> = props => {
    const location = useLocation()

    const onCloseMenu = () => {
        setIsMenuActive(false)
    }

    const containerRef = useOnclickOutside(onCloseMenu)

    const onActivateMenu = () => {
        setIsMenuActive(true)
    }

    const [isMenuActive, setIsMenuActive] = useState<boolean>(false)

    const isActiveMenuItem = (item: IMenuItem): boolean =>
        item.url === location.pathname

    return (
        <div className={`items-center hidden md:flex ${props.className}`}>
            <div className="flex-1" />

            <div
                className="py-8px px-16px cursor-pointer"
                onClick={onActivateMenu}
            >
                <img src={hamburgerIcon} alt="menu" />
            </div>

            {isMenuActive && (
                <div
                    ref={containerRef}
                    className={`
                        fixed h-screen z-30 bg-brand-white p-24px shadow-7 overflow-hidden overflow-y-auto
                        w-365px sm:w-screen top-0 right-0
                    `}
                >
                    <div className="flex">
                        <div className="flex-1" />

                        <div
                            className="cursor-pointer px-16px pb-4px"
                            onClick={onCloseMenu}
                        >
                            <img src={closeIcon} alt="close" />
                        </div>
                    </div>

                    <div>
                        {props.menuItems.map(
                            (item: IMenuItem, index: number) => {
                                const isActive = isActiveMenuItem(item)
                                const hasSubmenu = !!item.submenu
                                const hasSubmenuItems = (item.submenu?.length ?? 0) > 0

                                return hasSubmenu ? (
                                    hasSubmenuItems ? (
                                        <div key={index}>
                                            <PrimaryText
                                                typography="subtitle"
                                                className="mt-20px"
                                            >
                                                {item.label}
                                            </PrimaryText>

                                            {item.submenu?.map(
                                                (subItem: IMenuItem) => {
                                                    const isActiveSubItem =
                                                        isActiveMenuItem(subItem)

                                                    return (
                                                        <Link
                                                            to={subItem.url}
                                                            key={subItem.label}
                                                            className="no-underline!"
                                                        >
                                                            <div
                                                                className="ml-32px"
                                                                onClick={
                                                                    onCloseMenu
                                                                }
                                                            >
                                                                <PrimaryText
                                                                    typography={
                                                                        isActiveSubItem
                                                                            ? "subtitleSemibold"
                                                                            : "subtitle"
                                                                    }
                                                                    className="mt-20px"
                                                                >
                                                                    {subItem.label}
                                                                </PrimaryText>
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            )}
                                        </div>
                                    ) : null
                                ) : (
                                    <Link
                                        to={item.url}
                                        key={index}
                                        className="no-underline!"
                                    >
                                        <div onClick={onCloseMenu}>
                                            <PrimaryText
                                                typography={
                                                    isActive
                                                        ? "subtitleSemibold"
                                                        : "subtitle"
                                                }
                                                className="mt-20px"
                                            >
                                                {item.label}
                                            </PrimaryText>
                                        </div>
                                    </Link>
                                )
                            }
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
