/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { fetchUserAttributes } from "aws-amplify/auth"
import {
    ButtonElement,
    PrimaryText,
    InputElement,
    VALIDATION_CONFIG,
    CheckboxElement,
} from "nirvana-react-elements"

import { UtilHelper } from "../../../helpers/util.helper"
import { authSetMfaChallengePhoneNumber } from "../../../store/slices/auth.slice"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { authSelector } from "../../../store/selectors/auth.selector"
import { AUTH_CONFIG } from "../../../config/auth.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { authResendMfaCode } from "../../../store/thunks/auth.thunks"

import arrowRightIcon from "../../../assets/images/icons/arrow-right.svg"
import verificationIcon from "../../../assets/images/icons/verification-code-dark.svg"

export const CodeForm: React.FunctionComponent<ICodeFormProps> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const dispatch = useAppDispatch()
    const authState = useAppSelector(authSelector)

    const [rememberDevice, setRememberDevice] = useState<boolean>()

    const [codeResendWaitSeconds, setCodeResendWaitSeconds] =
        useState<number>(0)

    const [codeResentAt, setCodeResentAt] = useState<number>(
        new Date().getTime()
    )

    const [codeTriggeredCount, setCodeTriggeredCount] = useState<number>(1)

    const [isRememberDeviceAvailable, setIsRememberDeviceAvailable] =
        useState<boolean>(false)

    const maskedPhoneNumber = useMemo<string | undefined>(() => {
        return authState.mfaChallengePhoneNumber
            ? UtilHelper.maskString(authState.mfaChallengePhoneNumber)
            : undefined
    }, [authState.mfaChallengePhoneNumber])

    // Need to check if user is logged in already
    // If yes -> it's MFA setup flow
    // If not -> it's regular MFA challenge during login -> in this case we allow to remember device
    useEffect(() => {
        const checkRememberDeviceAvailable = async () => {
            try {
                await fetchUserAttributes()

                setIsRememberDeviceAvailable(false)
            } catch (e) {
                setIsRememberDeviceAvailable(true)
            }
        }

        checkRememberDeviceAvailable()
    }, [])

    // Cleanup
    useEffect(() => {
        return () => {
            dispatch(authSetMfaChallengePhoneNumber(null))
        }
    }, [])

    // interval for when code can be resent
    useEffect(() => {
        const calculateCodeResendWaitSeconds = () => {
            const secondsToWait = Math.max(
                0,
                Math.floor(
                    (codeResentAt +
                        AUTH_CONFIG.mfaCodeSendDelayMS -
                        new Date().getTime()) /
                        1000
                )
            )

            setCodeResendWaitSeconds(secondsToWait)
        }

        const intervalId = setInterval(calculateCodeResendWaitSeconds, 1000)

        calculateCodeResendWaitSeconds()

        return () => {
            clearInterval(intervalId)
        }
    }, [codeResentAt])

    const onSubmit = (data: Record<string, string> | { code: string }) => {
        props.onSubmit(data.code, rememberDevice)
    }

    const onResendCode = () => {
        if (codeResendWaitSeconds) {
            return
        }

        dispatch(
            authResendMfaCode({
                payload: {
                    loginData: authState.loginData,
                },
                onSuccess: () => {
                    setCodeResentAt(new Date().getTime())

                    setCodeTriggeredCount(current => {
                        return current + 1
                    })
                },
            })
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.withTopIcon ? (
                <div className="mb-24px w-full">
                    <img
                        className="mx-auto"
                        src={verificationIcon}
                        alt="Lock Icon"
                        width={100}
                    />
                </div>
            ) : null}

            <PrimaryText typography="h4Medium" centered>
                Enter Your Verification Code
            </PrimaryText>

            <PrimaryText className="mt-16px" typography="text" centered>
                We’ve sent a 6-digit code to{" "}
                {maskedPhoneNumber || "your phone number"}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div className="mt-32px flex items-start justify-center md:block">
                    <InputElement
                        className="mr-24px w-270px md:mr-0px md:w-full"
                        name="code"
                        placeholder="123456"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                    />

                    <ButtonElement
                        className="md:mt-16px"
                        buttonClassName="md:w-full"
                        label={props.ctaLabel || "Submit"}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={arrowRightIcon}
                        isRightIcon
                        isLoading={props.isCtaLoading}
                    />
                </div>

                {isRememberDeviceAvailable && (
                    <CheckboxElement
                        className="mt-16px w-400px mx-auto md:w-full"
                        checkboxClassName="flex! items-start!"
                        name="rememberDevice"
                        onChange={setRememberDevice}
                        defaultChecked={true}
                        label={
                            <PrimaryText>
                                Remember this device for{" "}
                                {AUTH_CONFIG.rememberedDeviceExpirationDays}{" "}
                                days
                            </PrimaryText>
                        }
                    />
                )}

                {codeTriggeredCount < AUTH_CONFIG.mfaCodeSendMaxAttempts ? (
                    <div className="flex w-400px mx-auto items-center mt-8px md:w-full">
                        <PrimaryText>Didn't get the code?</PrimaryText>

                        <ButtonElement
                            className="relative top--2px"
                            label={`Resend${
                                codeResendWaitSeconds
                                    ? ` in ${codeResendWaitSeconds} seconds`
                                    : ""
                            }`}
                            htmlType="button"
                            type="text"
                            onClick={onResendCode}
                            disabled={
                                !!codeResendWaitSeconds || props.isCtaLoading
                            }
                        />
                    </div>
                ) : null}

                <PrimaryText className="w-400px mx-auto mt-8px md:w-full">
                    If you’re unable to receive your verification code via SMS,
                    please email us at{" "}
                    <a href={`mailto:${GENERAL_CONFIG.supportEmail}`}>
                        {GENERAL_CONFIG.supportEmail}
                    </a>{" "}
                    for assistance.
                </PrimaryText>
            </form>
        </div>
    )
}
