/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { PageFloatingButton, PrimaryText } from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"
import { ViewportList } from "react-viewport-list"

import {
    checkerRemoveContinuousMonitoringInputData,
    checkerRemoveMedicaidInputData,
    checkerSetRunningState,
} from "../../../store/slices/checker.slice"
import {
    CoverageCheckerCheckType,
    CoverageCheckerRunningState,
} from "../../../config/checker.config"
import {
    checkerRunContinuousMonitoringChecks,
    checkerRunMedicaidChecks,
} from "../../../store/thunks/checker.thunks"
import { UploadCsvComponent } from "./uploadCsv.component"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { ScreenScrollableTableElement } from "../../../elements/screenScrollableTable.element"
import { SingleCsvCoverageCheckComponent } from "./singleCsvCoverageCheck.component"
import { ResultsQuickViewComponent } from "../quickView/resultsQuickView.component"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { OrganizationQuoteComponent } from "../../general/quotaChecksProgressComponent.component"
import { CSV_CHECKER_CONFIG } from "../../../config/csvChecker.config"
import { ToastrHelper } from "../../../helpers/toastr.helper"
import { userDetailsSelector } from "../../../store/selectors/userDetails.selector"
import { CheckerHelper } from "../../../helpers/checker.helper"
import { QuotaUsageType } from "../../../config/coverage.config"

import calculatorIcon from "../../../assets/images/icons/calculator-white.svg"

export const CoverageCheckerCSVComponent: React.FunctionComponent<
    ICoverageCheckerCSVComponentProps
> = props => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const checkerState = useAppSelector(checkerSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const { selectedPracticeUsageQuota } = useAppSelector(userDetailsSelector)

    const coverageChecks = useMemo<
        IMedicaidCoverageCheck[] | IContinuousMonitoringCoverageCheck[]
    >(() => {
        switch (checkerState.csvCheckType) {
            case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                return checkerState.continuousMonitoringCoverageChecks

            case CoverageCheckerCheckType.MEDICAID:
                return checkerState.medicaidChecks

            case CoverageCheckerCheckType.INTAKE:
                return []
        }
    }, [
        checkerState.csvCheckType,
        checkerState.continuousMonitoringCoverageChecks,
        checkerState.medicaidChecks,
    ])

    const resultsCount = useMemo<number>(() => {
        return (
            (coverageChecks as { result?: any | null }[]).filter(
                item => !!item?.result
            ).length || 0
        )
    }, [coverageChecks])

    const isValidInputRow = (
        row:
            | IContinuousMonitoringCoverageCheckInputData
            | IMedicaidCheckerInputData
    ): boolean => {
        return !row.validationErrors?.length
    }

    const getValidInputStateRows = () => {
        return coverageChecks
            .map(
                (
                    item:
                        | IMedicaidCoverageCheck
                        | IContinuousMonitoringCoverageCheck
                ) => item.inputData
            )
            .filter(isValidInputRow)
    }

    const validInputRowsCount = useMemo<number>(() => {
        return getValidInputStateRows().length
    }, [checkerState.inputDataSetAt])

    const performCalculationWithQuotaConfirmation = () => {
        if (!selectedPracticeUsageQuota) {
            return
        }

        CheckerHelper.runChecksWithQuotaConfirmation(
            selectedPracticeUsageQuota,
            performCalculation,
            checkerState.csvCheckType === CoverageCheckerCheckType.MEDICAID
                ? QuotaUsageType.MEDICAID
                : QuotaUsageType.GENERAL,
            getValidInputStateRows().length
        )
    }

    const performCalculation = () => {
        if (!selectedPracticeRole) {
            return
        }

        const onSuccess = () => {
            dispatch(
                checkerSetRunningState(CoverageCheckerRunningState.RESULTS_CSV)
            )
        }

        let triggered = false

        switch (checkerState.csvCheckType) {
            case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                dispatch(
                    checkerRunContinuousMonitoringChecks({
                        payload:
                            getValidInputStateRows() as IContinuousMonitoringCoverageCheckInputData[],
                        practice: selectedPracticeRole.practice,

                        onSuccess,
                    })
                )

                triggered = true

                break

            case CoverageCheckerCheckType.MEDICAID:
                dispatch(
                    checkerRunMedicaidChecks({
                        payload:
                            getValidInputStateRows() as IMedicaidCheckerInputData[],
                        practice: selectedPracticeRole.practice,

                        onSuccess,
                    })
                )

                triggered = true

                break

            case CoverageCheckerCheckType.INTAKE:
                ToastrHelper.error(
                    "Intake checker does not support bulk checks"
                )
        }

        // Add random part to the url, so we can safely catch browser back button then
        // And if back button is clicked from showing results -> it will initiate "run new checks"
        // Acts as history.push
        triggered &&
            navigate(
                `${ROUTES_CONFIG.coverageCheckerUrl}?${
                    GENERAL_CONFIG.urlSearchParamsKeys.results
                }=${new Date().getTime()}`
            )
    }

    const onRemoveCoverageInputData = (
        coverageInputData:
            | IContinuousMonitoringCoverageCheckInputData
            | IMedicaidCheckerInputData
    ) => {
        switch (checkerState.csvCheckType) {
            case CoverageCheckerCheckType.CONTINUOUS_MONITORING:
                dispatch(
                    checkerRemoveContinuousMonitoringInputData(
                        coverageInputData as IContinuousMonitoringCoverageCheckInputData
                    )
                )

                break

            case CoverageCheckerCheckType.MEDICAID:
                dispatch(
                    checkerRemoveMedicaidInputData(
                        coverageInputData as IMedicaidCheckerInputData
                    )
                )

                break

            case CoverageCheckerCheckType.INTAKE:
                ToastrHelper.error(
                    "Intake checker does not support bulk checks"
                )
        }
    }

    const tableHeaders = useMemo<JSX.Element>(() => {
        return (
            <div className="table-row w-full">
                <div
                    className="
                        flex items-center px-16px py-8px
                        bg-brand-white
                    "
                >
                    <div className="mr-16px flex-shrink-0 w-100px">
                        <PrimaryText
                            className="opacity-75 flex items-center"
                            typography="h6"
                        >
                            Row #
                        </PrimaryText>
                    </div>

                    {CSV_CHECKER_CONFIG.uploadedCsvPreviewMapping[
                        checkerState.csvCheckType
                    ].map((columnConfig, index) => {
                        return (
                            <div
                                key={index}
                                className="mr-16px flex-shrink-0 w-250px"
                            >
                                <PrimaryText
                                    className="opacity-75 flex items-center"
                                    typography="h6"
                                >
                                    {columnConfig.header}
                                </PrimaryText>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }, [checkerState.csvCheckType])

    return (
        <div
            className={`
                ${props.className}
                relative
            `}
        >
            {[
                CoverageCheckerRunningState.INPUT_CSV,
                CoverageCheckerRunningState.PROCESS_CSV,
            ].includes(checkerState.runningState) ? (
                <UploadCsvComponent className="mt-24px" />
            ) : null}

            {resultsCount ? (
                <>
                    <ResultsQuickViewComponent
                        checkType={checkerState.csvCheckType}
                        className="mt-50px ml--45px mr--45px sm:ml--16px sm:mr--16px"
                    />

                    {coverageChecks.length > resultsCount ? (
                        <div
                            className="
                                mt-44px flex flex-col items-center justify-center px-16px py-24px
                                rounded-8px bg-brand-warmLight025
                            "
                        >
                            <PrimaryText typography="h5" centered>
                                Please note that it takes approximately 30
                                minutes to run 1000 checks. Please keep your tab
                                open until the checks are complete.
                            </PrimaryText>
                        </div>
                    ) : null}
                </>
            ) : coverageChecks.length &&
              checkerState.runningState ===
                  CoverageCheckerRunningState.PREVIEW_CSV ? (
                <>
                    <OrganizationQuoteComponent className="max-w-800px mt-24px" />

                    <ScreenScrollableTableElement
                        className="mt-32px"
                        headers={tableHeaders}
                    >
                        <ViewportList<
                            | IMedicaidCoverageCheck
                            | IContinuousMonitoringCoverageCheck
                        >
                            items={coverageChecks}
                        >
                            {(item, index) => (
                                <SingleCsvCoverageCheckComponent
                                    key={index}
                                    rowIndex={index}
                                    coverageCheck={item}
                                    onInputDataRemove={
                                        onRemoveCoverageInputData
                                    }
                                />
                            )}
                        </ViewportList>
                    </ScreenScrollableTableElement>

                    {/*RUN COVERAGE CHECKS BTN*/}
                    {/*SHOW when no results at all*/}
                    {!resultsCount &&
                    validInputRowsCount &&
                    !props.isLoading ? (
                        <PageFloatingButton
                            className="bottom-60px!"
                            zIndex={40}
                            label={`Run ${validInputRowsCount} Check${
                                validInputRowsCount > 1 ? "s" : ""
                            }`}
                            icon={calculatorIcon}
                            onClick={performCalculationWithQuotaConfirmation}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    )
}
