import {
    BrowserStorageHelper,
    BrowserStorageType,
    UtilHelper,
} from "nirvana-react-elements"
import { NavigateFunction } from "react-router-dom"
import moment from "moment-timezone"
import {
    confirmSignIn,
    confirmUserAttribute,
    deleteUserAttributes,
    fetchDevices,
    fetchMFAPreference,
    fetchUserAttributes,
    forgetDevice,
    rememberDevice,
    resendSignUpCode,
    sendUserAttributeVerificationCode,
    signIn,
    signOut,
    updateMFAPreference,
    updateUserAttribute,
} from "aws-amplify/auth"

import { API_ROUTES, ROUTES_CONFIG } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { GENERAL_CONFIG } from "../config/general.config"
import { AuthHelper } from "../helpers/auth.helper"
import { ToastrHelper } from "../helpers/toastr.helper"
import { AUTH_CONFIG, LoginStep } from "../config/auth.config"

export class AuthService {
    /**
     * Login user to app - using Amplify sdk
     */
    static async login(
        data: ILoginData,
        navigate?: NavigateFunction,
        withLogout = true
    ): Promise<{
        result: boolean
        newLoginStep: LoginStep | null
        ignoreOnSuccessCallback: boolean
        ignoreOnErrorCallback: boolean
        mfaChallengePhoneNumber: string | null
    }> {
        let result = false
        let newLoginStep: LoginStep | null = null
        let ignoreOnSuccessCallback = false
        let ignoreOnErrorCallback = false
        let mfaChallengePhoneNumber: string | null = null

        const onMFASetupRequired = () => {
            // Need to collect user's phone number and enable mfa for account
            newLoginStep = LoginStep.MFA_PHONE_COLLECTION
            ignoreOnSuccessCallback = true
            ignoreOnErrorCallback = true
        }

        const onUserSignInDone = async () => {
            const needMFASetup = await AuthService.checkMFASetupNeeded()

            if (needMFASetup) {
                onMFASetupRequired()
            } else {
                result = await AuthHelper.processUserSuccessLoggedIn(navigate)
            }
        }

        // Logout just in case, controlled by argument
        // In new login flow -> need to logout
        // In resend MFA code flow -> no need
        try {
            withLogout && (await signOut())
        } catch (e) {}

        try {
            const singInResult = await signIn({
                username: data.email,
                password: data.password,
            })

            switch (singInResult.nextStep?.signInStep) {
                case "CONFIRM_SIGN_UP":
                    await resendSignUpCode({
                        username: data.email,
                    })

                    // Redirect to register success page with needed messaging
                    // setTimeout so it's processed in next tick
                    setTimeout(() => {
                        UtilHelper.redirectTo(
                            ROUTES_CONFIG.registerSuccessUrl +
                                `?email=${data.email}`,
                            navigate
                        )
                    })

                    ignoreOnSuccessCallback = true
                    ignoreOnErrorCallback = true

                    break

                case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
                    // Need to collect user's code from sms and proceed with MFA challenge
                    newLoginStep = LoginStep.MFA_CODE_COLLECTION
                    ignoreOnSuccessCallback = true
                    ignoreOnErrorCallback = true

                    mfaChallengePhoneNumber =
                        singInResult.nextStep.codeDeliveryDetails
                            ?.destination || null

                    break

                case "DONE":
                    const isUserDeviceExpired =
                        await AuthService.checkRememberedDevicesExpired()

                    // If some device expired -> we need to init login flow again
                    if (isUserDeviceExpired) {
                        return await AuthService.login(
                            data,
                            navigate,
                            withLogout
                        )
                    }

                    await onUserSignInDone()

                    break
            }
        } catch (e: any) {
            switch (e.name) {
                case "UserAlreadyAuthenticatedException":
                    await onUserSignInDone()

                    break

                // Don't show toast if wrong creds, it's handled by form
                case "NotAuthorizedException":
                    break

                case "LimitExceededException":
                    ToastrHelper.error(e.message)
                    ignoreOnErrorCallback = true

                    break

                case "MFAMethodNotFoundException":
                    onMFASetupRequired()

                    break

                default:
                    ToastrHelper.error(e.message)
            }
        }

        return {
            result,
            newLoginStep,
            ignoreOnSuccessCallback,
            ignoreOnErrorCallback,
            mfaChallengePhoneNumber,
        }
    }

    /**
     * Update phone number of a user - using Amplify
     * User needs to be authenticated with Amplify
     */
    static async updatePhoneNumber(phoneNumber: string): Promise<boolean> {
        try {
            await updateUserAttribute({
                userAttribute: {
                    attributeKey: "phone_number",
                    value: phoneNumber,
                },
            })

            await sendUserAttributeVerificationCode({
                userAttributeKey: "phone_number",
            })

            return true
        } catch (e: any) {
            switch (e.name) {
                case "InvalidParameterException":
                    ToastrHelper.error(
                        "Please provide valid phone number. It should be in international format. Example: +1234567890"
                    )

                    return false
            }

            console.error(e)

            ToastrHelper.error(
                "Failed to update phone number. Please try again or contact support"
            )

            return false
        }
    }

    /**
     * Process MFA code - using Amplify
     * Null means that auto re-login is needed
     */
    static async processMFACode(
        data: IProcessMFACodeData,
        onSessionExpired?: () => void
    ): Promise<boolean | null> {
        try {
            // User is considered logged in Amplify if in MFA setup flow
            const isSetupFlow = await AuthService.isLoggedIn()

            if (isSetupFlow) {
                await confirmUserAttribute({
                    userAttributeKey: "phone_number",
                    confirmationCode: data.code,
                })

                // We have only sms for now
                await updateMFAPreference({
                    sms: "PREFERRED",
                })
            } else {
                await confirmSignIn({
                    challengeResponse: data.code,
                })

                // here need to check browser storage and set phone number to null in cognito if force reset MFA
                // Then trigger login action again
                // It will auto login again in password.step component and will show user MFA setup form

                const needForceResetMFA = BrowserStorageHelper.get(
                    GENERAL_CONFIG.browserStorageKeys.authForceResetMFA,
                    undefined,
                    BrowserStorageType.sessionStorage
                )

                BrowserStorageHelper.remove(
                    GENERAL_CONFIG.browserStorageKeys.authForceResetMFA,
                    BrowserStorageType.sessionStorage
                )

                if (needForceResetMFA && data.withLoggingIn) {
                    // Disable MFA for user -> it's required for removing phone number from user
                    await updateMFAPreference({
                        sms: "DISABLED",
                    })

                    // Remove current phone number from user
                    await deleteUserAttributes({
                        userAttributeKeys: ["phone_number"],
                    })

                    return null
                }

                if (data.rememberDevice) {
                    // Remembering device fail shouldn't fail MFA process
                    try {
                        await rememberDevice()
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            return data.withLoggingIn
                ? await AuthHelper.processUserSuccessLoggedIn()
                : true
        } catch (e: any) {
            switch (e.name) {
                case "CodeMismatchException":
                    ToastrHelper.error(
                        "Invalid code provided. Please try again"
                    )

                    break

                case "NotAuthorizedException":
                    ToastrHelper.error(
                        "Your session has expired. Please start the flow from the beginning"
                    )

                    onSessionExpired?.()

                    break

                default:
                    console.error(e)

                    ToastrHelper.error(
                        "Failed to process verification code. Please try again or contact support"
                    )
            }

            return false
        }
    }

    /**
     * Resend MFA code - using Amplify
     * For not authenticated users (when doing challenge during login) -> will hit login endpoint again, that will trigger resend
     * For authenticated users (when doing setup) -> will trigger directly
     */
    static async resendMfaCode(loginData: ILoginData | null): Promise<boolean> {
        try {
            // User is considered logged in Amplify if in MFA setup flow
            const isSetupFlow = await AuthService.isLoggedIn()

            if (isSetupFlow) {
                await sendUserAttributeVerificationCode({
                    userAttributeKey: "phone_number",
                })
            } else {
                if (!loginData) {
                    return false
                }

                await AuthService.login(loginData, undefined, false)
            }

            return true
        } catch (e: any) {
            return false
        }
    }

    /**
     * Register user in application - using backend
     */
    static async register(data: IRegisterData): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_REGISTER,
            {
                ...(data.emailData
                    ? {
                          email: data.emailData.email,
                      }
                    : {}),
                ...(data.passwordData || {}),

                inviteToken: data.inviteToken,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Initiate forgot password flow - using backend
     */
    static async forgotPassword(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_FORGOT_PASSWORD,
            {
                email,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Process forgot password flow - using backend
     */
    static async forgotPasswordProcess(
        data: IPasswordRestoreFinishData
    ): Promise<{ forgotPasswordFlowDone: boolean } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_FORGOT_PASSWORD_PROCESS,
            data,
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Check if email is available - using backend
     */
    static async emailAvailable(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_EMAIL_AVAILABLE,
            {
                email,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Resend verification link to just registered user - using backend
     */
    static async resendVerificationLink(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_RESEND_VERIFY_RESEND,
            {
                email,
            },
            "post"
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Confirm user by email and verification code in Cognito - using backend
     */
    static async confirmUser(
        email: string,
        confirmationCode: string
    ): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_CONFIRM_USER,
            {
                email,
                confirmationCode,
            },
            "post",
            undefined,
            undefined,
            GENERAL_CONFIG.extendedToastrTimeout
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Check invite token of a user - using backend
     */
    static async checkInviteToken(
        inviteToken: string
    ): Promise<IOrganizationUserInvite | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.AUTH_CHECK_INVITE_TOKEN.replace(
                ":inviteToken",
                inviteToken
            )
        )

        return !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Check if user's remembered devices expired
     * We need to implement custom logic for this (based on our rules of expiration), since Cognito doesn't support it out of the bog
     * And then if expired -> we'll forget it and init login flow again
     */
    static async checkRememberedDevicesExpired(
        forceExpireAll = false
    ): Promise<boolean> {
        try {
            if (!AuthService.isMFAActivatedForEnvironment()) {
                return false
            }

            // WIll fail if user is not logged in
            const devices = await fetchDevices()

            let hasExpiredDevices = false

            for (const device of devices) {
                const isExpired = forceExpireAll
                    ? true
                    : device.createDate
                    ? moment(device.createDate)
                          .add(
                              AUTH_CONFIG.rememberedDeviceExpirationDays,
                              "days"
                          )
                          .toDate()
                          .getTime() < new Date().getTime()
                    : false

                if (!isExpired) {
                    continue
                }

                hasExpiredDevices = true

                await forgetDevice({
                    device,
                })
            }

            return hasExpiredDevices
        } catch (e) {
            return false
        }
    }

    /**
     * Check if user needs MFA setup
     * We handle this requirement on the code level
     * Since to handle it on the Cognito level we need to require phone number during sign up
     * There's no way easily update it later if user is not authenticated
     * And in order to authenticate, MFA is required (phone number), so it's going in circles
     */
    static async checkMFASetupNeeded(): Promise<boolean> {
        try {
            if (!AuthService.isMFAActivatedForEnvironment()) {
                return false
            }

            const mfaPreferences = await fetchMFAPreference()
            const userAttributes = await fetchUserAttributes()

            // Just check phone number since we require it for MFA for now
            // In the future change logic if also auth app will be available

            // This will be only set after user confirms phone number
            return (
                !mfaPreferences.preferred ||
                !userAttributes.phone_number ||
                !userAttributes.phone_number_verified
            )
        } catch (e) {
            return true
        }
    }

    /**
     * Check if user is logged in
     */
    private static async isLoggedIn(): Promise<boolean> {
        try {
            // WIll fail if user is not logged in
            await fetchUserAttributes()

            return true
        } catch (e) {
            return false
        }
    }

    /**
     * Check if MFA is activated for environment
     */
    static isMFAActivatedForEnvironment(): boolean {
        return GENERAL_CONFIG.isProduction
    }
}
