import { NavigateFunction } from "react-router-dom"
import {
    fetchAuthSession,
    fetchUserAttributes,
    signOut,
} from "aws-amplify/auth"
import {
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../config/routes.config"
import { GENERAL_CONFIG } from "../config/general.config"
import { SocketsHelper } from "./sockets.helper"
import { authReset } from "../store/slices/auth.slice"
import { userDetailsReset } from "../store/slices/userDetails.slice"
import { coveragePayersReset } from "../store/slices/coveragePayers.slice"
import { organizationReset } from "../store/slices/organization.slice"
import { billerGroupsReset } from "../store/slices/billerGroups.slice"
import { checkerReset } from "../store/slices/checker.slice"
import { policiesReset } from "../store/slices/policies.slice"
import { coverageOverridesReset } from "../store/slices/coverageOverrides.slice"
import { runtimeReset } from "../store/slices/runtime.slice"
import { supportReset } from "../store/slices/support.slice"
import { ToastrHelper } from "./toastr.helper"
import { UtilHelper } from "./util.helper"
import { PoliciesListType } from "../config/policies.config"
import { AuthService } from "../services/auth.service"

export class AuthHelper {
    /**
     * Check if user is logged in
     */
    static async isLoggedIn(): Promise<boolean> {
        return (
            !!(await AuthHelper.getToken()) &&
            !(await AuthService.checkMFASetupNeeded())
        )
    }

    /**
     * Get token from localStorage
     */
    static async getToken(): Promise<string | undefined> {
        try {
            return (await fetchAuthSession()).tokens?.accessToken.toString()
        } catch (e) {
            return undefined
        }
    }

    /**
     * Process user after successful login
     */
    static async processUserSuccessLoggedIn(
        navigate?: NavigateFunction
    ): Promise<boolean> {
        try {
            const jwtToken = await AuthHelper.getToken()

            if (!jwtToken) {
                ToastrHelper.error("Could not authenticate user")

                return false
            }

            // Handle redirectTo from url query
            UtilHelper.redirectTo(
                UtilHelper.getRawUrlSearchParam(
                    GENERAL_CONFIG.urlSearchParamsKeys.redirectTo
                ) || ROUTES_CONFIG.baseApplicationUrl,
                navigate
            )

            setTimeout(() => {
                UtilHelper.checkAppVersionUpdateNeeded()
            })

            return true
        } catch (e) {
            return false
        }
    }

    /**
     * Logout user
     */
    static logout(
        redirectUrl: string = ROUTES_CONFIG.loginUrl,
        navigate?: NavigateFunction,
        needForceResetMFA = false
    ) {
        signOut()
            .then(() => {
                try {
                    sessionStorage.clear()

                    // Since we are clearing all session storage, we need to keep force MFA reset variable if it's set
                    if (needForceResetMFA) {
                        BrowserStorageHelper.set(
                            GENERAL_CONFIG.browserStorageKeys.authForceResetMFA,
                            true,
                            BrowserStorageType.sessionStorage
                        )
                    }
                } catch (e) {}

                // Clear saved filters in local storage
                try {
                    Object.values(PoliciesListType).forEach(viewType => {
                        BrowserStorageHelper.remove(
                            viewType +
                                GENERAL_CONFIG.browserStorageKeys
                                    .policiesFiltersConfigurationSuffix,
                            BrowserStorageType.localStorage
                        )
                    })
                } catch (e) {}

                AuthHelper.dispatch(authReset())
                AuthHelper.dispatch(billerGroupsReset())
                AuthHelper.dispatch(checkerReset())
                AuthHelper.dispatch(coverageOverridesReset())
                AuthHelper.dispatch(coveragePayersReset())
                AuthHelper.dispatch(organizationReset())
                AuthHelper.dispatch(policiesReset())
                AuthHelper.dispatch(runtimeReset())
                AuthHelper.dispatch(supportReset())
                AuthHelper.dispatch(userDetailsReset())

                AuthHelper.removeEagleEyeViewAsData(false)

                // Clear socket.io connection
                const socketInstance = SocketsHelper.getInstance()
                socketInstance.disconnect(true)

                UtilHelper.redirectTo(redirectUrl, navigate)
            })
            .catch(e => {
                ToastrHelper.error(e.message)
            })
    }

    /**
     * Set view as data for Eagle Eye functionality
     * that allows to view as any other user and view any practice that that user has access to
     */
    static setEagleEyeViewAsData(
        data: IEagleEyeViewAsConfig,
        withReload = true
    ) {
        BrowserStorageHelper.set(
            GENERAL_CONFIG.browserStorageKeys.adminViewAsKey,
            data,
            BrowserStorageType.localStorage
        )

        if (withReload) {
            window.location.reload()
        }
    }

    /**
     * Get view as data for Eagle Eye functionality
     */
    static getEagleEyeViewAsData(): IEagleEyeViewAsConfig | null {
        return BrowserStorageHelper.get(
            GENERAL_CONFIG.browserStorageKeys.adminViewAsKey,
            null,
            BrowserStorageType.localStorage
        )
    }

    /**
     * Delete view as data for Eagle Eye functionality
     */
    static removeEagleEyeViewAsData(withReload = true) {
        BrowserStorageHelper.remove(
            GENERAL_CONFIG.browserStorageKeys.adminViewAsKey,
            BrowserStorageType.localStorage
        )

        if (withReload) {
            window.location.reload()
        }
    }

    /**
     * Get phone number that is used for MFA
     */
    static async getMFAPhoneNumber(): Promise<string | null> {
        try {
            const attributes = await fetchUserAttributes()

            return attributes.phone_number_verified === "true"
                ? attributes.phone_number || null
                : null
        } catch (e) {
            return null
        }
    }

    /**
     * Dispatch some action to store
     */
    private static dispatch(action: any) {
        const dispatch = require("../store/store").store.dispatch

        dispatch(action)
    }
}
